import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { formatMessage } from '../../../utils/formatMessage';
import { ValidLicenceInterval } from './playlistError';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
export const timeRangeMessages = {
  // licence - obecné
  licenseErrorDefaultTitle: {
    id: 'LicenseError.PastDate.title',
    defaultMessage: 'Je nám líto, video nelze přehrát',
    description: 'Titulek zprávy pro error při licenci která je mimo platnost',
  },
  licenseErrorDefaultSubtitle: {
    id: 'LicenseError.PastDate.subtitle',
    defaultMessage: 'Nemáme pro ně potřebnou licenci.',
    description: 'Podtitulek zprávy pro error při licenci která je mimo platnost',
  },
  // datum - hlášky vázané na konkrétní čas
  licenseErrorDateTitle: {
    id: 'LicenseError.Date.title',
    defaultMessage: 'Video bude dostupné',
    description: 'Titulek zprávy pro error při licenci která vejde v platnost',
  },

  licenseErrorDateShortTitle: {
    id: 'LicenseError.Date.shorttitle',
    defaultMessage: 'Dostupné',
    description: 'Titulek zprávy pro error při licenci která vejde v platnost',
  },

  licenseErrorDateSubtitle: {
    id: 'LicenseError.Date.subtitle',
    defaultMessage: 'Momentálně je nelze přehrát kvůli licenčnímu omezení.',
    description: 'Podtitulek zprávy pro error při licenci která vejde v platnost',
  },
};
const getLicenceTimeRangeError = (validLicenceInterval?: ValidLicenceInterval) => {
  const now = dayjs();

  // pokud nedorazí data o platnosti licence, vrátí obecnou hlášku
  if (!validLicenceInterval) {
    return {
      title: formatMessage(timeRangeMessages.licenseErrorDefaultTitle),
      subtitle: formatMessage(timeRangeMessages.licenseErrorDefaultSubtitle),
    };
  }

  const getFormattedLicenseDateFrom = () => {
    if (dayjs(validLicenceInterval.validFrom).isToday()) {
      return 'dnes';
    }
    if (dayjs(validLicenceInterval.validFrom).isTomorrow()) {
      return 'zítra';
    }
    return dayjs(validLicenceInterval.validFrom).format('DD. MM.');
  };

  const licenseTimeFrom = dayjs(validLicenceInterval.validFrom).format('H:mm');
  const licenseTimePast = dayjs(validLicenceInterval.validTo).isBefore(now);
  const LicenseTimeFuture = now.add(7, 'days').isBefore(dayjs(validLicenceInterval.validFrom));

  // licence platila v minulosti nebo platí více než 7 dní v budoucnosti
  if (LicenseTimeFuture || licenseTimePast) {
    return {
      title: formatMessage(timeRangeMessages.licenseErrorDefaultTitle),
      subtitle: formatMessage(timeRangeMessages.licenseErrorDefaultSubtitle),
    };
  }

  // licence bude platná v následujícíh 7mi dnech
  return {
    title: `${formatMessage(
      timeRangeMessages.licenseErrorDateTitle
    )} ${getFormattedLicenseDateFrom()} od ${licenseTimeFrom}`,
    shortTitle: `${formatMessage(
      timeRangeMessages.licenseErrorDateShortTitle
    )} ${getFormattedLicenseDateFrom()} od ${licenseTimeFrom}`,
    subtitle: formatMessage(timeRangeMessages.licenseErrorDateSubtitle),
  };
};

export default getLicenceTimeRangeError;
