import React, {
  ComponentType,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useContextWithProvider } from '@czechtv/components';
import { AnalyticsClient, AnalyticsPlayerContext } from '@czechtv/analytics';
import { useAnalytics } from '@czechtv/analytics-react';
import { usePlayerSetup } from '../Setup/usePlayerSetup';
import { PlayerNormalizedError } from '../../components/Error/playerError';

export interface PlayerAnalyticsValues {
  client: AnalyticsClient<AnalyticsPlayerContext>;
  onAdaptiveResolutionChange: () => void;
  onBuffering: () => void;
  onEnd: (snapshot?: AnalyticsPlayerContext) => void;
  onError: (error: PlayerNormalizedError) => void;
  onIndexStartBar: () => void;
  onIndexStartList: () => void;
  onNextAutoCancel: () => void;
  onPlay: ({ interaction }: { interaction: boolean }) => void;
  onProgress: () => void;
  onReplay: () => void;
  onSeekBack: () => void;
  onSeekBackButtonPressed: (isTimeshift: boolean) => void;
  onSeekForward: () => void;
  onSeekForwardButtonPressed: (isTimeshift: boolean) => void;
  onShare: () => void;
  onTimeshiftBackToLive: () => void;
  onTimeshiftSeekBack: () => void;
  onTimeshiftSeekForward: () => void;
  onTimeshiftStart: () => void;
  onTimeshiftStartButtonPressed: () => void;
  setSeekedRecently: () => void;
}

export interface PlayerAnalyticsProviderProps {
  children: React.ReactNode;
  client?: AnalyticsClient<AnalyticsPlayerContext>;
  timeshiftGapDuration?: number;
}

export const PlayerAnalytics = createContext<PlayerAnalyticsValues | undefined>(undefined);

PlayerAnalytics.displayName = 'PlayerAnalytics';

export const usePlayerAnalytics = () => useContextWithProvider(PlayerAnalytics);

export const PlayerAnalyticsProvider = ({
  client: defaultClient,
  children,
  timeshiftGapDuration,
}: PlayerAnalyticsProviderProps) => {
  const client = useAnalytics<AnalyticsPlayerContext>();
  const { playerClient, playerWrapperRef } = usePlayerSetup();
  const [seekedRecently, setSeekedRecently] = useState(false);
  const isMounted = useRef(false);
  const contextOverride = useRef<AnalyticsPlayerContext | null>(null);

  const analyticsClient = defaultClient || client;

  useEffect(() => {
    // před triggerem eventy si načtu aktuální stav playeru do contextu
    analyticsClient.setPreAction(() => {
      const context = analyticsClient.getContext();
      if (context && !contextOverride.current) {
        analyticsClient.setContext({
          ...context,
          ...(context.type === 'VOD' ? { duration: playerClient.getDuration() } : {}),
          position: playerClient.getCurrentTime(),
          width: playerWrapperRef.current?.offsetWidth,
          playtime: playerClient.getPlayedTime(),
          bitrate: playerClient.getBitrate(),
          playbackRate: playerClient.getPlaybackRate(),
          ...(context.type === 'LIVE'
            ? { broadcastDate: playerClient.getLiveBroadcastDate(timeshiftGapDuration) }
            : {}),
        });
      }
      // potrebujeme v pripade dohrani prerollu pretizit context na posledni znamy,
      // ktery se vaze k hlavnimu obsahu
      if (contextOverride.current) {
        analyticsClient.setContext(contextOverride.current);
      }
    });

    return () => {
      analyticsClient.setPreAction(null);
    };
  }, [analyticsClient, playerClient, playerWrapperRef, timeshiftGapDuration]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setSeekedRecentlyCallback = useCallback(() => {
    setSeekedRecently(true);
    setTimeout(() => {
      if (isMounted.current) {
        setSeekedRecently(false);
      }
    }, 1000);
  }, []);

  const onNextAutoCancel = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerNextAutoCancel' });
  }, [analyticsClient]);

  const onShare = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerShare' });
  }, [analyticsClient]);

  const onEnd = useCallback(
    (snapshot?: AnalyticsPlayerContext) => {
      if (snapshot) {
        contextOverride.current = snapshot;
      }
      analyticsClient.trigger({ type: 'PlayerEnd' });
      contextOverride.current = null;
    },
    [analyticsClient]
  );

  const onReplay = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerReplay' });
  }, [analyticsClient]);

  const onTimeshiftSeekBack = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerTimeshiftSeekBack' });
  }, [analyticsClient]);

  const onTimeshiftSeekForward = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerTimeshiftSeekForward' });
  }, [analyticsClient]);

  const onTimeshiftBackToLive = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerTimeshiftBackToLive' });
  }, [analyticsClient]);

  const onTimeshiftStart = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerTimeshiftStart' });
  }, [analyticsClient]);

  const onTimeshiftStartButtonPressed = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerTimeshiftFromBeginning' });
  }, [analyticsClient]);

  const onSeekForward = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerSeekForward' });
  }, [analyticsClient]);

  const onSeekBack = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerSeekBack' });
  }, [analyticsClient]);

  const onSeekForwardButtonPressed = useCallback(
    (isTimeshift: boolean) => {
      if (isTimeshift) {
        analyticsClient.trigger({ type: 'PlayerTimeshift10sForward' });
      } else {
        analyticsClient.trigger({ type: 'Player10sForward' });
      }
    },
    [analyticsClient]
  );

  const onSeekBackButtonPressed = useCallback(
    (isTimeshift: boolean) => {
      if (isTimeshift) {
        analyticsClient.trigger({ type: 'PlayerTimeshift10sBack' });
      } else {
        analyticsClient.trigger({ type: 'Player10sBack' });
      }
    },
    [analyticsClient]
  );

  const onPlay = useCallback(
    ({ interaction }: { interaction: boolean }) => {
      analyticsClient.trigger({ type: 'PlayerPlay', data: { interaction } });
    },
    [analyticsClient]
  );

  const onIndexStartBar = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerIndexStartBar' });
  }, [analyticsClient]);

  const onIndexStartList = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerIndexStartList' });
  }, [analyticsClient]);

  const onBuffering = useCallback(() => {
    if (!seekedRecently && playerClient.getCurrentTime() !== 0) {
      analyticsClient.trigger({ type: 'PlayerBuffering' });
    }
  }, [analyticsClient, playerClient, seekedRecently]);

  const onAdaptiveResolutionChange = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerBitrateAdaptation' });
  }, [analyticsClient]);

  const onProgress = useCallback(() => {
    analyticsClient.trigger({ type: 'PlayerProgress' });
  }, [analyticsClient]);

  const onError = useCallback(
    (error: PlayerNormalizedError) => {
      analyticsClient.trigger({
        type: 'PlayerError',
        data: {
          error: `type: video, category: ${error.category}, code: ${error.code}, message: ${error.message}}`,
        },
      });
    },
    [analyticsClient]
  );

  const contextValue = useMemo<PlayerAnalyticsValues>(
    () => ({
      client: analyticsClient,
      onAdaptiveResolutionChange,
      onBuffering,
      onEnd,
      onError,
      onIndexStartBar,
      onIndexStartList,
      onNextAutoCancel,
      onPlay,
      onProgress,
      onReplay,
      onSeekBack,
      onSeekBackButtonPressed,
      onSeekForward,
      onSeekForwardButtonPressed,
      onShare,
      onTimeshiftBackToLive,
      onTimeshiftSeekBack,
      onTimeshiftSeekForward,
      onTimeshiftStart,
      onTimeshiftStartButtonPressed,
      setSeekedRecently: setSeekedRecentlyCallback,
    }),
    [
      analyticsClient,
      onAdaptiveResolutionChange,
      onBuffering,
      onEnd,
      onError,
      onIndexStartBar,
      onIndexStartList,
      onNextAutoCancel,
      onPlay,
      onProgress,
      onReplay,
      onSeekBack,
      onSeekBackButtonPressed,
      onSeekForward,
      onSeekForwardButtonPressed,
      onShare,
      onTimeshiftBackToLive,
      onTimeshiftSeekBack,
      onTimeshiftSeekForward,
      onTimeshiftStart,
      onTimeshiftStartButtonPressed,
      setSeekedRecentlyCallback,
    ]
  );

  return <PlayerAnalytics.Provider value={contextValue}>{children}</PlayerAnalytics.Provider>;
};

type ComponentWithPlayerAnalytics<P> = Omit<P, 'playerAnalytics'>;

// HOC s analytickym kontextem pro prehravac pro pouziti v classscomponente Player
export const withPlayerAnalytics = <P,>(
  WrappedComponent: ComponentType<P>
): ComponentType<ComponentWithPlayerAnalytics<P>> => {
  return (props: ComponentWithPlayerAnalytics<P>) => {
    return (
      <PlayerAnalytics.Consumer>
        {(playerAnalytics) => {
          if (playerAnalytics === undefined) {
            throw new Error('playerAnalytics must be used within a PlayerAnalytics');
          }
          return <WrappedComponent {...(props as P)} playerAnalytics={playerAnalytics} />;
        }}
      </PlayerAnalytics.Consumer>
    );
  };
};

export default PlayerAnalyticsProvider;
