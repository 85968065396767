import classNames from 'classnames';
import React, { memo, SyntheticEvent } from 'react';
import { useFocusVisibleClassName } from '@czechtv/components';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { formatMessage } from '../../../../utils/formatMessage';
import { liveButtonClassnames } from './LiveButton.css';

interface LiveButtonProps {
  className?: string;
  live?: boolean;
  onClick?: (event: SyntheticEvent<HTMLElement>) => void;
}

export const LiveButton = memo(({ className, onClick, live = false }: LiveButtonProps) => {
  const focusVisibleClassName = useFocusVisibleClassName();

  const liveText = formatMessage({
    id: 'LiveButton.text',
    defaultMessage: 'ŽIVĚ',
    description: 'Text tlačítka ŽIVĚ',
  });

  const liveButtonClassName = classNames(
    liveButtonClassnames.liveButton,
    className,
    focusVisibleClassName
  );

  if (live) {
    return (
      <div className={liveButtonClassName} data-testid="LiveText">
        <div className={liveButtonClassnames.dot} data-testid="LiveButtonDot" />
        {liveText}
      </div>
    );
  }

  return (
    <PlayerNativeButton
      className={liveButtonClassName}
      data-testid="LiveButton"
      title={formatMessage({
        id: 'LiveButton.title',
        defaultMessage: 'Přejít na živé vysílání',
        description: 'Titulek tlačítka ŽIVĚ',
      })}
      onClick={onClick}
    >
      {liveText}
    </PlayerNativeButton>
  );
});
