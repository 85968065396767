import React, { RefObject, useEffect, useState } from 'react';
import { IconPause, IconPlay, IconRefresh } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import PlayerClientState from '../../../../Providers/Client/state';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../../items';
import { usePlayerContext } from '../../../PlayerContext';
import { ChromecastState } from '../../../../utils/chromecast/useChromecast';

interface PlayPauseReplayProps {
  className?: string;
  onPauseButtonPressed: () => void;
  onPlayButtonPressed: () => void;
  onReplayButtonPressed: () => void;
  playerState: PlayerClientState;
  videoRef?: RefObject<HTMLVideoElement>;
}

export const PlayPauseReplay = ({
  className,
  playerState,
  videoRef,
  onPlayButtonPressed,
  onPauseButtonPressed,
  onReplayButtonPressed,
}: PlayPauseReplayProps) => {
  const [showPause, setShowPause] = useState(playerState === PlayerClientState.PAUSED);
  const context = usePlayerContext();

  const { chromecastState, activeChromecastDevice } = context;

  useEffect(() => {
    if (activeChromecastDevice) {
      if (chromecastState === ChromecastState.PLAYING) {
        setShowPause(true);
      } else {
        setShowPause(false);
      }
      return;
    }

    if (
      (playerState === PlayerClientState.PLAYING || playerState === PlayerClientState.BUFFERING) &&
      !videoRef?.current?.paused
    ) {
      setShowPause(true);
    }

    if (
      (playerState === PlayerClientState.PAUSED || playerState === PlayerClientState.BUFFERING) &&
      videoRef?.current?.paused
    ) {
      setShowPause(false);
    }
  }, [activeChromecastDevice, chromecastState, playerState, videoRef]);

  if (playerState === PlayerClientState.FINISHED) {
    return (
      <PlayerNativeButton
        className={className}
        data-testid="playerReplayIcon"
        title={VODPlayerControlsItemsDefinition[PlayerControlsItem.REPLAY]}
        onClick={onReplayButtonPressed}
      >
        <IconRefresh tabIndex={-1} />
      </PlayerNativeButton>
    );
  }
  if (showPause) {
    return (
      <PlayerNativeButton
        className={className}
        data-testid="playerPauseIcon"
        title={VODPlayerControlsItemsDefinition[PlayerControlsItem.PAUSE]}
        onClick={onPauseButtonPressed}
      >
        <IconPause tabIndex={-1} />
      </PlayerNativeButton>
    );
  }
  return (
    <PlayerNativeButton
      className={className}
      data-testid="playerPlayIcon"
      title={VODPlayerControlsItemsDefinition[PlayerControlsItem.PLAY]}
      onClick={onPlayButtonPressed}
    >
      <IconPlay tabIndex={-1} />
    </PlayerNativeButton>
  );
};
