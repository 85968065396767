import React, { memo } from 'react';
import classNames from 'classnames';
import { Spinner } from '@czechtv/components';
import { ScreenMode } from '../../../../constants';
import GradientOverlay from '../GradientOverlay/GradientOverlay';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { baseLoadingOverlayClassnames } from './BaseLoadingOverlay.css';

export interface BaseLoadingOverlayProps {
  defaultMessage: string;
  screenMode?: ScreenMode;
  showProgress?: boolean;
}

const BaseLoadingOverlay = ({
  screenMode = ScreenMode.NORMAL,
  showProgress = true,
  defaultMessage,
}: BaseLoadingOverlayProps) => {
  const position = screenMode === ScreenMode.NORMAL ? 'absolute' : 'fixed';

  return (
    <GradientOverlay
      className={baseLoadingOverlayClassnames.loadingOverlay}
      style={{ position: position }}
    >
      <div className={baseLoadingOverlayClassnames.positioner}>
        <div aria-live="polite" className={baseLoadingOverlayClassnames.loadingWrapper}>
          <Spinner aria-hidden className={baseLoadingOverlayClassnames.spinner} />
          <div
            className={baseLoadingOverlayClassnames.loadingText}
            data-testid="VODLoadingOverlay-Message"
          >
            {defaultMessage}
          </div>
        </div>
      </div>
      <ProgressBar
        isLoading
        className={classNames(
          baseLoadingOverlayClassnames.progressBar,
          !showProgress ? 'hide' : ''
        )}
      />
    </GradientOverlay>
  );
};

export default memo(BaseLoadingOverlay);
