/* eslint-disable canonical/filename-no-index */
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { IconHelpCircle, IconRefresh } from '@czechtv/icons';
import { AspectRatioPlaceholder, Button } from '@czechtv/components';
import { AnalyticsContext } from '@czechtv/analytics-react';
import GradientOverlay from '../../../Player/Overlay/VOD/GradientOverlay/GradientOverlay';
import { useMediaBreakpoints } from '../../../utils/playerResponsive';
import PlayerBasedResponsive from '../../PlayerBasedResponsive/PlayerBasedResponsive';
import { BUTTON_SEPARATOR } from '../../../constants';
import { supportWebsite, useReportErrorUrl } from '../../../utils/supportUrls';
import { Link } from '../../Link';
import { formatMessage } from '../../../utils/formatMessage';
import { overlayClassnames } from './Overlay.css';

export interface PlayerErrorMessage {
  subtitle?: string;
  subtitleShort?: string;
  title?: string;
}

export interface PlayerErrorOverlayProps {
  baseUrl?: string;
  errorDescription?: PlayerErrorMessage;
  errorDetail?: string;
  mainContentId?: string;
  onReloadButtonClick?: () => void;
  showFooter?: boolean;
  usingNewLivePlaylist?: boolean;
}

export const removeButtonSeparatorFromString = (
  raw?: string,
  buttonSeparator?: string
): string | undefined => {
  if (!raw) {
    return undefined;
  }
  const separator = buttonSeparator || '%';
  return raw.split(separator).join('');
};

const messages = {
  reloadButton: {
    id: 'PlayerErrorOverlay.reloadButton',
    defaultMessage: 'Znovu načíst',
    description: 'Tačítko pro pokus znovu načíst video',
  },
  reportErrorButton: {
    id: 'PlayerErrorOverlay.reportErrorButton',
    defaultMessage: 'Nahlásit chybu',
    description: 'Tačítko pro nahlášení chyby',
  },
  defaultErrorTitle: {
    id: 'PlayerErrorOverlay.defaultErrorTitle',
    defaultMessage: 'Omlouváme se, ale video se nepodařilo načíst',
    description: 'Defaultní název chybové hlášky',
  },
  desktopQuestion: {
    id: 'PlayerErrorOverlay.desktopVersionQuestion',
    defaultMessage: 'Máte stále problémy s přehráváním videa?',
    description: 'Hláška v případě větší velikosti než tablet',
  },
  tabletQuestion: {
    id: 'PlayerErrorOverlay.tabletVersionQuestion',
    defaultMessage: 'Máte stále problémy?',
    description: 'Zkrácená hláška na tabletu',
  },
  answer: {
    id: 'PlayerErrorOverlay.answer',
    defaultMessage: 'Podívejte se do ',
    description: 'Hláška v případě větší velikosti než tablet',
  },
  answer2: {
    id: 'PlayerErrorOverlay.answer2',
    defaultMessage: ', nebo nám ',
    description: 'Hláška v případě větší velikosti než tablet',
  },
};

const PlayerErrorOverlay = ({
  onReloadButtonClick = () => {
    window.location.reload();
  },
  errorDescription,
  errorDetail,
  showFooter = true,
  baseUrl,
  mainContentId,
  usingNewLivePlaylist,
}: PlayerErrorOverlayProps) => {
  const { isMaxMediumDesktop, isMaxTablet, isMaxMediumMobile } = useMediaBreakpoints();
  const reportUrl = useReportErrorUrl({ baseUrl });
  const reportedRef = useRef<boolean>(false);
  // nepouzivat primo useAnalytics, aby si v iframu standalone error overlay nestezoval,
  // ze mu chybi provider
  const analytics = useContext(AnalyticsContext);

  const errDescription = useMemo(
    () =>
      errorDescription
        ? {
            title: removeButtonSeparatorFromString(errorDescription.title, BUTTON_SEPARATOR),
            subtitle: removeButtonSeparatorFromString(errorDescription.subtitle, BUTTON_SEPARATOR),
            subtitleShort: removeButtonSeparatorFromString(
              errorDescription.subtitleShort,
              BUTTON_SEPARATOR
            ),
          }
        : {
            title: formatMessage(messages.defaultErrorTitle),
          },
    [errorDescription]
  );

  useEffect(() => {
    if (!analytics || reportedRef.current) {
      return;
    }
    // info budeme prilepovat kvuli analytikum primo do message
    const addLivePlaylistInfo = (message: string) => {
      if (message && usingNewLivePlaylist !== undefined) {
        return `${message}___USING_NEW_LIVE_PLAYLIST=${usingNewLivePlaylist}___`;
      }
      return message;
    };
    const error = errorDetail || JSON.stringify(errDescription);
    analytics.trigger({
      type: 'PlayerError',
      data: {
        error: addLivePlaylistInfo(error),
        ...(mainContentId ? { mainContentId } : {}),
      },
    });
    reportedRef.current = true;
  }, [analytics, errDescription, errorDetail, mainContentId, usingNewLivePlaylist]);

  const separator =
    errDescription.title &&
    ((errDescription.subtitle && !isMaxMediumMobile) ||
      (errDescription.subtitleShort && isMaxMediumMobile) ||
      showFooter);

  return (
    <div className={overlayClassnames.overlayContainer} data-testid="PlayerError">
      <GradientOverlay className={overlayClassnames.overlay}>
        <div className={overlayClassnames.wrapper}>
          <div className={classNames(overlayClassnames.errorTitle, { separator })}>
            {errDescription.title}
          </div>
          <div className={overlayClassnames.errorSubtitle}>
            {isMaxMediumMobile ? errDescription.subtitleShort : errDescription.subtitle}
          </div>
          {showFooter && (
            <div className={overlayClassnames.errorFooter}>
              <Button
                className={overlayClassnames.button}
                icon={isMaxMediumMobile ? undefined : IconRefresh}
                size={isMaxMediumDesktop ? 'medium' : 'large'}
                onClick={onReloadButtonClick}
              >
                {formatMessage(messages.reloadButton)}
              </Button>
              {isMaxMediumMobile ? (
                <>
                  <Link
                    absolute
                    className={overlayClassnames.helpIcon}
                    href={supportWebsite}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <IconHelpCircle className={overlayClassnames.icon} />
                  </Link>
                  <span>
                    <Link
                      absolute
                      className={overlayClassnames.footerLink}
                      href={reportUrl}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {formatMessage(messages.reportErrorButton)}
                    </Link>
                  </span>
                </>
              ) : (
                <span>
                  {isMaxTablet
                    ? formatMessage(messages.tabletQuestion)
                    : formatMessage(messages.desktopQuestion)}{' '}
                  {isMaxTablet ? null : <br />}
                  {formatMessage(messages.answer)}
                  <Link
                    absolute
                    className={overlayClassnames.footerLink}
                    href={supportWebsite}
                    rel="noreferrer"
                    target="_blank"
                  >
                    nápovědy
                  </Link>
                  {formatMessage(messages.answer2)}
                  <Link
                    absolute
                    className={overlayClassnames.footerLink}
                    href={reportUrl}
                    rel="noreferrer"
                    target="_blank"
                    onClick={() =>
                      analytics?.trigger({
                        type: 'PlayerErrorReport',
                        data: {
                          error: JSON.stringify(errDescription),
                          ...(mainContentId ? { mainContentId } : {}),
                        },
                      })
                    }
                  >
                    nahlaste chybu
                  </Link>
                </span>
              )}
            </div>
          )}
        </div>
      </GradientOverlay>
    </div>
  );
};

export default memo(PlayerErrorOverlay);

export const PlayerErrorOverlayStandalone = (props: PlayerErrorOverlayProps) => {
  return (
    <PlayerBasedResponsive>
      <AspectRatioPlaceholder>
        <PlayerErrorOverlay {...props} />
      </AspectRatioPlaceholder>
    </PlayerBasedResponsive>
  );
};
