export const castMessages = {
  playerControlsItemChromecast: {
    id: 'PlayerControlsItem.chromecast',
    defaultMessage: 'Sdílet přes Chromecast',
    description: 'Ovládání: PlayerControlsItem.CHROMECAST',
  },
  playerControlsItemAirplay: {
    id: 'PlayerControlsItem.airplay',
    defaultMessage: 'Sdílet přes AirPlay',
    description: 'Ovládání: PlayerControlsItem.AIRPLAY',
  },
  connectedTVs: {
    id: 'castMenu.connectedTVs',
    defaultMessage: 'Přehrát na televizi přes HbbTV',
    description: 'nadpis seznamu propojených TV',
  },
  connectedDevicesAirplay: {
    id: 'castMenu.connectedDevicesAirplay',
    defaultMessage: 'Přehrát přes AirPlay',
    description: 'nadpis AirPlay',
  },
  connectedDevicesChromecast: {
    id: 'castMenu.connectedDevicesChromecast',
    defaultMessage: 'Přehrát přes Chromecast',
    description: 'nadpis Chromecast',
  },
  castMenu: {
    id: 'castMenu.hbbtvMenu',
    defaultMessage: 'menu sdílení na televizi',
    description: 'nadpis seznamu propojených TV',
  },
  castMenuclose: {
    id: 'castMenu.hbbtvMenuclose',
    defaultMessage: 'zavřít menu sdílení na televizi',
    description: 'nadpis seznamu propojených TV',
  },
  disconnect: {
    id: 'castMenu.disconnect',
    defaultMessage: 'Odpojit',
    description: 'text odpojovacího tlačítka',
  },
  keepConnection: {
    id: 'castMenu.keepConnection',
    defaultMessage: 'Zachovat připojení',
    description: 'text tlačítka zachování spojení',
  },
  addTv: {
    id: 'castMenu.addTv',
    defaultMessage: 'Přidat TV',
    description: 'popis tlačítka přidat TV',
  },
  instructions1a: {
    id: 'castMenu.instructions1a',
    defaultMessage: 'Stiskněte',
    description: 'instrukce k propojení přehrávače s HBBTV bod 1',
  },
  instructions1b: {
    id: 'castMenu.instructions1b',
    defaultMessage: 'na jakémkoliv kanále ČT.',
    description: 'instrukce k propojení přehrávače s HBBTV bod 1',
  },
  instructions2: {
    id: 'castMenu.instructions2',
    defaultMessage: 'Stiskněte na ovladači číslo',
    description: 'instrukce k propojení přehrávače s HBBTV bod 2',
  },
  instructions3: {
    id: 'castMenu.instructions3',
    defaultMessage: 'Zadejte kód z TV.',
    description: 'instrukce k propojení přehrávače s HBBTV bod 3',
  },
  playOnTv: {
    id: 'castMenu.playOnTv',
    defaultMessage: 'Propojení s tv',
    description: 'nadpis menu HBBTV připojení',
  },
  mustConnectTv: {
    id: 'castMenu.mustConnectTv',
    defaultMessage:
      'Televizor musí být připojen k internetu a mít aktivní službu HbbTV (červené tlačítko).',
    description: 'informace o nutnosti připojení televizoru k internetu',
  },
  moreInfo: {
    id: 'castMenu.moreInfo',
    defaultMessage: 'Více informací',
    description: 'odkaz na více informací k propojení přehrávače s hbbtv',
  },
  confirmRemoval1: {
    id: 'castMenu.confirmRemoval1',
    defaultMessage: 'Opravdu chcete odpojit televizor {message} ?',
    description: 'textace potvrzovacího okna odpojení televize',
  },
  confirmRemoval2: {
    id: 'castMenu.confirmRemoval2',
    defaultMessage:
      'Po odpojení již nebude možné na televizor promítat. Lze však opět kdykoli připojit.',
    description: 'textace potvrzovacího okna odpojení televize',
  },
  playOnTvName: {
    id: 'castMenu.playOnTvName',
    defaultMessage: 'Přehrát na televizi',
    description: 'nadpis seznamu propojených TV',
  },
  pauseOnTvName: {
    id: 'castMenu.pauseOnTvName',
    defaultMessage: 'zastavit přehrávání na televizi',
    description: 'nadpis seznamu propojených TV',
  },
  deleteTv: {
    id: 'castMenu.deleteTv',
    defaultMessage: 'odstranit televizi',
    description: 'nadpis seznamu propojených TV',
  },
};
