import React, { memo } from 'react';
import { IconErrorCircle, IconCheckMarkCircle } from '@czechtv/icons';
import { Red_base, Valid } from '@czechtv/styles';

export interface ValidationIconProps {
  className?: string;
  isValid?: boolean;
}

export const ValidationIcon = memo<ValidationIconProps>(({ isValid, className }) => {
  if (isValid === false) {
    return <IconErrorCircle className={className} fill={Red_base} height={20} width={20} />;
  }
  if (isValid) {
    return <IconCheckMarkCircle className={className} fill={Valid} height={20} width={20} />;
  }
  return null;
});
