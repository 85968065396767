import React, { memo } from 'react';
import { scrimVariants } from './Scrim.css';

interface ScrimProps {
  visibleBottom?: boolean;
  visibleRight?: boolean;
  visibleTop?: boolean;
}

const Scrim = ({ visibleBottom = true, visibleTop = true, visibleRight = true }: ScrimProps) => {
  return (
    <div className={scrimVariants.controlsScrim}>
      <div className={scrimVariants.topScrim} style={{ opacity: visibleTop ? 1 : 0 }} />
      <div className={scrimVariants.bottomScrim} style={{ opacity: visibleBottom ? 1 : 0 }} />
      <div className={scrimVariants.rightScrim} style={{ opacity: visibleRight ? 1 : 0 }} />
    </div>
  );
};

export default memo(Scrim);
