import { PlaylistData } from './constants';

interface ReportParams {
  id: string;
  idType: 'bonus' | 'index' | 'episode' | 'channel' | 'ct24' | 'externalLiveStreams';
  playlistData: PlaylistData;
  playlistVodUri: string;
}

export const reportToShadowPlaylistService = async ({
  playlistVodUri,
  id,
  idType,
  playlistData,
}: ReportParams) => {
  // nechceme hlasit, kdyz byl umyslne volan zivak
  // zive vysilani muze pritect, ale pouze u tzv. "zivych IDECu" - tam bude id episode/idec
  if (idType === 'channel') {
    return;
  }
  const mainContentItem = playlistData.streamUrls.find((obj) => !obj.isLabeling);
  const body = JSON.stringify({
    streamUrl: mainContentItem?.main || '',
    params: {
      id,
      idType: idType === 'episode' ? 'idec' : idType,
    },
  });
  try {
    await fetch(`${playlistVodUri}/shadow-call`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });
  } catch (e) {
    // nic nelogujeme
  }
};
