import React, { Ref, memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ScreenMode } from '../../../constants';
import { overlayWrapperClassnames } from './OverlayWrapper.css';

export interface OverlayWrapperProps {
  classNameOverlay?: string;
  overlayRef?: Ref<HTMLDivElement>;
  previewImage?: string;
  screenMode: ScreenMode;
  showVideoTitle?: boolean;
}

export const OverlayWrapper = memo(
  ({
    classNameOverlay,
    overlayRef,
    screenMode,
    children,
    previewImage,
    showVideoTitle,
  }: PropsWithChildren<OverlayWrapperProps>) => {
    return (
      <div
        className={classNames(
          previewImage ? '' : classNameOverlay,
          overlayWrapperClassnames.overlay,
          {
            [overlayWrapperClassnames.overlayFullscreen]: screenMode === ScreenMode.FULLSCREEN,
          }
        )}
        data-testid="RecommendationOverlay"
        role="button"
        style={{
          backgroundImage: previewImage && `url(${previewImage})`,
          backgroundSize: previewImage ? 'cover' : 'initial',
          backgroundPosition: previewImage ? '50% 50%' : 'initial',
        }}
        tabIndex={-1}
      >
        <div
          className={classNames(overlayWrapperClassnames.contentWrapper, {
            notEmbedWrapper: !showVideoTitle,
          })}
          ref={overlayRef}
        >
          {children}
        </div>
      </div>
    );
  }
);
