import React, { memo, useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { IconClose } from '@czechtv/icons';
import { ImageLoader, useFocusVisibleClassName, Scrollbar } from '@czechtv/components';
import { formatTime } from '@czechtv/utils';
import { body16, DefaultRadius, Grey_30, Grey_70, label16 } from '@czechtv/styles';
import { useAnalytics } from '@czechtv/analytics-react';
import { AnalyticsPlayerContext } from '@czechtv/analytics';
import {
  Breakpoint,
  getPlayerResponsiveRule,
  useMediaBreakpoints,
} from '../../../../utils/playerResponsive';
import { IndexItemProps } from '../ProgressBar/IndexList';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { ZINDEX_VIDEO_INDEX_LIST } from '../../../../zindexes';
import { formatMessage } from '../../../../utils/formatMessage';

interface VideoIndexListProps {
  currentIndex?: string;
  indexListVisible?: boolean;
  indexes: IndexItemProps[];
  licenseInfo?: string;
  onClickCloseButton: () => void;
  onClickIndexItem: (id: string) => void;
  playerPreview?: boolean;
}

const HEADER_TITLE_HEIGHT = 25;
const HEADER_TITLE_BORDER = 1;

const useStyles = createUseStyles({
  listWrapper: {
    height: '100%',
    backgroundColor: 'rgba(0,0,0, .8)',
    color: '#fff',
    position: 'absolute',
    left: 0,
    zIndex: ZINDEX_VIDEO_INDEX_LIST,
    width: '100%',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktopMedium])]: {
      '&.licenseInfo.playerPreview': {
        width: 'calc(50% - 148px - 15px)',
      },
    },
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      '&.playerPreview': {
        width: 'calc(50% - 60px - 15px)',
        '&.licenseInfo': {
          width: '100%',
        },
      },
    },
    [getPlayerResponsiveRule([Breakpoint.isMinMobile])]: {
      maxWidth: 400,
    },
  },
  titleWrapper: {
    ...label16,
    fontweight: 600,
    height: HEADER_TITLE_HEIGHT,
    padding: 13,
    borderBottom: `${HEADER_TITLE_BORDER}px solid ${Grey_70}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [getPlayerResponsiveRule(Breakpoint.isMinMobile)]: {
      padding: 16,
    },
  },
  scrollWrapper: {
    height: `calc(100% - ${13 * 2 + HEADER_TITLE_HEIGHT + HEADER_TITLE_BORDER}px)`,
    width: '100%',
    [getPlayerResponsiveRule(Breakpoint.isMinMobile)]: {
      height: `calc(100% - ${16 * 2 + HEADER_TITLE_HEIGHT + HEADER_TITLE_BORDER}px)`,
    },
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    ...body16,
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: [15, 16, 15, 8],
    '&.active': {
      backgroundColor: 'rgba(255,255,255, .15)',
    },
  },
  timestamp: {
    marginRight: 12,
    color: Grey_30,
    minWidth: 48,
    textAlign: 'end',
  },
  itemTitle: {
    lineHeight: '20px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    alignSelf: 'flex-start',
    '&.active': {
      fontWeight: 600,
    },
  },
  videoImage: {
    borderRadius: DefaultRadius,
    overflow: 'hidden',
    width: 72,
    minWidth: 72,
    marginRight: 12,
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      display: 'none',
    },
  },
  icon: {
    width: 24,
    height: 24,
    fill: '#fff',
    cursor: 'pointer',
  },
  closeButton: {
    height: 24,
    fontSize: 0,
  },
});

const VideoIndexList = memo(
  ({
    indexListVisible,
    licenseInfo,
    currentIndex,
    indexes,
    onClickIndexItem,
    playerPreview = false,
    onClickCloseButton,
  }: VideoIndexListProps) => {
    const classes = useStyles();
    const { isMinDesktop, isMinDesktopMedium } = useMediaBreakpoints();
    const [indexActive, setIndexActive] = useState(currentIndex);
    const [indexListFirstClosed, setIndexListFirstClosed] = useState(false);
    // V Safari neni videt focus, pokud neni inset
    const focusVisibleClassName = useFocusVisibleClassName({ inset: true });
    const analytics = useAnalytics();

    const showIndexes = useCallback(() => {
      // pokud indexy nemáme, neukazujeme
      if (indexes.length === 0) {
        return false;
      }
      // pokud došlo k interakci, skryjeme/zobrazíme podle ní
      if (indexListVisible !== undefined) {
        return indexListVisible;
      }
      // na 938+ máme indexy otevřené by default
      if (isMinDesktopMedium) {
        return true;
      }
      // na 768+ máme indexy otevřené pokud není zobrazená informace o licenci
      if (isMinDesktop && !licenseInfo) {
        return true;
      }
      // na ostatních rozlišeních jsou indexy by default skryté
      return false;
    }, [indexListVisible, indexes.length, isMinDesktop, isMinDesktopMedium, licenseInfo]);

    useEffect(() => {
      const focusTrap = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          // knihovna neumoznuje zavrit index list escapem kdyz je pausnuty, timto to umoznime
          if (!indexListFirstClosed) {
            onClickCloseButton();
          }
        }
      };
      // kdyz je index list schovany, ale mounted, nepridavame listener
      if (showIndexes()) {
        document.addEventListener('keydown', focusTrap);
      }
      return () => {
        document.removeEventListener('keydown', focusTrap);
      };
    }, [onClickCloseButton, showIndexes, indexListFirstClosed]);

    const onClickListItemHandler = (id: string) => {
      setIndexActive(id);
      onClickIndexItem(id);

      const activeIndex = indexes.find((index) => index.indexId === id);
      if (activeIndex) {
        const analyticsContext: Partial<AnalyticsPlayerContext> = {
          position: activeIndex.startTime,
        };
        const context = analytics.getContext();
        if (context) {
          analytics.setContext({ ...context, ...analyticsContext });
          analytics.trigger({ type: 'PlayerIndexStartList' });
        }
      }
    };

    const closeIndexList = () => {
      setIndexListFirstClosed(true);
      onClickCloseButton();
    };

    useEffect(() => {
      setIndexActive(currentIndex);
    }, [currentIndex]);

    if (!showIndexes()) {
      return null;
    }
    const indexItems = indexes.map((item: IndexItemProps) => {
      const isActiveItem = indexActive === item.indexId;
      return (
        <li key={item.indexId}>
          <PlayerNativeButton
            aria-label={`${item.title} - ${formatTime(item.startTime, 'H:i:s', 2)}`}
            className={classNames(classes.listItem, focusVisibleClassName, {
              active: isActiveItem,
            })}
            data-testid="videoIndexListItem"
            onClick={() => onClickListItemHandler(item.indexId)}
          >
            <span className={classes.timestamp}>{formatTime(item.startTime, 'G:i:s', 2)}</span>
            <ImageLoader
              className={classes.videoImage}
              src={item.imageUrl ? item.imageUrl : undefined}
            />
            <span className={classNames(classes.itemTitle, { active: isActiveItem })}>
              {item.title}
            </span>
          </PlayerNativeButton>
        </li>
      );
    });

    return (
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: closeIndexList,
          clickOutsideDeactivates: true,
          returnFocusOnDeactivate: true,
          escapeDeactivates: true,
        }}
        // pri preview s otevrenym indexlistem focus trap pred prvnim zavrenim indexlistu pausneme
        paused={
          !indexListFirstClosed &&
          playerPreview &&
          ((isMinDesktop && !licenseInfo) || isMinDesktopMedium)
        }
      >
        <div
          aria-modal
          aria-label={formatMessage(
            {
              id: 'VideoIndexList.title',
              defaultMessage: 'Části ({indexCount})',
              description: 'Titulek seznamu indexů',
            },
            { indexCount: `obsahuje ${indexes.length} částí videa` }
          )}
          className={classNames(classes.listWrapper, {
            licenseInfo,
            playerPreview,
          })}
          data-testid="videoIndexList"
        >
          <div className={classes.titleWrapper}>
            <div>
              {formatMessage(
                {
                  id: 'VideoIndexList.title',
                  defaultMessage: 'Části ({indexCount})',
                  description: 'Titulek seznamu indexů',
                },
                { indexCount: indexes.length }
              )}
            </div>
            <PlayerNativeButton
              aria-label={formatMessage({
                id: 'VideoIndexList.CloseIndexListAriaLabel',
                defaultMessage: 'Zavřít panel s indexy',
                description: 'Aria-label pro zavření panelu s indexy',
              })}
              className={classes.closeButton}
              onClick={onClickCloseButton}
            >
              <IconClose className={classes.icon} />
            </PlayerNativeButton>
          </div>
          <Scrollbar className={classes.scrollWrapper}>
            <ul className={classes.list} data-testid="videoIndexListItems">
              {indexItems}
            </ul>
          </Scrollbar>
        </div>
      </FocusTrap>
    );
  }
);

export { VideoIndexList };
