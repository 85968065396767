import React from 'react';
import { mandatoryStatusClassnames } from './MandatoryStatus.css';

interface Props {
  required?: boolean;
}

export const MandatoryStatus = ({ required }: Props) => {
  return (
    <>
      {required && (
        <span aria-hidden className={mandatoryStatusClassnames.asterisk}>
          &#42;
        </span>
      )}
      {required === false && (
        <span className={mandatoryStatusClassnames.labelSmall}>(nepovinné)</span>
      )}
    </>
  );
};
