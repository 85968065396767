import React, { memo, ReactNode, RefObject, useCallback, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import {
  body16,
  body18,
  DefaultRadius,
  DEFAULT_FONT,
  Grey_20,
  Grey_30,
  label14,
  label16,
  Notice,
} from '@czechtv/styles';
import { Button, useFocusVisibleClassName } from '@czechtv/components';
import {
  IconTrash,
  IconPlay,
  IconPause,
  IconAirplay,
  IconArrowLeft,
  IconRedButton,
  IconKeypad8,
  IconErrorCircle,
} from '@czechtv/icons';
import useAirplay from '../../../../utils/useAirplay';
import { usePlayerContext } from '../../../PlayerContext';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import MenuPopup from '../MenuPopup';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import { HbbtvConnect, MenuPopupType } from '../../../../constants';
import { formatMessage } from '../../../../utils/formatMessage';
import { castMessages as messages } from './messages';
import { CastTvGraphics } from './CastTvGraphics';
import { CastMessage } from './CastMessage';

// kvuli Sportu - v budoucnu budeme resit jinak
const resetOfGlobalSvgOverrides = {
  '& svg': {
    width: 'inherit',
    height: 'inherit',
  },
};

interface CastProps {
  castMenuRef?: RefObject<HTMLDivElement>;
  hbbtv: any;
  isAudioDescription?: boolean;
  isTimeshift: boolean;
  onClose?: () => void;
  onHbbtvDeviceSelect: () => void;
  playerRef?: RefObject<HTMLElement>;
  streamUrl: string | null;
  videoRef?: RefObject<HTMLVideoElement>;
}

enum HbbtvState {
  newConnection = 'newConnection',
  noConnection = 'noConnection',
  removeConnection = 'removeConnection',
  selectConnection = 'selectConnection',
}

const useStyles = createUseStyles({
  selectButtonStreaming: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    paddingLeft: 13,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#fff',
  },
  chromecastIcon: {
    '--disconnected-color': '#fff',
  },
  title: {
    ...label16,
    fontWeight: 600,
    margin: [4, 4, 4, 16],
    color: Grey_20,
  },
  text: {
    ...body16,
    margin: [0, 0, 12, 0],
    color: Grey_20,
    '& a': {
      color: Grey_20,
      fontFamily: DEFAULT_FONT,
      fontWeight: 600,
      textDecoration: 'underline',
    },
  },
  addButton: {
    width: '100%',
    fontFamily: DEFAULT_FONT,
  },
  content: {
    ...body16,
    maxWidth: 300,
    textAlign: 'left',
    width: '100%',
    padding: 16,
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      width: 300,
    },
    '& p': {
      color: 'inherit',
    },
  },
  image: {
    display: 'none',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktopMedium])]: {
      display: 'block',
      width: 176,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 24,
      marginBottom: 24,
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'decimal inside none',
  },
  listItem: {
    ...body16,
    lineHeight: 'initial',
    [getPlayerResponsiveRule([Breakpoint.isMinMobile])]: {
      lineHeight: '26px',
    },
    '&::marker': {
      fontweight: 600,
      letterSpacing: 1,
    },
    '& svg': {
      verticalAlign: 'middle',
      marginLeft: 6,
      marginRight: 6,
    },
  },
  codeInput: {
    padding: [8, 12],
    extend: body18,
    fontWeight: 600,
    color: 'white',
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: DefaultRadius,
    boxSizing: 'border-box',
    '-webkit-appearance': 'none',
    marginRight: 8,
    width: 208,
    letterSpacing: '3px',
    '&::placeholder': {
      letterSpacing: '4px',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  form: {
    display: 'flex',
    marginTop: 16,
    [getPlayerResponsiveRule([Breakpoint.isMaxSmallMobile])]: {
      marginTop: 12,
    },
  },
  tvList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginBottom: 16,
  },
  tvListItem: {
    borderStyle: 'solid',
    borderBottomWidth: 0,
    borderWidth: `1px`,
    borderColor: `rgba(255, 255, 255, 0.3)`,
    borderRadius: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:first-child': {
      borderRadius: `${DefaultRadius}px ${DefaultRadius}px 0 0`,
    },
    '&:last-child': {
      borderRadius: `0 0 ${DefaultRadius}px ${DefaultRadius}px`,
      borderBottomWidth: 1,
    },
    '&:first-child&:last-child': {
      borderRadius: `${DefaultRadius}px ${DefaultRadius}px ${DefaultRadius}px ${DefaultRadius}px`,
      borderBottomWidth: 1,
    },
    '& svg': {
      width: 24,
      fill: Grey_30,
      margin: [0, 8, 0, 0],
    },
  },
  tvListTitle: {
    margin: [0, 0, 12, 0],
    ...label14,
    fontWeight: 600,
    '&.large': {
      ...label16,
      fontweight: 600,
    },
    '&.hideOnMobile': {
      [getPlayerResponsiveRule([Breakpoint.isMaxLargeMobile])]: {
        display: 'none',
      },
    },
  },
  tvListItemTitle: {
    color: Grey_20,
    flex: '1 auto',
    padding: [9, 0, 9, 0],
    margin: 0,
    lineHeight: '22px',
    '&:hover': {
      color: '#fff',
    },
    '&.active': {
      fontFamily: DEFAULT_FONT,
      fontWeight: 600,
      color: '#fff',
    },
  },
  streamDeviceListItemTitle: {
    ...body16,
    padding: [8, 0, 8, 0],
    margin: 0,
  },
  iconPlay: {
    paddingLeft: 8,
    alignSelf: 'center',
  },
  iconPause: {
    paddingLeft: 8,
    alignSelf: 'center',
    '& path': {
      fill: '#fff',
    },
  },
  deleteButton: {
    paddingLeft: 8,
    borderLeft: 'solid 1px rgba(255, 255, 255, 0.3)',
    display: 'flex',
    alignItems: 'center',
  },
  selectButton: {
    display: 'flex',
    flex: 'auto',
  },
  disconnectTitle: {
    ...body18,
    margin: [0, 0, 16, 0],
    marginRight: 48,
  },
  disconnectSubTitle: {
    ...body16,
    margin: [0, 0, 16, 0],
    color: Grey_20,
  },
  btnsContainer: {
    display: 'flex',
  },
  disconnectButton: {
    width: 144,
    marginRight: 8,
    fontFamily: DEFAULT_FONT,
  },
  keepButton: {
    width: 144,
    fontFamily: DEFAULT_FONT,
    padding: [0, 8],
  },
  backButton: {
    ...label16,
    fontweight: 600,
    display: 'flex',
    alignItems: 'center',
    color: Grey_20,
    '& svg': {
      width: 24,
      height: 24,
      marginRight: 8,
      '& path': {
        fill: Grey_20,
      },
    },
  },
  errorMessage: {
    ...body16,
    marginTop: 16,
    '& p': {
      margin: 0,
      marginBottom: 8,
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      width: 24,
      height: 24,
      marginRight: 8,
      verticalAlign: 'bottom',
      fill: Notice,
    },
  },
  errorMessageTitle: {
    ...resetOfGlobalSvgOverrides,
    fontFamily: DEFAULT_FONT,
    fontWeight: 600,
  },
  errorMessageSubtitle: {
    color: Grey_30,
  },
  tvListContainer: {
    marginTop: 12,
    '&:not(:first-child)': {
      marginTop: 24,
    },
  },
  instructions: {
    ...resetOfGlobalSvgOverrides,
  },
});

export const Cast = memo(
  ({
    castMenuRef,
    onHbbtvDeviceSelect,
    playerRef,
    videoRef,
    hbbtv,
    isAudioDescription,
    isTimeshift,
    onClose = () => {},
  }: CastProps) => {
    const classes = useStyles();
    const {
      hbbtvStreamingActiveDevice,
      disableAirplay,
      disableChromecast,
      isChromecastApiAvailable,
      toggleChromecast,
      activeChromecastDevice,
    } = usePlayerContext();

    const { triggerAirplay } = useAirplay({ video: videoRef?.current });
    const [isChromecastDeviceAvailable, setIsChromecastDeviceAvailable] = useState(false);
    const focusVisibleClassName = useFocusVisibleClassName({ inset: true });
    const chromecastButtonRef = useRef<HTMLButtonElement>(null);

    const isChromecastEnabled = !disableChromecast;
    const isAirplayEnabled = !disableAirplay;

    const canUseChromecast = !!(
      isChromecastApiAvailable &&
      isChromecastEnabled &&
      !isAudioDescription &&
      !isTimeshift
    );
    const canUseAirplay = isAirplayEnabled && !isAudioDescription && isTimeshift;

    // zatim takto, pripadne logiku rozsirime, Airplay bude zatim disabled
    const isAirplaySupported = !!(window as { WebKitPlaybackTargetAvailabilityEvent?: Event })
      .WebKitPlaybackTargetAvailabilityEvent;
    const isChromecastSupported = isChromecastApiAvailable;

    const isCastingAvailable = canUseChromecast || canUseAirplay;

    const hbbtvDataFactory = (): HbbtvConnect => {
      return {
        ...hbbtv,
      };
    };

    const {
      onAddTvButtonClick,
      onCreateTvButtonClick,
      onCloseButtonClick,
      onBackClick,
      onSelectItem,
      onDeleteItem,
      onConfirmDeleteItemButtonClick,
      onKeepTvButtonClick,
      hbbtvState,
      setPairingId,
      pairedDevices,
      deviceToRemove,
      connectionError,
    } = hbbtvDataFactory();

    const chromecastDeviceName = activeChromecastDevice?.friendlyName;

    const getChromecastDeviceName = useCallback(() => {
      // tohle je hack, jak nastavit, ze v siti bylo zjisteno zarizeni
      // na webu kvuli privacy/security to nejde jen tak zjistit pomoci API
      // stara se o to web component Chromecast button - viz. is="google-cast-button"
      // pokud se zobrazi, zavolame funkci pro zobrazeni textu a zaroven zmenime flag
      const buttonDisplayed = chromecastButtonRef.current?.style.display !== 'none';
      if (chromecastButtonRef.current && isChromecastDeviceAvailable !== buttonDisplayed) {
        setIsChromecastDeviceAvailable(buttonDisplayed);
      }

      return chromecastDeviceName || `Chromecast`;
    }, [chromecastDeviceName, isChromecastDeviceAvailable]);

    const getTitleBarContent = useCallback(
      (hbbtvState: any): ReactNode | null | string => {
        if (hbbtvState === HbbtvState.newConnection) {
          return (
            <p className={classes.title}>
              <PlayerNativeButton
                className={classes.backButton}
                type="button"
                onClick={onBackClick}
              >
                <IconArrowLeft />
                zpět
              </PlayerNativeButton>
            </p>
          );
        }
        if (
          hbbtvState === HbbtvState.selectConnection ||
          (hbbtvState === HbbtvState.noConnection && isCastingAvailable)
        ) {
          return <p className={classes.title}>{formatMessage(messages.playOnTv)}</p>;
        }
        return null;
      },
      [classes.backButton, classes.title, isCastingAvailable, onBackClick]
    );

    return (
      <MenuPopup
        hasLeftSectionContent
        scrollableContent
        closeButtonAriaLabel={formatMessage(messages.castMenuclose)}
        menuPopupRef={castMenuRef}
        menuPopupTitleAriaLabel={formatMessage(messages.castMenu)}
        name={MenuPopupType.CAST}
        playerRef={playerRef}
        setMenuPopupVisible={onCloseButtonClick}
        titleBarContent={getTitleBarContent(hbbtvState)}
        onClose={onClose}
      >
        <div className={classes.content}>
          {(hbbtvState === HbbtvState.noConnection ||
            hbbtvState === HbbtvState.selectConnection) && (
            <>
              <>
                {/* list chromecast/airplay */}
                <>
                  {canUseChromecast ? (
                    <p className={classes.tvListTitle}>
                      {formatMessage(messages.connectedDevicesChromecast)}
                    </p>
                  ) : null}
                  {canUseAirplay ? (
                    <p className={classes.tvListTitle}>
                      {formatMessage(messages.connectedDevicesAirplay)}
                    </p>
                  ) : null}
                  {isCastingAvailable ? (
                    <ul className={classes.tvList}>
                      {canUseChromecast && (
                        // toto je hodne specificky case, v zasade tento button sam zjistuje
                        // informace o tom, jestli je nejake zarizeni dostupne (viz. komentar vyse)
                        <li
                          className={classes.tvListItem}
                          {...(!isChromecastDeviceAvailable
                            ? { style: { borderStyle: 'hidden' } }
                            : {})}
                        >
                          <button
                            aria-label={formatMessage(messages.connectedDevicesChromecast)}
                            // Jde o custom web component, proto tady className nejde
                            // @ts-ignore
                            class={classNames(
                              classes.selectButtonStreaming,
                              classes.chromecastIcon
                            )}
                            // Timhle rikame, ze se ma pouzit odpovidajici web component
                            // (injectnuta Chromecast scriptem)
                            is="google-cast-button"
                            ref={chromecastButtonRef}
                            style={{ display: 'flex' }}
                            title={formatMessage(messages.connectedDevicesChromecast)}
                            type="button"
                            onClick={toggleChromecast}
                          >
                            <p className={classes.streamDeviceListItemTitle}>
                              {getChromecastDeviceName()}
                            </p>
                          </button>
                        </li>
                      )}
                      {canUseChromecast && !isChromecastDeviceAvailable && (
                        <p className={classes.text}>Připojte zařízení</p>
                      )}
                      {canUseAirplay ? (
                        <li className={classes.tvListItem} key="airplay">
                          <PlayerNativeButton
                            aria-label={formatMessage(messages.connectedDevicesAirplay)}
                            className={classNames(classes.selectButtonStreaming)}
                            title={formatMessage(messages.connectedDevicesAirplay)}
                            type="button"
                            onClick={triggerAirplay}
                          >
                            <IconAirplay />
                            <p className={classes.streamDeviceListItemTitle}>AirPlay</p>
                          </PlayerNativeButton>
                        </li>
                      ) : null}
                    </ul>
                  ) : null}
                </>
                <div className={classes.tvListContainer}>
                  {hbbtvState !== HbbtvState.noConnection && (
                    <p className={classes.tvListTitle}>{formatMessage(messages.connectedTVs)}</p>
                  )}

                  {hbbtvState === HbbtvState.noConnection && (
                    <>
                      {!isCastingAvailable && (
                        <div className={classes.image}>
                          <CastTvGraphics />
                        </div>
                      )}
                      <p className={classNames(classes.tvListTitle)}>
                        {formatMessage(messages.connectedTVs)}
                      </p>
                      <p className={classes.text}>
                        {formatMessage(messages.mustConnectTv)}
                        <br />
                        <a
                          href="https://www.ceskatelevize.cz/hbbtv/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {formatMessage(messages.moreInfo)}
                        </a>
                      </p>
                    </>
                  )}
                  {pairedDevices.length > 0 && (
                    <ul className={classes.tvList}>
                      {pairedDevices.map((pairedDevice) => {
                        return (
                          <li className={classes.tvListItem} key={pairedDevice.id}>
                            <PlayerNativeButton
                              aria-label={
                                hbbtvStreamingActiveDevice?.id !== pairedDevice.id
                                  ? formatMessage(messages.playOnTvName)
                                  : formatMessage(messages.pauseOnTvName)
                              }
                              className={classes.selectButton}
                              value={pairedDevice.id}
                              onClick={() => onSelectItem(pairedDevice, onHbbtvDeviceSelect)}
                            >
                              {hbbtvStreamingActiveDevice?.id !== pairedDevice.id ? (
                                <IconPlay className={classes.iconPlay} />
                              ) : (
                                <IconPause className={classes.iconPause} />
                              )}
                              <p
                                className={classNames(classes.tvListItemTitle, {
                                  active: hbbtvStreamingActiveDevice?.id === pairedDevice.id,
                                })}
                              >
                                {pairedDevice.type || pairedDevice.name}
                              </p>
                            </PlayerNativeButton>
                            <PlayerNativeButton
                              aria-label={formatMessage(messages.deleteTv)}
                              className={classes.deleteButton}
                              value={pairedDevice.id}
                              onClick={() => onDeleteItem(pairedDevice)}
                            >
                              <IconTrash />
                            </PlayerNativeButton>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </>
              <Button
                className={classes.addButton}
                size="medium"
                styleType="inverted"
                onClick={onAddTvButtonClick}
              >
                {formatMessage(messages.addTv)}
              </Button>
              <CastMessage
                contentNotAllowed={isAudioDescription || isTimeshift}
                isAirplayEnabled={!disableAirplay}
                isAirplaySupported={isAirplaySupported}
                isChromecastEnabled={!disableChromecast}
                isChromecastSupported={isChromecastSupported}
              />
            </>
          )}
          {hbbtvState === HbbtvState.newConnection && (
            <div className={classes.instructions}>
              <p className={classNames(classes.tvListTitle, { large: true, hideOnMobile: true })}>
                {formatMessage(messages.connectedTVs)}
              </p>
              <ol className={classes.list}>
                <li className={classes.listItem}>
                  {formatMessage(messages.instructions1a)}
                  <IconRedButton />
                  {formatMessage(messages.instructions1b)}
                </li>

                <li className={classes.listItem}>
                  {formatMessage(messages.instructions2)}
                  <IconKeypad8 />
                </li>
                <li className={classes.listItem}>{formatMessage(messages.instructions3)}</li>
                {connectionError ? (
                  <div className={classes.errorMessage}>
                    <p className={classes.errorMessageTitle}>
                      <IconErrorCircle height={24} width={24} />K televizi se nelze připojit
                    </p>
                    <p className={classes.errorMessageSubtitle}>
                      Zkontrolujte jestli jsou všechna zařízení připojena k internetu a zadejte kód
                      znovu.{' '}
                    </p>
                  </div>
                ) : null}
                <div className={classes.form}>
                  <input
                    aria-label={formatMessage(messages.addTv)}
                    className={classNames(classes.codeInput, focusVisibleClassName)}
                    placeholder="___ ___ ___ ___"
                    type="number"
                    onBlur={(e) => {
                      setPairingId(e.target.value);
                    }}
                    onChange={(e) => {
                      setPairingId(e.target.value);
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                  />

                  <Button
                    className={classes.addButton}
                    size="medium"
                    onClick={onCreateTvButtonClick}
                  >
                    Přidat
                  </Button>
                </div>
              </ol>
            </div>
          )}
          {hbbtvState === HbbtvState.removeConnection && (
            <>
              <div>
                <p className={classes.disconnectTitle}>
                  {formatMessage(messages.confirmRemoval1, {
                    message: `${deviceToRemove?.name || deviceToRemove?.type}`,
                  })}
                </p>
                <p className={classes.disconnectSubTitle}>
                  {formatMessage(messages.confirmRemoval2)}
                </p>
              </div>
              <div className={classes.btnsContainer}>
                <Button
                  className={classes.disconnectButton}
                  size="medium"
                  onClick={onConfirmDeleteItemButtonClick}
                >
                  {formatMessage(messages.disconnect)}
                </Button>
                <Button
                  className={classes.keepButton}
                  size="medium"
                  styleType="inverted"
                  onClick={onKeepTvButtonClick}
                >
                  {formatMessage(messages.keepConnection)}
                </Button>
              </div>
            </>
          )}
        </div>
      </MenuPopup>
    );
  }
);
