import React, { KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { BoxShadowFocus, DefaultRadius } from '@czechtv/styles';
import { useAnalytics } from '@czechtv/analytics-react';
import { debounce } from '@czechtv/utils';
import { useFocusVisibleClassName, FOCUS_VISIBLE_ACTIVE_CLASS_NAME } from '@czechtv/components';
import { formatMessage } from '../../../../utils/formatMessage';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import { Keys } from '../../../../constants';

interface Props {
  onDragChange?: (isDragging: boolean) => void;
  onVolumeChange: (volume: number) => void;
  vertical?: boolean;
  visible?: boolean;
  // 0 - 1
  volume: number;
}

export const MAX_VOLUME_WIDTH = 75;
export const VOLUME_KNOB_WIDTH = 14;
const VOLUME_SLIDER_STEP = 0.05;

const useStyles = createUseStyles({
  volumeControlContainer: {
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    // k maximální šířce přidáme paddingy vnitrnich wrapperu
    maxWidth: 0,
    opacity: 0,
    transition: 'max-width 0.1s linear, opacity 0.1s linear',
    position: 'absolute',
    top: 40,
    transform: 'translate3d(22px, -40px, 0)',
    zIndex: 3,
    touchAction: 'none',
    '&.visible': {
      opacity: 1,
      maxWidth: MAX_VOLUME_WIDTH + 25 * 2,
    },
  },
  inner: {
    padding: [6, 15],
    pointerEvents: 'none',
    touchAction: 'none',
    outline: 'none',
  },
  innerBackground: {
    padding: [14, 10],
    borderRadius: DefaultRadius,
    [getPlayerResponsiveRule([Breakpoint.isMaxLargeMobile])]: {
      background: 'rgba(0, 0, 0, 80%)',
    },
    [`.${FOCUS_VISIBLE_ACTIVE_CLASS_NAME} > &`]: {
      boxShadow: BoxShadowFocus,
      borderRadius: 0,
    },
  },
  volumeControlWrapper: {
    height: 4,
    borderRadius: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    width: MAX_VOLUME_WIDTH,
  },
  volumeIndicator: {
    height: 4,
    borderRadius: 5,
    backgroundColor: '#ffffff',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'inline-block',
      width: VOLUME_KNOB_WIDTH,
      height: VOLUME_KNOB_WIDTH,
      borderRadius: VOLUME_KNOB_WIDTH,
      backgroundColor: '#ffffff',
      position: 'absolute',
      right: -VOLUME_KNOB_WIDTH / 2,
      bottom: -5,
    },
  },
});

const messages = {
  soundLevel: {
    id: 'VolumeControl.buttonTitle.soundLevel',
    defaultMessage: 'Úroveň hlasitosti',
    description: '',
  },
  soundAdjustment: {
    id: 'VolumeControl.buttonTitle.soundAdjustment',
    defaultMessage: 'Úprava hlasitosti',
    description: '',
  },
};

const VolumeKnob = ({
  volume,
  visible = true,
  onVolumeChange,
  vertical = false,
  onDragChange = () => {},
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMounted = useRef(true);
  const volumeRef = useRef(volume);
  const focusVisibleClassName = useFocusVisibleClassName({ detectOnly: true });
  const analytics = useAnalytics();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const classes = useStyles();

  const knobNewPosition = useCallback(
    (e: React.MouseEvent | MouseEvent | React.TouchEvent): number => {
      if (!containerRef.current) {
        return 0;
      }

      // získáme pozici podle myší nebo touch
      const position = (() => {
        if ('clientY' in e) {
          return vertical ? e.clientY : e.clientX;
        }

        const touch = e.touches[0];
        return vertical ? touch.clientY : touch.clientX;
      })();

      const { left, right, top, bottom } = containerRef.current.getBoundingClientRect();

      const containerWidth = vertical ? bottom - top : right - left;

      const newPosition =
        Math.round(((position - (vertical ? top : left)) / containerWidth) * 100) / 100;

      // omezíme 0 - 1
      return Math.min(1, Math.max(0, vertical ? 1 - newPosition : newPosition));
    },
    [vertical]
  );

  const onMouseDown = (e: React.MouseEvent) => {
    onDragChange(true);

    // chceme změnit volume při začátku dragu
    onVolumeChange(knobNewPosition(e));

    const onDraging = (e: MouseEvent) => {
      if (isMounted.current) {
        onVolumeChange(knobNewPosition(e));
      }
    };

    const debounceDraging = debounce(onDraging);

    const onDragEnd = () => {
      onDragChange(false);
      // potrebujeme poslat jen jeden event na konci posouvani, posilame pouze, kdyz se zmeni
      // na mouseup volume
      if (volumeRef.current !== volume) {
        analytics.trigger({ type: 'PlayerSettingsChangeVolume' });
      }
      document.body.style.cursor = 'auto';
      document.removeEventListener('mouseup', onDragEnd);
      document.removeEventListener('mousemove', debounceDraging);
    };

    document.body.style.cursor = 'pointer';
    document.addEventListener('mouseup', onDragEnd);
    document.addEventListener('mousemove', debounceDraging);
  };

  const onContainerKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    // reagujeme pouze na šipku doleva a doprava
    if (event.code !== Keys.ARROW_LEFT && event.code !== Keys.ARROW_RIGHT) {
      return;
    }
    event.stopPropagation();
    // změníme volume
    const newVolume = volume - VOLUME_SLIDER_STEP * (event.code === Keys.ARROW_LEFT ? 1 : -1);
    analytics.trigger({ type: 'PlayerSettingsChangeVolume' });
    // omezíme 0 - 1
    onVolumeChange(Math.min(1, Math.max(0, newVolume)));
  };

  const volumePercentage = Math.round(volume * 100);

  return (
    <div
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={volumePercentage}
      aria-valuetext={`${formatMessage(messages.soundAdjustment)} ${volumePercentage}%`}
      className={classNames(classes.volumeControlContainer, { visible: visible || isFocused })}
      data-testid="VolumeControl"
      role="slider"
      tabIndex={-1}
      title={`${formatMessage(messages.soundLevel)} ${volumePercentage}%`}
      onClick={(e) => {
        e.preventDefault();
        onVolumeChange(knobNewPosition(e));
      }}
      onKeyDown={onContainerKeyPress}
      onMouseDown={onMouseDown}
      onTouchEnd={(e) => {
        e.preventDefault();
        onDragChange(false);
      }}
      onTouchMove={(e) => {
        onVolumeChange(knobNewPosition(e));
      }}
      onTouchStart={(e) => {
        onVolumeChange(knobNewPosition(e));
        onDragChange(true);
      }}
    >
      <div
        className={classNames(classes.inner, focusVisibleClassName)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      >
        <div className={classes.innerBackground}>
          <div
            className={classes.volumeControlWrapper}
            data-testid="VolumeControlWrapper"
            ref={containerRef}
          >
            <div
              className={classes.volumeIndicator}
              data-testid="VolumeControlIndicator"
              style={{ width: `${volume * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VolumeKnob);
