import React, { createContext, memo, useContext, useEffect } from 'react';
import { PlayerClient } from '.';

export interface PlayerClientContextValue {
  playerClient?: PlayerClient;
  setPlayerClient: (playerClient: PlayerClient | undefined) => void;
}

interface Props {
  children: React.ReactNode;
  playerClient?: PlayerClient;
}

export const PlayerClientContext = createContext<PlayerClientContextValue | undefined>(undefined);

export const usePlayerClientContext = () => useContext(PlayerClientContext);

export const PlayerClientProvider = memo<Props>(
  ({ children, playerClient: defaultPlayerClient }) => {
    const [playerClient, setPlayerClient] = React.useState<PlayerClient | undefined>(
      defaultPlayerClient
    );

    useEffect(() => {
      setPlayerClient(defaultPlayerClient);
    }, [defaultPlayerClient]);

    return (
      <PlayerClientContext.Provider value={{ playerClient, setPlayerClient }}>
        {children}
      </PlayerClientContext.Provider>
    );
  }
);
