import { ScreenMode } from '../constants';

// TODO: Detekce pipu ve Firefoxu
// TODO: Detekce pipu v Chrome, Android
export const getScreenMode = (element: HTMLElement): ScreenMode => {
  const { webkitPresentationMode } = element as any;

  const { webkitIsFullScreen } = document as any;
  if (webkitIsFullScreen !== undefined) {
    return (document as any).webkitIsFullScreen ? ScreenMode.FULLSCREEN : ScreenMode.NORMAL;
  }

  if (webkitPresentationMode === 'inline') {
    return ScreenMode.NORMAL;
  }

  if (webkitPresentationMode === 'fullscreen') {
    return ScreenMode.FULLSCREEN;
  }

  if (webkitPresentationMode === 'picture-in-picture') {
    return ScreenMode.PICTURE_IN_PICTURE;
  }

  if (document.fullscreenElement) {
    return ScreenMode.FULLSCREEN;
  }

  return ScreenMode.NORMAL;
};

export const leaveFullscreen = async (): Promise<void> => {
  const { webkitCancelFullScreen } = document as any;
  if (webkitCancelFullScreen !== undefined) {
    return (document as any).webkitCancelFullScreen();
  }
  return document.exitFullscreen();
};

// detekce podpory webkitového fullscreenu
export const hasWebkitFullscreen = (element: HTMLElement) => {
  return (element as any).webkitRequestFullScreen !== undefined;
};

export const enterFullscreen = async (element: HTMLElement): Promise<void> => {
  // přepínání fullscreen safari mac
  if (hasWebkitFullscreen(element)) {
    return (element as any).webkitRequestFullScreen();
  }
  // ios neumí fullscreen na wrapperu, umí jen fullscreen na videu
  if (!element.requestFullscreen) {
    const video = element.querySelector('video');
    if (!video) {
      return Promise.reject(new Error('Video element was not found'));
    }
    return (video as any).webkitEnterFullscreen();
  }

  return element.requestFullscreen();
};
