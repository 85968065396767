export const formatTime = (time: number, format: string = 'H:i:s', digits: number = 0): string => {
  let seconds = Math.floor(time) || 0;
  let result = '';

  const hours = Math.floor(seconds / 3600);

  // G jsou hodiny se zakazanym zero-paddingem + udaj hodin se vynecha pokud je 0
  if (format.includes('H') || (format.includes('G') && hours > 0)) {
    result += hours.toString().padStart(format.includes('G') ? 0 : digits || 0, '0');
    if (format.includes('H:') || format.includes('G:')) {
      result += ':';
    }

    if (hours > 0) {
      seconds -= hours * 3600;
    }
  }

  if (format.includes('i')) {
    const minutes = Math.floor(seconds / 60);
    result += minutes.toString().padStart(digits || 0, '0');
    if (format.includes('i:')) {
      result += ':';
    }

    if (minutes > 0) {
      seconds -= minutes * 60;
    }
  }

  if (format.includes('s')) {
    result += seconds.toString().padStart(digits || 0, '0');
  }

  return result;
};

const MINUTE_SINGLE = 'minuta';
const MINUTE_MULTIPLE_LOW = 'minuty';
const MINUTE_MULTIPLE_HIGH = 'minut';

export const timeUnitMinute = (time: number) => {
  switch (true) {
    case time > 300:
      return MINUTE_MULTIPLE_HIGH;
    case time < 300 && time > 119:
      return MINUTE_MULTIPLE_LOW;
    case time <= 119 && time > 59:
      return MINUTE_SINGLE;
    case time <= 59:
      return MINUTE_MULTIPLE_HIGH;
    default:
      return MINUTE_MULTIPLE_HIGH;
  }
};

const SECOND_SINGLE = 'sekundu';
const SECOND_MULTIPLE_LOW = 'sekundy';
const SECOND_MULTIPLE_HIGH = 'sekund';

export const timeUnitSecond = (time: number) => {
  switch (true) {
    case time > 4:
      return SECOND_MULTIPLE_HIGH;
    case time <= 4 && time > 1:
      return SECOND_MULTIPLE_LOW;
    case time === 1:
      return SECOND_SINGLE;
    default:
      return SECOND_MULTIPLE_HIGH;
  }
};

// formáty
// XXXX:XX:XX
// XX:XX
// XX
export const convertTimeCodeToSeconds = (timeCode: string): number => {
  const times = timeCode.split(':').map((value) => Number(value));
  if (times.length > 3) {
    throw new Error(`Invalid timecode ${timeCode} has more than 4 colons.`);
  }
  const invalidInputs = times.filter((value) => !Number.isInteger(value));
  if (invalidInputs.length > 0) {
    throw new Error(`Timecode ${timeCode} has invalid inputs ${invalidInputs.join(', ')}.`);
  }
  if (times.length === 3) {
    return times[0] * 60 * 60 + times[1] * 60 + times[2];
  }
  if (times.length === 2) {
    return times[0] * 60 + times[1];
  }
  return times[0];
};

export const getDurationInMinutes = (durationInSeconds: number) => {
  // zaokrouhlejeme minuty vzdy nahoru
  return Math.ceil(durationInSeconds / 60);
};

export const getReadableDuration = (durationInSeconds: number) => {
  const durationInMinutes = getDurationInMinutes(durationInSeconds);

  return `${durationInMinutes} ${timeUnitMinute(durationInMinutes * 60)}`;
};
