import React from 'react';
import { ComponentBasedResponsive, ComponentBasedResponsiveProps } from '@czechtv/components';
import { PLAYER_RESPONSIVE_PREFIX, useMediaBreakpoints } from '../../utils/playerResponsive';

interface PlayerBasedResponsiveProps
  extends Omit<ComponentBasedResponsiveProps, 'classPrefix' | 'breakpoints'> {}

const PlayerBasedResponsive = (props: PlayerBasedResponsiveProps) => {
  return (
    <ComponentBasedResponsive
      {...props}
      breakpoints={useMediaBreakpoints}
      classPrefix={PLAYER_RESPONSIVE_PREFIX}
    />
  );
};

export default PlayerBasedResponsive;
