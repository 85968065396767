import {
  Channel_CT1,
  Channel_CT2,
  Channel_CT3,
  Channel_CT_Sport,
  Channel_CT24,
  Channel_CT_D,
  Channel_CT_Art,
  Red_base,
} from './styles';
import { getEnumValues } from './enum';
import { assertNever } from './assertNever';

// enum slugů kanálu pro url webu
export enum ChannelSlug {
  art = 'art',
  ct1 = 'ct1',
  ct1Brno = 'ct1-brno',
  ct1JihozapadniCechy = 'ct1-jihozapadni-cechy',
  ct1Ostrava = 'ct1-ostrava',
  ct1SeverovychodniCechy = 'ct1-severovychodni-cechy',
  ct2 = 'ct2',
  ct24 = 'ct24',
  decko = 'decko',
  sport = 'sport',
}

// enum kanálů které user uvidí na webu
export enum Channel {
  art = 'art',
  ct1 = 'ct1',
  ct1Brno = 'ct1Brno',
  ct1JihozapadniCechy = 'ct1JihozapadniCechy',
  ct1Ostrava = 'ct1Ostrava',
  ct1SeverovychodniCechy = 'ct1SeverovychodniCechy',
  ct2 = 'ct2',
  ct24 = 'ct24',
  ct24plus = 'ct24plus',
  ct3 = 'ct3',
  ctSportExtra = 'ctSportExtra',
  decko = 'decko',
  iVysilani = 'iVysilani',
  sport = 'sport',
}

export enum Encoder {
  'CH_1' = 'CH_1',
  'CH_1_JM' = 'CH_1_JM',
  'CH_1_JZC' = 'CH_1_JZC',
  'CH_1_SM' = 'CH_1_SM',
  'CH_1_SVC' = 'CH_1_SVC',
  'CH_2' = 'CH_2',
  'CH_24' = 'CH_24',
  'CH_25' = 'CH_25',
  'CH_26' = 'CH_26',
  'CH_27' = 'CH_27',
  'CH_28' = 'CH_28',
  'CH_29' = 'CH_29',
  'CH_30' = 'CH_30',
  'CH_31' = 'CH_31',
  'CH_32' = 'CH_32',
  'CH_4' = 'CH_4',
  'CH_5' = 'CH_5',
  'CH_6' = 'CH_6',
  'CH_7' = 'CH_7',
  'CH_MOB_01' = 'CH_MOB_01',
  'CH_MP_01' = 'CH_MP_01',
  'CH_MP_02' = 'CH_MP_02',
  'CH_MP_03' = 'CH_MP_03',
  'CH_MP_04' = 'CH_MP_04',
  'CH_MP_05' = 'CH_MP_05',
  'CH_MP_06' = 'CH_MP_06',
  'CH_MP_07' = 'CH_MP_07',
  'CH_MP_08' = 'CH_MP_08',
}

export type ChannelType = keyof typeof Channel;

export const getEncoder = (channel: Channel): string | undefined => {
  switch (channel) {
    case Channel.ct1:
      return Encoder.CH_1;
    case Channel.ct2:
      return Encoder.CH_2;
    case Channel.ct3:
      return Encoder.CH_7;
    case Channel.ct24:
      return Encoder.CH_24;
    case Channel.sport:
      return Encoder.CH_4;
    case Channel.art:
      return Encoder.CH_6;
    case Channel.decko:
      return Encoder.CH_5;
    case Channel.ct1Brno:
      return Encoder.CH_1_JM;
    case Channel.ct1Ostrava:
      return Encoder.CH_1_SM;
    case Channel.ct1JihozapadniCechy:
      return Encoder.CH_1_JZC;
    case Channel.ct1SeverovychodniCechy:
      return Encoder.CH_1_SVC;
    default:
      return undefined;
  }
};

export const getChannelTitle = (channel: Channel | ChannelType) => {
  switch (channel) {
    case Channel.ct1:
    case 'ct1':
      return 'ČT1';
    case Channel.ct1Brno:
    case 'ct1Brno':
      return 'ČT1 (Brno)';
    case Channel.ct1Ostrava:
    case 'ct1Ostrava':
      return 'ČT1 (Ostrava)';
    case Channel.ct1JihozapadniCechy:
    case 'ct1JihozapadniCechy':
      return 'ČT1 (Jihozápadní Čechy)';
    case Channel.ct1SeverovychodniCechy:
    case 'ct1SeverovychodniCechy':
      return 'ČT1 (Severovýchodní Čechy)';
    case Channel.ct2:
    case 'ct2':
      return 'ČT2';
    case Channel.ct3:
    case 'ct3':
      return 'ČT3';
    case Channel.ct24:
    case 'ct24':
      return 'ČT24';
    case Channel.decko:
    case 'decko':
      return 'ČT :D';
    case Channel.art:
    case 'art':
      return 'ČT art';
    case Channel.sport:
    case 'sport':
      return 'ČT sport';
    case Channel.ctSportExtra:
    case 'ctSportExtra':
      return 'ČT sport Plus';
    case Channel.iVysilani:
    case 'iVysilani':
      return 'iVysílání';
    case Channel.ct24plus:
    case 'ct24plus':
      return 'ČT24 plus';
    default:
      return assertNever(channel);
  }
};

export const getChannelColor = (channel: ChannelType): string => {
  switch (channel) {
    case 'ct1':
    case 'ct1Brno':
    case 'ct1Ostrava':
    case 'ct1JihozapadniCechy':
    case 'ct1SeverovychodniCechy':
      return Channel_CT1;
    case 'ct2':
      return Channel_CT2;
    case 'ct3':
      return Channel_CT3;
    case 'ct24':
    case 'ct24plus':
      return Channel_CT24;
    case 'sport':
    case 'ctSportExtra':
      return Channel_CT_Sport;
    case 'decko':
      return Channel_CT_D;
    case 'art':
      return Channel_CT_Art;
    default:
      return Red_base;
  }
};

export const channels = getEnumValues(Channel) as ChannelType[];

export const channelsWithoutRegions = getEnumValues(Channel).filter(
  (channel) =>
    ![
      Channel.ct1Brno,
      Channel.ct1Ostrava,
      Channel.ct1SeverovychodniCechy,
      Channel.ct1JihozapadniCechy,
    ].includes(channel)
);

// součástí nejsou regionální kanály
export const terrestrialChannels = [
  Channel.ct1,
  Channel.ct2,
  Channel.sport,
  Channel.ct24,
  Channel.art,
  Channel.decko,
  Channel.ct3,
];
