import classNames from 'classnames';
import React, { HTMLAttributes, memo } from 'react';
import { Red_base } from '@czechtv/styles';
import { progressBarClassnames } from './ProgressBar.css';

export interface ProgressBarProps extends Omit<HTMLAttributes<HTMLDivElement>, 'role'> {
  className?: string;
  color?: string;
  progress: number;
}

export const ProgressBar = memo<ProgressBarProps>(
  ({ color = Red_base, progress, className, ...rest }) => {
    return (
      <div
        className={classNames(progressBarClassnames.progressBarContainer, className)}
        data-testid="progressBar"
        role="progressbar"
        {...rest}
      >
        <div
          className={progressBarClassnames.progressBar}
          style={{ width: `${progress}%`, backgroundColor: color }}
        />
      </div>
    );
  }
);
