import React, { CSSProperties, memo, ReactNode } from 'react';
import classNames from 'classnames';
import { gradientOverlayClassnames } from './GradientOverlay.css';

interface GradientOverlayProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  style?: CSSProperties;
}

const GradientOverlay = ({ children, className, style }: GradientOverlayProps) => {
  return (
    <div className={classNames(gradientOverlayClassnames.gradientOverlay, className)} style={style}>
      {children}
    </div>
  );
};

export default memo(GradientOverlay);
