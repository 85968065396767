import classNames from 'classnames';
import React from 'react';
import { IconCheckMark } from '@czechtv/icons';
import { checkboxIconClassnames } from './CheckboxIcon.css';

interface Props {
  disabled?: boolean;
  inverted?: boolean;
}

export const CheckboxIcon = ({ disabled, inverted }: Props) => {
  return (
    <IconCheckMark
      className={classNames(checkboxIconClassnames.icon, {
        inverted,
        disabled,
      })}
      height={20}
      width={20}
    />
  );
};
