import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { label14 } from '@czechtv/styles';

interface LabelingProps {
  ageRestriction: string;
  className?: string;
}

const useStyles = createUseStyles({
  labeling: {
    ...label14,
    fontWeight: 600,
    position: 'relative',
    color: '#fff',
    margin: '14px auto',
    width: 56,
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
});

const Labeling = ({ ageRestriction, className }: LabelingProps) => {
  const classes = useStyles();
  return <div className={classNames(classes.labeling, className)}>{ageRestriction}</div>;
};

export default memo(Labeling);
