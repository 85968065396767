import { Styles } from 'react-jss';
import { body14, body16 } from '@czechtv/styles';

const styles: Styles = {
  icon: {
    width: 24,
    height: 24,
    fill: '#fff',
    background: 'initial',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    marginRight: 12,
    '&:focus': {
      outline: '2px solid transparent',
    },
    '&:focus-visible': {
      outline: '2px solid #60a0ff',
    },
  },
  listHeader: {
    ...body16,
    textTransform: 'uppercase',
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',
  },
  popupContent: {
    padding: 0,
  },
  settingsListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  linksWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: [0, 16, 18, 14],
    '&> a, &> button': {
      ...body14,
      color: '#C6C6C6',
      background: 'none',
      border: 'none',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&> a:not(:last-child)': {
      marginRight: 16,
    },
  },
};

export default styles;
