import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import { IconList } from '@czechtv/icons';
import { useFocusVisibleClassName } from '@czechtv/components';
import { formatMessage } from '../../../../utils/formatMessage';
import { VideoMeta } from '../../../../components/PlayerPreview/PlayerPreview';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { videoTitleClassnames } from './VideoTitle.css';

interface VideoTitleProps {
  className?: string;
  hbbtvStreamingActiveDevice?: boolean;
  indexTitle?: string | null;
  meta: VideoMeta;
  onIndexTitleClick?: () => void;
}

export const VideoTitle = memo(
  ({
    className,
    hbbtvStreamingActiveDevice,
    meta,
    indexTitle,
    onIndexTitleClick: onIndexTitleClickCallback,
  }: VideoTitleProps) => {
    // V Safari neni videt focus, pokud neni inset
    const focusVisibleClassName = useFocusVisibleClassName({ inset: true });
    const { title, showTitle, indexes } = meta;
    const mainTitle = title || showTitle;

    const onIndexTitleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onIndexTitleClickCallback) {
          e.stopPropagation();
          onIndexTitleClickCallback();
        }
      },
      [onIndexTitleClickCallback]
    );

    return (
      <div className={classNames(videoTitleClassnames.container, className)}>
        {showTitle && title && <p className={videoTitleClassnames.showTitle}>{showTitle}</p>}
        {mainTitle && <p className={videoTitleClassnames.videoTitle}>{mainTitle}</p>}
        {indexes.length > 0 && !hbbtvStreamingActiveDevice && (
          <PlayerNativeButton
            aria-haspopup="dialog"
            className={classNames(videoTitleClassnames.indexTitle, focusVisibleClassName)}
            data-testid="IndexTitle"
            onClick={onIndexTitleClick}
          >
            {indexTitle ? (
              <div className={videoTitleClassnames.indexTitleWrapper}>
                <div className={videoTitleClassnames.indexTitleContainer}>
                  <IconList height={23} width={23} />
                  <div
                    className={videoTitleClassnames.indexLabel}
                    style={{ outline: 'none' }}
                    tabIndex={-1}
                  >
                    <span style={{ outline: 'none' }} tabIndex={-1}>
                      {formatMessage({
                        id: 'VideoTitle.buttonOpenIndexWithDetails',
                        defaultMessage: 'Části',
                        description: 'Tlačítko pro otevření seznamu indexů - info o indexu',
                      })}
                    </span>
                  </div>
                  <div
                    className={videoTitleClassnames.indexTitleContent}
                    style={{ outline: 'none' }}
                    tabIndex={-1}
                  >
                    {`:\xa0${indexTitle}`}
                  </div>
                  <div
                    className={videoTitleClassnames.indexTitleCount}
                    style={{ outline: 'none' }}
                    tabIndex={-1}
                  >
                    {`\xa0(${indexes.length})`}
                  </div>
                </div>
              </div>
            ) : (
              <span className={videoTitleClassnames.indexLabel}>
                <IconList height={24} width={24} />
                <span style={{ outline: 'none' }} tabIndex={-1}>
                  {formatMessage(
                    {
                      id: 'VideoTitle.buttonOpenIndexInitial',
                      defaultMessage: 'Zobrazit části videa\xa0({message})',
                      description: 'Tlačítko pro otevření seznamu indexů',
                    },
                    { message: indexes.length.toString() }
                  )}
                </span>
              </span>
            )}
          </PlayerNativeButton>
        )}
      </div>
    );
  }
);
