import React from 'react';
import classNames from 'classnames';
import { IconHeadphones } from '@czechtv/icons';
import { useMobileDevice } from '@czechtv/styles';
import { useMediaBreakpoints } from '../../../../utils/playerResponsive';
import { extraControlsClassnames } from './ExtraControls.css';
import { audioOnlyClassnames } from './AudioOnly.css';

interface AudioOnlyProps {
  className?: string;
  isPreview?: boolean;
  message?: string;
}

export const AudioOnly = ({
  className,
  isPreview,
  message = 'Obsahuje pouze zvuk',
}: AudioOnlyProps) => {
  const isMobileDevice = useMobileDevice();
  const { isMinMobile } = useMediaBreakpoints();

  return (
    <div
      className={classNames(
        className,
        extraControlsClassnames.wrapper,
        audioOnlyClassnames.audioOnlyWrapper,
        {
          [extraControlsClassnames.controlsVisible]: !isPreview,
          isMinMobile,
          isNotMobileDevice: !isMobileDevice,
        }
      )}
    >
      <div className={classNames(extraControlsClassnames.button, audioOnlyClassnames.audioOnly)}>
        <div
          className={classNames(
            extraControlsClassnames.iconWrapper,
            audioOnlyClassnames.audioOnlyIconWrapper
          )}
        >
          <IconHeadphones
            className={classNames(extraControlsClassnames.icon, audioOnlyClassnames.audioOnlyIcon)}
            color="#fff"
          />
        </div>
        <div className={extraControlsClassnames.title}>{message}</div>
      </div>
    </div>
  );
};
