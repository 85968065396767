import React, { memo } from 'react';
import { AnalyticsContextProvider } from '@czechtv/analytics-react';
import { PlayerErrorOverlayStandalone as PlayerErrorOverlay } from '../Error/Overlay/Overlay';
import { useDefaultAnalyticsSubscribers } from '../Analytics/useDefaultAnalyticsSubscribers';
import { Product } from '../../constants';
import {
  ErrorResponse,
  getPlaylistErrorDescription,
  PlaylistError,
  PlaylistErrorCodes,
} from '../Error/playlistError/playlistError';

interface Props {
  mainContentId?: string;
  startupProps: {
    czechTVBaseUri: string;
    dev?: boolean;
    errorDetail: PlaylistError | null;
    gemiusId?: string;
    gemiusPlayerId?: string;
    nielsenAppId?: string;
    nielsenDebug?: boolean;
    product: Product;
    suppressAnalytics?: boolean;
    usingNewLivePlaylist?: boolean;
  };
}

export const PlaylistPlayerError = memo(
  ({
    startupProps: {
      errorDetail,
      product,
      dev,
      gemiusId,
      gemiusPlayerId,
      nielsenAppId,
      nielsenDebug,
      suppressAnalytics,
      czechTVBaseUri,
      usingNewLivePlaylist,
    },
    mainContentId,
  }: Props) => {
    const defaultAnalyticsSubscribers = useDefaultAnalyticsSubscribers({
      product,
      dev,
      gemiusId,
      gemiusPlayerId,
      nielsenAppId,
      nielsenDebug,
      suppressAnalytics,
    });
    // v pripade, ze dojde k erroru pri nacitani playlistu, posilame do analytiky erroru
    // ve vetsine pripadu to pry znamena, ze neni dostupna licence - analytika si to prebere

    const { title, subtitle } = getPlaylistErrorDescription({
      errorCode: errorDetail?.error,
      licenseErrorCodes: errorDetail?.message || undefined,
    } as ErrorResponse);

    const showFooter = !(errorDetail && errorDetail.error === PlaylistErrorCodes.PROMO_EXPIRED);

    return (
      <AnalyticsContextProvider subscribers={defaultAnalyticsSubscribers}>
        <PlayerErrorOverlay
          baseUrl={czechTVBaseUri}
          errorDescription={{ title, subtitle }}
          errorDetail={errorDetail?.message}
          mainContentId={mainContentId}
          showFooter={showFooter}
          usingNewLivePlaylist={usingNewLivePlaylist}
        />
      </AnalyticsContextProvider>
    );
  }
);
