import {
  SHAKA_CANNOT_STORE_LIVE_OFFLINE,
  SHAKA_CURRENT_DAI_REQUEST_NOT_FINISHED,
  SHAKA_QUOTA_EXCEEDED_ERROR,
  SHAKA_STORAGE_NOT_SUPPORTED,
} from '../../Providers/Client/Shaka/ShakaError';
import { formatMessage } from '../../utils/formatMessage';
import { PlayerErrorCategory } from './PlayerErrorCategory';
import { isNetworkOnline, PlayerNormalizedError } from './playerError';
import { PlayerErrorMessage } from './Overlay/Overlay';

const messages = {
  networkOfflineTitle: {
    id: 'PlayerError.network.isOffline.title',
    defaultMessage: 'Zdá se, že nemáte připojení k internetu. ',
    description: 'PlayerErrorCategory.NETWORK: offline 1/2',
  },
  networkOfflineSubtitle: {
    id: 'PlayerError.network.isOffline.subtitle',
    defaultMessage: 'Připojte zařízení a zkuste video %načíst znovu%.',
    description: 'PlayerErrorCategory.NETWORK: offline 2/2',
  },
  networkOnlineTitle: {
    id: 'PlayerError.network.isOnline.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.NETWORK: online 1/2',
  },
  networkOnlineSubtitle: {
    id: 'PlayerError.network.isOnline.subtitle',
    defaultMessage: 'Zkuste ho prosím %načíst znovu%.',
    description: 'PlayerErrorCategory.NETWORK: online 2/2',
  },
  textTrackTitle: {
    id: 'PlayerError.textTrack.title',
    defaultMessage: 'Titulky v tuto chvíli bohužel nejsou k dispozici.',
    description: 'PlayerErrorCategory.TEXT 1/1',
  },
  mediaQuotaExceededTitle: {
    id: 'PlayerError.media.quotaExceeded.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.MEDIA: quota exceeded 1/2',
  },
  mediaQuotaExceededSubtitle: {
    id: 'PlayerError.media.quotaExceeded.subtitle',
    defaultMessage: 'Zkuste prosím snížit kvalitu přehrávaného videa.',
    description: 'PlayerErrorCategory.MEDIA: quota exceeded 2/2',
  },
  mediaDefaultTitle: {
    id: 'PlayerError.media.default.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.MEDIA: ostatni media errory 1/2',
  },
  mediaDefaultSubtitle: {
    id: 'PlayerError.media.default.subtitle',
    defaultMessage: 'Usilovně pracujeme na opravě chyby. Zkuste prosím video spustit později.',
    description: 'PlayerErrorCategory.MEDIA: ostatni media errory 2/2',
  },
  manifestTitle: {
    id: 'PlayerError.manifest.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.MANIFEST 1/2',
  },
  manifestSubtitle: {
    id: 'PlayerError.manifest.subtitle',
    defaultMessage: 'Usilovně pracujeme na opravě chyby. Zkuste prosím video spustit později.',
    description: 'PlayerErrorCategory.MANIFEST 2/2',
  },
  streamingTitle: {
    id: 'PlayerError.streaming.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.STREAMING 1/2',
  },
  streamingSubtitle: {
    id: 'PlayerError.streaming.subtitle',
    defaultMessage: 'Zkuste ho prosím %načíst znovu%.',
    description: 'PlayerErrorCategory.STREAMING 2/2',
  },
  drmTitle: {
    id: 'PlayerError.drm.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.DRM 1/2',
  },
  drmSubtitle: {
    id: 'PlayerError.drm.subtitle',
    defaultMessage: 'Zkuste ho prosím %načíst znovu%.',
    description: 'PlayerErrorCategory.DRM 2/2',
  },
  playerTitle: {
    id: 'PlayerError.player.title',
    defaultMessage: 'Video se nepodařilo načíst.',
    description: 'PlayerErrorCategory.PLAYER 1/2',
  },
  playerSubtitle: {
    id: 'PlayerError.player.subtitle',
    defaultMessage: 'Zkuste ho prosím %načíst znovu%.',
    description: 'PlayerErrorCategory.PLAYER 2/2',
  },
  castDefaultTitle: {
    id: 'PlayerError.cast.default.title',
    defaultMessage: 'Není dostupné žádné zařízení pro zrcadlení obrazovky.',
    description: 'PlayerErrorCategory.CAST 1/1',
  },
  storageDefaultTitle: {
    id: 'PlayerError.storage.default.title',
    defaultMessage: 'Video bohužel nelze sledovat offline.',
    description: 'PlayerErrorCategory.STORAGE: vychozi zprava 1/2',
  },
  storageDefaultSubtitle: {
    id: 'PlayerError.storage.default.subtitle',
    defaultMessage: 'Sledovat ho můžete pouze s připojením na internet.',
    description: 'PlayerErrorCategory.STORAGE: vychozi zprava 2/2',
  },
  storageNotSupportedTitle: {
    id: 'PlayerError.storage.notSupported.title',
    defaultMessage: 'Video nelze sledovat offline v tomto prohlížeči.',
    description: 'PlayerErrorCategory.STORAGE: not supported 1/2',
  },
  storageNotSupportedSubtitle: {
    id: 'PlayerError.storage.notSupported.subtitle',
    defaultMessage: 'Zkuste ho prosím spustit v jiném prohlížeči nebo se připojte na internet.',
    description: 'PlayerErrorCategory.STORAGE: not supported 2/2',
  },
  storageCanNotStoreLiveTitle: {
    id: 'PlayerError.storage.canNotStoreLive.title',
    defaultMessage: 'Živé video bohužel nelze sledovat offline.',
    description: 'PlayerErrorCategory.STORAGE: live video nelze ulozit 1/2',
  },
  storageCanNotStoreLiveSubtitle: {
    id: 'PlayerError.storage.canNotStoreLive.subtitle',
    defaultMessage: 'Vyčkejte až bude uložené v archivu nebo se připojte na internet.',
    description: 'PlayerErrorCategory.STORAGE: live video nelze ulozit 2/2',
  },
  adsDefaultTitle: {
    id: 'PlayerError.ads.default.title',
    defaultMessage: 'Reklamu se nepodařilo načíst.',
    description: 'PlayerErrorCategory.ADS: Vychozi zprava 1/1',
  },
  adsDaiNotFinishedTitle: {
    id: 'PlayerError.ads.daiNotFinished.title',
    defaultMessage: 'Reklamu se nepodařilo načíst.',
    description: 'PlayerErrorCategory.ADS: CURRENT_DAI_REQUEST_NOT_FINISHED 1/2',
  },
  adsDaiNotFinishedSubtitle: {
    id: 'PlayerError.ads.daiNotFinished.subtitle',
    defaultMessage: 'Přehrávaný pořad se spustí za okamžik.',
    description: 'PlayerErrorCategory.ADS: CURRENT_DAI_REQUEST_NOT_FINISHED 2/2',
  },
  unsupportedBrowserTitle: {
    id: 'PlayerError.unsupportedBrowser.title',
    defaultMessage: 'Video se nepodařilo spustit v tomto prohlížeči.',
    description: 'PlayerErrorCategory.ADS: CURRENT_DAI_REQUEST_NOT_FINISHED 2/2',
  },
  unsupportedBrowserSubtitle: {
    id: 'PlayerError.unsupportedBrowser.subtitle',
    defaultMessage: 'Zkuste aktualizovat verzi prohlížeče nebo použít jiný.',
    description: 'PlayerErrorCategory.ADS: CURRENT_DAI_REQUEST_NOT_FINISHED 2/2',
  },
};

export const getErrorMessage = (playerError: PlayerNormalizedError): PlayerErrorMessage => {
  switch (playerError.category) {
    case PlayerErrorCategory.NETWORK:
      if (!isNetworkOnline()) {
        return {
          title: formatMessage(messages.networkOfflineTitle),
          subtitle: formatMessage(messages.networkOfflineSubtitle),
        };
      }
      return {
        title: formatMessage(messages.networkOnlineTitle),
        subtitle: formatMessage(messages.networkOnlineSubtitle),
      };

    case PlayerErrorCategory.TEXT:
      return {
        title: formatMessage(messages.textTrackTitle),
      };

    case PlayerErrorCategory.MEDIA:
      if (playerError.code === SHAKA_QUOTA_EXCEEDED_ERROR) {
        return {
          title: formatMessage(messages.mediaQuotaExceededTitle),
          subtitle: formatMessage(messages.mediaQuotaExceededSubtitle),
        };
      }
      return {
        title: formatMessage(messages.mediaDefaultTitle),
        subtitle: formatMessage(messages.mediaDefaultSubtitle),
      };

    case PlayerErrorCategory.MANIFEST:
      return {
        title: formatMessage(messages.manifestTitle),
        subtitle: formatMessage(messages.manifestSubtitle),
      };

    case PlayerErrorCategory.STREAMING:
      return {
        title: formatMessage(messages.streamingTitle),
        subtitle: formatMessage(messages.streamingSubtitle),
      };

    case PlayerErrorCategory.DRM:
      return {
        title: formatMessage(messages.drmTitle),
        subtitle: formatMessage(messages.drmSubtitle),
      };

    case PlayerErrorCategory.PLAYER:
      return {
        title: formatMessage(messages.playerTitle),
        subtitle: formatMessage(messages.playerSubtitle),
      };
    case PlayerErrorCategory.BROWSER:
      return {
        title: formatMessage(messages.unsupportedBrowserTitle),
        subtitle: formatMessage(messages.unsupportedBrowserSubtitle),
      };
    // TODO: po MVP dodělat detailní rozdělení errorů pro cast
    case PlayerErrorCategory.CAST:
      return {
        title: formatMessage(messages.castDefaultTitle),
      };

    case PlayerErrorCategory.STORAGE:
      if (playerError.code === SHAKA_STORAGE_NOT_SUPPORTED) {
        return {
          title: formatMessage(messages.storageNotSupportedTitle),
          subtitle: formatMessage(messages.storageNotSupportedSubtitle),
        };
      }
      if (playerError.code === SHAKA_CANNOT_STORE_LIVE_OFFLINE) {
        return {
          title: formatMessage(messages.storageCanNotStoreLiveTitle),
          subtitle: formatMessage(messages.storageCanNotStoreLiveSubtitle),
        };
      }
      return {
        title: formatMessage(messages.storageDefaultTitle),
        subtitle: formatMessage(messages.storageDefaultSubtitle),
      };

    case PlayerErrorCategory.ADS:
      if (playerError.code === SHAKA_CURRENT_DAI_REQUEST_NOT_FINISHED) {
        return {
          title: formatMessage(messages.adsDaiNotFinishedTitle),
          subtitle: formatMessage(messages.adsDaiNotFinishedSubtitle),
        };
      }
      return {
        title: formatMessage(messages.adsDefaultTitle),
      };

    default:
      return {};
  }
};
