import { log } from './logger';

const defaultPreviewDomain = 'imgct.ceskatelevize.cz';
const allowedPreviewDomains = [/ceskatelevize.cz$/, /o2tv.cz$/, /vecko.dev$/];
// TODO: prehodnotit, pripadne nepouzivat vubec - asi zbytecna validace
export const getValidPreviewImage = (inputPreviewImage?: string): string | undefined => {
  if (!inputPreviewImage) {
    return undefined;
  }

  const previewImage = inputPreviewImage.replace(/^\/\//, 'https://').replace(/^\//, '');

  const previewImageLink =
    previewImage.search(/^http/) === -1
      ? `https://${defaultPreviewDomain}/${previewImage}`
      : previewImage;

  try {
    const parsedUrl = new URL(previewImageLink);
    if (parsedUrl.protocol !== 'https:') {
      log.debug({ message: `Url ${inputPreviewImage} is not https.` });
      return undefined;
    }

    if (!allowedPreviewDomains.some((domain) => domain.test(parsedUrl.hostname))) {
      log.debug({ message: `Url ${inputPreviewImage} does not have valid domain.` });
      return undefined;
    }
    return previewImageLink;
  } catch (exception) {
    log.debug({
      error: exception,
      message: `Url ${inputPreviewImage} for preview image is not valid.`,
    });
    return undefined;
  }
};
