export const IVYS_ID_TOKEN_COOKIE_NAME = 'iVysilaniIdToken';

export const getCookie = (name: string) => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const match = cookie.match(/([^=]+?)=(.*)/);
    if (match) {
      const [, name, value] = match;
      acc.set(name, value);
    }
    return acc;
  }, new Map<string, string>());
  return cookies.get(name);
};

function getOptanonConsentValues() {
  const cookieValue = getCookie('OptanonConsent');
  if (!cookieValue) {
    return null;
  }

  const params = new URLSearchParams(cookieValue.replace(/\+/g, ' '));
  const groups = params.get('groups');

  if (!groups) {
    return null;
  }

  const requiredKeys = ['C0001', 'C0002', 'C0003', 'C0004'];
  const groupValues: Record<string, boolean> = {};

  groups.split(',').forEach((group) => {
    const [key, value] = group.split(':');
    if (requiredKeys.includes(key)) {
      groupValues[key] = value === '1';
    }
  });

  const allKeysValid = requiredKeys.every((key) => key in groupValues);

  if (!allKeysValid) {
    return null;
  }

  return groupValues;
}

export const getPlayerHasAnalyticsConsent = () => {
  const consentValues = getOptanonConsentValues();
  if (!consentValues) {
    return false;
  }
  // souhlas s analytikou
  return !!consentValues['C0002'];
};
