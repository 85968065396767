import classNames from 'classnames';
import React, { memo } from 'react';
import { IconSpinner } from '@czechtv/icons';
import { spinnerClassnames } from './Spinner.css';

export interface SpinnerProps extends React.SVGAttributes<SVGElement> {}

export const Spinner = memo<SpinnerProps>(({ className, ...rest }) => {
  return <IconSpinner className={classNames(spinnerClassnames.spinner, className)} {...rest} />;
});
