import { useEffect, useRef, useState } from 'react';
import { ResizeObserver } from '@czechtv/styles';
import { debounce } from '@czechtv/utils';

export const useWidth = (ref: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState(0);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    let mounted = true;
    const element = ref.current;
    if (!element) {
      return () => {
        mounted = false;
      };
    }

    const computeNewWidth = ({ width }: { width: number }) => {
      if (!mounted) {
        return;
      }
      if (!width) {
        setWidth(0);
        return;
      }
      setWidth(Math.round(width));
    };

    computeNewWidth(element.getBoundingClientRect());

    const resizeObserverHandler = debounce((entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        computeNewWidth(entry.contentRect);
      });
    });

    resizeObserver.current = new ResizeObserver(resizeObserverHandler);
    resizeObserver.current.observe(element);

    return () => {
      resizeObserver.current?.unobserve(element);
      mounted = false;
    };
  }, [ref, setWidth]);

  return width;
};
