import React, { memo } from 'react';
import { FormElementProps, LabelProps, uniqueElementId } from '../../common';
import { TextInput } from '../../inputs/TextInput/TextInput';
import { ValidatedFormElementWrapper } from '../../ValidatedFormElementWrapper';

export interface ValidatedTextInputProps
  extends FormElementProps,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'aria-describedby' | 'id' | 'name'> {
  label: string | LabelProps;
  maxWidth?: number | string;
  showValidationIcon?: boolean;
  width?: number | string;
}

export const ValidatedTextInput = memo(
  React.forwardRef<HTMLInputElement, ValidatedTextInputProps>(
    (
      {
        label,
        name,
        prefix = '',
        width = '100%',
        maxWidth = '100%',
        inverted = false,
        showValidationIcon,
        error,
        displayValidationStatus,
        wrapperClassName,
        ...restProps
      },
      ref
    ) => {
      // id by mělo být unikátní pro celou stránku
      const id = uniqueElementId(prefix, name);
      const idDescribedBy = uniqueElementId(prefix, `error-${name}`);

      return (
        <ValidatedFormElementWrapper
          className={wrapperClassName}
          error={displayValidationStatus ? error : undefined}
          errorId={idDescribedBy}
          id={id}
          inverted={inverted}
          label={label}
        >
          <TextInput
            aria-describedby={idDescribedBy}
            id={id}
            inverted={inverted}
            isValid={displayValidationStatus ? !error : undefined}
            maxWidth={maxWidth}
            name={name}
            ref={ref}
            showValidationIcon={showValidationIcon}
            width={width}
            {...restProps}
          />
        </ValidatedFormElementWrapper>
      );
    }
  )
);
