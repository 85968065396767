import React, {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { IconClose } from '@czechtv/icons';
import { Keys } from '../../constants';
import { PlayerNativeButton } from '../PlayerNativeButton/PlayerNativeButton';
import { playerPopupClassnames } from './PlayerPopup.css';

export interface Props {
  children: React.ReactNode;
  location?: 'left' | 'right';
  onClose?: (event: React.SyntheticEvent<Element, Event> | Event) => void;
  renderAsPopup?: boolean;
}

export interface PlayerPopupContext {
  hasOpenPopup: boolean;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
}

const PlayerPopupContext = React.createContext<PlayerPopupContext | undefined>(undefined);

export const usePlayerPopupContext = () => useContext(PlayerPopupContext);

export const PlayerPopupContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [hasOpenPopup, setOpenPopup] = useState(false);
  return (
    <PlayerPopupContext.Provider value={{ hasOpenPopup, setOpenPopup }}>
      {children}
    </PlayerPopupContext.Provider>
  );
};

// TODO: VECKO-2508: Přístupnost
const PlayerPopup = ({ children, onClose, renderAsPopup = true, location = 'right' }: Props) => {
  const popupContext = usePlayerPopupContext();
  const setOpenPopup = popupContext?.setOpenPopup;
  const ref = useRef<HTMLDivElement>(null);

  // V contextu držíme informaci, jestli je popup otevřený,
  // aby se po kliku do videa netrigroval klik na video
  useEffect(() => {
    if (!setOpenPopup) {
      return () => {};
    }

    setOpenPopup(true);
    return () => {
      setOpenPopup(false);
    };
  }, [setOpenPopup]);

  // Pokud nevykreslujeme jako overlay popup, klikem mimo zavřeme okno
  useEffect(() => {
    if (!ref.current || renderAsPopup) {
      return () => {};
    }

    const popupElement = ref.current;

    const onDocumentClick = (event: Event) => {
      if (!popupElement.contains(event.target as Node) && onClose) {
        onClose(event);
      }
    };

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [ref, onClose, renderAsPopup]);

  // Pokud vykreslujeme jako popup přes celou obrazovku, zavíráme escapem.
  useEffect(() => {
    if (!renderAsPopup) {
      return () => {};
    }
    const onKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.code === Keys.ESC && onClose) {
        onClose(event);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [renderAsPopup, onClose]);

  if (!renderAsPopup) {
    return (
      <div
        className={classNames(playerPopupClassnames.absolute, playerPopupClassnames[location])}
        ref={ref}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      aria-modal
      aria-labelledby="sdiletPopup"
      className={playerPopupClassnames.overlayWrapper}
      ref={ref}
      role="dialog"
    >
      <PlayerNativeButton className={playerPopupClassnames.close} onClick={onClose}>
        <IconClose fill="white" height={24} width={24} />
      </PlayerNativeButton>
      <div>{children}</div>
    </div>
  );
};

export default memo(PlayerPopup);
