import { PlaylistStreamSubtitles } from './constants';

/* VTT titulky vypadaji nejak takto:

WEBVTT

00:00:28.840 --> 00:00:32.120
kam Češi jezdí na Velikonoce
nejčastěji do zahraničí.

00:00:32.120 --> 00:00:34.560
Barcelona je jedno
z oblíbených míst.

00:00:34.560 --> 00:00:36.920
Podrobně to probereme
před sedmou hodinou.

*/

const VTT_ARROW = '-->';

function timeToSeconds(time: string): number {
  const [hours, minutes, seconds] = time.split(':');
  return parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds);
}

function secondsToTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
    .toFixed(3)
    .padStart(6, '0')}`;
}

const createVttLink = (retimedVTTText: string) => {
  const blob = new Blob([retimedVTTText], { type: 'text/vtt' });
  return URL.createObjectURL(blob);
};

const fetchVttFileText = async (url: string) => {
  const file = await fetch(url);
  const text = await file.text();
  return text;
};

const retimeVtt = ({
  cropEnd,
  cropStart,
  vtt,
}: {
  cropEnd: number;
  cropStart: number;
  vtt: string;
}): string => {
  const lines = vtt.split('\n');
  let output = 'WEBVTT\n\n';

  for (let i = 0; i < lines.length; i += 1) {
    if (lines[i].includes(VTT_ARROW)) {
      const [start, end] = lines[i].split(` ${VTT_ARROW} `);
      const startSeconds = timeToSeconds(start) - cropStart;
      const endSeconds = timeToSeconds(end) - cropStart;

      if (startSeconds >= 0 && endSeconds <= cropEnd) {
        output += `${secondsToTime(startSeconds)} ${VTT_ARROW} ${secondsToTime(endSeconds)}\n`;
        i += 1;
        while (i < lines.length && lines[i].trim() !== '') {
          output += `${lines[i]}\n`;
          i += 1;
        }
        output += '\n';
      }
    }
  }

  return output;
};

export const retimeSubtitle = async ({
  subtitle,
  cropStart,
  cropEnd,
}: {
  cropEnd: number;
  cropStart: number;
  subtitle: PlaylistStreamSubtitles;
}) => {
  const text = await fetchVttFileText(subtitle.url);
  const retimed = retimeVtt({
    vtt: text,
    cropStart,
    cropEnd,
  });
  return {
    ...subtitle,
    url: createVttLink(retimed),
  };
};
