import classNames from 'classnames';
import React from 'react';
import { IconInfoCircle } from '@czechtv/icons';
import { Message, MessageProps } from './Message';
import { messageInfoClassnames } from './MessageInfo.css';

export interface MessageInfoProps extends Omit<MessageProps, 'children'> {
  description?: React.ReactNode;
  inverted?: boolean;
  title?: React.ReactNode;
}

export const MessageInfo = ({
  leftIcon = IconInfoCircle,
  leftIconClassName,
  className,
  description,
  inverted,
  title,
  ...rest
}: MessageInfoProps) => {
  return (
    <Message
      {...rest}
      className={classNames(className, { [messageInfoClassnames.inverted]: inverted })}
      leftIcon={leftIcon}
      leftIconClassName={classNames(leftIconClassName || messageInfoClassnames.iconInfo, {
        inverted,
      })}
    >
      {title && (
        <span className={classNames(messageInfoClassnames.title, { inverted })}>{title}</span>
      )}
      {description && <span className={messageInfoClassnames.description}>{description}</span>}
    </Message>
  );
};
