import { useMediaQuery, useMobileDevice } from '@czechtv/styles';

export const PLAYER_RESPONSIVE_PREFIX = 'player';

export enum Breakpoint {
  isMaxLargeMobile = 'isMaxLargeMobile', // < 591
  isMaxMediumDesktop = 'isMaxMediumDesktop', // <937
  isMaxMediumMobile = 'isMaxMediumMobile', // < 479
  isMaxSmallMobile = 'isMaxSmallMobile', // < 359
  isMaxTablet = 'isMaxTablet', // < 767
  isMaxTouchMediumMobile = 'isMaxTouchMediumMobile', // < 479 & touch
  isMaxTouchSmallMobile = 'isMaxTouchSmallMobile', // < 359 & touch
  isMinDesktop = 'isMinDesktop', // 768 <
  isMinDesktopLarge = 'isMinDesktopLarge', // 1136 <
  isMinDesktopMedium = 'isMinDesktopMedium', // 938 <
  isMinMediumMobile = 'isMinMediumMobile', // 360 <
  isMinMobile = 'isMinMobile', // 480 <
  isMinTablet = 'isMinTablet', // 592 <
  isNotTouchScreen = 'isNotTouchScreen', // not touch
  isTouchScreen = 'isTouchScreen', // touch
}

export const maxWidthMobileSmall = 359;
export const minWidthMobileMedium = maxWidthMobileSmall + 1; // 360
export const maxWidthMobileMedium = 479;
export const minWidthMobileLarge = maxWidthMobileMedium + 1; // 480
export const maxWidthMobileLarge = 591;
export const minWidthTablet = maxWidthMobileLarge + 1; // 592
export const maxWidthTablet = 767;
export const minWidthDesktop = maxWidthTablet + 1; // 768
export const maxWidthMediumDesktop = 937;
export const minWidthLargeDesktop = maxWidthMediumDesktop + 1; // 938
export const minWidthLargestDesktop = 1136;

export const useMediaBreakpoints = (): Record<Breakpoint, boolean> => {
  const isMinDesktopLarge = useMediaQuery({ minWidth: minWidthLargestDesktop });
  const isMinDesktopMedium = useMediaQuery({ minWidth: minWidthLargeDesktop });
  const isMinDesktop = useMediaQuery({ minWidth: minWidthDesktop });
  const isMaxTablet = useMediaQuery({ maxWidth: maxWidthTablet });
  const isMinMediumMobile = useMediaQuery({ minWidth: minWidthMobileMedium });
  const isMaxMediumMobile = useMediaQuery({ maxWidth: maxWidthMobileMedium });
  const isMaxMediumDesktop = useMediaQuery({ maxWidth: maxWidthMediumDesktop });
  const isMinMobile = useMediaQuery({ minWidth: minWidthMobileLarge });
  const isMinTablet = useMediaQuery({ minWidth: minWidthTablet });
  const isMaxLargeMobile = useMediaQuery({ maxWidth: maxWidthMobileLarge });
  const isMaxSmallMobile = useMediaQuery({ maxWidth: maxWidthMobileSmall });

  // touch definujeme tak, že podle userAgenta zjišťujeme zda se jedná o mobilní zařízení
  // telefon má touch vždy
  // konvertibilní notebook používá desktop verzi přehrávače, která je navržena tak,
  // aby byla dostatečně dobře ovladatelná i na touch zařízeních
  const isTouchScreen = useMobileDevice();
  const isNotTouchScreen = !isTouchScreen;
  const isMaxTouchMediumMobile = isTouchScreen && isMaxMediumMobile;
  const isMaxTouchSmallMobile = isTouchScreen && isMaxSmallMobile;

  return {
    isMinDesktopLarge,
    isMinDesktopMedium,
    isMinDesktop,
    isMaxTablet,
    isMinTablet,
    isTouchScreen,
    isNotTouchScreen,
    isMinMediumMobile,
    isMaxMediumDesktop,
    isMaxMediumMobile,
    isMaxSmallMobile,
    isMaxLargeMobile,
    isMinMobile,
    isMaxTouchMediumMobile,
    isMaxTouchSmallMobile,
  };
};

export const getPlayerResponsiveRule = (breakpoint: Breakpoint | Breakpoint[]) => {
  const breakpoints = Array.isArray(breakpoint) ? breakpoint : [breakpoint];
  return `.${breakpoints.map((item) => `${PLAYER_RESPONSIVE_PREFIX}-${item}`).join('.')} &`;
};
