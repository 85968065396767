import classNames from 'classnames';
import React from 'react';
import { IconChevronDown, IconChevronUp } from '@czechtv/icons';
import { Grey_30, Grey_50, Grey_60 } from '@czechtv/styles';
import { iconChevronClassnames } from './IconChevron.css';

interface Props {
  className?: string;
  inverted?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
}

const getFill = (disabled: boolean, inverted: boolean) => {
  if (inverted) {
    if (disabled) {
      return Grey_60;
    }
    return Grey_30;
  }
  if (disabled) {
    return Grey_50;
  }
  return '#000';
};

export const IconChevron = ({ isDisabled = false, inverted = false, isOpen, className }: Props) => {
  const Icon = isOpen ? IconChevronDown : IconChevronUp;

  return (
    <Icon
      className={classNames(iconChevronClassnames.chevronIcon, className, { inverted })}
      fill={getFill(isDisabled, inverted)}
      height={20}
      width={20}
    />
  );
};
