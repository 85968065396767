import { useCallback, useEffect, useState } from 'react';

const useAirplay = ({
  video,
  onAirplayStatusChange,
}: {
  onAirplayStatusChange?: (status: 'online' | 'offline') => void;
  video?: HTMLVideoElement | null;
}) => {
  const [canUseAirplay, setCanUseAirplay] = useState<boolean | undefined>();
  const [isAirplayConnected, setIsAirplayConnected] = useState(false);

  useEffect(() => {
    if (!video) {
      setCanUseAirplay(false);
      return () => {};
    }
    if (!(window as any).WebKitPlaybackTargetAvailabilityEvent) {
      setCanUseAirplay(false);
      return () => {};
    }

    const listener = (event: any) => {
      if (event.availability === 'available') {
        setCanUseAirplay(true);
      } else if (event.availability === 'not-available') {
        setCanUseAirplay(false);
      }
    };

    video.addEventListener('webkitplaybacktargetavailabilitychanged', listener);

    return () => {
      video.removeEventListener('webkitplaybacktargetavailabilitychanged', listener);
    };
  }, [video]);

  useEffect(() => {
    if (!video) {
      return () => {};
    }
    if (!onAirplayStatusChange) {
      return () => {};
    }

    const listener = () => {
      onAirplayStatusChange(isAirplayConnected ? 'offline' : 'online');
      setIsAirplayConnected(!isAirplayConnected);
    };

    video.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', listener);

    return () => {
      return video.removeEventListener('webkitcurrentplaybacktargetiswirelesschanged', listener);
    };
  }, [onAirplayStatusChange, video, isAirplayConnected]);

  const triggerAirplay = useCallback(() => {
    if (!canUseAirplay) {
      return;
    }

    (video as any).webkitShowPlaybackTargetPicker();
  }, [canUseAirplay, video]);

  return { canUseAirplay, triggerAirplay };
};

export default useAirplay;
