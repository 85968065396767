import React, { memo, useMemo, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { formatTime } from '@czechtv/utils';
import { body14, body16, DefaultRadius, DefaultRadiusSmall, pxToRem } from '@czechtv/styles';
import { formatMessage } from '../../../utils/formatMessage';
import { INDICATOR_SIZE } from '../constants';
import {
  getTimeshiftFromTimeline,
  LIVE_STREAM_DURATION,
  PlayerVariantEnum,
  TIMESHIFT_GAP_DURATION,
} from '../../../constants';
import {
  Breakpoint,
  getPlayerResponsiveRule,
  useMediaBreakpoints,
} from '../../../utils/playerResponsive';
import { usePlayerSetup } from '../../../Providers/Setup/usePlayerSetup';
import { PlayerContext } from '../../../Player/PlayerContext';
import ProgressBarImage from './ThumbnailImage';

interface Props {
  getFullLiveStreamLength: () => number;
  // pozice na časové ose v %, počítá se z ní pozice thumbnailu
  positionInPercentage: number;
  // offset začátku pro thumbnails ve vícenásobných playlistech
  startOffset?: number;
  // vlastní url thumbnailu přepíše výchozí
  thumbnailUrl?: string;
  // url pro celou sadu náhledových obrázků
  thumbnailsUrl?: string;
  // z timestampu se počítá náhledový obrázek
  timestamp: number;
  // název thumbnailu
  title?: string;
  // šírka nadřazeného elementu pro výpočet koncových pozic
  wrapperWidth: number;
}

interface StylesOptions {
  offset: number;
  popupWidth: number;
  positionInPercentage: number;
}

const useStyles = createUseStyles<string, StylesOptions>({
  popup: {
    left: ({ positionInPercentage }) => `${positionInPercentage}%`,
    position: 'absolute',
    bottom: 12,
    backgroundColor: 'rgba(0,0,0, .8)',
    padding: 2,
    borderRadius: DefaultRadius,
    color: '#fff',
    width: ({ popupWidth }) => popupWidth,
    height: 'auto',
    overflow: 'hidden',
    transform: ({ offset }) => `translate(${offset}px)`,
  },
  title: {
    ...body14,
    lineHeight: pxToRem(18),
    padding: 6,
  },
  textContainer: {
    height: 89,
  },
  text: {
    ...body14,
    padding: [13, 16, 0],
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      padding: [13, 11, 0],
    },
  },
  titleCropper: {
    overflow: 'hidden',
    display: 'box',
    '-webkit-line-clamp': 7,
    boxOrient: 'vertical',
    [getPlayerResponsiveRule(Breakpoint.isMaxLargeMobile)]: {
      '-webkit-line-clamp': 4,
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      '-webkit-line-clamp': 2,
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      '-webkit-line-clamp': 1,
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxMediumMobile, Breakpoint.isTouchScreen])]: {
      '-webkit-line-clamp': 3,
    },
  },
  timestamp: {
    ...body16,
    position: 'absolute',
    bottom: 6,
    left: '50%',
    transform: 'translate(-50%)',
    backgroundColor: 'rgba(0,0,0, .6)',
    borderRadius: DefaultRadiusSmall,
    padding: [2, 6, 2, 6],
  },
  isUnavailable: {
    background: 'transparent',
  },
  image: {
    borderRadius: DefaultRadiusSmall,
    overflow: 'hidden',
  },
});

export const calcOffset = (
  position: number,
  wrapperWidth: number,
  elementWidth: number
): number => {
  const offset = (wrapperWidth / 100) * (100 - position);

  if (offset < elementWidth / 2 + INDICATOR_SIZE) {
    return -(elementWidth - offset + INDICATOR_SIZE);
  }
  if (wrapperWidth - offset === 0) {
    return 0;
  }
  if (wrapperWidth - offset < elementWidth / 2) {
    return -(wrapperWidth - offset);
  }
  return -(elementWidth / 2);
};

export const ProgressBarPopup = memo(
  ({
    thumbnailsUrl,
    positionInPercentage,
    title,
    thumbnailUrl,
    wrapperWidth,
    timestamp,
    getFullLiveStreamLength,
  }: Props) => {
    const { isMaxMediumMobile } = useMediaBreakpoints();
    const { playerVariant } = usePlayerSetup();
    // nepouzivat usePlayerContext naprimo kvuli testum
    const playerContext = useContext(PlayerContext);
    const liveStreamDuration = playerContext?.liveStreamDuration || LIVE_STREAM_DURATION;
    const timeshiftGapDuration = playerContext?.timeshiftGapDuration || TIMESHIFT_GAP_DURATION;

    const isLivePlayer = playerVariant === PlayerVariantEnum.LIVE;

    const popupWidth = isMaxMediumMobile ? 132 : 160;

    // spočítáme pozici zobrazení na časové ose
    const popupOffset = useMemo(() => {
      return calcOffset(positionInPercentage, wrapperWidth, popupWidth);
    }, [positionInPercentage, wrapperWidth, popupWidth]);

    // zjistíme číselný offset, abychom věděli, co zobrazit v popusku
    const timeshiftOffset = useMemo(() => {
      if (!isLivePlayer) {
        return 0;
      }
      return getTimeshiftFromTimeline(positionInPercentage, getFullLiveStreamLength());
    }, [isLivePlayer, positionInPercentage, getFullLiveStreamLength]);

    // potřebujeme zjistit, jestli má být náhled dostupný
    const isUnavailable = useMemo(() => {
      if (!isLivePlayer && !playerContext?.liveMode) {
        return false;
      }
      return (
        (timeshiftOffset >= liveStreamDuration && timeshiftOffset <= timeshiftGapDuration) ||
        playerContext?.liveMode
      );
    }, [
      isLivePlayer,
      playerContext?.liveMode,
      timeshiftOffset,
      liveStreamDuration,
      timeshiftGapDuration,
    ]);

    const classes = useStyles({ offset: popupOffset, positionInPercentage, popupWidth });

    const previewTrackStartOffset = playerContext?.previewTrackStartOffset ?? 0;

    // pro zobrazení času u náhledu
    const time = `${isLivePlayer ? '-' : ''}${formatTime(
      Math.abs(isLivePlayer ? timeshiftOffset : timestamp),
      'G:i:s',
      2
    )}`;

    // náhled (pro live přehrávač) není dostupný
    if (isUnavailable) {
      return (
        <div className={classes.popup}>
          <div className={classes.textContainer}>
            <div className={classes.text}>
              {formatMessage({
                id: 'ProgressBarPopup.isUnavailable',
                defaultMessage: 'Náhled není dostupný',
                description:
                  'Popisek u nedostupného náhledu na časové ose pro živé vysílaní a liveMode',
              })}
            </div>
            <div className={classNames(classes.timestamp, classes.isUnavailable)}>{time}</div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.popup} data-testid="TimestampPopUp">
        {title && (
          <div className={classes.title} data-testid="TimestampPopUpTitle">
            <div className={classes.titleCropper}>{title}</div>
          </div>
        )}
        <div className={classes.image} data-testid="TimestampPopUpThumbnail">
          <ProgressBarImage
            thumbnailUrl={thumbnailUrl}
            thumbnailsUrl={thumbnailsUrl}
            timestamp={
              timestamp +
              (playerContext?.startOffset || 0) +
              (playerContext?.cropStart || 0) +
              previewTrackStartOffset
            }
          />
        </div>
        <div className={classes.timestamp}>{time}</div>
      </div>
    );
  }
);
