import classNames from 'classnames';
import React, { Ref } from 'react';
import { FormElementProps, uniqueElementId } from '../../common';
import { Checkbox, CheckboxProps } from '../../inputs/Checkbox/Checkbox';
import { ValidatedFormElementWrapper } from '../../ValidatedFormElementWrapper';
import { validatedCheckboxClassnames } from './ValidatedCheckbox.css';

export interface ValidatedCheckboxProps<Value extends boolean | boolean[] = boolean>
  extends FormElementProps,
    Omit<CheckboxProps<Value>, 'aria-describedby' | 'name'> {}

const ValidatedCheckboxComponent = <Value extends boolean | boolean[] = boolean>(
  {
    label,
    name,
    prefix = '',
    inverted = false,
    error,
    displayValidationStatus,
    item,
    wrapperClassName,
    ...rest
  }: ValidatedCheckboxProps<Value>,
  ref: Ref<HTMLInputElement>
) => {
  // pokud prijde item jako single object zabalit ho do array aby splnoval CheckboxItem[]
  const checkbox = Array.isArray(item) ? item : [item];
  // id by mělo být unikátní pro celou stránku
  const id = uniqueElementId(prefix, checkbox[0].title);
  const idDescribedBy = uniqueElementId(prefix, `error-${name}`);

  return (
    <ValidatedFormElementWrapper
      className={classNames(validatedCheckboxClassnames.shiftAlertNotification, wrapperClassName)}
      error={displayValidationStatus ? error : undefined}
      errorId={idDescribedBy}
      id={id}
      inverted={inverted}
      label={label}
    >
      <Checkbox
        {...rest}
        idDescribedBy={idDescribedBy}
        inverted={inverted}
        isValid={displayValidationStatus ? !error : undefined}
        item={item}
        name={name}
        ref={ref}
      />
    </ValidatedFormElementWrapper>
  );
};

// potřebuju přechcat typescript, aby mi umožnil nastavit generické typy pro forwarf ref
type ValidatedCheckboxComponentType = <Value extends boolean | boolean[] = boolean>(
  props: ValidatedCheckboxProps<Value>,
  ref?: React.Ref<HTMLInputElement>
) => React.ReactElement | null;

export const ValidatedCheckbox = React.forwardRef(
  ValidatedCheckboxComponent
) as ValidatedCheckboxComponentType;
