import dayjs, { Dayjs } from 'dayjs';
import cs from 'dayjs/locale/cs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(cs);

export const CZECH_TIMEZONE = 'Europe/Prague';

// Zaslany cas natvrdo rozparsuje vzhledem k nasi ceske TZ.
//
// POZOR, navzdory nejasne dokumentaci dayjs nelze pouzit dayjs(date).tz(CZECH_TIMEZONE) -
// pokud by byla v systemu TZ=UTC, tak by to treba "2022-05-23 10:00" parsovalo
// jako 10:00 v UTC, coz je nesmysl.
export const getCzechDate = (date?: dayjs.ConfigType): Dayjs => dayjs.tz(date, CZECH_TIMEZONE);
