import classNames from 'classnames';
import React, {
  ChangeEvent,
  ComponentType,
  ForwardedRef,
  HTMLAttributes,
  LegacyRef,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFocusVisibleClassName, useWidth } from '@czechtv/components';
import { SvgComponent } from '@czechtv/icons';
import { useMobileDevice } from '@czechtv/styles';
import { LinkProps } from '@czechtv/utils';
import { commonFormElementClassnames, commonFormTextElementClassnames } from '../../common.css';
import { IconChevron } from './IconChevron';
import { Option as OptionComponent, OptionProps } from './Option';
import { selectClassnames } from './Select.css';

interface SelectItem {
  id: string | number;
  text: string;
  url?: LinkProps;
}

export interface SelectProps
  extends Omit<
    HTMLAttributes<HTMLSelectElement>,
    'aria-invalid' | 'disabled' | 'placeholder' | 'onChange'
  > {
  Icon?: SvgComponent;
  Option?: ComponentType<OptionProps>;
  ariaDescribedBy?: string;
  choiceListClassName?: string;
  choiceListItemClassName?: string;
  disabled?: boolean;
  iconToLeft?: boolean;
  inForm?: boolean;
  inverted?: boolean;
  isValid?: boolean;
  maxWidth?: number | string;
  mobileIconClassName?: string;
  name: string;
  onChange: (value: string) => void;
  options: SelectItem[];
  pickerClassName?: string;
  placeholder: string;
  selectedOption?: string;
  simple?: boolean;
  width?: number | string;
}

export const Select = memo(
  React.forwardRef<HTMLSelectElement, SelectProps>(
    (
      {
        inverted,
        className,
        choiceListClassName,
        choiceListItemClassName,
        pickerClassName,
        placeholder,
        options,
        selectedOption,
        ariaDescribedBy,
        simple,
        disabled,
        width,
        mobileIconClassName,
        maxWidth,
        isValid,
        onChange,
        name,
        Icon,
        inForm = true,
        iconToLeft = false,
        Option = OptionComponent,
        ...restProps
      },
      ref
    ) => {
      const [showChoices, setShowChoices] = useState(false);
      const isMobileDevice = useMobileDevice();

      const picker = useRef<HTMLElement>(null);
      const choiceListWidth = useWidth(picker);
      const [choiceListLeft, setChoiceListLeft] = useState<number | string>(0);

      const focusVisibleClassName = useFocusVisibleClassName();

      const toggle = (e: MouseEvent) => {
        const { current } = picker;
        if (current && !current.contains(e.target as Node)) {
          setShowChoices(false);
        }
      };

      useEffect(() => {
        document.addEventListener('mousedown', toggle);
        return () => {
          document.removeEventListener('mousedown', toggle);
        };
      }, []);

      useEffect(() => {
        document.addEventListener('mousedown', toggle);
        return () => {
          document.removeEventListener('mousedown', toggle);
        };
      }, []);

      const toggleChoicesHandler = () => {
        if (!disabled) {
          if (picker.current && typeof width === 'number') {
            const menuWidth = width > 200 ? width : 200;
            if (picker.current.offsetLeft + menuWidth > window.innerWidth) {
              setChoiceListLeft(picker.current.offsetLeft + picker.current.offsetWidth - menuWidth);
            } else {
              setChoiceListLeft('auto');
            }
          }
          setShowChoices((state) => !state);
        }
      };

      const selectOptionHandler = (chosenOption: string) => {
        onChange(chosenOption);
        setShowChoices(false);
      };

      const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        selectOptionHandler(event.target.value);
      };

      const onKeyboardOpen = (e: React.KeyboardEvent) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
          e.preventDefault();
          setShowChoices((prevState) => !prevState);
          e.stopPropagation();
        }
      };

      const keyboardSelectOptionHandler = (e: React.KeyboardEvent, chosenOption: string) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
          onChange(chosenOption);
          setShowChoices(false);
          e.preventDefault();
          e.stopPropagation();
        }
      };

      return (
        <>
          <div
            className={classNames(
              selectClassnames.relative,
              selectClassnames.picker,
              pickerClassName,
              {
                hidden: !isMobileDevice,
                inverted,
              }
            )}
            style={{
              width: typeof width !== 'undefined' ? width : '100%',
              maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
            }}
          >
            {Icon ? (
              <Icon
                className={classNames(
                  selectClassnames.icon,
                  selectClassnames.mobileIcon,
                  mobileIconClassName,
                  {
                    inverted,
                    iconToLeft,
                  }
                )}
              />
            ) : (
              <IconChevron
                className={classNames(selectClassnames.mobileIcon, { iconToLeft })}
                inverted={inverted}
                isDisabled={disabled}
                isOpen={!showChoices}
              />
            )}
            <select
              aria-describedby={ariaDescribedBy}
              aria-disabled={disabled}
              aria-invalid={isValid === false}
              className={classNames(
                commonFormElementClassnames.commonFormElement,
                commonFormTextElementClassnames.commonFormTextElement,
                selectClassnames.containerSelect,
                selectClassnames.hideSelectArrow,
                selectClassnames.placeholder,
                className,
                {
                  disabled,
                  [selectClassnames.error]: isValid === false,
                  [selectClassnames.simple]: simple,
                  isMobileDevice,
                  inverted,
                  selectedOption,
                  iconToLeft,
                }
              )}
              disabled={disabled}
              name={name}
              ref={ref}
              style={{
                width: typeof width !== 'undefined' ? width : '100%',
                maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
              }}
              value={selectedOption}
              onChange={handleSelect}
              {...restProps}
              id={isMobileDevice ? name : undefined}
            >
              {!selectedOption && (
                <option
                  className={classNames(selectClassnames.placeholder, {
                    inverted,
                    disabled,
                    selectedOption,
                  })}
                  defaultValue={placeholder}
                >
                  {placeholder}
                </option>
              )}
              {options.map((option) => {
                return (
                  <option
                    className={selectClassnames.mobileOption}
                    key={option.id}
                    value={option.text}
                  >
                    {option.text}
                  </option>
                );
              })}
            </select>
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={classNames(selectClassnames.picker, pickerClassName, {
              hidden: isMobileDevice,
              inverted,
            })}
            ref={picker as LegacyRef<HTMLDivElement>}
            style={{
              width: typeof width !== 'undefined' ? width : '100%',
              maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
            }}
            onKeyDown={onKeyboardOpen}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              aria-describedby={ariaDescribedBy}
              aria-disabled={disabled}
              className={classNames(
                focusVisibleClassName,
                commonFormElementClassnames.commonFormElement,
                commonFormTextElementClassnames.commonFormTextElement,
                selectClassnames.containerSelect,
                className,
                {
                  open: showChoices,
                  [selectClassnames.error]: isValid === false,
                  [selectClassnames.inForm]: inForm,
                  [selectClassnames.simple]: simple,
                  [selectClassnames.simpleError]: simple && isValid === false,
                  isMobileDevice,
                  icon: Icon,
                  inverted,
                  iconToLeft,
                }
              )}
              role="button"
              style={{
                width: typeof width !== 'undefined' ? width : '100%',
                maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
              }}
              tabIndex={0}
              onClick={toggleChoicesHandler}
            >
              <>
                <p
                  className={classNames(selectClassnames.placeholder, {
                    inverted,
                    disabled,
                    selectedOption,
                    iconToLeft,
                    isDesktop: !isMobileDevice,
                  })}
                >
                  {selectedOption || placeholder}
                </p>
                {Icon ? (
                  <Icon
                    className={classNames(selectClassnames.icon, {
                      inverted,
                      iconToLeft,
                    })}
                  />
                ) : (
                  <IconChevron
                    className={classNames({ iconToLeft })}
                    inverted={inverted}
                    isDisabled={disabled}
                    isOpen={!showChoices}
                  />
                )}
              </>
            </div>
            {/* hidden input ktery drzi selectedOption kvuli validace pres react-hook-form */}
            <input
              id={!isMobileDevice ? name : undefined}
              name={name}
              ref={ref as ForwardedRef<HTMLInputElement>}
              style={{ display: 'none' }}
              type="text"
              value={selectedOption}
              onChange={() => {}}
            />
            {!disabled && (
              <ul
                className={classNames(
                  selectClassnames.choiceList,
                  showChoices ? 'show' : 'hide',
                  choiceListClassName,
                  { icon: Icon, inverted }
                )}
                style={{
                  maxWidth: choiceListWidth || '100%',
                  left: Icon ? choiceListLeft : undefined,
                }}
              >
                {options.map((choice) => {
                  return (
                    <li
                      className={classNames(
                        choiceListItemClassName,
                        selectClassnames.choiceListItem,
                        {
                          inverted,
                        }
                      )}
                      key={choice.id}
                      style={{
                        width: typeof width !== 'undefined' ? width : '100%',
                        maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
                      }}
                      onClick={() => {
                        selectOptionHandler(choice.text);
                      }}
                      // eslint-disable-next-line react/jsx-sort-props
                      onKeyDown={(e) => {
                        keyboardSelectOptionHandler(e, choice.text);
                      }}
                      role="presentation"
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                      tabIndex={0}
                    >
                      <Option
                        inverted={inverted}
                        isActive={!!(selectedOption && choice.text === selectedOption)}
                        link={choice.url}
                        text={choice.text}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </>
      );
    }
  )
);

export default memo(Select);
