import React, { memo, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { IconErrorCircle } from '@czechtv/icons';
import { Notice, body16, pxToRem } from '@czechtv/styles';
import { Breakpoint, getPlayerResponsiveRule } from '../../../utils/playerResponsive';
import { PlayerNativeButton } from '../../PlayerNativeButton/PlayerNativeButton';
import { ZINDEX_TOAST_MESSAGE } from '../../../zindexes';
import { BUTTON_SEPARATOR } from '../../../constants';

const useStyles = createUseStyles({
  icon: {
    fill: Notice,
    width: 24,
    height: 24,
    paddingBottom: 5,
    display: 'none',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      display: 'block',
    },
  },
  wrapper: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.5s, opacity 0.5s linear',
    zIndex: ZINDEX_TOAST_MESSAGE,
    boxSizing: 'border-box',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#FFFFFF',
    ...body16,
    lineHeight: pxToRem(20),
    textTransform: 'none',
    background: '#000000',
    borderRadius: 4,
    padding: [9, 16, 12, 16],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 340,
    maxWidth: '100%',
    '&.visible': {
      visibility: 'visible',
      opacity: 0.8,
      transition: 'none',
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxSmallMobile])]: {
      width: 230,
    },
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      top: '35%',
      transform: 'translate(-50%, -35%)',
    },
  },
  title: {
    textAlign: 'center',
    maxWidth: '100%',
    fontWeight: 600,
  },
  subtitle: {
    textAlign: 'center',
    maxWidth: '100%',
    fontWeight: 200,
  },
  reloadButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const TOAST_MESSAGE_TIMEOUT = 4000;

export interface ToastMessageProps {
  onButtonClick?: () => void;
  subtitle?: string;
  title?: string;
}

type StringMeta = {
  content?: string;
  type?: string;
};

/* pokud string obsahuje text zvyrazneny specialnim znakem (buttonSeparator), pridame mu
type 'button', abychom ho mohli vykreslit na libovolne pozici dle textace */
export const getStringsWithMeta = (raw?: string, buttonSeparator?: string): StringMeta[] => {
  if (!raw) {
    return [];
  }
  const separator = buttonSeparator || '%';
  const parts = raw.split(separator);

  const parsed = parts.map((part, index) => {
    if (index === 1) {
      return { type: 'button', content: part };
    }
    return { type: 'text', content: part };
  });

  const result = parsed.filter((p) => p.content !== '');
  return result;
};

const ToastMessage = ({ subtitle, title, onButtonClick }: ToastMessageProps) => {
  const [canShowMessage, setCanShowMessage] = useState(true);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const startTimeout = !!title && !subtitle;
  const visible = canShowMessage && !!title;

  const classes = useStyles();

  const messages = getStringsWithMeta(subtitle, BUTTON_SEPARATOR);

  useEffect(() => {
    // Zobrazime toast pri zmene chyby
    setCanShowMessage(true);
  }, [title]);

  // Zajistime, ze pri chybejicim tlacitku pro reload chybova hlaska po chvili zmizi
  useEffect(() => {
    if (!startTimeout) {
      return () => {};
    }

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = setTimeout(() => {
      setCanShowMessage(false);
    }, TOAST_MESSAGE_TIMEOUT);

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [startTimeout]);

  return (
    <div aria-atomic="true" aria-live="polite" aria-relevant="all">
      <div
        className={classNames(classes.wrapper, {
          visible,
        })}
      >
        <IconErrorCircle aria-hidden className={classes.icon} />
        <div>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>
            {messages.map((message) =>
              message.type === 'text' ? (
                <span key={message.content}>{message.content}</span>
              ) : (
                <PlayerNativeButton
                  className={classes.reloadButton}
                  data-testid="ToastReload"
                  key={message.content}
                  onClick={onButtonClick}
                >
                  {message.content}
                </PlayerNativeButton>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ToastMessage);
