import React, { forwardRef, Ref, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { MissingProviderError, useFocusVisibleClassName } from '@czechtv/components';
import { BoxShadowFocus, DefaultRadius } from '@czechtv/styles';
import { UrlObject } from 'url';
import { PlayerSetup } from '../../Providers/Setup/usePlayerSetup';

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    '&.focus-visible': {
      boxShadow: BoxShadowFocus,
      outline: 'none',
      borderRadius: DefaultRadius,
    },
  },
});

interface LinkPropsBase extends React.HTMLProps<HTMLAnchorElement> {
  children?: React.ReactNode;
  ref?: Ref<HTMLAnchorElement>;
}

export interface LinkPropsAbsolute extends LinkPropsBase {
  absolute: true;
  href: string;
}

export interface LinkPropsRouter extends Omit<LinkPropsBase, 'href'> {
  as: string;
  href: UrlObject | string;
}

export const Link = React.memo(
  forwardRef<HTMLAnchorElement, LinkPropsAbsolute | LinkPropsRouter>(
    (props: LinkPropsAbsolute | LinkPropsRouter, ref: Ref<HTMLAnchorElement>) => {
      const classes = useStyles();
      const focusVisibleClassName = useFocusVisibleClassName();
      const playerSetup = useContext(PlayerSetup);

      const linkClassName = classNames(props.className, classes.link, focusVisibleClassName);

      if ('absolute' in props) {
        const { absolute, children, className, ...rest } = props;
        return (
          <a className={linkClassName} ref={ref} {...rest}>
            {children}
          </a>
        );
      }

      if (!playerSetup) {
        throw new MissingProviderError(PlayerSetup);
      }

      const { as, href, onClick, children, className, ...rest } = props;

      return (
        <a
          className={linkClassName}
          href={as}
          ref={ref}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
            playerSetup.playerRouter.push(href, as);
            e.preventDefault();
          }}
          {...rest}
        >
          {children}
        </a>
      );
    }
  )
) as {
  (props: LinkPropsAbsolute): React.ReactElement<LinkPropsAbsolute> | null;
  (props: LinkPropsRouter): React.ReactElement<LinkPropsRouter> | null;
};

export default Link;
