export const getCzechMonth = (month: string): string | null => {
  switch (month) {
    case '1':
      return 'ledna';
    case '2':
      return 'února';
    case '3':
      return 'března';
    case '4':
      return 'dubna';
    case '5':
      return 'května';
    case '6':
      return 'června';
    case '7':
      return 'července';
    case '8':
      return 'srpna';
    case '9':
      return 'září';
    case '10':
      return 'října';
    case '11':
      return 'listopadu';
    case '12':
      return 'prosince';
    default:
      return null;
  }
};
