import React, { memo, useEffect, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { buildUrl, convertTimeCodeToSeconds, formatTime, Page } from '@czechtv/utils';
import { IconClose } from '@czechtv/icons';
import { Button, Tooltip, useFocusVisibleClassName } from '@czechtv/components';
import { Checkbox, TextInput } from '@czechtv/form';
import { usePlayerSetup } from '../../../../Providers/Setup/usePlayerSetup';
import { useMediaBreakpoints } from '../../../../utils/playerResponsive';
import Popup from '../../../../components/PlayerPopup/PlayerPopup';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerClient } from '../../../../Providers/Client';
import { formatMessage } from '../../../../utils/formatMessage';
import { shareDialogClassnames } from './ShareDialog.css';

export interface ShareDialogProps {
  id: string;
  onClose?: (event: React.SyntheticEvent<Element, Event> | Event) => void;
  playerClient?: PlayerClient;
}

const timeToSeconds = (time: string) => {
  try {
    return convertTimeCodeToSeconds(time);
  } catch (exception) {
    // exception pokud uživatel zadá nevalidní formát
    return 0;
  }
};

const formatShareTime = (time: number) => {
  return formatTime(time, time >= 60 * 60 ? 'H:i:s' : 'i:s', 2);
};

export const TESTING_ID_SHARE_TIME_INPUT = 'share-time-input';

export const ShareDialog = memo(({ id, onClose, playerClient }: ShareDialogProps) => {
  const {
    config: { SHARE_VIDEO_DOMAIN, SHARE_VIDEO_PROTOCOL },
  } = usePlayerSetup();
  const [useCurrentTime, setUseCurrentTime] = useState(false);
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const { isMinDesktop } = useMediaBreakpoints();
  const [time, setTime] = useState('');
  const timeInSeconds = timeToSeconds(time);
  const focusVisibleClassName = useFocusVisibleClassName();
  // TODO přidat showIdWithCode
  const link = `${SHARE_VIDEO_DOMAIN}${buildUrl({
    page: Page.EPISODE,
    showIdWithCode: 'xyz-dwarf',
    id,
    t: useCurrentTime ? timeInSeconds : null,
  })}`;

  const onButtonClick = (text: string) => {
    // TODO: Odchytávání chyb z copy
    if (copy(`${SHARE_VIDEO_PROTOCOL}://${text}`)) {
      setTooltipVisibility(true);
    }
  };

  // timeout na skrytí tooltipu
  useEffect(() => {
    if (!isTooltipVisible) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      setTooltipVisibility(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isTooltipVisible]);

  // pokud se zaškrtne konkrétní čas, vezmeme aktuální čas z videa
  useEffect(() => {
    if (useCurrentTime) {
      setTime(formatShareTime(playerClient ? playerClient.getCurrentTime() : 0));
    }
  }, [playerClient, useCurrentTime]);

  // nastavíme šířku inputu pro čas na základě šířky obsahu
  const timeInputWidth = useMemo(() => {
    if (!useCurrentTime) {
      return 0;
    }
    const div = document.createElement('div');
    // nemusíme dávat stejnou class jako time input, protože focus neovlivní velikost písma
    div.classList.add(shareDialogClassnames.timeInput);
    div.style.display = 'inline';
    div.style.position = 'absolute';
    div.style.zIndex = '-1';
    div.innerText = time || 'M';
    document.body.appendChild(div);
    const { width } = div.getBoundingClientRect();
    div.remove();
    return Math.ceil(width);
  }, [useCurrentTime, time]);

  return (
    <Popup renderAsPopup={!isMinDesktop} onClose={onClose}>
      <div className={shareDialogClassnames.wrapper}>
        <div className={shareDialogClassnames.title} id="sdiletPopup">
          {formatMessage({
            id: 'ShareDialog.title',
            defaultMessage: 'Sdílet',
            description: 'Popup Sdílet: titulek ',
          })}
        </div>
        {isMinDesktop && (
          <PlayerNativeButton className={shareDialogClassnames.close} onClick={onClose}>
            <IconClose fill="white" height={24} tabIndex={-1} width={24} />
          </PlayerNativeButton>
        )}
        <div className={shareDialogClassnames.copyWrapper}>
          <TextInput inverted readOnly className={shareDialogClassnames.textInput} value={link} />
          <Tooltip
            text={formatMessage({
              id: 'ShareDialog.buttonTooltip',
              defaultMessage: 'Zkopírováno do schránky',
              description: 'Popup Sdílet: zpráva po stisknutí tlačítka Kopírovat',
            })}
            visible={isTooltipVisible}
          >
            <Button
              className={shareDialogClassnames.button}
              size="medium"
              onClick={() => onButtonClick(link)}
            >
              {formatMessage({
                id: 'ShareDialog.copyButton',
                defaultMessage: 'Kopírovat',
                description: 'Popup Sdílet: tlačítko Kopírovat',
              })}
            </Button>
          </Tooltip>
        </div>
        <div className={shareDialogClassnames.useCurrentTime}>
          <Checkbox
            inverted
            className={shareDialogClassnames.checkbox}
            isChecked={useCurrentTime}
            item={{
              id: '1',
              title: formatMessage({
                id: 'ShareDialog.checkboxLabel',
                defaultMessage: 'Konkrétní čas',
                description: 'Popup Sdílet: popisek checkboxu',
              }),
            }}
            onChange={() => setUseCurrentTime(!useCurrentTime)}
          />
          {useCurrentTime ? (
            <input
              className={classNames(shareDialogClassnames.timeInput, focusVisibleClassName)}
              data-testid={TESTING_ID_SHARE_TIME_INPUT}
              style={{ width: timeInputWidth }}
              type="text"
              value={time}
              onBlur={() => setTime(formatShareTime(timeInSeconds))}
              onChange={(e) => setTime(e.target.value)}
            />
          ) : null}
        </div>
      </div>
    </Popup>
  );
});
