import classNames from 'classnames';
import React, { forwardRef, memo, ReactNode, SyntheticEvent, LegacyRef } from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultRadius, Red_base } from '@czechtv/styles';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';

const useStyles = createUseStyles({
  menuPopupButtonWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      padding: 4,
    },
    '&.active svg': {
      backgroundColor: '#000',
      borderRadius: DefaultRadius,
    },
    '&.activeStreaming svg': {
      backgroundColor: Red_base,
      borderRadius: DefaultRadius,
      '& path': {
        fill: '#fff',
      },
    },
  },
  menuPopupIcon: {
    width: 'auto',
    height: 'auto',
    fontSize: 0,
    cursor: 'pointer',
  },
  menuPopupIconInnerWrapper: {
    padding: 6,
  },
});

interface MenuPopupProps {
  ariaExpanded?: boolean;
  ariaHasPopup:
    | boolean
    | 'dialog'
    | 'menu'
    | 'true'
    | 'false'
    | 'grid'
    | 'listbox'
    | 'tree'
    | undefined;
  ariaLabel: string;
  buttonTestId?: string;
  children: ReactNode;
  className?: string;
  onClick?: (e: SyntheticEvent<any>) => void;
  title: string;
}

const MenuPopupButton = forwardRef((props: MenuPopupProps, ref) => {
  const classes = useStyles();
  const {
    className,
    ariaExpanded,
    ariaLabel,
    title,
    buttonTestId,
    children,
    ariaHasPopup,
    onClick,
  } = props;

  return (
    <div
      className={classNames(className, classes.menuPopupButtonWrapper)}
      ref={ref as LegacyRef<HTMLDivElement>}
    >
      <PlayerNativeButton
        aria-controls="settings-menu"
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        aria-label={ariaLabel}
        className={classes.menuPopupIcon}
        data-testid={buttonTestId}
        title={title}
        onClick={onClick}
      >
        <div className={classes.menuPopupIconInnerWrapper} tabIndex={-1}>
          {children}
        </div>
      </PlayerNativeButton>
    </div>
  );
});

export default memo(MenuPopupButton);
