import React, { memo } from 'react';
import { Button } from './Button';
import { ButtonNoStyleTypeComponent, ButtonNoStyleTypeProps } from './Button.types';

export const ButtonPrimary = memo<ButtonNoStyleTypeProps>((props) => {
  // podmínka kvůli typescriptu, aby seděly typy button vs anchor
  if (props.anchor) {
    return <Button {...props} styleType="primary" />;
  }
  return <Button {...props} styleType="primary" />;
}) as ButtonNoStyleTypeComponent;
