export enum GemiusEvents {
  BUFFER = 'buffer',
  CLOSE = 'close',
  COMPLETE = 'complete',
  PAUSE = 'pause',
  PLAY = 'play',
  SEEK = 'seek',
  SKIP = 'skip',
}

export interface GemiusVODPlaylistResponse {
  programDuration: number;
  programId: string;
  programName: string;
  programType: string;
  typology?: string;
}

export interface GemiusMainContentPayload extends GemiusVODPlaylistResponse {}

export interface GemiusLivePlaylistResponse extends GemiusVODPlaylistResponse {}
