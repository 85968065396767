import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconFacebookHoverWhiteColor: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" fill="#FFF" r="10" />
      <path
        d="M12 1a11 11 0 1 1 0 22 11 11 0 0 1 0-22zm3.52 5h-1.9c-1.53 0-2.65 1.25-2.73 2.82v1.23H8.85v1.96h2.04V18h2.25v-5.99h2.02l.36-1.96h-2.38V8.78c0-.36.2-.63.4-.69h1.97V6z"
        fill="#1778F2"
      />
    </g>
  </svg>
));
