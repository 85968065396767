import { VASTParser } from '@dailymotion/vast-client';
import { CLIENT_ID } from '@czechtv/analytics';
import { convertTimeCodeToSeconds } from '@czechtv/utils';
import { log } from '../utils/logger';
import {
  AdTracking,
  AdTypeDetail,
  AD_OCEAN_URL,
  ParsedAd,
  PlayerAdType,
  PlayerStream,
  PlayerStreamType,
  StreamUrl,
  TrackingType,
} from '../constants';
import { AdsConfig } from '../Providers/Client';
import { Parser } from '../vastTypes';
import {
  NEW_CDN_CONTENT_ID_PARAM_KEY,
  OLD_CDN_CONTENT_ID_PARAM_KEY,
  getParamFromUrl,
} from './getParamFromUrl';
import { shouldPlayDash } from './shouldPlayDash';

const TITLE_SEPARATOR = ' - ';
const DISABLE_ADS = false;
const USE_MOCKED_ADS = false;

// TODO: mock, bude odstraneno
const TEST_URLS = [
  'https://ctfs-development.vecko.dev/static/assets/vasts/v3-mpd/preroll-1-1-1.xml?typkodu=js&keywords=SIDP%2310571672457;IDEC%23219%20512%2012015/0003;IVYSILANI;;VOD;dash;testBB&bust=626298078',
  'https://ctfs-development.vecko.dev/static/assets/vasts/v3-mpd/preroll-1-1-2.xml?typkodu=js&keywords=SIDP%2310571672457;IDEC%23219%20512%2012015/0003;IVYSILANI;;VOD;dash;testBB&bust=626298078',
  'https://ctfs-development.vecko.dev/static/assets/vasts/v3-mpd/postroll-2-1-4.xml?typkodu=js&keywords=SIDP%2310571672457;IDEC%23219%20512%2012015/0003;IVYSILANI;;VOD;dash;testBB&bust=626298078',
];

// TODO: mock, bude odstraneno

const TEST_ADS_CONFIG: AdsConfig = {
  preRoll: [TEST_URLS[0], TEST_URLS[1]],
  postRoll: [TEST_URLS[2]],
};

const DASH_SELECTOR = 'MediaFile[type="application/dash+xml"]';
const HLS_SELECTOR = 'MediaFile[type="application/x-mpegURL"]';

export const IVYS_VAST_CONFIG = {
  adOceanPrerollId1: 'PUk2UpMyggSxr7s6ba5h4uKHYA8fqGO2xANtrEsoCpb.g7',
  adOceanPrerollId2: 'zeGbUeMaSKWRzrkc1wgv82KEXt4PAPsSPs_I1TAciP3.M7',
  adOceanPostrollId1: 'hcCVpVAw7cbvX9nWtEiYeCjvLagAFecQIrUo27rLXPP.i7',
};

interface AdWithMeta {
  index: number;
  type: PlayerAdType;
  url: string;
}

interface ParsedAdsConfig {
  postRoll?: ParsedAd[];
  preRoll?: ParsedAd[];
}

interface AdTagsInputData {
  adSpaceId: string;
  customTargeting?: string[];
  id?: string;
  isLive?: boolean;
  origin?: string;
  sections?: string[];
  sidp?: string;
}

const getTypeDetail = (type: string): AdTypeDetail => {
  // 01 neexistuje
  switch (type) {
    case '03':
      return 'self-promo';
    case '04':
      return 'labeling';
    default:
      return 'ad';
  }
};

// nemelo by se to stavat, ale v testovacich reklamach obcas prisla url pouze s http
// 15.11.22 objevilo se i na produkci u stareho reseni reklam
const httpToHttps = (url: string) => {
  return url.replace('http://', 'https://');
};

const skipAdWithErrorLog = (message: string, error?: any) => {
  log.debug({
    message,
    error,
  });
  return null;
};

const getCDATA = (content: string | undefined | null) => {
  if (!content) {
    return null;
  }
  if (!content.includes('<![CDATA')) {
    return content.trim();
  }
  const cdataContent = new DOMParser().parseFromString(content, 'text/xml').textContent;
  if (cdataContent) {
    return cdataContent.trim();
  }
  return null;
};

export const trackProgress = async (
  eventType: TrackingType,
  trackingEvents: AdTracking[]
  // consent?: boolean
) => {
  const sendRequest = async (url: string, type: TrackingType) => {
    try {
      await fetch(url);
      log.debug({ message: `Fired ${eventType} tracking event. ${url}` });
    } catch (e) {
      log.warn({
        message: `Failed to track: ${type}`,
        error: e,
      });
    }
  };
  const eventsToBeFired = trackingEvents.filter((item) => item.type === eventType);
  await Promise.all(eventsToBeFired.map((item) => sendRequest(item.url, item.type)));
};

const legacyParseVastDocument = (doc: Document, adWithMeta: AdWithMeta): ParsedAd | null => {
  const { url, type, index } = adWithMeta;
  const creative = doc.querySelector('Creative');
  const extensions = doc.querySelector('Extensions');
  const impressionNodes = doc.querySelectorAll('Impression');

  const impressions = Array.from(impressionNodes)
    .map((impression) => ({
      url: impression.textContent,
    }))
    .filter((item) => item.url && item.url.includes('http'));

  if (!creative) {
    return skipAdWithErrorLog(
      `Invalid VAST. No Creative present: ${url}. Skiping ${type} at position ${index}`
    );
  }

  const dashMediaString = creative.querySelector(DASH_SELECTOR)?.textContent;
  const hlsMediaString = creative.querySelector(HLS_SELECTOR)?.textContent;

  const dashUrl = getCDATA(dashMediaString);
  const hlsUrl = getCDATA(hlsMediaString);

  if (!dashUrl || !hlsUrl) {
    return skipAdWithErrorLog(
      `Invalid VAST. Required MediaFiles not present.: ${url}.
      Skiping ${type} at position ${index}. DASH: ${dashUrl}. HLS: ${hlsUrl}`
    );
  }

  const trackingEventsScope = creative.querySelector('TrackingEvents');
  const trackingEvents = Array.from(
    trackingEventsScope ? trackingEventsScope.querySelectorAll('Tracking') : []
  );

  const adTracking: AdTracking[] = trackingEvents
    .map((item) => {
      const type = item.getAttribute('event') as AdTracking['type'];
      const url = getCDATA(item.textContent) as AdTracking['url'];
      return {
        type,
        url,
      };
    })
    .filter((item) => item.type && item.url);

  const skipOffsetTC = creative.querySelector('Linear')?.getAttribute('skipoffset');
  const durationTC = getCDATA(creative.querySelector('Duration')?.textContent);

  const vastTitle = getCDATA(doc.querySelector('AdTitle')?.textContent) || '';
  const skipOffset = skipOffsetTC ? convertTimeCodeToSeconds(skipOffsetTC) : null;

  const duration = durationTC ? convertTimeCodeToSeconds(durationTC) : null;
  const clickUrl = getCDATA(creative.querySelector('ClickThrough')?.textContent);

  // vast nemusi mit vzdy extensions
  const asmea = extensions?.querySelector('ASMEACode')?.textContent || null;

  // nazvy reklam v sobe maji ukryte mene nebo vice infa, ale mely by zacinat na 02|03|04 + ' - '
  // podle toho muzeme zjistit detailnejsi typ reklamy, zbytek povazujeme za titulek
  const titleSplit = vastTitle.split(TITLE_SEPARATOR);

  let title = vastTitle;
  // default 02 - ad
  let nielsenType = '02';

  if (titleSplit.length > 1) {
    const [nType, ...rest] = titleSplit;
    // pro pripad, ze zbytek obsahuje TITLE_SEPARATOR v nazvu reklamy
    title = rest.join(TITLE_SEPARATOR);
    nielsenType = nType;
  }

  const typeDetail = getTypeDetail(nielsenType);

  const contentId =
    getParamFromUrl(dashUrl, OLD_CDN_CONTENT_ID_PARAM_KEY) ||
    getParamFromUrl(dashUrl, NEW_CDN_CONTENT_ID_PARAM_KEY);

  const nielsen = {
    type: adWithMeta.type.toLowerCase(),
    assetid: `${CLIENT_ID}-${contentId}`,
    nol_c4: `p4,${asmea || ''}`,
    nol_c5: `p5,${nielsenType}`,
    nol_c6: `p6,${adWithMeta.type.toLowerCase()}`,
    title: vastTitle,
    length: Math.floor(Number(duration ?? 0)).toString(),
  };

  return {
    asmea,
    title,
    dashUrl: httpToHttps(dashUrl),
    hlsUrl: httpToHttps(hlsUrl),
    clickUrl,
    duration,
    impressions,
    skipOffset,
    type: adWithMeta.type,
    position: adWithMeta.index,
    tracking: adTracking,
    typeDetail,
    nielsenType,
    nielsen,
  };
};

export const legacyGetAdData = async (ad: AdWithMeta) => {
  const { url, type, index } = ad;
  const options: RequestInit = { ...(USE_MOCKED_ADS ? {} : { credentials: 'include' }) };
  let data: string = '';
  try {
    const response = await fetch(url, options);
    data = await response.text();
  } catch (e) {
    return skipAdWithErrorLog(
      `Failed to fetch data from: ${url}. Skiping ${type} at position ${index}`,
      e
    );
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, 'text/xml');
  const vastData = legacyParseVastDocument(doc, ad);
  return vastData;
};

export const legacyGetAds = async (adsConfig: AdsConfig) => {
  if (DISABLE_ADS) {
    adsConfig = {};
  }

  if (USE_MOCKED_ADS && !DISABLE_ADS) {
    adsConfig = TEST_ADS_CONFIG;
  }

  const adsWithMeta: AdWithMeta[] = [
    ...(adsConfig.preRoll
      ? adsConfig.preRoll.map((url, index) => ({ url, type: PlayerAdType.preRoll, index }))
      : []),
    ...(adsConfig.postRoll
      ? adsConfig.postRoll.map((url, index) => ({ url, type: PlayerAdType.postRoll, index }))
      : []),
  ];

  const promises = adsWithMeta.map((ad) => legacyGetAdData(ad));
  const ads = await Promise.all(promises);

  const preRoll = ads.filter(
    (ad) => ad?.type === PlayerAdType.preRoll
  ) as ParsedAdsConfig['preRoll'];
  const postRoll = ads.filter(
    (ad) => ad?.type === PlayerAdType.postRoll
  ) as ParsedAdsConfig['postRoll'];

  const result = {
    ...(preRoll && preRoll.length > 0 ? { preRoll } : {}),
    ...(postRoll && postRoll.length > 0 ? { postRoll } : {}),
  };
  return result;
};

export const transformLabelingsToPrerolls = (
  streams: StreamUrl[],
  prerollsCount: number
): ParsedAd[] =>
  streams.map((stream, index) => ({
    dashUrl: httpToHttps(stream.main),
    hlsUrl: httpToHttps(stream.main),
    asmea: '',
    duration: null,
    clickUrl: null,
    nielsen: {
      type: 'preroll',
      assetid: `${CLIENT_ID}-${
        getParamFromUrl(httpToHttps(stream.main), OLD_CDN_CONTENT_ID_PARAM_KEY) ||
        getParamFromUrl(httpToHttps(stream.main), NEW_CDN_CONTENT_ID_PARAM_KEY)
      }`,
      nol_c4: 'p4,',
      nol_c5: 'p5,04',
      nol_c6: `p6,preroll`,
      title: 'Varování 18+',
      // nezname, zjistime pozdeji
      length: '',
    },
    impressions: [],
    nielsenType: '04',
    position: prerollsCount + index + 1,
    skipOffset: 5,
    title: 'labeling',
    tracking: [],
    type: PlayerAdType.preRoll,
    typeDetail: 'labeling',
  }));

export const getNewAdsAsPlayerStream = async ({
  params,
  type,
}: {
  params?: string;
  type: PlayerAdType;
}): Promise<PlayerStream[]> => {
  if (!params) {
    return [];
  }

  const vastParser: Parser = new VASTParser();
  try {
    const response = await vastParser.getAndParseVAST(`${AD_OCEAN_URL}${params}`);
    const parsedAds = response.ads.map((item, index) => {
      const creative = item.creatives[0];
      const impressions = (item.impressionURLTemplates || []).map((impression) => ({
        url: impression.url,
      }));

      const dashUrl = creative.mediaFiles.find((item) =>
        item.mimeType?.includes('application/dash+xml')
      )?.fileURL;

      const hlsUrl = creative.mediaFiles.find((item) =>
        item.mimeType?.includes('application/x-mpegURL')
      )?.fileURL;

      // TODO: muzeme pak nekdy zkusit i treti variantu - progresivni mp4
      // const progressive = creative.mediaFiles.find((item) =>
      //   item.mimeType?.includes('video/mp4')
      // )?.fileURL;

      if (!dashUrl || !hlsUrl) {
        return skipAdWithErrorLog('Invalid VAST');
      }
      const asmea = (item.extensions[0]?.value as string) || null;

      const vastTitle = item.title || '';
      // nazvy reklam v sobe maji ukryte mene nebo vice infa, ale mely by zacinat
      // na 02 | 03 | 04 + ' - '
      // podle toho muzeme zjistit detailnejsi typ reklamy, zbytek povazujeme za titulek
      const titleSplit = vastTitle.split(TITLE_SEPARATOR);

      let title = vastTitle;
      // default 02 - ad
      let nielsenType = '02';

      if (titleSplit.length > 1) {
        const [nType, ...rest] = titleSplit;
        // pro pripad, ze zbytek obsahuje TITLE_SEPARATOR v nazvu reklamy
        title = rest.join(TITLE_SEPARATOR);
        nielsenType = nType;
      }

      const typeDetail = getTypeDetail(nielsenType);

      const contentId =
        getParamFromUrl(dashUrl, OLD_CDN_CONTENT_ID_PARAM_KEY) ||
        getParamFromUrl(dashUrl, NEW_CDN_CONTENT_ID_PARAM_KEY);

      const nielsen = {
        type: type.toLowerCase(),
        assetid: `${CLIENT_ID}-${contentId}`,
        nol_c4: `p4,${asmea || ''}`,
        nol_c5: `p5,${nielsenType}`,
        nol_c6: `p6,${type.toLowerCase()}`,
        title,
        length: Math.floor(Number(creative.duration ?? 0)).toString(),
      };

      const adTracking: AdTracking[] = Object.keys(creative.trackingEvents)
        .map((eventName) => {
          const type = eventName as TrackingType;
          const url = creative.trackingEvents[eventName][0];
          return {
            type,
            url,
          };
        })
        .filter((item) => item.type && item.url);

      const ad: ParsedAd = {
        asmea,
        title,
        dashUrl: httpToHttps(dashUrl),
        hlsUrl: httpToHttps(hlsUrl),
        impressions,
        clickUrl: creative.videoClickThroughURLTemplate?.url || null,
        duration: creative.duration,
        skipOffset: item.creatives[0].skipDelay,
        type,
        position: index,
        tracking: adTracking,
        typeDetail,
        nielsenType,
        nielsen,
      };
      return ad;
    });

    const filtered = parsedAds.filter((ad) => ad !== null) as ParsedAd[];
    const streams: PlayerStream[] = filtered.map((adData, index) => {
      const { dashUrl, hlsUrl, ...rest } = adData;
      const url = shouldPlayDash() ? dashUrl : hlsUrl;
      const result: PlayerStream = {
        id: Date.now().toString(),
        url,
        index,
        type: PlayerStreamType.ad,
        category: type,
        meta: { ...rest },
      };
      return result;
    });
    return streams;
  } catch (e) {
    skipAdWithErrorLog('No VAST response');
  }
  return [];
};

export const getAdOceanParams = (data: AdTagsInputData) => {
  const idParam = data.adSpaceId;
  const hrefData = [
    ...(data.id ? [data.id] : []),
    ...(data.sidp ? [data.sidp] : []),
    ...(data.origin ? [data.origin] : []),
    ...(data.sections ? data.sections : []),
    ...(data.customTargeting ? data.customTargeting : []),
    ...(data.isLive ? ['LIVE'] : []),
  ];
  const href = hrefData.join('|').toUpperCase();

  return `?id=${idParam}&href=${href}`;
};
