/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Ref, SyntheticEvent, useCallback } from 'react';
import classNames from 'classnames';
import { ButtonInverted, ChannelProgressBar } from '@czechtv/components';
import { IconHeadphones } from '@czechtv/icons';
import { ScreenMode } from '../../../../constants';
import VideoIconButton from '../../../../components/VideoIconButton/VideoIconButton';
import { VideoHeader } from '../../../Controls/Common/VideoHeader/VideoHeader';
import VideoDuration from '../../../Controls/VOD/BeforePlay/VideoDuration/VideoDuration';
import Scrim from '../Scrim/Scrim';
import { VideoMeta } from '../../../../components/PlayerPreview/PlayerPreview';
import { usePlayerPopupContext } from '../../../../components/PlayerPopup/PlayerPopup';
import { AudioOnly } from '../../../Controls/Common/ExtraControls/AudioOnly';
import { formatMessage } from '../../../../utils/formatMessage';
import { timestampToTimeString } from '../../../../utils/timer';
import videoPlaceholder from '../../../../assets/images/video_placeholder.png';
import { readyOverlayClassnames } from './ReadyOverlay.css';

export interface VODReadyOverlayProps {
  canPlayOnlySound?: boolean;
  hidePreviewImage?: boolean;
  id?: string;
  isAudioOnly?: boolean;
  licenseInfo?: string;
  meta?: VideoMeta;
  onClick?: (e: SyntheticEvent<HTMLElement>) => void;
  onIndexListVisible?: () => void;
  onPlayButtonClick?: (e: SyntheticEvent<HTMLElement>) => void;
  onSoundOnlyButtonClick?: (e: SyntheticEvent<HTMLElement>) => void;
  overlayRef?: Ref<HTMLDivElement>;
  previewImage?: string;
  progress?: number;
  screenMode?: ScreenMode;
  showSimpleVideoHeader?: boolean;
  startsAt?: number;
}

export const ReadyOverlay = ({
  onClick,
  onSoundOnlyButtonClick,
  onIndexListVisible,
  overlayRef,
  previewImage,
  progress,
  screenMode = ScreenMode.NORMAL,
  canPlayOnlySound = false,
  licenseInfo,
  meta = { indexes: [] },
  id,
  showSimpleVideoHeader = true,
  isAudioOnly,
  startsAt,
}: VODReadyOverlayProps) => {
  const { duration, indexes, title } = meta;
  const popupContext = usePlayerPopupContext();
  const hasOpenPopup = popupContext?.hasOpenPopup;
  const hasIndexes = !!indexes.length;
  const audioPlayButton = meta.isAudioOnly;
  const ariaPlayLabel = `Přehrát video${title ? ` s názvem ${title}` : ''}`;
  const backgroundStyle = { backgroundImage: `url(${previewImage || videoPlaceholder})` };
  const className =
    screenMode === ScreenMode.FULLSCREEN
      ? readyOverlayClassnames.overlayFullscreen
      : readyOverlayClassnames.overlay;

  const onOverlayClick = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      if (onClick && !hasOpenPopup) {
        onClick(event);
      }
    },
    [hasOpenPopup, onClick]
  );

  const playButtonText = () => {
    if (startsAt) {
      return `Živé vysílání začne ${timestampToTimeString(startsAt, undefined, true)}`;
    }
    if (licenseInfo) {
      return `${licenseInfo}`;
    }
    if (audioPlayButton) {
      return `${formatMessage({
        id: 'VODReadyOverlay.playAudio',
        defaultMessage: 'Spustit audio',
        description: 'spustit audio ve video přehrávači',
      })}`;
    }
    return undefined;
  };

  return (
    <div
      aria-label={ariaPlayLabel}
      className={className}
      data-testid="VODReadyOverlay"
      ref={overlayRef}
      role="button"
      style={backgroundStyle}
      tabIndex={-1}
      onClick={startsAt ? undefined : onOverlayClick}
    >
      <Scrim visibleBottom visibleTop={!showSimpleVideoHeader || hasIndexes || !!startsAt} />
      <div className={readyOverlayClassnames.playWrapper} ref={overlayRef}>
        <VideoIconButton
          ariaLabel={ariaPlayLabel}
          className={classNames(readyOverlayClassnames.overlayIcon, { hasIndexes })}
          disabled={!!startsAt}
          startsAt={startsAt}
          text={playButtonText()}
          textClassName={classNames(readyOverlayClassnames.licenceText, { hasIndexes })}
          onClick={startsAt ? undefined : onClick}
        />
      </div>
      <VideoHeader
        visible
        id={id}
        meta={meta}
        showSimpleVideoHeader={showSimpleVideoHeader}
        onIndexListVisible={onIndexListVisible}
      />

      {canPlayOnlySound && (
        <ButtonInverted
          className={readyOverlayClassnames.playSoundOnlyOnlyButton}
          icon={IconHeadphones}
          size="small"
          onClick={onSoundOnlyButtonClick}
        >
          {formatMessage({
            id: 'VODReadyOverlay.soundOnlyButton',
            defaultMessage: 'Přehrát jen zvuk',
            description: 'Tlačítko Přehrát jen zvuk',
          })}
        </ButtonInverted>
      )}
      {duration && duration !== Infinity && (
        <VideoDuration className={readyOverlayClassnames.videoDuration} duration={duration} />
      )}
      {!!progress && (
        <ChannelProgressBar
          className={readyOverlayClassnames.progressIndicator}
          progress={progress}
          style={{ position: 'absolute' }}
        />
      )}
      {isAudioOnly && (
        <AudioOnly isPreview className={classNames(readyOverlayClassnames.preview)} />
      )}
    </div>
  );
};
