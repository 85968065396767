/* 
Tyto tabulky potřebujeme pro ČT Sport, když při zátěži reguluje, 
co má vybrat player při kvalitě "auto" nebo při omezení dostupných kvalit
*/

type BitrateTable = {
  [resolution: number]: number;
};

type ResolutionTable = {
  [bitrate: number]: number;
};

const bitrateTable: BitrateTable = {
  144: 296,
  288: 628,
  404: 1160,
  576: 2176,
  720: 3712,
  1080: 6272,
};

const resolutionTable = Object.keys(bitrateTable).reduce<ResolutionTable>((acc, resolutionKey) => {
  const resolution = parseInt(resolutionKey);
  const bitrate = bitrateTable[resolution];
  acc[bitrate] = resolution;
  return acc;
}, {});

export const getBitrateByResolution = (resolution?: number) => {
  if (!resolution) {
    return undefined;
  }
  const foundValue = bitrateTable[resolution];
  if (foundValue) {
    // hodnota se nakonec násobí tisícem, aby odpovídala reálu v manifestu (viz. bandwidth v mpd)
    // Sport interně používá hodnotu / 1000
    return foundValue * 1e3;
  }
  return undefined;
};

export const getResolutionByBitrate = (bitrate?: number) => {
  if (!bitrate) {
    return undefined;
  }
  return resolutionTable[bitrate];
};
