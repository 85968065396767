import { stringify } from 'qs';
import { Encoder } from '@czechtv/utils';
import { LiveMode } from '../../constants';
import {
  PlaylistError,
  PlaylistErrorCodes,
} from '../../components/Error/playlistError/playlistError';
import { VodIsLiveError } from '../../components/Error/vodIsLiveError';
import {
  ClientPlaylistResponse,
  GetLegacyStreamDataParams,
  GetLiveStreamDataParams,
  GetVodStreamDataParams,
  LegacyPlaylistErrorResponse,
  LegacyPlaylistSuccessResponse,
  LEGACY_PLAYLIST_ITEM_BASE,
  LEGACY_PLAYLIST_SETUP_BASE,
  NewLivePlaylist,
  NewVodPlaylist,
  PEGI_RATING_DATA,
  PlaylistStreamResponse,
  NewVodPlaylistData,
} from './constants';
import { getSubtitleDisplayName } from './utils';

const SKIP_GEO_IP_HEADERS = {
  'X-GEOIP-COUNTRY': 'cz',
  'X-DEVICE': 'web',
};

const joinMultiplePlaylists = (playlistData: NewVodPlaylistData[]) => {
  const newVodPlaylistData = { ...playlistData[0] };
  newVodPlaylistData.streams = playlistData.map((playlist) => playlist.streams[0]);
  newVodPlaylistData.duration = playlistData.reduce((acc, playlist) => acc + playlist.duration, 0);
  return newVodPlaylistData;
};

// na dev prostredi v kombinaci s labkou si muzeme vynutit vypnuti drm
const isLabDrmException = (playlistUrl: string, origin?: string) => {
  const keywords = ['testing', 'staging', 'development'];
  return origin === 'lab_nodrm' && keywords.some((keyword) => playlistUrl.includes(keyword));
};

export const getLegacyStreamData = async ({
  type,
  id,
  cropStart,
  cropEnd,
  supportedDRMs,
  playlistUri,
  streamType,
  origin,
  logFallbackError,
}: GetLegacyStreamDataParams) => {
  // pro indexy muze chodit vice polozek oddelenych carkou
  const ids = id.split(',');
  const playlist = ids.map((id) => ({
    type,
    id,
    ...(cropStart ? { startTime: cropStart } : {}),
    ...(cropEnd ? { stopTime: cropEnd } : {}),
  }));

  const playlistResponse = await fetch(playlistUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    body: stringify({
      playlist,
      requestUrl: '/ivysilani/embed/iFramePlayer.php',
      requestSource: origin || 'iVysilani',
      type: 'html',
      canPlayDRM: supportedDRMs.length > 0,
      streamingProtocol: streamType,
    }),
  });

  if (!playlistResponse.ok) {
    if (logFallbackError) {
      logFallbackError(id);
    }
    throw new Error('Unknown playlist error');
  }

  const playlistResponseData: ClientPlaylistResponse = await playlistResponse.json();
  if (!('url' in playlistResponseData)) {
    if (logFallbackError) {
      logFallbackError(id);
    }
    throw new Error('Missing stream url in response');
  }

  const streamResponse = await fetch(playlistResponseData.url);
  const streamData: LegacyPlaylistSuccessResponse | LegacyPlaylistErrorResponse =
    await streamResponse.json();

  if (!streamResponse.ok) {
    if (logFallbackError) {
      logFallbackError(id);
    }
    throw new Error('error' in streamData ? streamData.error : 'Unknown stream error');
  }
  return streamData;
};

export const getLiveStreamData = async ({
  supportedDRMs,
  playlistUrl: originalPlaylistUrl,
  encoder,
  startTimestamp,
  endTimestamp,
  liveMode,
  quality,
  sessionId,
  origin,
  streamType,
}: GetLiveStreamDataParams) => {
  /* 
  Vyjimka:
  Pokud chceme prehrat CH_24, potrebujeme zmenit parametr canPlayDrm na false.
  Nove playlisty pak 24ku vrati automaticky bez DRM.
  Pokud prijde CH_3, chceme poptat s playlistu CH_24. Defaultne se vrati s DRM.
  */

  const forceNoDrm =
    originalPlaylistUrl.includes('CH_24') || isLabDrmException(originalPlaylistUrl, origin);

  const playlistUrl =
    // zatim ponechano
    encoder === ('CH_3' as Encoder)
      ? originalPlaylistUrl.replace('CH_3', 'CH_24')
      : originalPlaylistUrl;

  const options: Record<string, string | number | boolean> = {
    canPlayDrm: forceNoDrm ? false : supportedDRMs.length > 0,
    streamType,
    quality,
    maxQualityCount: 5,
    sessionId,
    ...(liveMode === LiveMode.liveAsVod ? { startTimestamp, endTimestamp } : {}),
    ...(origin && { origin_id: origin }),
  };
  const queryString = Object.keys(options)
    .map((key) => `${key}=${options[key]}`)
    .join('&');

  const url = `${playlistUrl}?${queryString}`;
  const newLivePlaylistResponse = await fetch(url);
  const newLivePlaylistData: NewLivePlaylist = await newLivePlaylistResponse.json();

  if (!newLivePlaylistResponse.ok) {
    if (newLivePlaylistResponse.status <= 400 && newLivePlaylistResponse.status >= 300) {
      throw new PlaylistError(
        'message' in newLivePlaylistData
          ? newLivePlaylistData.message
          : 'Unknown new playlist error',
        PlaylistErrorCodes.MEDIUM_NOT_FOUND
      );
    }
    throw new PlaylistError(
      'message' in newLivePlaylistData ? newLivePlaylistData.message : 'Unknown new playlist error',
      PlaylistErrorCodes.INTERNAL_ERROR
    );
  }
  if ('message' in newLivePlaylistData) {
    throw new PlaylistError(newLivePlaylistData.message, PlaylistErrorCodes.MEDIUM_NOT_FOUND);
  }
  if (!newLivePlaylistData.streamUrls) {
    throw new Error('Missing stream playlist data');
  }
  if (!newLivePlaylistData.streamUrls.main) {
    throw new Error('Missing main stream url');
  }

  const streamUrls =
    liveMode === LiveMode.liveAsVod
      ? {
          isBlocked: newLivePlaylistData.streamUrls.isBlocked,
          // v liveMode 1 prehravame oriznuty timeshift jako VOD, pouzivame pouze main
          main: newLivePlaylistData.streamUrls.timeshift,
          timeshift: newLivePlaylistData.streamUrls.timeshift,
        }
      : { ...newLivePlaylistData.streamUrls };

  const streamData: PlaylistStreamResponse = {
    ...(newLivePlaylistData.analytics ? { analytics: { ...newLivePlaylistData.analytics } } : {}),
    setup: {
      ...LEGACY_PLAYLIST_SETUP_BASE,
      adOcean: newLivePlaylistData.adOcean,
      title: newLivePlaylistData.title || encoder || '',
      previewImageUrl: newLivePlaylistData.previewImageUrl,
    },
    playlist: [
      {
        ...LEGACY_PLAYLIST_ITEM_BASE,
        id: String(newLivePlaylistData.id),
        assetId: encoder || '',
        previewImageUrl: newLivePlaylistData.previewImageUrl,
        previewTrackBaseUrl: newLivePlaylistData.previewTrackBaseUrl,
        title: newLivePlaylistData.title || encoder || '',
        streamUrls: { ...streamUrls },
        type: liveMode === LiveMode.liveAsVod ? 'VOD' : 'LIVE',
      },
    ],
  };
  return streamData;
};

export const getVodStreamData = async ({
  accessToken,
  bypassGeoIp,
  cropEnd,
  cropStart,
  disableLabeling,
  id,
  playlistUrl,
  origin,
  quality,
  replayToken,
  sessionId,
  supportedDRMs,
  streamType,
}: GetVodStreamDataParams) => {
  const forceNoDrm = isLabDrmException(playlistUrl, origin);

  const options: Record<string, string | number | boolean> = {
    canPlayDrm: forceNoDrm ? false : supportedDRMs.length > 0,
    quality,
    streamType,
    sessionId,
    ...(replayToken ? { replayToken } : {}),
    ...(cropStart ? { cropStart } : {}),
    ...(cropEnd ? { cropEnd } : {}),
    ...(origin && { origin_id: origin }),
  };
  const queryString = Object.keys(options)
    .map((key) => `${key}=${options[key]}`)
    .join('&');

  const url = `${playlistUrl}?${queryString}`;

  const headers = {
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    ...(bypassGeoIp ? { ...SKIP_GEO_IP_HEADERS } : {}),
  };

  const fetchOptions: RequestInit = {
    ...(Object.keys(headers).length > 0 ? { headers } : {}),
  };

  const newVodplaylistResponse = await fetch(url, fetchOptions);

  const newVodPlaylistDataUnparsed: NewVodPlaylist | NewVodPlaylistData[] =
    await newVodplaylistResponse.json();

  // v pripade indexu oddelenych carkou muze prijit pole jednotlivych playlistu
  // musime je mergnout, jakoby se jednalo o idec se skipy
  const newVodPlaylistData = Array.isArray(newVodPlaylistDataUnparsed)
    ? joinMultiplePlaylists(newVodPlaylistDataUnparsed)
    : newVodPlaylistDataUnparsed;

  if ('platformChannel' in newVodPlaylistData) {
    throw new VodIsLiveError('Vod is live!', newVodPlaylistData.platformChannel);
  }

  if (!newVodplaylistResponse.ok) {
    if (newVodplaylistResponse.status <= 500 && newVodplaylistResponse.status >= 400) {
      if (
        'error' in newVodPlaylistData &&
        newVodPlaylistData.error === PlaylistErrorCodes.PROMO_EXPIRED
      ) {
        throw new PlaylistError(newVodPlaylistData.message, PlaylistErrorCodes.PROMO_EXPIRED);
      }
      if (
        'error' in newVodPlaylistData &&
        // toto ma zatim na playlistech stejny vyznam
        [PlaylistErrorCodes.OUT_OF_TIME_RANGE, PlaylistErrorCodes.UNAVAILABLE_LICENCE].includes(
          newVodPlaylistData.error
        )
      ) {
        throw new PlaylistError(newVodPlaylistData.message, PlaylistErrorCodes.OUT_OF_TIME_RANGE);
      }
      if (
        'error' in newVodPlaylistData &&
        newVodPlaylistData.error === PlaylistErrorCodes.UNSUPPORTED_GEOLOCATION
      ) {
        throw new PlaylistError(
          newVodPlaylistData.message,
          PlaylistErrorCodes.UNSUPPORTED_GEOLOCATION
        );
      }
      // tento Error umoznuje fallback na stare pl. (pokud je fallback zapnuty)
      throw new PlaylistError(
        'message' in newVodPlaylistData ? newVodPlaylistData.message : 'Unknown new playlist error',
        PlaylistErrorCodes.MEDIUM_NOT_FOUND
      );
    }
    throw new PlaylistError(
      'message' in newVodPlaylistData ? newVodPlaylistData.message : 'Unknown new playlist error',
      PlaylistErrorCodes.INTERNAL_ERROR
    );
  }
  if ('message' in newVodPlaylistData) {
    throw new PlaylistError(newVodPlaylistData.message, newVodPlaylistData.error);
  }
  if (!newVodPlaylistData.streams) {
    throw new Error('Missing stream playlist data');
  }

  const promoReplayToken =
    'replayToken' in newVodPlaylistData && newVodPlaylistData.replayToken
      ? newVodPlaylistData.replayToken
      : replayToken;

  const streamData: PlaylistStreamResponse = {
    ...(newVodPlaylistData.analytics ? { analytics: { ...newVodPlaylistData.analytics } } : {}),
    setup: {
      ...LEGACY_PLAYLIST_SETUP_BASE,
      adOcean: newVodPlaylistData.adOcean,
      title: newVodPlaylistData.title,
      previewImageUrl: '',
      duration: String(newVodPlaylistData.duration),
      ...(newVodPlaylistData.externalId ? { externalId: newVodPlaylistData.externalId } : {}),
      replayToken: promoReplayToken,
      targetGroup: newVodPlaylistData.targetGroup,
    },
    playlist: newVodPlaylistData.streams.map((stream, index) => ({
      ...LEGACY_PLAYLIST_ITEM_BASE,
      id: String(index),
      ...(stream.chapters.length && newVodPlaylistData.streams.length === 1
        ? {
            indexes: stream.chapters.map((chapter) => ({
              title: chapter.title,
              // ve starych playlistech je imageURL ne imageUrl!
              imageURL: chapter.imageUrl,
              time: chapter.time,
            })),
          }
        : {}),
      assetId: id,
      duration: String(stream.duration),
      previewImageUrl: '',
      previewTrackBaseUrl: newVodPlaylistData.previewTrackBaseUrl,
      title: `${newVodPlaylistData.title}${
        newVodPlaylistData.streams.length > 1 ? ` (${index + 1})` : ''
      }`,
      type: 'VOD',
      streamUrls: {
        main: stream.url,
        ...(stream.audioDescriptionUrl
          ? { audioDescription: quality === 'audioad' ? stream.url : stream.audioDescriptionUrl }
          : {}),
      },
      subtitles: stream.subtitles
        .map((item) => {
          return {
            code: item.language,
            title: getSubtitleDisplayName(item.language),
            url: item.files.find((file) => file.format === 'vtt')?.url || '',
          };
        })
        .filter((item) => item.url),
    })),
  };

  // Pridame na zacatek pole polozku typu TRAILER, ktery se pozdeji transformuje v klasicky
  // labeling. Zde se jedna o upozorneni 18+ (PEGI-15 se rusi)
  if (
    !disableLabeling &&
    newVodPlaylistData.pegiRatingLink &&
    newVodPlaylistData.pegiRating === 18
  ) {
    streamData.playlist = [
      {
        ...LEGACY_PLAYLIST_ITEM_BASE,
        id: PEGI_RATING_DATA.PEGI_18.contentId,
        duration: PEGI_RATING_DATA.PEGI_DURATION,
        assetId: PEGI_RATING_DATA.PEGI_18.assetId,
        type: 'TRAILER',
        title: PEGI_RATING_DATA.PEGI_18.title,
        previewImageUrl: '',
        previewTrackBaseUrl: '',
        streamUrls: { main: newVodPlaylistData.pegiRatingLink },
        gemius: {
          ...LEGACY_PLAYLIST_ITEM_BASE.gemius,
          NAZEV: PEGI_RATING_DATA.PEGI_18.gemiusTitle,
          ID: PEGI_RATING_DATA.PEGI_18.assetId,
        },
      },
      ...streamData.playlist,
    ];
  }
  return streamData;
};
