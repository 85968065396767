import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { LabelProps } from './common';
import { commonFormElementClassnames } from './common.css';
import { Label } from './Label/Label';

interface Props {
  children: ReactNode;
  className?: string;
  error?: FieldError;
  errorId?: string;
  hideValidationIcon?: boolean;
  id?: string;
  inverted?: boolean;
  label?: string | LabelProps;
}

export const ValidatedFormElementWrapper = ({
  label,
  error,
  children,
  inverted,
  id,
  className,
  errorId,
}: Props) => {
  return (
    <div className={classNames(commonFormElementClassnames.commonFormBlock, className)}>
      {label && (
        <Label
          htmlFor={id}
          inverted={inverted}
          required={typeof label === 'object' ? label.required : undefined}
        >
          {typeof label === 'object' ? label.title : label}
        </Label>
      )}
      {children}
      {/* přístupné errorové hlášky s rolí alert */}
      {error?.message ? (
        <span className={commonFormElementClassnames.commonErrorMsg} id={errorId} role="alert">
          {error.message}
        </span>
      ) : null}
    </div>
  );
};
