import React, { useEffect, useState } from 'react';
import { PlayerDynamicImportProvider } from '../usePlayerSetup';

const basicDynamicImport: PlayerDynamicImportProvider = (loader, options) => {
  return (props) => {
    const [component, setComponent] = useState<React.ReactElement<typeof props>>();
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
      let isMounted = true;
      (async () => {
        try {
          const resolved = await loader();
          if (isMounted) {
            setComponent(
              // @ts-expect-error ... netušim
              React.createElement('default' in resolved ? resolved.default : resolved, props)
            );
          }
        } catch (exception) {
          if (isMounted) {
            setError(exception instanceof Error ? exception : new Error(`${exception}`));
          }
        }
      })();
      return () => {
        isMounted = false;
      };
    }, [props]);

    if (component) {
      return component;
    }

    if (options?.loading) {
      return options.loading({ error });
    }

    return null;
  };
};

export default basicDynamicImport;
