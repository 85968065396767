/* eslint-disable canonical/filename-no-index */
import { AnalyticsSubscriber } from '../common';
import { nielsenSdkHandler } from './nielsenSdkHandler';
import { NielsenAdPayload, NielsenEvents, NielsenMainContentPayload } from './nielsenTypes';
import { getNielsenPosition, nielsenContextToMeta } from './nielsenTransformers';
import { getAdPosition, getNielsenAdMeta } from './nielsenPlayerAd';

interface NielsenClient {
  ggPM(
    eventName: NielsenEvents.LOAD_METADATA,
    data: NielsenMainContentPayload | NielsenAdPayload
  ): void;
  ggPM(
    eventName: NielsenEvents.END | NielsenEvents.SET_PLAYHEAD_POSITION | NielsenEvents.STOP,
    data: number
  ): void;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    NOLBUNDLE: {
      nlsQ: (
        appId: string,
        instanceName: string,
        options?: { nol_sdkDebug: 'debug' }
      ) => NielsenClient;
    };
  }
}

interface NielsenSubscriberConfig {
  clientid?: string;
  debug?: boolean;
  id: string;
  instanceName?: string;
}

export const nielsenSubscriber = ({
  id,
  instanceName = 'player',
  debug = false,
}: NielsenSubscriberConfig): AnalyticsSubscriber => {
  let client: NielsenClient;
  // na ssr žádné eventy nelogujeme
  if (typeof window === 'undefined') {
    return {
      name: 'ssr',
      callback: () => {},
    };
  }

  // odložíme načtení sdk do prvního requestu
  // any[] protože si to neporadí s přetíženou funkcí
  const fireEvent: NielsenClient['ggPM'] = (...args: any[]) => {
    if (!client) {
      nielsenSdkHandler();

      client = window.NOLBUNDLE.nlsQ(
        id,
        instanceName,
        debug ? { nol_sdkDebug: 'debug' } : undefined
      );
    }
    client.ggPM(...(args as [any, any]));
  };

  return {
    name: 'nielsen',
    callback: (event) => {
      // pro chromecast musime ignorovat Nielsen (stara se o nej receiver apka)
      if (event.data && 'chromecast' in event.data && event.data.chromecast) {
        return;
      }
      switch (event.type) {
        case 'PlayerLoad':
        case 'PlayerLoadNielsenOnly':
        case 'PlayerReplay':
        case 'PlayerLiveStreamChanged':
          fireEvent(NielsenEvents.LOAD_METADATA, nielsenContextToMeta(event.context));
          break;
        case 'PlayerExtraBroadcastChanged':
          fireEvent(NielsenEvents.END, getNielsenPosition(event.context));
          fireEvent(NielsenEvents.LOAD_METADATA, nielsenContextToMeta(event.context));
          break;
        case 'PlayerPause':
          fireEvent(NielsenEvents.STOP, getNielsenPosition(event.context));
          break;
        case 'PlayerEnd':
        case 'PlayerLiveStreamChanging':
          fireEvent(NielsenEvents.END, getNielsenPosition(event.context));
          break;
        case 'PlayerUnload':
          fireEvent(NielsenEvents.STOP, getNielsenPosition(event.context));
          fireEvent(NielsenEvents.END, getNielsenPosition(event.context));
          break;
        case 'PlayerProgressEverySecond':
          fireEvent(NielsenEvents.SET_PLAYHEAD_POSITION, getNielsenPosition(event.context));
          break;
        case 'PlayerAdLoad':
          fireEvent(NielsenEvents.LOAD_METADATA, getNielsenAdMeta(event.context));
          break;

        case 'PlayerAdProgress':
          fireEvent(NielsenEvents.SET_PLAYHEAD_POSITION, getAdPosition(event.context));
          break;
        case 'PlayerNielsenEnd':
          fireEvent(NielsenEvents.STOP, getNielsenPosition(event.context));
          break;
        case 'PlayerAdEnd':
        case 'PlayerAdSkip':
          fireEvent(NielsenEvents.STOP, getAdPosition(event.context));
          break;
        default:
          // jiné eventy nelogujeme
          break;
      }
    },
  };
};
