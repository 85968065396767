import classNames from 'classnames';
import React from 'react';
import { IconErrorCircle } from '@czechtv/icons';
import { MessageProps } from './Message';
import { messageFailedClassnames } from './MessageFailed.css';
import { MessageInfo } from './MessageInfo';

export interface MessageFailedProps extends Omit<MessageProps, 'children'> {
  description?: React.ReactNode;
  title?: React.ReactNode;
}

export const MessageFailed = ({
  className,
  leftIcon = IconErrorCircle,
  leftIconClassName,
  description,
  title,
  ...rest
}: MessageFailedProps) => {
  return (
    <MessageInfo
      {...rest}
      className={classNames(messageFailedClassnames.failed, className)}
      description={description}
      leftIcon={leftIcon}
      leftIconClassName={leftIconClassName || messageFailedClassnames.iconFailed}
      title={title}
    />
  );
};
