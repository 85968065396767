import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconPlayableNow: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#FB8D8F" height="16" rx="8" width="22" x="1" y="4" />
      <path
        d="M9.08 9.2v5.6a.83.83 0 0 0 1.25.72l4.96-2.8a.83.83 0 0 0 0-1.45l-4.96-2.79a.83.83 0 0 0-1.25.73z"
        fill="#FFF"
      />
    </g>
  </svg>
));
