export interface VariantTrack {
  active: boolean;
  audioBandwidth: number | null;
  audioCodec: string | null;
  audioId: number | null;
  audioRoles: string[] | null;
  audioSamplingRate: number | null;
  bandwidth: number;
  channelsCount: number | null;
  codecs: string;
  frameRate: number | null;
  height: number | null;
  id: number;
  kind: string | null;
  label: string | null;
  language: string;
  mimeType: string;
  originalAudioId: string | null;
  originalTextId: string | null;
  originalVideoId: string | null;
  primary: boolean;
  roles: string[];
  videoBandwidth: number | null;
  videoCodec: string | null;
  videoId: number | null;
  width: number | null;
}
interface ShakaStats {}
interface DashManifest {}

declare global {
  interface Window {
    shakaUtils?: {
      getActiveVariantTrack: () => VariantTrack | null;
      getAudioLanguages: () => string[];
      getConfiguration(): () => DashManifest;
      getStats: () => ShakaStats;
      getVariantTracks: () => VariantTrack[];
    };
  }
}

export const exposeShakaInfo = (shakaPlayerInstance: any) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.shakaUtils = {
    getAudioLanguages: () => shakaPlayerInstance.getAudioLanguages(),
    getActiveVariantTrack: () => {
      const active = shakaPlayerInstance
        .getVariantTracks()
        ?.filter((track: VariantTrack) => track.active);
      return active.length ? active[0] : null;
    },
    getConfiguration: () => shakaPlayerInstance.getConfiguration(),
    getStats: () => shakaPlayerInstance.getStats(),
    getVariantTracks: () => shakaPlayerInstance.getVariantTracks(),
  };
};

/**
 * Determines the initial resolution based on desired, maximum, and automatic quality settings.
 * If either `maxAutoQuality` or `maxQuality` is set and less than 1080p, the function returns `null`, which means
 * automatic quality selection (Auto ABR quality) will be used, and `desiredResolution` won't be manually set.
 *
 * @param {(number|null)} desiredResolution - The resolution desired by the user. Can be a number or `null`.
 * @param {number} [maxQuality] - The maximum quality allowed. If provided and less than 1080p, it disables the setting of the desired resolution.
 * @param {number} [maxAutoQuality] - The maximum automatic quality allowed. If provided and less than 1080p, it disables the setting of the desired resolution.
 * @returns {(number|null)} The `desiredResolution` if both `maxQuality` and `maxAutoQuality` are not defined or greater than/equal to 1080p; otherwise, returns `null` to use Auto ABR quality.
 */
export const getInitialResolution = (
  desiredResolution: number | null,
  maxQuality?: number,
  maxAutoQuality?: number
) => {
  if (maxAutoQuality && maxAutoQuality < 1080) {
    return null;
  }
  if (maxQuality && maxQuality < 1080) {
    return null;
  }
  return desiredResolution;
};
