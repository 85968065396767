export interface AdScriptMeta {
  assetid: string;
  attribute: string; // 1;
  channelId: string;
  crossId: string;
  length: string;
  livestream: string; // '0' nebo '1'
  program: string;
  title: string;
  type: string; // 'content'
}

export interface AdScriptAdMeta {
  asmea: string;
  assetid: string;
  attribute: string;
  length: string;
  title: string;
  type: 'preroll' | 'postroll' | 'midroll';
}

export enum AdScriptEvents {
  COMPLETE = 'complete',
  FIRST_QUARTILE = 'firstQuartile',
  MIDPOINT = 'midpoint',
  PROGRESS1 = 'progress1',
  START = 'start',
  THIRD_QUARTILE = 'thirdQuartile',
}

export interface AdScriptContentPayload extends AdScriptMeta {}
