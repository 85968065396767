import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
  breakpoints: () => Record<string, boolean>;
  children: ReactNode;
  classPrefix?: string;
}

const ResponsiveClassesWrapper = React.forwardRef<HTMLDivElement, Props>(
  ({ children, classPrefix = '', breakpoints }, ref) => {
    const classNamesValue = Object.entries(breakpoints()).map(([key, value]) => {
      return {
        [`${classPrefix}${classPrefix ? `-${key}` : key}`]: value,
      };
    });

    return (
      <div className={classNames(classNamesValue)} ref={ref}>
        {children}
      </div>
    );
  }
);

export default memo(ResponsiveClassesWrapper);
