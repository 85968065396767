import { LANG_CODE_AD, AUDIO_MESSAGES } from '../constants';

export const getAudioLanguageLabel = (languageCode: string, shortNames?: boolean): string => {
  const lowerCasedLanguage = languageCode.toLocaleLowerCase();

  if (
    lowerCasedLanguage === 'que' ||
    lowerCasedLanguage === 'qp' ||
    lowerCasedLanguage === LANG_CODE_AD
  ) {
    if (shortNames) {
      return AUDIO_MESSAGES.audioBlind.defaultMobileMessage as string;
    }

    return AUDIO_MESSAGES.audioBlind.defaultMessage;
  }

  if (shortNames) {
    return (AUDIO_MESSAGES[lowerCasedLanguage]?.defaultMobileMessage ||
      AUDIO_MESSAGES.audioOriginal.defaultMobileMessage) as string;
  }

  return (
    AUDIO_MESSAGES[lowerCasedLanguage]?.defaultMessage ||
    AUDIO_MESSAGES.audioOriginal.defaultMessage
  );
};
