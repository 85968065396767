import React, { HTMLAttributes, memo } from 'react';
import { ChannelType, getChannelColor } from '@czechtv/utils';
import { Red_base } from '@czechtv/styles';
import { ProgressBar } from '../ProgressBar/ProgressBar';

export interface ChannelProgressBarProps extends Omit<HTMLAttributes<HTMLDivElement>, 'role'> {
  /* Toto je úžasný kanál */
  channel?: ChannelType;
  className?: string;
  progress: number;
}

export const ChannelProgressBar = memo<ChannelProgressBarProps>(
  ({ channel, ...rest }: ChannelProgressBarProps) => {
    return <ProgressBar {...rest} color={channel ? getChannelColor(channel) : Red_base} />;
  }
);
