// error kody shaka playeru https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
import { PlayerErrorCategory } from '../../../components/Error/PlayerErrorCategory';
import { PlayerError } from '../PlayerError';

export const SHAKA_BAD_HTTP_STATUS = 1001;
export const SHAKA_HTTP_ERROR_CODE = 1002;
export const SHAKA_QUOTA_EXCEEDED_ERROR = 3017;
export const SHAKA_CANNOT_STORE_LIVE_OFFLINE = 9005;
export const SHAKA_STORAGE_NOT_SUPPORTED = 9000;
export const SHAKA_CURRENT_DAI_REQUEST_NOT_FINISHED = 10004;
export const CONTENT_UNSUPPORTED_BY_BROWSER = 4032;

const getPlayerErrorCategory = (category: number, code: number): PlayerErrorCategory => {
  if (code === CONTENT_UNSUPPORTED_BY_BROWSER) {
    return PlayerErrorCategory.BROWSER;
  }
  switch (category) {
    case 1:
      return PlayerErrorCategory.NETWORK;
    case 2:
      return PlayerErrorCategory.TEXT;
    case 3:
      return PlayerErrorCategory.MEDIA;
    case 4:
      return PlayerErrorCategory.MANIFEST;
    case 5:
      return PlayerErrorCategory.STREAMING;
    case 6:
      return PlayerErrorCategory.DRM;
    case 7:
      return PlayerErrorCategory.PLAYER;
    case 8:
      return PlayerErrorCategory.CAST;
    case 9:
      return PlayerErrorCategory.STORAGE;
    case 10:
      return PlayerErrorCategory.ADS;
    default:
      return PlayerErrorCategory.APPLICATION;
  }
};

export interface ShakaErrorEvent extends ErrorEvent {
  detail: ShakaRawError;
}

type ShakaErrorData = number | string;

export interface ShakaRawError {
  category: number;
  code: number;
  data: ShakaErrorData[];
  handled: boolean;
  severenity: number;
}

const getShakaMessage = ({ code, data, category }: ShakaRawError) => {
  if (code === SHAKA_BAD_HTTP_STATUS) {
    // error.data[0] is the URI.
    // error.data[1] is the status code.
    // error.data[2] is the response text, or null if the response could not be interpretted as text
    // error.data[3] is the map of response headers.
    // error.data[4] is the NetworkingEngine.RequestType of the request, if one was provided.
    return `Shaka error: code:${data[1]}`;
  }
  if (code === SHAKA_HTTP_ERROR_CODE) {
    // An HTTP network request failed with an error, but not from the server.
    // error.data[0] is the URI.
    // error.data[1] is the original error.
    // error.data[2] is the NetworkingEngine.RequestType of the request.
    return `Shaka error: An HTTP network request failed with an error, but not from the server`;
  }
  if (category === 6) {
    return `Shaka error: Cannot play DRM.`;
  }
  return `Shaka error: code:${code}`;
};

export class ShakaError extends PlayerError {
  data: ShakaRawError | ShakaErrorEvent;

  playerErrorCategory: PlayerErrorCategory;

  parsedData: ShakaRawError;

  constructor(data: ShakaRawError | ShakaErrorEvent) {
    super(getShakaMessage('detail' in data ? data.detail : data));
    this.data = data;
    this.parsedData = 'detail' in data ? data.detail : data;
    this.playerErrorCategory = getPlayerErrorCategory(
      this.parsedData.category,
      this.parsedData.code
    );
  }

  getCode = () => {
    return this.parsedData.code;
  };

  getStatusCode = () => {
    const detail = 'detail' in this.data ? this.data.detail : null;
    const statusCode = detail?.data && detail.data.length > 1 ? detail.data[1] : 500;
    return statusCode;
  };
}

export const isShakaError = (data: unknown): data is ShakaRawError | ShakaErrorEvent => {
  if (!data) {
    return false;
  }
  if (typeof data !== 'object') {
    return false;
  }
  const item = data as Object;
  return 'detail' in item || ('category' in item && 'code' in item && 'data' in item);
};
