import { useEffect, useState } from 'react';
import { getEnumValues } from '@czechtv/utils';
import { log } from '../utils/logger';

export enum DRM {
  fairplay = 'fairplay',
  fairplay_1_0 = 'fairplay_1_0',
  fairplay_2_0 = 'fairplay_2_0',
  fairplay_3_0 = 'fairplay_3_0',
  playready = 'playready',
  widevine = 'widevine',
}

const isEMESupported = (): boolean =>
  'MediaKeys' in window || 'WebKitMediaKeys' in window || 'MSMediaKeys' in window;

const isRMKSASupported = (): boolean => 'requestMediaKeySystemAccess' in window.navigator;

const keySystems = {
  [DRM.widevine]: 'com.widevine.alpha',
  [DRM.playready]: 'com.microsoft.playready',
  [DRM.fairplay]: 'com.apple.fps',
  [DRM.fairplay_1_0]: 'com.apple.fps.1_0',
  [DRM.fairplay_2_0]: 'com.apple.fps.2_0',
  [DRM.fairplay_3_0]: 'com.apple.fps.3_0',
};

export const getIsDrmOnlyFromUrl = (url: string) => url.includes('drmOnly=true');

const getSupportedKeySystemsConfig = (keySystem: DRM) => {
  // Pro widevine specifikujeme konkretni `robustness`, proto ma vlastni konfig
  if (keySystem === DRM.widevine) {
    return [
      {
        initDataTypes: ['cenc'],
        videoCapabilities: [
          {
            contentType: 'video/mp4;codecs="avc1.42E01E"',
            robustness: 'SW_SECURE_CRYPTO',
          },
        ],
      },
    ];
  }

  return [
    {
      initDataTypes: ['cenc'],
      videoCapabilities: [
        {
          contentType: 'video/mp4;codecs="avc1.42E01E"',
          robustness: '',
        },
      ],
    },
  ];
};

const getLegacySafariDRM = (): DRM[] => {
  const { WebKitMediaKeys } = window as any;

  if (typeof WebKitMediaKeys !== 'function') {
    return [];
  }

  return getEnumValues(DRM).filter((drm) => {
    return WebKitMediaKeys.isTypeSupported(keySystems[drm], 'video/mp4');
  });
};

export const getSupportedDRMs = async (): Promise<DRM[]> => {
  if (!isEMESupported() || !isRMKSASupported()) {
    return [];
  }

  const supportedKeySystems = (
    await Promise.all(
      getEnumValues(DRM).map(async (drm) => {
        try {
          await navigator.requestMediaKeySystemAccess(
            keySystems[drm],
            getSupportedKeySystemsConfig(drm)
          );
          return drm;
        } catch (error) {
          // chyba je pro nás ok, znamená to, že uživatel dané drm nepodporuje
          // dal by se logovat i reason, např. na firefoxu se dá drm vypnout
          return false;
        }
      })
    )
  ).filter((item) => item !== false) as DRM[];

  return Array.from(new Set([...supportedKeySystems, ...getLegacySafariDRM()]));
};

export const useSupportedDRMs = () => {
  const [drms, setDrms] = useState<DRM[] | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;
    getSupportedDRMs()
      .then((result) => {
        if (!isMounted) {
          return;
        }
        setDrms(result);
      })
      .catch((error) => {
        if (!isMounted) {
          return;
        }
        log.error({ message: 'Unable to get supported DRMs', error });
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return drms;
};

// TODO: Otestovat
export const getStreamDRMFromUrl = (url: string): 'fairplay' | 'widevine' | null => {
  if (url.includes('encryption=wv')) {
    return 'widevine';
  }
  if (url.includes('encryption=fp')) {
    return 'fairplay';
  }

  return null;
};
