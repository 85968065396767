import classNames from 'classnames';
import React, { ImgHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
// TODO: Obrazek by mel chodit ze serveru v cilove velikosti a kvalite
import PlaceholderImage from './__mocks__/video_placeholder.png';
import { imageLoaderClassnames } from './ImageLoader.css';

export interface ImageLoaderProps extends ImgHTMLAttributes<HTMLImageElement> {
  classNameImg?: string;
  // skryje container přes arria-hidden
  hideMetaInfo?: boolean;
}

export const ImageLoader = memo<ImageLoaderProps>(
  ({ hideMetaInfo, className, classNameImg, alt, src, ...props }) => {
    // Pro odstraneni Warning: Expected server HTML to contain a matching <div> in <div>
    // Je ocekavan identicky prvotni content na strane serveru i clienta X pouzivame typeof window
    // pripadne mozne ignorovat pomoci suppressHydrationWarning
    const [renderOnClient, setRenderOnClient] = useState(false);
    // Obrazek se pri SSR nenacita
    const [isLoading, setIsLoading] = useState(typeof window !== 'undefined');
    const [hasError, setError] = useState(false);
    // const [src, setSrc] = useState(props.src);

    const imageRef = useRef<HTMLImageElement>(null);

    const onImageLoad = () => {
      setIsLoading(false);
    };

    const onImageError = () => {
      setError(true);
    };

    useEffect(() => {
      setRenderOnClient(true);
      setError(false);

      if (!imageRef.current) {
        return;
      }

      const { complete, naturalWidth } = imageRef.current;

      if (complete && naturalWidth !== 0) {
        // Pri SSR neni zavolan onLoad callback,
        // je potreba detekovat rucne, ze uz je obrazek nacteny
        setIsLoading(false);
      } else {
        // Zmenily se parametry, nastavime priznak nacitani
        setIsLoading(true);
      }
    }, [src]);

    if (!src) {
      return (
        <div
          className={classNames(imageLoaderClassnames.imageContainer, className)}
          data-testid="videoImagePlaceholder"
        >
          <img
            {...props}
            alt=""
            className={classNames(imageLoaderClassnames.image, classNameImg)}
            src={PlaceholderImage}
            onLoad={onImageLoad}
          />
        </div>
      );
    }

    return (
      <div
        aria-hidden={hideMetaInfo}
        className={classNames(imageLoaderClassnames.imageContainer, className)}
        data-testid="videoImage"
      >
        <img
          {...props}
          alt={renderOnClient ? alt || undefined : undefined}
          className={classNames(imageLoaderClassnames.image, classNameImg)}
          ref={imageRef}
          src={hasError ? PlaceholderImage : src}
          onError={onImageError}
          onLoad={onImageLoad}
        />
        {renderOnClient && isLoading && (
          <div className={classNames(imageLoaderClassnames.loadingOverlay)} />
        )}
      </div>
    );
  }
);
