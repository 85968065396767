import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconRedButton: SvgComponent = memo((props) => (
  <svg height="12px" version="1.1" viewBox="0 0 23 12" width="23px" {...props}>
    <title>Red Button</title>
    <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
      <g fill="#ED1C24" transform="translate(-102.000000, -81.000000)">
        <g id="Steps" transform="translate(16.000000, 47.000000)">
          <rect height="12" id="Red-Button" rx="4" width="23" x="86" y="34" />
        </g>
      </g>
    </g>
  </svg>
));
