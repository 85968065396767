import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { IconRewind } from '@czechtv/icons';
import { body14 } from '@czechtv/styles';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { formatMessage } from '../../../../utils/formatMessage';

const useStyles = createUseStyles({
  wrapper: {
    ...body14,
    color: '#fff',
    padding: 0,
    margin: [0, 2],
    '& svg': {
      padding: [4, 6, 4, 4],
      fill: '#fff',
      width: 24,
      height: 24,
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      fontSize: 0,
      background: 'transparent',
      letterSpacing: 0,
      '& svg': {
        padding: 10,
      },
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxTouchMediumMobile])]: {
      outline: 'none',
      margin: [-5, 6, -1, -12],
      letterSpacing: 0,
      padding: 0,
    },
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 12,
    outline: 'none',
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      paddingRight: 0,
    },
  },
});

interface TimeshiftStartButtonProps {
  onClick?: () => void;
}

export const TimeshiftStartButton = memo(({ onClick }: TimeshiftStartButtonProps) => {
  const classes = useStyles();

  return (
    <PlayerNativeButton className={classes.wrapper} onClick={onClick}>
      <div className={classes.innerWrapper} tabIndex={-1}>
        <IconRewind />
        {formatMessage({
          id: 'Controls.timeshiftStartButton',
          defaultMessage: 'Na začátek',
          description: 'Tlačítko Na začátek',
        })}
      </div>
    </PlayerNativeButton>
  );
});
