import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import throttle from 'lodash/throttle';
import { BoxShadowFocus } from '@czechtv/styles';
import {
  FOCUS_VISIBLE_ACTIVE_CLASS_NAME,
  useFocusVisibleClassName,
  useWidth,
} from '@czechtv/components';
import PlayerClientState from '../../../../Providers/Client/state';
import { usePlayerAnalytics } from '../../../../Providers/Analytics/useAnalyticsContext';
import { usePlayerContext } from '../../../PlayerContext';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { default as TimeDisplayShift } from '../../../../components/ProgressBar/RemainingTime';
import { ZINDEX_PROGRESS_BAR } from '../../../../zindexes';
import { FULL_LIVE_STREAM_LENGTH, PlayerMode } from '../../../../constants';
import { PROGRESS_BAR_PADDING, SNAP_SIZE_PX } from '../../../../components/ProgressBar/constants';
import { Timestamps } from '../../../../components/ProgressBar/Timestamps/Timestamps';
import { formatMessage } from '../../../../utils/formatMessage';
import { TimeDisplaySwitch } from '../../../playerSettings';
import IndexList, { IndexItemProps } from './IndexList';

interface StylesOptions {
  isAdMode: boolean;
  isLoading?: boolean;
}

const messages = {
  remainingTime: {
    id: 'TimeDisplay.buttonTitle.remainingTime',
    defaultMessage: 'Zbývající čas',
    description: '',
  },
  currentTime: {
    id: 'TimeDisplay.buttonTitle.currentTime',
    defaultMessage: 'Uběhlý čas',
    description: '',
  },
};

const useStyles = createUseStyles({
  progressBarComponentWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    outline: 'none',
  },
  progressBarContainer: {
    width: '100%',
    paddingTop: PROGRESS_BAR_PADDING,
    paddingBottom: PROGRESS_BAR_PADDING,
    cursor: ({ isLoading, isAdMode }: StylesOptions) =>
      isLoading || isAdMode ? 'default' : 'pointer',
    userSelect: 'none',
    marginRight: 20,
    outline: 'none',
    zIndex: ZINDEX_PROGRESS_BAR,
    position: 'relative',
    [getPlayerResponsiveRule([Breakpoint.isMaxTouchMediumMobile])]: {
      marginRight: 0,
    },
    [`.${FOCUS_VISIBLE_ACTIVE_CLASS_NAME} > &`]: {
      outline: 'none',
      boxShadow: BoxShadowFocus,
    },
  },
  infoSection: {
    display: 'flex',
    [getPlayerResponsiveRule([Breakpoint.isMaxTouchMediumMobile])]: {
      position: 'absolute',
      bottom: 16,
      right: 0,
    },
  },
});

interface ProgressBarProps {
  buffered?: number;
  className?: string;
  indicatorColor?: string;
  isLoading?: boolean;
  onClick: (progress: number) => void;
  onTimeDisplayClick?: () => void;
  playerMode?: PlayerMode;
  playerState?: PlayerClientState;
  progress: number;
  thumbnailsUrl?: string;
  timeDisplaySwitch?: TimeDisplaySwitch;
  videoDuration: number;
}

export const VODProgressBar = ({
  buffered,
  className,
  isLoading,
  indicatorColor,
  thumbnailsUrl,
  playerMode,
  playerState,
  progress,
  onClick,
  onTimeDisplayClick,
  videoDuration,
  timeDisplaySwitch,
}: ProgressBarProps) => {
  // pozice pro zobrazování indexu
  const [pointerPosition, setPointerPosition] = useState<undefined | number>(undefined);
  const [isDragging, setIsDragging] = useState(false);
  const { indexes, setPreventHideControls } = usePlayerContext();
  const { onIndexStartBar } = usePlayerAnalytics();
  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const progressBarTimestampsWidth = useWidth(progressBarContainerRef);
  const stylesOptions: StylesOptions = {
    isLoading,
    isAdMode: playerMode === PlayerMode.ads,
  };
  const classes = useStyles(stylesOptions);
  const focusVisibleClassName = useFocusVisibleClassName();
  const shouldRegisterMouseEvents = !isLoading && playerMode !== PlayerMode.ads;
  const currentTimeSeconds = videoDuration * (progress / 100);
  const remainingTime = useMemo(() => {
    if (playerState === PlayerClientState.FINISHED) {
      return 0;
    }
    return videoDuration - currentTimeSeconds;
  }, [currentTimeSeconds, playerState, videoDuration]);
  const timeDisplayMessage =
    timeDisplaySwitch === TimeDisplaySwitch.REMAINING_TIME
      ? messages.remainingTime
      : messages.currentTime;

  useEffect(() => {
    if (isDragging) {
      setPreventHideControls(true);
    } else {
      setPreventHideControls(false);
    }
  }, [isDragging, setPreventHideControls]);

  // pokud je dany timestamp blizko indexu, vrat tento index
  const snapIndex = useCallback(
    (timestamp: number): IndexItemProps | undefined => {
      if (!indexes) {
        return undefined;
      }
      const secPerPx = videoDuration / progressBarTimestampsWidth;
      const timestampSnapSizeSec = Math.floor(secPerPx * SNAP_SIZE_PX);

      const snapTimestamp = (item: IndexItemProps) => {
        return (
          item.startTime < timestamp + timestampSnapSizeSec &&
          item.startTime > timestamp - timestampSnapSizeSec
        );
      };
      return indexes.find((item) => snapTimestamp(item));
    },
    [indexes, progressBarTimestampsWidth, videoDuration]
  );

  // wrapper pro onClick, ktery zajisti snapnuti timestampu k blizkym indexum
  const onClickWithSnap = (progress: number): void => {
    const newTime = (videoDuration * progress) / 100;
    const snappedIndex = snapIndex(newTime);
    // timestamp posunem primo do indexu pokud je blizko nej, jinak neposouvame
    const snapNewTime = snappedIndex?.startTime || newTime;

    onClick((snapNewTime / videoDuration) * 100);

    // analytika (pri prechodu do indexu)
    if (snappedIndex) {
      onIndexStartBar();
    }
  };

  /**
   * Handlers
   */

  const calcProgress = (e: { pageX: number }): number => {
    if (!progressBarContainerRef.current) {
      return 0;
    }
    const { left, right } = progressBarContainerRef.current.getBoundingClientRect();

    const relativeProgress = e.pageX - left;
    const relativeMaxProgress = right - left;

    const newProgress = (relativeProgress / relativeMaxProgress) * 100;

    return Math.min(100, Math.max(0, newProgress));
  };

  const durationInMinutes = `${Math.floor(videoDuration / 60)} minut a ${Math.floor(
    videoDuration % 60
  )} sekund`;
  const progressInMinutes = `${Math.floor(
    currentTimeSeconds / 60
  )} minut a ${Math.floor(currentTimeSeconds % 60)} sekund`;

  const onProgressBarMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setPointerPosition(calcProgress(e));
  };

  const onProgressBarMouseLeave = () => {
    if (isDragging) {
      return;
    }
    setPointerPosition(undefined);
  };

  // TODO: Implement keypress callback for accessibility
  const onProgressBarKeyPress = () => {};

  const onTouchStart = (event: React.TouchEvent) => {
    setPointerPosition(calcProgress(event.touches[0]));
  };

  const onTouchEnd = (event: React.TouchEvent) => {
    // nechceme pro touch spoustet mouse eventy (delaji to browsery kvuli zpetne kompatibilite)
    event.preventDefault();
    onClickWithSnap(pointerPosition || 0);
    setPointerPosition(undefined);
    setIsDragging(false);
  };

  const onTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!event.touches[0]) {
      return;
    }
    setPointerPosition(calcProgress(event.touches[0]));
    setIsDragging(true);
  };

  const bodyMouseMove = useMemo(
    () =>
      throttle((e: MouseEvent) => {
        setPointerPosition(calcProgress(e));
      }, 50),
    []
  );

  useEffect(() => {
    return () => bodyMouseMove.cancel();
  }, [bodyMouseMove]);

  // náhrada za drag event, kde nelze manipulovat s kurzorem myši
  const onMouseDown = () => {
    const onMouseOut = (e: MouseEvent) => {
      onClickWithSnap(calcProgress(e));
      setPointerPosition(undefined);
      setIsDragging(false);

      // po konci dragu odstraníme listenery
      document.removeEventListener('mousemove', bodyMouseMove);
      document.removeEventListener('mouseup', onMouseOut);
      document.body.style.cursor = 'auto';
    };

    setIsDragging(true);

    // nabindujeme eventy pro dragování
    document.addEventListener('mousemove', bodyMouseMove);
    document.addEventListener('mouseup', onMouseOut);
    document.body.style.cursor = 'pointer';
  };

  const getProgress = () => {
    if (playerState === PlayerClientState.FINISHED) {
      return 100;
    }
    return progress;
  };

  return (
    <div
      className={classNames(classes.progressBarComponentWrapper, focusVisibleClassName, className)}
    >
      <div
        aria-label={formatMessage({
          id: 'VodProgressBarAriaLabelTitle',
          defaultMessage: 'Posuvník přehrávání',
          description: 'Aria label pro ProgressBar',
        })}
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={progress}
        aria-valuetext={formatMessage(
          {
            id: 'VodProgressBar.fromTotalDuration',
            defaultMessage: '{progressInMinutes} z celkové délky {durationInMinutes}',
            description: '(progressInMinutes) z celkové délky (durationInMinutes)',
          },
          { progressInMinutes, durationInMinutes }
        )}
        className={classes.progressBarContainer}
        data-testid="ProgressBar"
        ref={progressBarContainerRef}
        role="slider"
        tabIndex={0}
        onKeyPress={shouldRegisterMouseEvents ? onProgressBarKeyPress : undefined}
        onMouseDown={shouldRegisterMouseEvents ? onMouseDown : undefined}
        onMouseLeave={shouldRegisterMouseEvents ? onProgressBarMouseLeave : undefined}
        onMouseMove={shouldRegisterMouseEvents ? onProgressBarMouseEnter : undefined}
        onTouchEnd={shouldRegisterMouseEvents ? onTouchEnd : undefined}
        onTouchMove={shouldRegisterMouseEvents ? onTouchMove : undefined}
        onTouchStart={shouldRegisterMouseEvents ? onTouchStart : undefined}
      >
        {indexes && !isLoading && playerMode !== PlayerMode.ads ? (
          <IndexList indexes={indexes} videoDuration={videoDuration} />
        ) : null}

        {!isLoading && playerMode !== PlayerMode.ads ? (
          <Timestamps
            duration={videoDuration}
            getFullLiveStreamLength={() => FULL_LIVE_STREAM_LENGTH}
            positionPercentage={pointerPosition}
            progressBarTimestampsWidth={progressBarTimestampsWidth}
            snapIndex={snapIndex}
            thumbnailsUrl={thumbnailsUrl}
          />
        ) : null}

        <ProgressBar
          buffered={buffered}
          hover={pointerPosition !== undefined}
          indicatorColor={indicatorColor}
          isLoading={isLoading}
          progress={isDragging && shouldRegisterMouseEvents ? pointerPosition || 0 : getProgress()}
        />
      </div>
      <div className={classes.infoSection}>
        {!isLoading && (
          <TimeDisplayShift
            aria-label={formatMessage(timeDisplayMessage)}
            remainingTime={
              timeDisplaySwitch === TimeDisplaySwitch.REMAINING_TIME
                ? remainingTime
                : currentTimeSeconds
            }
            role="button"
            title={formatMessage(timeDisplayMessage)}
            onClick={onTimeDisplayClick}
          />
        )}
      </div>
    </div>
  );
};
