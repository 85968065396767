/* eslint-disable canonical/filename-no-index */
import classNames from 'classnames';
import React, { LegacyRef, memo, ReactNode, RefObject, useEffect, useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import { createUseStyles } from 'react-jss';
import { Grey_20 } from '@czechtv/styles';
import { IconClose } from '@czechtv/icons';
import { Scrollbar, useHeight } from '@czechtv/components';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import { ZINDEX_SETTINGS_MENU } from '../../../../zindexes';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { MenuPopupType } from '../../../../constants';
import { SettingsListItemsEnum } from '../SettingsMenu/SettingsList/SettingsListItem/SettingsListItem';

interface MenuPopupProps {
  activeItem?: string;
  children: ReactNode;
  closeButtonAriaLabel: string;
  hasLeftSectionContent?: boolean;
  manuPopupContentClassName?: string;
  menuPopupRef?: RefObject<HTMLDivElement>;
  menuPopupTitleAriaLabel: string;
  name?: MenuPopupType;
  onClose?: () => void;
  playerRef?: RefObject<HTMLElement>;
  scrollableContent?: boolean;
  setMenuPopupVisible: (visible: MenuPopupType | null) => void;
  titleBarContent?: ReactNode | string | null;
  visible?: boolean;
}

interface StyleProps {
  height: number;
  isCaptions: boolean;
}

const MIN_POPUP_HEIGHT_SPACE = 160;

const useStyles = createUseStyles<string, StyleProps>({
  menuPopupContainer: {
    position: 'absolute',
    textAlign: 'right',
    right: 0,
    bottom: 80,
    color: '#ffffff',
    margin: '0px 16px 0px 0px',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    background: 'rgba(0, 0, 0, .8)',
    borderRadius: 4,
    height: 'auto',
    maxHeight: ({ height }) => height - MIN_POPUP_HEIGHT_SPACE,
    width: 'auto',
    '&.hasLeftSectionContent': {
      margin: '0px 70px 0px 0px',
    },
    zIndex: ZINDEX_SETTINGS_MENU,
    [getPlayerResponsiveRule([Breakpoint.isMaxTablet])]: {
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      maxHeight: 'initial',
      background: 'rgb(0, 0, 0)',
    },
  },

  closeIcon: {
    position: 'absolute',
    display: 'flex',
    top: 12,
    right: 12,
    '& path': {
      fill: Grey_20,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '&:focus': {
    outline: '2px solid transparent',
  },
  '&:focus-visible': {
    outline: '2px solid #60a0ff',
  },
  menuPopupContent: {
    display: 'flex',
    minHeight: ({ isCaptions }) => (isCaptions ? 'calc(100% - 54px)' : 'calc(100% - 32px)'),
    alignItems: 'center',
    justifyContent: 'center',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      minWidth: 320,
    },
  },
  titleBar: {
    width: '100%',
    flexShrink: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    '&.titleBarContent': {
      height: 48,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
    },
  },
  scrollBarWrapper: {
    '&.scroll': {
      overflow: 'overlay',
    },
  },
  titleBarContent: {
    position: 'absolute',
  },
});

const MenuPopup = ({
  activeItem,
  hasLeftSectionContent = false,
  menuPopupRef,
  menuPopupTitleAriaLabel,
  children,
  playerRef,
  closeButtonAriaLabel,
  titleBarContent,
  scrollableContent = false,
  onClose = () => {},
  manuPopupContentClassName: menuPopupContentClassName,
}: MenuPopupProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isCaptions = activeItem === SettingsListItemsEnum.SUBTITLES;
  const height = useHeight(playerRef || ref);
  const classes = useStyles({ height, isCaptions });
  const innerRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerHeight = useHeight(innerRef);
  const outerHeight = useHeight(outerRef);
  const scrollable = innerHeight + 15 > outerHeight;

  /*
  Menu pro castovani a settings se musi dat zavrit do Focus Trap. Zaroven je treba, aby:
  - slo zavrit pomoci Esc (resi Focus Trap)
  - slo zavrit kliknutim na X a opetovnym kliknutim na ikonku
  - slo zavrit kliknutim mimo menu (zde je problem v menu pro castovani, protoze jeho ref se zmeni
    pri prechodu mezi jeho jednotlivymi obrazovkami, takze event target na 'click' uz nelze najit
    v ramci noveho obsahu refu)
  - musi jit rovnou prepinat mezi jednotlivymi menu
  */
  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      // klasicke overovani kliknuti do menu
      const isStaticMenuIncluded = e.target && menuPopupRef?.current?.contains(e.target as Node);
      // kliknuti na button v menu
      let isMenuButton = false;
      if (e.target && 'closest' in e.target) {
        const closestButton = (e.target as Element).closest('button');
        isMenuButton = !!closestButton;
      }
      // kombinace obojiho
      const isClickedInside = isStaticMenuIncluded || isMenuButton;
      if (!isClickedInside) {
        onClose();
      }
    };
    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [menuPopupRef, onClose]);

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true, onDeactivate: onClose }}>
      <div
        aria-modal
        aria-label={menuPopupTitleAriaLabel}
        className={classNames(classes.menuPopupContainer, {
          hasLeftSectionContent: !hasLeftSectionContent,
        })}
        data-testid="playerSettingsMenu"
        id="settings-menu"
        ref={menuPopupRef}
        role="dialog"
      >
        <div className={classNames(classes.titleBar, { scrollable, titleBarContent })}>
          {titleBarContent ? (
            <div className={classes.titleBarContent}>{titleBarContent}</div>
          ) : null}
          <PlayerNativeButton
            aria-label={closeButtonAriaLabel}
            className={classNames(classes.closeIcon)}
            onClick={onClose}
          >
            <IconClose height={24} width={24} />
          </PlayerNativeButton>
        </div>
        {scrollableContent ? (
          <Scrollbar
            className={classNames(classes.scrollBarWrapper, { titleBarContent, scroll: true })}
            ref={outerRef as React.ForwardedRef<HTMLDivElement>}
          >
            <div
              className={classNames(classes.menuPopupContent, {
                scrollable,
              })}
              ref={innerRef as LegacyRef<HTMLDivElement>}
            >
              {children}
            </div>
          </Scrollbar>
        ) : (
          <div className={classNames(classes.menuPopupContent, menuPopupContentClassName, {})}>
            {children}
          </div>
        )}
      </div>
    </FocusTrap>
  );
};

export default memo(MenuPopup);
