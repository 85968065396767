import React, { memo, useMemo } from 'react';
import { Encoder } from '@czechtv/utils';
import { getMediaId } from '../../utils/parseIds';
import { PlayerLoadingOverlay } from '../PlayerLoadingOverlay';
import { PlaylistLoader, PlaylistLoaderProps } from './PlaylistLoader';
import { ExternalPlayerConfig } from './PlayerLoader';

interface SupportedIdentificators {
  bonusId?: string;
  encoder?: string;
  idec?: string;
  indexId?: string;
  live?: string;
  mediaId?: string;
  multimediaId?: string;
  versionId?: string;
  videoId?: string;
}

// zde jsou v Omitu věci, které zatím nechceme v komponentě podporovat
interface SupportedConfig extends Omit<ExternalPlayerConfig, 'maxStreamQuality'> {}

export type PlayerProps = SupportedConfig & SupportedIdentificators;

export const Player = memo((props: PlayerProps) => {
  const {
    bonusId,
    encoder,
    idec,
    indexId,
    live,
    multimediaId,
    versionId,
    mediaId,
    videoId,
    useNewPlaylist,
    borderRadius,
    externalLiveStreams,
  } = props;

  const ids = useMemo(
    () =>
      getMediaId({
        bonusId,
        encoder,
        idec,
        indexId,
        live,
        multimediaId,
        versionId,
        videoId,
        mediaId,
        useNewPlaylist: !!useNewPlaylist,
      }),
    [
      bonusId,
      encoder,
      idec,
      indexId,
      live,
      mediaId,
      multimediaId,
      useNewPlaylist,
      versionId,
      videoId,
    ]
  );

  const hasValidVodId =
    ids.externalId || ids.mediaId || ids.versionId || ids.encoder || externalLiveStreams;

  if (!hasValidVodId) {
    // nevyhazujeme error pro pripad, ze si nekdo vykresli komponentu behem async poptavani dat
    // ukazujeme pouze loading overlay, ale dame vedet do konzole
    console.warn('Waiting for a valid id or external live stream urls');
  }

  const supportedConfig = useMemo(() => {
    const {
      accessToken,
      additionalAdKeywords,
      adminMode,
      allControlsHidden,
      autoplay,
      bypassGeoIp,
      bypassUserSettings,
      cropEnd,
      cropStart,
      debugStreamPausedTimeout,
      debugStreamUrlExpiredTimeout,
      delayLoadingPlaylist,
      dev,
      disableAds,
      disableAirplay,
      disableChromecast,
      disableLabeling,
      displayHeaderInfo,
      duration,
      dynamicImportProvider,
      endTimestamp,
      fairplayAccessToken,
      forceAudioDescription,
      forceAudioOnly,
      gemiusId,
      gemiusPlayerId,
      liveMode,
      nielsenAppId,
      nielsenDebug,
      noGapTimeshift,
      maxAutoQuality,
      maxQuality,
      onError,
      onEvent,
      onPlaylistData,
      origin,
      parentUrl,
      playerRef,
      playerVersion,
      playlistLiveUri,
      playlistUri,
      playlistVodUri,
      previewImageUrl,
      product,
      router,
      shareVideoDomain,
      shareVideoProtocol,
      showId,
      showTitle,
      startTimeInSeconds,
      startTimestamp,
      externalLiveStreams,
      suppressAnalytics,
      tags,
      useLegacyPlaylistFallback,
      useNewAds,
      useNewPlaylist,
      userId,
      userIdTokenCookieName,
      userVideoProgressReportingUrl,
      vastConfig,
      videoTitle,
      widevineAccessToken,
    } = props;

    return {
      accessToken,
      additionalAdKeywords,
      adminMode,
      allControlsHidden,
      autoplay,
      bypassGeoIp,
      bypassUserSettings,
      cropEnd,
      cropStart,
      debugStreamPausedTimeout,
      debugStreamUrlExpiredTimeout,
      delayLoadingPlaylist,
      dev,
      disableAds,
      disableAirplay,
      disableChromecast,
      disableLabeling,
      displayHeaderInfo,
      duration,
      dynamicImportProvider,
      endTimestamp,
      fairplayAccessToken,
      forceAudioDescription,
      forceAudioOnly,
      gemiusId,
      gemiusPlayerId,
      liveMode,
      maxAutoQuality,
      maxQuality,
      nielsenAppId,
      nielsenDebug,
      noGapTimeshift,
      onError,
      onEvent,
      onPlaylistData,
      origin,
      parentUrl,
      playerRef,
      playerVersion,
      playlistLiveUri,
      playlistUri,
      playlistVodUri,
      previewImageUrl,
      product,
      router,
      shareVideoDomain,
      shareVideoProtocol,
      showId,
      showTitle,
      startTimeInSeconds,
      startTimestamp,
      externalLiveStreams,
      suppressAnalytics,
      tags,
      useLegacyPlaylistFallback,
      useNewAds,
      useNewPlaylist,
      userId,
      userIdTokenCookieName,
      userVideoProgressReportingUrl,
      vastConfig,
      videoTitle,
      widevineAccessToken,
    };
  }, [props]);

  const playerProps = useMemo<PlaylistLoaderProps | null>(() => {
    if (supportedConfig.externalLiveStreams) {
      return { ...supportedConfig };
    }
    if ('externalId' in ids && ids.externalId) {
      const vodProps: PlaylistLoaderProps = {
        ...(indexId ? { indexId } : {}),
        externalId: ids.externalId,
        ...supportedConfig,
      };
      return vodProps;
    }
    if ('versionId' in ids && ids.versionId) {
      const vodProps: PlaylistLoaderProps = {
        versionId: ids.versionId,
        ...supportedConfig,
      };
      return vodProps;
    }
    if ('mediaId' in ids && ids.mediaId) {
      const vodProps: PlaylistLoaderProps = {
        mediaId: ids.mediaId,
        ...supportedConfig,
      };
      return vodProps;
    }
    if ('encoder' in ids && ids.encoder) {
      const liveProps: PlaylistLoaderProps = {
        encoder: ids.encoder as Encoder,
        ...supportedConfig,
      };
      return liveProps;
    }

    return null;
  }, [ids, supportedConfig, indexId]);

  if (playerProps) {
    return <PlaylistLoader key={JSON.stringify(playerProps)} {...playerProps} />;
  }

  return <PlayerLoadingOverlay borderRadius={borderRadius} />;
});
