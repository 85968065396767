import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAnalytics } from '@czechtv/analytics-react';
import { MenuPopupType, HbbtvDevice, HbbtvState, HbbtvConnect } from '../constants';

interface RemoteInstance {
  getAllPaired: () => any;
  onCommand: (from: any, data: any) => void;
  onPairDeviceResult: (data: any) => void;
  pairDevice: (id: string) => any;
  removePairedDevice: (id: string) => void;
  sendCommand: (id: string, command: { command: string; data: any }) => Promise<any> | void;
}

interface RemoteClass {
  new (bool: boolean, type: string): RemoteInstance;
}

declare global {
  interface Window {
    Remote?: RemoteClass;
  }
}

const HBBTV_ANALYTICS_DATA = { hbbtv: true };

const hbbtvConnectScriptUrl = 'https://ctfs.ceskatelevize.cz/static/scripts/remoteweblib.js';
// nedari se rozchodit importovanou knihovnu v ramci kodu, proto se pouziva tahle klicka
const loadScriptAsync = async (url: string) => {
  return new Promise<RemoteClass>((resolve, reject) => {
    const firstScriptTag = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    script.onerror = (err) => {
      reject(err);
    };
    script.onabort = (err) => {
      reject(err);
    };
    script.onload = () => {
      if (window.Remote) {
        resolve(window.Remote);
      }
      reject();
    };
    if (firstScriptTag && firstScriptTag.parentNode) {
      const scriptToRemove = document.querySelectorAll(`[src="${hbbtvConnectScriptUrl}"]`);
      if (scriptToRemove.length === 0) {
        firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
      }
    }
  });
};

function cleanupRemoteScript() {
  const scriptToRemove = document.querySelectorAll(`[src="${hbbtvConnectScriptUrl}"]`)[0];
  if (scriptToRemove) {
    scriptToRemove.remove();
  }
}

const getDefaultState = (pairedDevices: HbbtvDevice[]) => {
  return pairedDevices.length > 0 ? HbbtvState.selectConnection : HbbtvState.noConnection;
};

interface HbbtvConnectConfig {
  bonusId?: string;
  cropEnd?: number;
  cropStart?: number;
  encoder?: string;
  hbbtvStreamingActiveDevice: HbbtvDevice | null;
  idec?: string | null;
  indexId?: string;
  setHbbtvStreamingActiveDevice: Dispatch<SetStateAction<HbbtvDevice | null>>;
  setMenuPopupVisible: Dispatch<SetStateAction<MenuPopupType | null>>;
}

const useHbbtvConnect = ({
  setHbbtvStreamingActiveDevice,
  hbbtvStreamingActiveDevice,
  setMenuPopupVisible,
  cropStart,
  cropEnd,
  idec,
  encoder,
  indexId,
  bonusId,
}: HbbtvConnectConfig): { hbbtv: HbbtvConnect } => {
  const [hbbtvState, setHbbtvState] = useState<HbbtvState>();
  const [pairingId, setPairingId] = useState<string>('');
  const [remote, setRemote] = useState<RemoteInstance>();
  const [deviceToRemove, setDeviceToRemove] = useState<HbbtvDevice | undefined>();
  const [castError, setCastError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [pairedDevices, setPairedDevices] = useState<HbbtvDevice[]>([]);
  const selectedItemRecently = useRef(false);
  const analytics = useAnalytics();

  // prevod formatu CH_1 na CT1 pozadovaneho knihovnou
  const getChannelString = (encoder?: string) => {
    return encoder?.replace('CH_', 'CT');
  };

  // checkneme kazdou vterinu stav prehravani v hbbtv
  useEffect(() => {
    const interval = setInterval(() => {
      const command = {
        command: 'getplaystate',
        data: {},
      };
      if (remote && hbbtvStreamingActiveDevice) {
        remote.sendCommand(hbbtvStreamingActiveDevice.id, command);
      }
      if (remote) {
        // pokud video v hbbtv prestaneme prehravat, propiseme informaci do playeru
        remote.onCommand = (_from, data) => {
          if (data.command === 'stopvideo' && !selectedItemRecently.current) {
            setHbbtvStreamingActiveDevice(null);
          }
          // hbbtv posila 'stopvideo' i pri sdileni z playeru do uz spusteneho videa v hbbtv
          // proto je treba rozlisit 'stopvideo' prikazy pres ref
          if (data.command === 'playstate' && selectedItemRecently.current) {
            selectedItemRecently.current = false;
          }
        };
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [hbbtvStreamingActiveDevice, remote, setHbbtvStreamingActiveDevice]);

  useEffect(() => {
    const loadRemoteScript = async () => {
      try {
        const Remote = await loadScriptAsync(hbbtvConnectScriptUrl);
        const remoteInstance = new Remote(true, 'PC');
        setRemote(remoteInstance);
      } catch (e) {
        setCastError(true);
      }
    };

    loadRemoteScript().catch(() => {
      console.log('remote script was not loaded');
    });

    return () => {
      cleanupRemoteScript();
    };
  }, []);

  useEffect(() => {
    // na začátku načteme už uložená zařízení
    if (remote) {
      const pairedAlready = remote.getAllPaired();
      type DeviceValuesType = { name: string; state: string };
      const paired = Object.entries(pairedAlready).map(([key, value]) => {
        const val = value as DeviceValuesType;
        return {
          id: key,
          ...val,
        };
      });
      setPairedDevices(paired);
    }
  }, [remote]);

  useEffect(() => {
    setHbbtvState(getDefaultState(pairedDevices));
  }, [pairedDevices]);

  const onAddTvButtonClick = useCallback(() => {
    setHbbtvState(HbbtvState.newConnection);
  }, []);

  const onCreateTvButtonClick = useCallback(() => {
    if (remote) {
      remote.onPairDeviceResult = (data: any) => {
        if (data.result === 'ok') {
          // Sparování proběhlo v pořádku
          const newDevice: HbbtvDevice = data.paireddevice;
          if (!pairedDevices.some((device) => device.id === newDevice.id)) {
            pairedDevices.push(newDevice);
            setPairedDevices(pairedDevices);
          }
          setHbbtvState(HbbtvState.selectConnection);
          setConnectionError(false);
        } else {
          setConnectionError(true);
          // Sparování neproběhlo v pořádku
        }
      };
      remote.pairDevice(pairingId);
      setPairingId('');
    }
  }, [pairedDevices, pairingId, remote]);

  useEffect(() => {
    if (pairingId) {
      if (pairingId.length === 12) {
        onCreateTvButtonClick();
      }
    }
  }, [pairingId, onCreateTvButtonClick]);

  const onCloseButtonClick = useCallback(() => {
    setMenuPopupVisible(null);
    setHbbtvState(getDefaultState(pairedDevices));
  }, [pairedDevices, setMenuPopupVisible]);

  const onSelectItem = useCallback(
    async (device: HbbtvDevice, onHbbtvDeviceSelect: () => void) => {
      const analyticsContext = analytics.getContext();
      selectedItemRecently.current = true;
      const isStreamingDevice = hbbtvStreamingActiveDevice?.id === device.id;
      onHbbtvDeviceSelect();
      setHbbtvStreamingActiveDevice(!isStreamingDevice ? device : null);
      // pokud je dostupny idec a indexId jedna se o index
      const index = indexId && idec ? `${idec},${indexId}` : null;
      // pokud je dostupny cropStart nebo cropEnd jedna se o cropnute video
      const croppedVOD = cropStart || cropEnd ? `${idec}:${cropStart}:${cropEnd}` : null;
      // bonusId prevedeme do formatu co knihovna papa
      const transformedBonusId = `BO-${bonusId}`;
      // enkoder prevedeme do formatu co knihovna papa
      const transformedEncoder = getChannelString(encoder);
      const commandPlay = {
        command: 'playvideo',
        data: {
          id: index || croppedVOD || idec || transformedEncoder || `BO-${transformedBonusId}`,
          adprerolldisable: false,
        },
      };
      const commandStop = {
        command: 'stopvideo',
        data: {},
      };
      const command = isStreamingDevice ? commandStop : commandPlay;
      if (remote) {
        await remote.sendCommand(device.id, commandStop);
        await remote.sendCommand(device.id, command);
      }
      if (!isStreamingDevice) {
        setMenuPopupVisible(null);
        setHbbtvState(getDefaultState(pairedDevices));
        analytics.setContext({ ...analyticsContext, cast: 'hbbtv' });
        analytics.trigger({
          type: 'PlayerSettingsChangeCast',
          data: HBBTV_ANALYTICS_DATA,
        });
      } else {
        analytics.setContext({ ...analyticsContext, cast: 'off' });
        analytics.trigger({
          type: 'PlayerSettingsChangeCast',
          data: HBBTV_ANALYTICS_DATA,
        });
      }
    },
    [
      hbbtvStreamingActiveDevice?.id,
      setHbbtvStreamingActiveDevice,
      indexId,
      idec,
      cropStart,
      cropEnd,
      bonusId,
      encoder,
      remote,
      setMenuPopupVisible,
      pairedDevices,
      analytics,
    ]
  );

  const onDeleteItem = useCallback((device: HbbtvDevice) => {
    setHbbtvState(HbbtvState.removeConnection);
    setDeviceToRemove(device);
  }, []);

  const onConfirmDeleteItemButtonClick = useCallback(() => {
    if (deviceToRemove?.id === hbbtvStreamingActiveDevice?.id) {
      setHbbtvStreamingActiveDevice(null);
    }
    if (remote && deviceToRemove) {
      remote.removePairedDevice(deviceToRemove.id);
    }
    setDeviceToRemove(undefined);
    const updatedDevices = pairedDevices.filter((device) => device.id !== deviceToRemove?.id);
    setPairedDevices(updatedDevices);
  }, [
    deviceToRemove,
    remote,
    pairedDevices,
    setHbbtvStreamingActiveDevice,
    hbbtvStreamingActiveDevice,
  ]);

  const onKeepTvButtonClick = useCallback(() => {
    setHbbtvState(HbbtvState.selectConnection);
  }, []);

  const onBackClick = useCallback(() => {
    setHbbtvState(getDefaultState(pairedDevices));
    setConnectionError(false);
  }, [pairedDevices]);

  const hbbtv = useMemo(
    () => ({
      onAddTvButtonClick,
      onCreateTvButtonClick,
      onCloseButtonClick,
      onSelectItem,
      onDeleteItem,
      onConfirmDeleteItemButtonClick,
      onKeepTvButtonClick,
      hbbtvState,
      onBackClick,
      setPairingId,
      pairedDevices,
      deviceToRemove,
      connectionError,
      castError,
    }),
    [
      castError,
      connectionError,
      deviceToRemove,
      hbbtvState,
      onAddTvButtonClick,
      onBackClick,
      onCloseButtonClick,
      onConfirmDeleteItemButtonClick,
      onCreateTvButtonClick,
      onDeleteItem,
      onKeepTvButtonClick,
      onSelectItem,
      pairedDevices,
    ]
  );

  return {
    hbbtv,
  };
};

export default useHbbtvConnect;
