import classNames from 'classnames';
import React, { memo, ReactNode, ReactNodeArray } from 'react';
import { playerPageWrapperClassnames } from './PlayerPageWrapper.css';

interface PlayerPageWrapperProps {
  children: ReactNodeArray | ReactNode;
}
// Tento Wrapper slouží k natáhnutí přehrávače a jeho vystřednění pro fullscreen

const PlayerPageWrapper = React.forwardRef<HTMLDivElement, PlayerPageWrapperProps>(
  ({ children }, ref) => {
    return (
      <div
        // Pridavame helper tridu, abychom mohli zacilit element (napr. pro vypnuti barvy pozadi),
        // aniz bychom museli pouzivat props drilling
        className={classNames(
          playerPageWrapperClassnames.playerPageWrapper,
          'playerPageWrapper',
          {}
        )}
        data-testid="PlayerPageWrapper"
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default memo(PlayerPageWrapper);
