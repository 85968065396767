import React, { memo, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { IconPause, IconPlay, IconSeekBack, IconSeekForward, SvgComponent } from '@czechtv/icons';
import { BlackOpacity50 } from '@czechtv/styles';
import { usePlayerContext } from '../../../PlayerContext';
import VideoIconButton from '../../../../components/VideoIconButton/VideoIconButton';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import { OverlayIconType } from './typeDefs';

const OVERLAY_ICON_TIMEOUT = 500;

const useStyles = createUseStyles({
  '@keyframes videoFadeOut': {
    from: { opacity: 1 },
    to: { opacity: 0, transform: 'scale(2)' },
  },
  overlayIconContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
  overlayIcon: {
    animation: `$videoFadeOut ${OVERLAY_ICON_TIMEOUT}ms linear 1 normal forwards`,
    [getPlayerResponsiveRule(Breakpoint.isMaxTouchMediumMobile)]: {
      backgroundColor: BlackOpacity50,
    },
  },
});

const getIcon = (
  iconType: OverlayIconType | null
): { Component: SvgComponent; testId: OverlayIconType } | null => {
  switch (iconType) {
    case OverlayIconType.PAUSE:
      return { Component: IconPause, testId: OverlayIconType.PAUSE };

    case OverlayIconType.PLAY:
      return { Component: IconPlay, testId: OverlayIconType.PLAY };

    case OverlayIconType.SEEK_FORWARD:
      return { Component: IconSeekForward, testId: OverlayIconType.SEEK_FORWARD };

    case OverlayIconType.SEEK_BACK:
      return { Component: IconSeekBack, testId: OverlayIconType.SEEK_BACK };

    default:
      return null;
  }
};

const OverlayIcon = () => {
  const { setOverlayIcon, overlay } = usePlayerContext();
  const overlayIconTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const classes = useStyles();

  useEffect(() => {
    if (overlayIconTimeout.current !== null) {
      clearTimeout(overlayIconTimeout.current);
    }

    if (overlay.icon !== null) {
      overlayIconTimeout.current = setTimeout(() => {
        setOverlayIcon(null);
        overlayIconTimeout.current = null;
      }, OVERLAY_ICON_TIMEOUT);
    }

    return () => {
      if (overlayIconTimeout.current !== null) {
        clearTimeout(overlayIconTimeout.current);
      }
    };
  }, [overlay, setOverlayIcon]);

  const icon = getIcon(overlay.icon);

  return (
    <div
      className={classes.overlayIconContainer}
      data-testid="playerOverlayIcon"
      key={overlay.updated}
    >
      {icon && (
        <span data-testid={`playerOverlayIcon-${icon.testId}`}>
          <VideoIconButton
            suppressHoverEffect
            Icon={icon.Component}
            className={classes.overlayIcon}
          />
        </span>
      )}
    </div>
  );
};

export default memo(OverlayIcon);
