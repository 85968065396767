import { PlayerAdContext } from '../../PlayerEvent';
import { NielsenAdPayload } from './nielsenTypes';

export const getNielsenAdMeta = (context: PlayerAdContext): NielsenAdPayload => {
  return {
    ...context.ad.nielsen,
  };
};

export const getAdPosition = (context: PlayerAdContext) => {
  return context.ad.timePosition;
};
