import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { body16 } from '@czechtv/styles';
import { formatTime } from '@czechtv/utils';
import { PROGRESS_BAR_WRAPPER_HEIGHT } from './constants';

interface Props {
  onClick?: () => void;
  remainingTime: number;
  role?: string;
  title?: string;
}

const useStyles = createUseStyles({
  remainingTime: {
    ...body16,
    fontWeight: 600,
    color: '#fff',
    alignSelf: 'center',
    userSelect: 'none',
    height: PROGRESS_BAR_WRAPPER_HEIGHT,
    // opticky to jinak vypadá nevystředněné
    lineHeight: `${PROGRESS_BAR_WRAPPER_HEIGHT - 2}px`,
    cursor: 'pointer',
  },
});

const RemainingTime = ({ remainingTime, role, title, onClick }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.remainingTime}
      data-testid="playerTime"
      role={role}
      title={title}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {`${remainingTime < 0 ? '-' : ''}${formatTime(Math.abs(remainingTime), 'G:i:s', 2)}`}
    </div>
  );
};

export default memo(RemainingTime);
