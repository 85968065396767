import React, { memo, ReactNode } from 'react';
import { ResponsiveContextProvider } from '@czechtv/styles';
import ResponsiveClassesWrapper from './ResponsiveClassesWrapper';

export interface ComponentBasedResponsiveProps {
  breakpoints: () => Record<string, boolean>;
  children: ReactNode;
  classPrefix?: string;
  // komponentě je možné nastavit ref html objektu podle kterého se bude počítat šířka
  customRef?: React.RefObject<HTMLElement>;
}

export const ComponentBasedResponsive = memo<ComponentBasedResponsiveProps>(
  ({ children, classPrefix, customRef, breakpoints }) => {
    const wrapRef = React.createRef<HTMLDivElement>();

    const computedRef = customRef !== undefined ? customRef : wrapRef;
    const ref = customRef !== undefined ? undefined : wrapRef;

    return (
      <ResponsiveContextProvider value={{ ref: computedRef }}>
        {classPrefix !== undefined ? (
          <ResponsiveClassesWrapper breakpoints={breakpoints} classPrefix={classPrefix} ref={ref}>
            {children}
          </ResponsiveClassesWrapper>
        ) : (
          <div ref={ref}>{children}</div>
        )}
      </ResponsiveContextProvider>
    );
  }
);
