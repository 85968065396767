/* eslint-disable */
// @ts-nocheck
export const gemiusSdkHandler = () => {
      function gemius_player_pending(obj,fun) {obj[fun] = obj[fun] || function() {var x =
        window['gemius_player_data'] = window['gemius_player_data'] || [];
        x[x.length]=[this,fun,arguments];};};
  gemius_player_pending(window,"GemiusPlayer");
  gemius_player_pending(GemiusPlayer.prototype,"newProgram");
  gemius_player_pending(GemiusPlayer.prototype,"newAd");
  gemius_player_pending(GemiusPlayer.prototype,"adEvent");
  gemius_player_pending(GemiusPlayer.prototype,"programEvent");
  gemius_player_pending(GemiusPlayer.prototype,"setVideoObject");
  (function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],
    l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');
    gt.setAttribute('defer','defer'); gt.src=l+'://scz.hit.gemius.pl/gplayer.js';
    s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');
};
