import React, { FormHTMLAttributes, useEffect, useRef } from 'react';
import { compute } from 'compute-scroll-into-view';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { UseFormReturn } from '../../hooks/useForm';
import { FormProvider } from './FormProvider';

// do formuláře vložíme callback na handlování storybook action
export interface FormProps<Values extends FieldValues = FieldValues>
  extends Omit<React.PropsWithChildren<FormHTMLAttributes<HTMLFormElement>>, 'onSubmit'> {
  // komponenta na vykreslení error stavu
  errorState?: (props: { error: unknown }) => React.ReactElement;
  form: UseFormReturn<Values>;
  onSubmit?: (data: Values) => Promise<unknown>;
  onValidationError?: (error: FieldErrors<Values>) => void;
  scrollTo?: (element: Element, top: number) => void;
  successState?: () => React.ReactElement;
}

const scrollToElement = (
  element: Element,
  scrollToFunction: NonNullable<FormProps['scrollTo']>
) => {
  compute(element, {
    scrollMode: 'always',
    block: 'start',
  }).forEach(({ el: element, top }) => {
    scrollToFunction(element, top);
  });
};

const defaultScrollTo = (element: Element, top: number) => {
  // číselná hodnota je tady, aby se to poradilo i s globální hlavičkou a "nemuselo se to moc řešit"
  // ale je možné si s tím pohrát, kam přesně to má zascrollovat
  // rozhodně by tam neměla být nula, to by bylo moc nalepené
  element.scrollTo({ top: top - 70 });
};

export function Form<Values extends FieldValues = FieldValues>({
  form,
  children,
  onSubmit,
  errorState: ErrorState,
  successState: SuccessState,
  scrollTo = defaultScrollTo,
  onValidationError,
  ...rest
}: FormProps<Values>) {
  const { asyncError, isSubmitSuccessful, isSubmitting } = form;

  const formRef = useRef<HTMLFormElement>(null);

  // pokud došlo k vykreslení error nebo success, zascrolluji na něj
  useEffect(() => {
    if (asyncError && ErrorState && formRef.current) {
      scrollToElement(formRef.current, scrollTo);
    }
  }, [asyncError, ErrorState, scrollTo]);

  useEffect(() => {
    if (isSubmitSuccessful && SuccessState && formRef.current) {
      scrollToElement(formRef.current, scrollTo);
    }
  }, [isSubmitSuccessful, SuccessState, scrollTo]);

  return (
    <form
      {...rest}
      // nativní validace je sice hezká, ale budeme používat vlastní
      noValidate
      ref={formRef}
      onSubmit={
        onSubmit
          ? (event) => {
              event.preventDefault();
              if (isSubmitting) {
                return;
              }

              void form.handleSubmit(onSubmit, onValidationError)(event);
            }
          : undefined
      }
    >
      <div role="alert">{ErrorState && asyncError ? <ErrorState error={asyncError} /> : null}</div>
      {SuccessState && isSubmitSuccessful ? <SuccessState /> : null}
      {/* Je mi stydno, ale mělo byt o být OK. */}
      {/* eslint-disable @typescript-eslint/no-explicit-any */}
      <FormProvider<UseFormReturn<any>> {...form}>{children}</FormProvider>
    </form>
  );
}
