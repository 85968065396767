import React, { createContext, memo } from 'react';
import { PlayerClient } from '../../Providers/Client';

type Players = Record<string, PlayerClient>;

export interface PlayersControllerContext {
  addPlayer: (key: string, newPlayer: PlayerClient) => void;
  players: Players;
  removePlayer: (key: string) => void;
  stopOtherVideos: (key: string) => void;
}

interface Props {
  children: React.ReactNode;
}

export const PlayersControllerContext = createContext<PlayersControllerContext | undefined>(
  undefined
);

export const PlayersControllerProvider = memo<Props>(({ children }) => {
  const [players, setPlayers] = React.useState<Players>({});

  const addPlayer = (key: string, newPlayer: PlayerClient) => {
    setPlayers((state) => {
      return { ...state, [key]: newPlayer };
    });
  };

  const removePlayer = (key: string) => {
    setPlayers((state) => {
      const newState = { ...state };
      delete newState[key];
      return newState;
    });
  };

  const stopOtherVideos = (key: string) => {
    Object.entries(players).forEach(([playerKey, value]) => {
      if (playerKey !== key) {
        value.pause();
      }
    });
  };

  return (
    <PlayersControllerContext.Provider
      value={{ players, addPlayer, removePlayer, stopOtherVideos }}
    >
      {children}
    </PlayersControllerContext.Provider>
  );
});
