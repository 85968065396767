import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import { scrollbarClassnames } from './Scrollbar.css';

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Scrollbar = forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        className={classNames(scrollbarClassnames.scrollBarWrapper, className)}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  }
);
