import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { useFocusVisibleClassName } from '@czechtv/components';
import { IconLock } from '@czechtv/icons';
import { uniqueElementId } from '../../common';
import { Label } from '../../Label/Label';
import { toggleClassnames } from './Toggle.css';

export enum ToggleVariant {
  DEFAULT = 'default',
  MENU_TOGGLE = 'menuToggle',
}
export interface Props {
  disabled?: boolean;
  isChecked?: boolean;
  label?: string;
  name: string;
  onChange?: (event: ChangeEvent, arg: boolean) => void;
  prefix?: string;
  variant?: ToggleVariant;
}

export const Toggle = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      disabled,
      isChecked,
      name,
      label,
      onChange,
      prefix = '',
      variant = ToggleVariant.DEFAULT,
    }: Props,
    ref
  ) => {
    const focusVisibleClassName = useFocusVisibleClassName();

    const id = uniqueElementId(prefix, name);

    const fullCircle = disabled ? (
      <span className={classNames(toggleClassnames.fullCircle, variant)}>
        <IconLock className={toggleClassnames.iconLock} height={12} width={12} />
      </span>
    ) : (
      <span className={classNames(toggleClassnames.fullCircle, variant)} />
    );

    return (
      <div className={classNames(toggleClassnames.toggleWrapper, variant)}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <label className={classNames(toggleClassnames.switch, variant)} htmlFor={id}>
          <input
            checked={isChecked}
            className={classNames(toggleClassnames.hiddenCheckbox, focusVisibleClassName, variant)}
            disabled={disabled}
            id={id}
            name={name}
            ref={ref}
            tabIndex={-1}
            type="checkbox"
            onChange={
              onChange
                ? (event) => {
                    onChange(event, !isChecked);
                  }
                : undefined
            }
          />
          <span className={classNames(toggleClassnames.slider, variant)}>
            {fullCircle}
            <span className={classNames(toggleClassnames.hollowCircle, variant)} />
          </span>
        </label>
      </div>
    );
  }
);
