import classNames from 'classnames';
import React from 'react';
import { IconCheckMark } from '@czechtv/icons';
import { LinkProps } from '@czechtv/utils';
import { optionClassnames } from './Option.css';

export interface OptionProps {
  className?: string;
  inverted?: boolean;
  isActive?: boolean;
  link?: LinkProps;
  text: string;
}

export const Option = ({ text, inverted, isActive, className }: OptionProps) => {
  return (
    <div className={classNames(optionClassnames.item, className)}>
      {isActive ? (
        <IconCheckMark fill={inverted ? 'white' : undefined} height={24} width={24} />
      ) : (
        <span />
      )}
      <span className={classNames({ [optionClassnames.selectedText]: isActive })}>{text}</span>
    </div>
  );
};
