import { PlayerVariantEnum } from '../constants';

export const getReadyOverlayDuration = ({
  currentDuration,
  durationFromPlaylist,
  playerVariant,
  isMainContent,
}: {
  currentDuration: number;
  durationFromPlaylist?: number;
  isMainContent: boolean;
  playerVariant: PlayerVariantEnum;
}) => {
  if (playerVariant === PlayerVariantEnum.VOD) {
    return isMainContent ? currentDuration : durationFromPlaylist;
  }
  return undefined;
};
