import React, { memo } from 'react';
import classNames from 'classnames';
import { getReadableDuration } from '@czechtv/utils';
import { videoDurationClassnames } from './VideoDuration.css';

interface VideoDurationProps {
  className?: string;
  duration: number;
}

const VideoDuration = ({ className, duration }: VideoDurationProps) => {
  return (
    <div className={classNames(videoDurationClassnames.time, className)}>
      <span>{getReadableDuration(duration)}</span>
    </div>
  );
};

export default memo(VideoDuration);
