import { log } from '../logger';

type CaptureOptions = {
  errorMessage: string;
  tagName: string;
  tagValue: string;
};

export const captureTaggedFallback = (
  message: string,
  { tagName, tagValue, errorMessage }: CaptureOptions
) => {
  log.scopedCapture({
    tags: { [tagName]: tagValue },
    message,
    extra: { fallback_reason: errorMessage },
  });
};
