export const OLD_CDN_CONTENT_ID_PARAM_KEY = 'id';
export const NEW_CDN_CONTENT_ID_PARAM_KEY = 'contentId';

export const getParamFromUrl = (url: string, key: string): string | undefined => {
  const queryPart = url.split('?')[1];
  if (!queryPart) {
    return undefined;
  }
  const keyValueStrings = queryPart.split('&');
  const obj: Record<string, string> = {};
  keyValueStrings.forEach((param) => {
    const [key, value] = param.split('=');
    obj[key] = value;
  });
  const found = obj[key];

  return found;
};
