import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconSpotifyHoverWhite: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" fill="#FFF" r="10" />
      <path
        d="M18.5 10.75c-3.54-2.1-9.39-2.3-12.77-1.27a1.03 1.03 0 1 1-.6-1.97c3.89-1.18 10.35-.95 14.43 1.47a1.03 1.03 0 0 1-1.05 1.77Zm-.1 3.12a.86.86 0 0 1-1.19.28c-2.96-1.81-7.46-2.34-10.96-1.28a.86.86 0 0 1-.5-1.64c4-1.21 8.96-.62 12.36 1.46.4.25.53.78.28 1.18Zm-1.36 3a.69.69 0 0 1-.94.22c-2.58-1.57-5.83-1.93-9.66-1.06a.69.69 0 1 1-.3-1.33c4.18-.96 7.78-.55 10.68 1.22.32.2.42.62.22.95ZM12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22Z"
        fill="#1DB954"
      />
    </g>
  </svg>
));
