import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconTikTokHoverWhite: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" fill="#FFF" r="10" />
      <path
        d="M12 1a11 11 0 1 1 0 22 11 11 0 0 1 0-22Zm2.73 4h-2.4v9.6a2.03 2.03 0 0 1-2.78 1.85l-.2-.1a2.03 2.03 0 0 1 1.41-3.76l.15.04v-2.45h-.09a4.43 4.43 0 0 0-3.17 7.95l.19.12.04.03c.63.41 1.37.67 2.16.71l.26.01h.08a4.43 4.43 0 0 0 4.35-4.22V9.68l.06.04c.86.6 1.89.97 3 1.02h.29V8.36h-.06c-.53 0-1.05-.14-1.52-.39l-.2-.11-.04-.03a3.36 3.36 0 0 1-1.43-1.98l-.05-.2-.03-.23-.02-.1V5Z"
        fill="#FE2C55"
      />
    </g>
  </svg>
));
