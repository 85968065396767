export enum PlayerErrorCategory {
  ADS = 'ads',
  APPLICATION = 'application',
  BROWSER = 'browser',
  CAST = 'cast',
  DRM = 'drm',
  LICENCE = 'licence',
  MANIFEST = 'manifest',
  MEDIA = 'media',
  NETWORK = 'network',
  PLAYER = 'player',
  STORAGE = 'storage',
  STREAMING = 'streaming',
  TEXT = 'text',
}
