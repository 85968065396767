enum PlayerClientState {
  BUFFERING = 'BUFFERING',
  FINISHED = 'FINISHED',
  INVALID = 'INVALID',
  LOADING = 'LOADING',
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  READY = 'READY',
}

export default PlayerClientState;
