import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { IconSubtitles } from '@czechtv/icons';
import { DefaultRadius, body16 } from '@czechtv/styles';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';
import {
  SettingsListItemProps,
  SettingsListItemsEnum,
} from './../SettingsMenu/SettingsList/SettingsListItem/SettingsListItem';

interface CaptionsStyleButtonProps {
  listContent: SettingsListItemProps;
  setActiveItem: (label: string) => void;
  setListContent: (listContent: SettingsListItemProps) => void;
}

const useStyles = createUseStyles({
  button: {
    ...body16,
    color: '#fff',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'inherit',
    padding: [0, 8, 0, 10],
    position: 'relative',
    height: 40,
    borderRadius: DefaultRadius,
    '&:hover': {
      backgroundColor: 'rgba(256, 256, 256, 0.16)',
    },
    '&:focus': {
      outline: '2px solid transparent',
    },
    '&:focus-visible': {
      outline: '2px solid #60a0ff',
    },
  },
  iconCaptions: {
    fill: '#fff',
    width: 20,
    height: 14,
    marginRight: 14,
  },
  captions: {
    display: 'flex',
    padding: [8, 0, 0, 0],
    margin: [0, 8, 8, 8],
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    [getPlayerResponsiveRule([Breakpoint.isMaxTablet])]: {
      position: 'absolute',
      bottom: 0,
      width: 'calc(100% - 16px)',
    },
  },
});

export const CaptionsStyleButton = ({
  listContent,
  setListContent,
  setActiveItem,
}: CaptionsStyleButtonProps) => {
  const classes = useStyles();

  const handleOnClick = () => {
    setActiveItem(SettingsListItemsEnum.SUBTITLES_STYLE);
    setListContent(listContent);
  };

  return (
    <div className={classNames(classes.captions)} data-testid={listContent.testId}>
      <button
        aria-haspopup="true"
        className={classNames(classes.button)}
        role="menuitem"
        type="button"
        onClick={handleOnClick}
      >
        <IconSubtitles className={classes.iconCaptions}></IconSubtitles>
        <span>{SettingsListItemsEnum.SUBTITLES_STYLE}</span>
      </button>
    </div>
  );
};
