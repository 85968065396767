import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconCTBeanDark: SvgComponent = memo((props) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 36 24"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.97134 0.313266C11.3454 0.12573 13.9151 0.0330199 16 0V3.07932C15.0113 3.11446 13.9083 3.1805 12.8729 3.29057C9.23116 3.67876 8.55105 4.46574 8.11038 7.95484C7.92656 9.40899 7.87221 10.9508 7.87221 12.0019C7.87221 13.0488 7.92656 14.594 8.11038 16.0435C8.55105 19.5309 9.23116 20.3187 12.8729 20.7069C13.9083 20.8178 15.0113 20.8838 16 20.919V24C13.9151 23.9666 11.3454 23.8738 8.97134 23.685C2.43855 23.1643 1.2197 22.1056 0.427086 17.4282C0.0967948 15.4796 0 13.4069 0 11.9994C0 10.5914 0.0967948 8.51915 0.427086 6.57055C1.2197 1.89272 2.43855 0.835235 8.97134 0.313266Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M35.5556 11.9994C35.5556 13.4069 35.4583 15.4796 35.1284 17.4282C34.3362 22.1056 33.1173 23.1643 26.5837 23.685C24.21 23.8734 21.6406 23.9666 19.5556 24V20.919C20.5435 20.8838 21.6469 20.8178 22.6812 20.7069C26.3243 20.3187 27.0036 19.5309 27.4439 16.0435C27.6277 14.594 27.6829 13.0488 27.6829 12.0019C27.6829 10.9508 27.6277 9.40899 27.4439 7.95484C27.0036 4.46574 26.3243 3.67876 22.6812 3.29057C21.6469 3.1805 20.5435 3.11446 19.5556 3.07932V0C21.6406 0.0330199 24.21 0.125306 26.5837 0.313266C33.1173 0.835235 34.3362 1.89272 35.1284 6.57055C35.4583 8.51915 35.5556 10.5914 35.5556 11.9994Z"
      fill="#ED1C24"
      fillRule="evenodd"
    />
  </svg>
));
