import React from 'react';

export const CastTvGraphics = () => {
  return (
    <svg height="116" width="176" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M0 0h17v17H0z" id="c" />
        <path
          d="M172 0a4 4 0 0 1 4 4v96a4 4 0 0 1-4 4H37V81c0-1-.8-2-1.9-2H21a2 2 0 0 0-2 2v23H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h168Z"
          id="a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M118 108.5a1.5 1.5 0 0 1 1.5 1.5v5.5h-63V110a1.5 1.5 0 0 1 1.5-1.5ZM72.5 103.5h31v5h-31z"
          stroke="#8D8D8D"
        />
        <mask fill="#fff" id="b">
          <use xlinkHref="#a" />
        </mask>
        <rect height="103" mask="url(#b)" rx="4" stroke="#8D8D8D" width="175" x=".5" y=".5" />
        <path
          d="M85.3 36v4.1c-1.3 0-2.8.1-4.1.3-4.9.5-5.8 1.6-6.4 6.2a45.5 45.5 0 0 0 0 10.8c.6 4.6 1.5 5.7 6.4 6.2 1.3.2 2.8.2 4.1.3V68c-2.8 0-6.2-.2-9.3-.4-8.8-.7-10.4-2.1-11.4-8.4a45.8 45.8 0 0 1 0-14.4c1-6.3 2.6-7.7 11.4-8.4 3.1-.2 6.5-.4 9.3-.4Zm4.8 0c2.8 0 6.2.2 9.4.4 8.7.7 10.3 2.1 11.3 8.4a45.8 45.8 0 0 1 0 14.4c-1 6.3-2.6 7.7-11.3 8.4-3.2.2-6.6.4-9.4.4v-4.1l4.2-.3c4.8-.5 5.7-1.6 6.3-6.2a45.4 45.4 0 0 0 0-10.8c-.6-4.6-1.5-5.7-6.3-6.2L90 40V36Z"
          fill="#FFF"
        />
        <g transform="translate(40 61)">
          <mask fill="#fff" id="d">
            <use xlinkHref="#c" />
          </mask>
          <circle cy="17" mask="url(#d)" r="5.5" stroke="#8D8D8D" />
          <circle cy="16" mask="url(#d)" r="10.5" stroke="#8D8D8D" />
          <circle cy="17" mask="url(#d)" r="16.5" stroke="#8D8D8D" />
        </g>
        <g transform="translate(18 79)">
          <rect height="36" rx="2" stroke="#8D8D8D" width="19" x=".5" y=".5" />
          <circle cx="10" cy="33" fill="#8D8D8D" r="2" />
          <path d="M0 29h20v1H0z" fill="#8D8D8D" />
          <path
            d="M9.4 11v.9h-.8c-1 .1-1.2.4-1.3 1.3a9.7 9.7 0 0 0 0 2.3c0 1 .3 1.2 1.3 1.3h.8v1l-2-.2c-1.7-.1-2-.4-2.3-1.7a9.7 9.7 0 0 1 0-3c.2-1.4.6-1.7 2.4-1.8l2-.1Zm1 0h2c1.8.2 2.1.5 2.4 1.8a9.7 9.7 0 0 1 0 3c-.3 1.4-.6 1.7-2.4 1.8l-2 .1V17l.9-.1c1-.1 1.2-.3 1.3-1.3a9.6 9.6 0 0 0 0-2.3c-.1-1-.3-1.2-1.3-1.3h-.9V11Z"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  );
};
