import { PlayerRouterProvider } from './usePlayerRouter';

const windowRouter: PlayerRouterProvider = {
  back: () => {
    if (typeof window === 'undefined') {
      throw new Error('Cannot use router.back because window is not defined.');
    }
    window.history.back();
  },
  push: (_url, as) => {
    if (typeof window === 'undefined') {
      return Promise.reject(new Error('Cannot use router.push because window is not defined.'));
    }
    window.location.assign(as);
    return Promise.resolve(true);
  },
  reload: () => {
    if (typeof window === 'undefined') {
      throw new Error('Cannot use router.reload because window is not defined.');
    }
    window.location.reload();
  },
  replace: (_url, as) => {
    if (typeof window === 'undefined') {
      return Promise.reject(new Error('Cannot use router.replace because window is not defined'));
    }
    window.location.replace(as);
    return Promise.resolve(true);
  },
};

export default windowRouter;
