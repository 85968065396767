import React, {
  ComponentType,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
} from 'react';

export interface PlayerLoaderRef {
  // Znici instanci playeru a uklidi po sobe
  destroy: () => Promise<void>;
  getIsMuted: () => boolean | undefined;
  getVolume: () => number | undefined;
  // Pausne video
  pause: () => void;
  // Spusti video
  play: (indexStartTime?: number) => Promise<void>;
  // Relativni seek. Umoznuje zadat zaporne hdonoty pro seekovani zpet
  seek: (seconds: number) => void;
  seekTo: (seconds: number) => void;
  setVolume: (value: number) => void;
  // Zastavi video (narozdil od pause hodi playhead na zacatek)
  stop: () => void;
  toggleFullscreen: (value?: boolean) => Promise<void>;
  toggleMute: (value?: boolean) => Promise<void>;
}

export interface PlayerLoaderRefContextValue {
  playerLoaderRef: PlayerLoaderRef | undefined;
  setPlayerLoaderRef: (playerLoaderRef: PlayerLoaderRef) => void;
}

interface Props {
  children: React.ReactNode;
  onPlayerLoaderRefChange?: (playerLoaderRef: PlayerLoaderRef) => void;
  playerLoaderRef?: PlayerLoaderRef;
}

export const PlayerLoaderRefContext = createContext<PlayerLoaderRefContextValue | undefined>(
  undefined
);

export const usePlayerLoaderRefContext = () => useContext(PlayerLoaderRefContext);

export const PlayerLoaderRefProvider = memo<Props>(
  ({ children, playerLoaderRef: defaultPlayerLoaderRef, onPlayerLoaderRefChange }) => {
    const [playerLoaderRef, setPlayerLoaderRef] = React.useState<PlayerLoaderRef | undefined>(
      defaultPlayerLoaderRef
    );

    const setPlayerLoaderRefCallback = useCallback(
      (playerLoaderRef: PlayerLoaderRef) => {
        setPlayerLoaderRef(playerLoaderRef);
        if (onPlayerLoaderRefChange) {
          onPlayerLoaderRefChange(playerLoaderRef);
        }
      },
      [onPlayerLoaderRefChange]
    );

    useEffect(() => {
      if (defaultPlayerLoaderRef) {
        setPlayerLoaderRefCallback(defaultPlayerLoaderRef);
      }
    }, [defaultPlayerLoaderRef, setPlayerLoaderRefCallback]);

    return (
      <PlayerLoaderRefContext.Provider
        value={{ playerLoaderRef, setPlayerLoaderRef: setPlayerLoaderRefCallback }}
      >
        {children}
      </PlayerLoaderRefContext.Provider>
    );
  }
);

type ComponentWithPlayerLoaderRef<P> = Omit<P, 'playerLoaderRef' | 'setPlayerLoaderRef'>;

// HOC s PlayerLoaderRef kontextem pro pouziti v classscomponente Player
export const withPlayerLoaderRef = <P,>(
  WrappedComponent: ComponentType<P>
): ComponentType<ComponentWithPlayerLoaderRef<P>> => {
  return (props: ComponentWithPlayerLoaderRef<P>) => {
    return (
      <PlayerLoaderRefContext.Consumer>
        {(context) => {
          if (!context) {
            // @ts-expect-error ... netušim
            return <WrappedComponent {...(props as P)} />;
          }
          const { playerLoaderRef, setPlayerLoaderRef } = context;
          if (typeof setPlayerLoaderRef === 'undefined') {
            throw new Error('setPlayerLoaderRef must be used within a PlayerLoaderRefProvider');
          }
          return (
            <WrappedComponent
              {...(props as P)}
              playerLoaderRef={playerLoaderRef}
              setPlayerLoaderRef={setPlayerLoaderRef}
            />
          );
        }}
      </PlayerLoaderRefContext.Consumer>
    );
  };
};
