import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { toTwoDigitsString, getCzechPreposition, getCzechMonth, getCzechDay } from '@czechtv/utils';

dayjs.extend(timezone);

const tz = 'Europe/Prague';

export interface TimerEvent {
  timestamp: number;
}

export const getTimestamp = () => {
  return Date.now();
};

export const TIMER_EVENT = 'playertime';

export const timestampToTimeString = (
  timestamp: number,
  today: Dayjs = dayjs().tz(tz),
  withCzechPreposition?: boolean
): string | null => {
  const startDate = dayjs(timestamp).tz(tz);
  const hours = startDate.hour();
  const minutes = startDate.minute();
  const diffDays = () => {
    if (Number(startDate.format('YYYY')) - Number(today.format('YYYY')) >= 1) {
      return 365;
    }
    if (Number(startDate.format('D')) - Number(today.format('D')) === 0) {
      return 0;
    }
    if (Number(startDate.format('D')) - Number(today.format('D')) === 1) {
      return 1;
    }
    return Math.ceil(dayjs(startDate).diff(today, 'day', true));
  };

  const pre = withCzechPreposition ? `${getCzechPreposition(hours)} ` : '';

  if (diffDays() === 365) {
    return `${startDate.format('D')}. ${getCzechMonth(startDate.format('M'))} ${startDate.format(
      'YYYY'
    )} ${pre}${hours}:${toTwoDigitsString(minutes)}`;
  }
  if (diffDays() >= 7) {
    return `${startDate.format('D')}. ${getCzechMonth(
      startDate.format('M')
    )} ${pre}${hours}:${toTwoDigitsString(minutes)}`;
  }
  if (diffDays() === 0) {
    return `dnes ${pre}${hours}:${toTwoDigitsString(minutes)}`;
  }
  if (diffDays() === 1) {
    return `zítra ${pre}${hours}:${toTwoDigitsString(minutes)}`;
  }
  if (diffDays() < 7) {
    return `${getCzechDay(startDate.format('d'))} ${pre}${hours}:${toTwoDigitsString(minutes)}`;
  }
  return null;
};
