import { PlayerError } from '../PlayerError';
import { PlayerErrorCategory } from '../../../components/Error/PlayerErrorCategory';

export const DRM_ERROR_CODE = 901;

export class NativeClientError extends PlayerError {
  error: Partial<MediaError>;

  playerErrorCategory: PlayerErrorCategory;

  constructor(error: Partial<MediaError>) {
    super(`Native error: ${error.message || 'Unknown error'}`);
    this.error = error;
    if (error.code === DRM_ERROR_CODE) {
      this.playerErrorCategory = PlayerErrorCategory.DRM;
    } else if (error.code === MediaError.MEDIA_ERR_NETWORK) {
      this.playerErrorCategory = PlayerErrorCategory.NETWORK;
    } else {
      this.playerErrorCategory = PlayerErrorCategory.PLAYER;
    }
  }
}
