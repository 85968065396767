/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { formatTime } from '@czechtv/utils';
import { Debugging } from '../../constants';
import { usePlayerContext } from '../PlayerContext';
import { CaptionColorVariant, CaptionFontSize } from '../../utils/closedCaptions';

const formatShareTime = (time: number) => {
  return formatTime(time, time >= 60 * 60 ? 'H:i:s' : 'i:s', 2);
};

interface Props {
  debugging?: Debugging;
  duration: number;
  onClose: () => void;
  onForceAudioOnlySwitch: () => Promise<void>;
  videoEl: HTMLVideoElement;
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    background: 'rgba(0,0,0, 0.5)',
    color: '#fff',
    top: 24,
    right: 24,
    zIndex: 9999,
    padding: 6,
    cursor: 'default',
    borderRadius: 4,
    fontSize: 14,
    fontFamily: 'Helvetica, sans-serif',
    minWidth: 140,
  },
  timeInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  baseButton: {
    color: '#fff',
    background: 'transparent',
    cursor: 'copy',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    '&:hover': {
      color: '#e1ff00',
    },
    '&:active': {
      borderColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  timeButton: {
    extend: 'baseButton',
    fontSize: 18,
  },
  idInfoButton: {
    extend: 'baseButton',
    fontSize: 12,
    fontWeight: 'bold',
    margin: '4px 0',
  },
  playerVersion: {
    margin: '6px 0 0',
    fontSize: 11,
    textAlign: 'right',
  },
  contextButtonBase: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
    '&:hover': {
      color: '#e1ff00',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 1,
    extend: 'contextButtonBase',
  },
  moreButton: {
    extend: 'contextButtonBase',
    fontSize: 11,
    padding: 0,
  },
  devToolsContainer: {
    margin: '3px 0',
    fontSize: 12,
    maxHeight: 160,
    overflow: 'auto',
  },
  selectOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  selectOption: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '& label': {
      fontSize: 10,
    },
    '& select': {
      background: 'transparent',
      color: '#fafafa',
      outline: 'none',
      border: '1px solid #fafafa',
    },
  },
  copiedMessage: {
    position: 'absolute',
    top: -22,
    right: '0',
    background: 'rgba(225, 255, 0, 0.7)',
    color: '#000',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: 11,
    opacity: 0,
    transition: 'opacity 0.4s ease',
    visibility: 'hidden',
  },
  showCopiedMessage: {
    opacity: 1,
    visibility: 'visible',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const DebugTools = ({
  videoEl,
  debugging,
  onClose,
  duration: _duration,
  onForceAudioOnlySwitch,
}: Props) => {
  const timeButtonRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  const playerVersion = debugging?.playerVersion?.replace('^', '');
  const context = usePlayerContext();
  const [showDevTools, setShowDevTools] = useState(false);

  const {
    captionColorVariant,
    captionFontSize,
    setCaptionColorVariant,
    setCaptionFontSize,
    playlistOptions,
    forcedAudioOnly,
  } = context;
  const { useNewPlaylist } = playlistOptions;
  const messageTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [copiedMessage, setCopiedMessage] = useState<string | undefined>();

  const duration = formatShareTime(_duration);

  useEffect(() => {
    return () => {
      if (messageTimeout.current) {
        clearTimeout(messageTimeout.current);
      }
    };
  }, []);

  const showCopiedMessage = (message: string) => {
    if (messageTimeout.current) {
      clearTimeout(messageTimeout.current);
    }
    messageTimeout.current = setTimeout(() => {
      setCopiedMessage(undefined);
    }, 1000);
    setCopiedMessage(message);
  };

  const handleCopyValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.innerText;
    navigator.clipboard
      .writeText(value)
      .then(() => {
        showCopiedMessage(`Zkopírováno ${value}`);
      })
      .catch(() => {
        showCopiedMessage(`Kopírování není povoleno`);
      });
  };

  const id = useMemo(() => {
    const options = context.playlistOptions;
    return (
      options.externalId ||
      options.encoder ||
      options.indexId ||
      options.bonusId ||
      options.mediaId ||
      options.versionId
    );
  }, [context.playlistOptions]);

  useEffect(() => {
    const onTimeUpdate = () => {
      if (timeButtonRef.current) {
        timeButtonRef.current.textContent = formatShareTime(videoEl.currentTime);
      }
    };
    videoEl.addEventListener('timeupdate', onTimeUpdate);
    return () => videoEl.removeEventListener('timeupdate', onTimeUpdate);
  }, [videoEl]);

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { id } = event.target;
      if (id === 'ccSizeSelect') {
        setCaptionFontSize(event.target.value as CaptionFontSize);
      }
      if (id === 'ccColorVariantSelect') {
        setCaptionColorVariant(event.target.value as CaptionColorVariant);
      }
    },
    [setCaptionColorVariant, setCaptionFontSize]
  );

  const handleOpenDevTools = useCallback(() => {
    setShowDevTools((prev) => !prev);
  }, []);

  return (
    <div className={classes.container} onClick={(e) => e.stopPropagation()}>
      <div
        className={classNames(classes.copiedMessage, {
          [classes.showCopiedMessage]: copiedMessage,
        })}
      >
        {copiedMessage}
      </div>
      <button className={classes.closeButton} type="button" onClick={onClose}>
        X
      </button>
      <button className={classes.idInfoButton} type="button" onClick={handleCopyValue}>
        {id}
      </button>
      <div className={classes.timeInfo}>
        <button
          className={classes.timeButton}
          ref={timeButtonRef}
          title="Čas od začátku"
          type="button"
          onClick={handleCopyValue}
        >
          00:00
        </button>
        /
        <button
          className={classes.timeButton}
          title="Celková stopáž"
          type="button"
          onClick={handleCopyValue}
        >
          {duration}
        </button>
      </div>
      <div className={classes.devToolsContainer}>
        <div style={{ width: '100%', textAlign: 'right' }}>
          <button className={classes.moreButton} type="button" onClick={handleOpenDevTools}>
            {showDevTools ? 'Skrýt' : 'Zobrazit další nástroje'}
          </button>
        </div>
        <hr />
        {showDevTools && (
          <div>
            <div>
              <div>
                <b>Titulky</b>
              </div>
              <div className={classes.selectOptionsContainer}>
                <div className={classes.selectOption}>
                  <label htmlFor="ccSizeSelect">Velikost písma</label>
                  <select id="ccSizeSelect" value={captionFontSize} onChange={handleSelectChange}>
                    {Object.values(CaptionFontSize).map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.selectOptionsContainer}>
                  <div className={classes.selectOption}>
                    <label htmlFor="ccColorVariantSelect">Barevná varianta</label>
                    <select
                      id="ccColorVariantSelect"
                      value={captionColorVariant}
                      onChange={handleSelectChange}
                    >
                      {Object.values(CaptionColorVariant).map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <b>Audio only switch</b>
              </div>
              <div className={classes.switchContainer}>
                <input
                  checked={forcedAudioOnly}
                  disabled={!useNewPlaylist}
                  id="audioOnlySwitch"
                  type="checkbox"
                  onChange={onForceAudioOnlySwitch}
                />
                {!useNewPlaylist && <i>pouze nove pl.</i>}
              </div>
            </div>
            <hr />
          </div>
        )}
      </div>
      {playerVersion && <div className={classes.playerVersion}>v{playerVersion}</div>}
    </div>
  );
};
