let initialized = false;

export const initChromecastLib = async () => {
  return new Promise((resolve) => {
    if (!initialized && typeof window !== 'undefined' && window.chrome && !window.chrome.cast) {
      const script = document.createElement('script');
      script.src = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
      script.onload = () => {
        initialized = true;
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.head.appendChild(script);
    } else {
      resolve(false);
    }
  });
};
