import React, {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useContextWithProvider } from '@czechtv/components';
import { AnalyticsContext, AnalyticsContextProvider } from '@czechtv/analytics-react';
import { AnalyticsSubscriber } from '@czechtv/analytics';
import { Encoder } from '@czechtv/utils';
import { PlayerPopupContextProvider } from '../../components/PlayerPopup/PlayerPopup';
import { PlayerRouterProvider } from '../Router/usePlayerRouter';
import { Codec } from '../../utils/codecs';
import { log } from '../../utils/logger';
import { DRM, useSupportedDRMs } from '../../utils/drm';
import isMediaSourceSupported from '../../utils/mediaSource';
import { PlayerClient } from '../Client';
import { PlayersControllerContext } from '../../components/PlayersControllerContext/PlayersControllerContext';
import { AutoplayCapability, PlayerTypeEnum, PlayerVariantEnum } from '../../constants';
import windowRouter from '../Router/windowRouter';
import { usePlayerClientContext } from '../Client/PlayerClientContext';
import { shouldPlayDash } from '../../utils/shouldPlayDash';
import basicDynamicImport from './DynamicImport/basicDynamicImport';

type LoaderComponent<P = {}> = Promise<
  | React.ComponentType<P>
  | {
      default: React.ComponentType<P>;
    }
>;

type Loader<P = {}> = () => LoaderComponent<P>;
export type LoadableOptions = {
  loading?: ({ error }: { error?: Error | null }) => JSX.Element | null;
  ssr?: boolean;
};

export type PlayerDynamicImportProvider = <P = {}>(
  dynamicOptions: Loader<P>,
  options?: LoadableOptions
) => React.ComponentType<P>;

export interface PlayerConfig {
  SHARE_VIDEO_DOMAIN?: string;
  SHARE_VIDEO_PROTOCOL?: 'http' | 'https';
}

export interface PlayerSetupValues {
  autoPlayCapability: AutoplayCapability;
  config: PlayerConfig;
  disableAds: boolean;
  disablePlayerCustomizations: boolean;
  dynamicImportProvider: PlayerDynamicImportProvider;
  forceAudioDescription?: boolean;
  key: string;
  mediaSourceSupported: boolean | undefined;
  playbackId: string;
  playerClient: PlayerClient;
  playerRouter: PlayerRouterProvider;
  playerType: PlayerTypeEnum;
  playerVariant: PlayerVariantEnum;
  playerWrapperRef: React.RefObject<HTMLDivElement>;
  playersControllerContext: PlayersControllerContext | undefined;
  product: string;
  reload: () => void;
  setAutoplay: (autoplay: boolean) => void;
  setPlayerType: (playerType: PlayerTypeEnum) => void;
  showSimpleVideoHeader: boolean | undefined;
  supportedCodecs: Codec[] | undefined;
  supportedDRM: DRM[] | undefined;
}

// POZOR
// pokud přidáváte novou property musí se ručně přidat do MockContextu (testy, storybook)
// a do všech našich Loaderů (jsou 4)
export interface PlayerLoaderSetupProps {
  analyticsSubscribers?: AnalyticsSubscriber[];
  autoPlayCapability: AutoplayCapability;
  config?: PlayerConfig;
  defaultPlayerClient?: PlayerClient;
  disableAds?: boolean;
  disablePlayerCustomizations?: boolean;
  dynamicImportProvider?: PlayerDynamicImportProvider;
  product: string;
  router?: PlayerRouterProvider;
  showSimpleVideoHeader?: boolean;
  supportedCodecs?: Codec[];
}

export interface PlayerSetupProviderProps extends PlayerLoaderSetupProps {
  children: React.ReactNode;
  encoder?: Encoder;
  forceAudioDescription?: boolean;
  playbackId: string;
  // Pokud neuvedeme typ prehravace, nebude se zadny nacitat (umoznuje odlozit nacteni klientu)
  playerType?: PlayerTypeEnum;
  playerVariant?: PlayerVariantEnum;
  playerWrapperRef: React.RefObject<HTMLDivElement>;
  reload: () => void;
}

export const PlayerSetup = createContext<PlayerSetupValues | undefined>(undefined);

PlayerSetup.displayName = 'PlayerSetup';

export const usePlayerSetup = () => useContextWithProvider(PlayerSetup);

export function usePlayerDynamicImport<P>(
  dynamicOptions: Loader<P>,
  options?: LoadableOptions
): React.ComponentType<P> {
  const { dynamicImportProvider } = usePlayerSetup();
  return useMemo<React.ComponentType<P>>(
    () => dynamicImportProvider(dynamicOptions, options),
    [dynamicImportProvider, dynamicOptions, options]
  );
}

class UnableToLoadClientError extends Error {}

const PlayerSetupProviderInternal = ({
  autoPlayCapability,
  product,
  children,
  defaultPlayerClient,
  forceAudioDescription,
  router = windowRouter,
  playerType: defaultPlayerType,
  playerVariant = PlayerVariantEnum.VOD,
  playerWrapperRef,
  showSimpleVideoHeader,
  supportedCodecs,
  config = {
    SHARE_VIDEO_DOMAIN: '',
    SHARE_VIDEO_PROTOCOL: 'https',
  },
  dynamicImportProvider = basicDynamicImport,
  disablePlayerCustomizations = false,
  disableAds = false,
  reload,
  playbackId,
}: PlayerSetupProviderProps) => {
  const [importError, setImportError] = useState(false);
  const [mediaSourceSupported, setMediaSourceSupported] = useState<boolean | undefined>(undefined);
  const [playerClient, setPlayerClient] = useState(defaultPlayerClient);
  const [playerType, setPlayerType] = useState(defaultPlayerType);
  const analyticsContext = useContext(AnalyticsContext);
  const isMounted = useRef(false);
  const playersControllerContext = useContext(PlayersControllerContext);
  const playerClientContext = usePlayerClientContext();
  const supportedDRM = useSupportedDRMs();

  // naslouchame zmenam analytickeho contextu, at mame vzdy aktualni info pro logovani
  analyticsContext?.setOnContextChange((newContext) => {
    log.setContext('analyticsContext', newContext);
    log.setTags({
      idec: newContext?.idec,
      version: newContext?.version,
      playTime: newContext?.playTime,
      autoplay: newContext?.autoPlay,
      audiotrack: newContext?.audiotrack,
      encoder: newContext?.encoder,
      origin: newContext?.origin,
      quality: newContext?.quality,
    });
  });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (playerType !== defaultPlayerType && typeof defaultPlayerType !== 'undefined') {
      setPlayerType(defaultPlayerType);
    }
  }, [defaultPlayerType, playerType]);

  useEffect(() => {
    if (defaultPlayerClient) {
      return;
    }

    if (playerType === PlayerTypeEnum.DASH) {
      import('../Client/Shaka/ShakaPlayerClient')
        .then((client) => {
          setPlayerClient(new client.ShakaPlayerClient());
        })
        .catch(() => setImportError(true));
    } else if (playerType === PlayerTypeEnum.HLS) {
      import('../Client/Native/NativeClient')
        .then((client) => {
          setPlayerClient(new client.NativeClient());
        })
        .catch(() => setImportError(true));
    }
  }, [defaultPlayerClient, playerType]);

  useEffect(() => {
    if (playersControllerContext && playerClient) {
      playersControllerContext.addPlayer(playbackId, playerClient);
    }

    return () => {
      if (playersControllerContext) {
        playersControllerContext.removePlayer(playbackId);
      }
    };
    // pokud by ze byla závislost na controller contextu, dostane se to do loopu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackId, playerClient]);

  useEffect(() => {
    if (playerClientContext && playerClient) {
      playerClientContext.setPlayerClient(playerClient);
    }
    // pokud by ze byla závislost na client contextu, dostane se to do loopu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerClient]);

  // podporovany media zdroj
  useEffect(() => {
    setMediaSourceSupported(isMediaSourceSupported());
  }, [setMediaSourceSupported]);

  const contextValue = useMemo<PlayerSetupValues>(
    () => ({
      autoPlayCapability,
      product,
      forceAudioDescription,
      mediaSourceSupported,
      // @ts-ignore PlayerClient resi early return
      playerClient,
      playerRouter: router,
      playersControllerContext,
      playerVariant,
      setMediaSourceSupported,
      showSimpleVideoHeader,
      supportedCodecs,
      supportedDRM,
      // Fallback, playerType je prazdny jen v pripade PlayerPreview
      playerType: playerType || shouldPlayDash() ? PlayerTypeEnum.DASH : PlayerTypeEnum.HLS,
      playerWrapperRef,
      config,
      dynamicImportProvider,
      setPlayerType,
      disablePlayerCustomizations,
      reload,
      playbackId,
      disableAds,
    }),
    [
      autoPlayCapability,
      config,
      disablePlayerCustomizations,
      dynamicImportProvider,
      forceAudioDescription,
      mediaSourceSupported,
      playbackId,
      playerClient,
      playersControllerContext,
      playerType,
      playerVariant,
      playerWrapperRef,
      product,
      reload,
      router,
      setPlayerType,
      showSimpleVideoHeader,
      supportedCodecs,
      supportedDRM,
      disableAds,
    ]
  );

  if (importError) {
    // pokud nenačteme nachunkovaný client, shodíme celý player
    throw new UnableToLoadClientError(`Unable to load player client ${playerType}.`);
  }

  return <PlayerSetup.Provider value={contextValue}>{children}</PlayerSetup.Provider>;
};

type ComponentWithPlayerSetup<P> = Omit<P, 'playerSetup'>;

// HOC s player setup kontextem pro pouziti v classscomponente Player
export const withPlayerSetup = <P,>(
  WrappedComponent: ComponentType<P>
): ComponentType<ComponentWithPlayerSetup<P>> => {
  return (props: ComponentWithPlayerSetup<P>) => {
    return (
      <PlayerSetup.Consumer>
        {(playerSetup) => {
          if (playerSetup === undefined) {
            throw new Error('playerSetup must be used within a PlayerSetupProvider');
          }
          return <WrappedComponent {...(props as P)} playerSetup={playerSetup} />;
        }}
      </PlayerSetup.Consumer>
    );
  };
};

export const PlayerSetupProvider = (props: PlayerSetupProviderProps) => (
  <AnalyticsContextProvider subscribers={props.analyticsSubscribers}>
    <PlayerPopupContextProvider>
      <PlayerSetupProviderInternal {...props} />
    </PlayerPopupContextProvider>
  </AnalyticsContextProvider>
);
