/* eslint-disable */
// @ts-nocheck
export const nielsenSdkHandler = () => {
!function(e,n){
  function t(e){
    return"object"==typeof e?JSON.parse(JSON.stringify(e)):e
  }
  e[n]=e[n]||
  {
    nlsQ:function(o,r,c){
      var s=e.document,
      a=s.createElement("script");
      a.async=1,
      a.src=("http:"===e.location.protocol?"http:":"https:")+"//cdn-gl.nmrodam.com/conf/"+o+".js#name="+r+"&ns="+n;
      var i=s.getElementsByTagName("script")[0];
      return i.parentNode.insertBefore(a,i),
      e[n][r]=e[n][r]||{g:c||{},
      ggPM:function(o,c,s,a,i){e[n][r].q=e[n][r].q||[];try{var l=t([o,c,s,a,i]);e[n][r].q.push(l)}
catch(e){console&&console.log&&console.log("Error: Cannot register event in Nielsen SDK queue.")}},
      trackEvent:function(o){e[n][r].te=e[n][r].te||[];try{var c=t(o);e[n][r].te.push(c)}
catch(e){console&&console.log&&console.log("Error: Cannot register event in Nielsen SDK queue.")}}},
      e[n][r]
    }
  }
}
(window,"NOLBUNDLE");
};
