import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { body14 } from '@czechtv/styles';
import { Breakpoint, getPlayerResponsiveRule } from '../../utils/playerResponsive';
import {
  getClosedCaptionFontSize,
  CaptionFontSize,
  getClosedCaptionColorVariant,
} from '../../utils/closedCaptions';
import { hasWebkitFullscreen } from '../../utils/screenMode';
import { CONTROLS_CONTAINER_TRANSFORM_DURATION } from '../../Player/Controls/Controls.consts';
import { ZINDEX_CAPTIONS } from '../../zindexes';
import { usePlayerContext } from '../../Player/PlayerContext';
import { ScreenMode } from '../../constants';

interface StylesOptions {
  background: string;
  captionsPosition: number;
  controlsVisible: ClosedCaptionsProps['controlsVisible'];
  fontSize: number;
  text: string;
  textShadow?: string;
}

const useStyles = createUseStyles({
  text: {
    background: ({ background }: StylesOptions) => background,
    padding: [7, 12],
    borderRadius: 4,
    textAlign: 'center',
    color: ({ text }: StylesOptions) => text,
    textShadow: ({ textShadow }: StylesOptions) => textShadow,
    userSelect: 'none',
    boxSizing: 'border-box',
    ...body14,
    fontSize: ({ fontSize }: StylesOptions) => fontSize,
    fontWeight: 400,
    lineHeight: 1.1,
    whiteSpace: 'pre-wrap',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktopLarge])]: {
      fontWeight: 600,
    },
  },
  closedCaptionsContainer: {
    zIndex: ZINDEX_CAPTIONS,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    transition: `bottom ${CONTROLS_CONTAINER_TRANSFORM_DURATION}s linear`,
    bottom: ({ controlsVisible }: StylesOptions) => (controlsVisible ? 72 : 24),
    [getPlayerResponsiveRule([Breakpoint.isMaxMediumMobile])]: {
      bottom: ({ controlsVisible }: StylesOptions) => (controlsVisible ? 48 : 12),
    },
    '&.fullscreen': {
      transition: 'none',
      '@media (orientation: portrait)': {
        bottom: ({ captionsPosition }: StylesOptions) => `calc(50% - ${captionsPosition}px)`,
      },
    },
  },
});

interface ShouldUpdateTextState {
  currentActiveCueText: string;
  isLivePlayer: boolean;
  previousCue?: VTTCue;
  textTrack: TextTrack | undefined;
}

// hack kvuli duplicitam v live titulcich, ktere zpusobuji problikavani titulku
const shouldUpdateTextState = ({
  textTrack,
  currentActiveCueText,
  previousCue,
  isLivePlayer,
}: ShouldUpdateTextState) => {
  if (!isLivePlayer) {
    return true;
  }
  // pokud aktivni cue je prazdna == skoncil titulek
  if (currentActiveCueText === '') {
    const cues = Array.from(textTrack?.cues || []) as VTTCue[];
    // najdeme ve fronte predchozi cue
    const indexOfCueWithPreviousStartTime = cues.findIndex(
      (cue) => cue.startTime === previousCue?.startTime
    );
    const nextCueText = cues[indexOfCueWithPreviousStartTime + 1]?.text;
    // porovname text nasledujici cue
    if (nextCueText === previousCue?.text) {
      return false;
    }
  }
  return true;
};

interface ClosedCaptionsProps {
  controlsVisible: boolean;
  isLivePlayer?: boolean;
  playerContainer: HTMLDivElement;
  screenMode: ScreenMode;
  textTrack?: TextTrack;
  videoElement: HTMLVideoElement;
  visible?: boolean;
}

export const ClosedCaptions = memo(
  ({
    controlsVisible,
    visible = true,
    textTrack: playerTextTrack,
    playerContainer,
    videoElement,
    screenMode,
    isLivePlayer,
  }: ClosedCaptionsProps) => {
    const { captionColorVariant, captionFontSize } = usePlayerContext();
    const [fontSize, setFontSize] = useState(
      getClosedCaptionFontSize(videoElement, CaptionFontSize.default)
    );
    const { text, background, textShadow } = getClosedCaptionColorVariant(captionColorVariant);
    const playerHeight = document.getElementById('player-provider')?.clientHeight || 0;
    const captionsPosition = playerHeight ? playerHeight / 2 : 0;
    const stylesOptions: StylesOptions = {
      controlsVisible,
      fontSize,
      text,
      background,
      textShadow: textShadow || 'none',
      captionsPosition,
    };
    const classes = useStyles(stylesOptions);
    const [cueText, setCueText] = useState('');
    const textTrack = playerTextTrack;
    const previousCueRef = useRef<VTTCue | undefined>();

    const setTextFromActiveCue = useCallback(() => {
      const cue = textTrack?.activeCues?.[0] as VTTCue | undefined;
      const currentActiveCueText = cue?.text || '';
      const shouldUpdate = shouldUpdateTextState({
        isLivePlayer: !!isLivePlayer,
        textTrack,
        currentActiveCueText,
        previousCue: previousCueRef.current,
      });

      if (shouldUpdate) {
        setCueText(currentActiveCueText);
      }
      // do promenne si musime ukladat pouze aktivni cue, ktere nejsou "pauzy" - tzn. maji nejaky text
      if (currentActiveCueText) {
        previousCueRef.current = cue;
      }
    }, [textTrack, isLivePlayer]);

    const onCueChange = useCallback(() => {
      setTextFromActiveCue();
    }, [setTextFromActiveCue]);

    useEffect(() => {
      setTextFromActiveCue();
    }, [setTextFromActiveCue]);

    useEffect(() => {
      const fontSize = getClosedCaptionFontSize(videoElement, captionFontSize);
      setFontSize(fontSize);
    }, [captionFontSize, videoElement]);

    useEffect(() => {
      if (!textTrack) {
        return () => {};
      }

      textTrack.mode = 'hidden';
      textTrack.addEventListener('cuechange', onCueChange);

      return () => {
        textTrack.removeEventListener('cuechange', onCueChange);
      };
    }, [onCueChange, textTrack]);

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout> | null = null;

      const onResize = () => {
        const fontSize = getClosedCaptionFontSize(videoElement, captionFontSize);
        setFontSize(fontSize);
      };

      // pouzivame po prechodu do fullscreenu - resize se obcas odpali v jinou chvili
      const onDelayedResize = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          const fontSize = getClosedCaptionFontSize(videoElement, captionFontSize);
          setFontSize(fontSize);
        }, 200);
      };

      window.addEventListener('resize', onResize);
      if (playerContainer) {
        /* Kvuli prepinani do fullscreenu je dulezite rozlisovat pouziti spravneho listeneru,
           jinak se stane to, ze pri zapnutych titulcich se nejde z fullscreenu vratit.
           Zda se, ze subscribe na 'fullscreenchange' zde v titulcich rozbije odpalovani event pomoci
           'webkitfullscreenchange' na miste, kde se pracuje s prepinanim do/z fullscreenu */
        playerContainer.addEventListener(
          hasWebkitFullscreen(playerContainer) ? 'webkitfullscreenchange' : 'fullscreenchange',
          onDelayedResize
        );
      }
      return () => {
        window.removeEventListener('resize', onResize);
        if (playerContainer) {
          playerContainer.removeEventListener(
            hasWebkitFullscreen(playerContainer) ? 'webkitfullscreenchange' : 'fullscreenchange',
            onDelayedResize
          );
        }
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [captionFontSize, playerContainer, videoElement]);

    if (!cueText || !visible) {
      return null;
    }

    return (
      <div
        className={classNames(
          classes.closedCaptionsContainer,
          screenMode === ScreenMode.FULLSCREEN && 'fullscreen'
        )}
        data-testid="playerClosedCaptionsContainer"
      >
        <div className={classes.text}>{cueText}</div>
      </div>
    );
  }
);
