import classNames from 'classnames';
import React, { AnchorHTMLAttributes, KeyboardEvent, Ref } from 'react';
import type { SvgComponent } from '@czechtv/icons';
import { useFocusVisibleClassName } from '../../hooks/useFocusVisible';
import { Spinner } from '../Spinner/Spinner';
import { buttonClassnames, buttonRecipe } from './Button.css';

interface BaseProps {
  anchor?: boolean;
  'data-testid'?: string;
  disabled?: boolean;
  icon?: SvgComponent;
  iconPosition?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large';
  styleType?: 'primary' | 'secondary' | 'inverted' | 'blank';
  waiting?: boolean;
}

export interface ButtonBaseProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'onKeyDown'>,
    BaseProps {
  anchor?: false;
  ref?: Ref<any>;
}

export interface ButtonAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement>, BaseProps {
  anchor: true;
  ref?: Ref<any>;
}

export type ButtonProps = ButtonBaseProps | ButtonAnchorProps;

export { buttonRecipe };

export const Button = React.forwardRef<unknown, ButtonBaseProps | ButtonAnchorProps>(
  (
    {
      className,
      children,
      icon: Icon,
      iconPosition = 'left',
      size = 'large',
      'data-testid': testId = 'Button',
      waiting = false,
      styleType = 'primary',
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const focusVisibleClassName = useFocusVisibleClassName();
    const showDisabled = waiting ? true : disabled;
    const buttonClassName = classNames(
      className,
      buttonClassnames.button,
      buttonRecipe({ size, type: styleType }),
      focusVisibleClassName,
      {
        [buttonClassnames.noChildren]: !children,
        [buttonClassnames.disabled]: showDisabled,
      }
    );
    const handleTrigger = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.stopPropagation();
      }
    };

    const inner = (
      <>
        {waiting ? (
          <Spinner className={buttonClassnames.spinnerIcon} data-testid="ButtonSpinnerIcon" />
        ) : null}
        <span
          className={classNames(buttonClassnames.children, { [buttonClassnames.waiting]: waiting })}
          data-testid="ButtonChildren"
        >
          {Icon && iconPosition === 'left' ? (
            <Icon
              className={classNames(buttonClassnames.icon, buttonClassnames.iconLeft, {
                [buttonClassnames.noChildren]: !children,
              })}
            />
          ) : null}
          {children}
          {Icon && iconPosition === 'right' ? (
            <Icon
              className={classNames(buttonClassnames.icon, buttonClassnames.iconRight, {
                [buttonClassnames.noChildren]: !children,
              })}
            />
          ) : null}
        </span>
      </>
    );

    if (rest.anchor) {
      const { anchor, ...anchorProps } = rest;
      return (
        <a
          {...anchorProps}
          className={buttonClassName}
          data-testid="Anchor"
          ref={ref as Ref<HTMLAnchorElement>}
        >
          {inner}
        </a>
      );
    }

    // do samotného buttonu anchor nesmí přijít
    // a vylečenit ho ve volání funkce nemůžu, protože by to nesežral typescript
    const { anchor, ...buttonProps } = rest;

    return (
      <button
        {...buttonProps}
        aria-disabled={waiting ? true : undefined}
        className={buttonClassName}
        data-testid={testId}
        disabled={disabled}
        ref={ref as Ref<HTMLButtonElement>}
        // eslint-disable-next-line react/button-has-type
        type={rest.type !== undefined ? (rest.type as 'submit' | 'reset' | 'button') : 'button'}
        onKeyDown={handleTrigger}
      >
        {inner}
      </button>
    );
  }
) as {
  (props: ButtonBaseProps): React.ReactElement<ButtonBaseProps> | null;
  (props: ButtonAnchorProps): React.ReactElement<ButtonAnchorProps> | null;
};
