// dash poptáme pokud uživatel NENÍ:
// * na iOS
// * na Safari na MacOS

// custom useragenta tu máme kvůli testům
export const shouldPlayDash = (customUserAgent?: string) => {
  const userAgent =
    customUserAgent || (typeof navigator === 'undefined' ? undefined : navigator.userAgent);

  if (userAgent === undefined) {
    return true;
  }

  // na iOS nechceme dash
  if (userAgent.match(/(iPad|iPhone|iPod)/g)) {
    return false;
  }

  // na Safari taky nechceme dash. Je tu trochu složitější detekce, protože chrome na macu má
  // v useragentovi safari
  if (userAgent.match(/^((?!chrome|android|crios|fxios).)*safari/i)) {
    return false;
  }

  return true;
};
