function transferEncoderToBasicCzechName(inputString: string): string {
  const pattern: RegExp = /^CH_(\d+)$/;
  if (pattern.test(inputString)) {
    return inputString.replace(pattern, 'ČT$1');
  }
  return inputString;
}

// Chromecast TV-friendly title dle stringu enkoderu (CH_x)
export const transformEncoderName = (encoder: string): string => {
  switch (encoder) {
    case 'CH_4':
      return 'ČT Sport';
    case 'CH_5':
      return 'ČT :D';
    case 'CH_6':
      return 'ČT art';
    case 'CH_1_JZC':
      return 'ČT1 Jihozápadní Čechy';
    case 'CH_1_SVC':
      return 'ČT1 Severovýchodní Čechy';
    case 'CH_1_JM':
      return 'ČT1 Brno';
    case 'CH_1_SM':
      return 'ČT1 Ostrava';
    default:
      return transferEncoderToBasicCzechName(encoder);
  }
};
