export type TextMessage = {
  defaultMessage: string;
  description: string;
  id: string;
};

export type VariableTextMessage = {
  [message: string]: string | number | JSX.Element;
};

export const formatMessage = (message: TextMessage, variable?: VariableTextMessage) => {
  if (variable) {
    let messageReplaced = message.defaultMessage;
    Object.entries(variable).forEach((item) => {
      messageReplaced = messageReplaced.replace(`{${item[0]}}`, `${item[1]}`);
    });
    return messageReplaced;
  }
  return message.defaultMessage;
};
