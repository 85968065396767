export enum NielsenEvents {
  END = 'end',
  LOAD_METADATA = 'loadMetadata',
  SET_PLAYHEAD_POSITION = 'setPlayheadPosition',
  STOP = 'stop',
}

export interface NielsenMainContentPayload {
  adloadtype: string; // 2
  airdate: string; // '19700101 00:00:00';
  assetid: string; // ‘cz-509218-’ + playlistItem.id ('cz-509218-61924494877644900')
  crossId1: string; // externalId nebo playlistItem.assetId ('417 233 10005/1001')
  hasAds: string; // 1
  isfullepisode: string; // y
  length: string; // playlistItem.duration
  mediaURL: string; // ''
  nol_c1: string; // 'p1,'
  nol_c2: string; // 'p2,' - v pripade live za
  program: string; // playlistItem.title ('Princip slasti')
  segB: string; // ''
  segC: string; // ''
  title: string; // 'princip - slasti - 14.10.2019 20: 05'; // playlistItem.gemius.NAZEV
  type: string;
}

export interface NielsenAdPayload {
  assetid: string;
  length: string;
  nol_c4: string;
  nol_c5: string;
  nol_c6: string;
  title: string;
  type: string;
}
