import { RegisterExtraInfo } from '../hooks/useForm';

export interface LabelProps {
  required: boolean;
  title: string;
}

export interface FormElementProps extends RegisterExtraInfo {
  inverted?: boolean;
  label?: string | LabelProps;
  name: string;
  prefix?: string;
  wrapperClassName?: string;
}

export const CHECKBOX_LEFT_PADDING = 30;

export function uniqueElementId(prefix = '', name: string) {
  return `${prefix}${name}`;
}
