import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconXTwitterHoverWhiteColor: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" fill="#fff" r="10" />
    <path
      clipRule="evenodd"
      d="M23 12a11 11 0 1 0-22 0 11 11 0 0 0 22 0Zm-5.4-6-4.5 5 4.9 7h-3.6l-3.2-4.7L7 18H6l4.7-5.3L6 6h3.6l3 4.4 4-4.4h1Zm-6.4 6 .5.7 3.2 4.6h1.7l-4-5.6-.5-.7-3-4.2H7.4l3.8 5.3Z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
));
