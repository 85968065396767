import classNames from 'classnames';
import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultRadius, body16 } from '@czechtv/styles';
import { IconCheckMark, IconChevronRight } from '@czechtv/icons';
import { Toggle, ToggleVariant } from '@czechtv/form';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../../../utils/playerResponsive';
import { useId } from '../../../../../../utils/useId';

export interface SettingsListItemProps {
  active?: boolean;
  customClass?: string;
  description?: string;
  isCaptionColor?: boolean;
  isCaptionSize?: boolean;
  isCaptions?: boolean;
  isInRoot?: boolean;
  isQuality?: boolean;
  itemKey?: string;
  key: string;
  label: string;
  onClickListItem?: (key: string, selectable: boolean, switchable: boolean) => void;
  onSelect?: (item: SettingsListItemProps) => void;
  onSwitch?: () => void;
  testId?: string;
  value?: boolean;
  valuesList?: SettingsListItemProps[];
}

export enum SettingsListItemsEnum {
  AUDIO = 'Zvuková stopa',
  AUDIO_ONLY = 'Pouze zvuk',
  PLAYBACK_RATE = 'Rychlost přehrávání',
  QUALITY = 'Kvalita',
  SIGN_LANGUAGE = 'Znakový jazyk',
  SUBTITLES = 'Titulky',
  SUBTITLES_STYLE = 'Vzhled titulků',
}

const useStyles = createUseStyles({
  listItem: {
    position: 'relative',
    height: 40,
    borderRadius: DefaultRadius,
    listStyle: 'none',
    display: 'flex',
    boxSizing: 'border-box',
    '&.active': {
      border: 'solid 1px white',
    },
    '&.isCaptionSize, &.isCaptionColor': {
      border: 'solid 1px transparent',
      height: 40,
      '&.active': {
        border: 'solid 1px white',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(256, 256, 256, 0.16)',
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxTablet])]: {
      height: 48,
      width: 'calc(33.2% - 5px)',
      '&.isInRoot, &.switchable': {
        width: '100%',
      },
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxSmallMobile])]: {
      width: 'calc(50% - 4px)',
      '&.isInRoot, &.switchable': {
        width: '100%',
      },
    },
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      width: '100%',
      '&.active': {
        border: 'none',
      },
    },
  },
  button: {
    ...body16,
    color: '#fff',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'inherit',
    padding: [0, 8, 0, 10],
    '&:focus': {
      outline: '2px solid transparent',
    },
    '&:focus-visible': {
      outline: '2px solid #60a0ff',
    },
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      paddingLeft: 46,
      '&.isInRoot': {
        paddingLeft: 10,
      },
      '&.active': {
        paddingLeft: 10,
      },
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxTablet])]: {
      justifyContent: 'center',
    },
    '&.isInRoot': {
      fontWeight: 600,
      justifyContent: 'space-between',
    },
    '&.isCaptions': {
      paddingLeft: 10,
      width: '100%',
    },
    '&.default': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    '&.yellowText': {
      color: 'rgba(255, 255, 0, 1)',
      backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    '&.noBackground': {
      color: 'rgba(255, 255, 255, 1)',
      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
      backgroundColor: 'none',
    },
    '&.blackText': {
      color: 'rgba(0, 0, 0, 1)',
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  selectedIcon: {
    display: 'none',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      display: 'block',
      fill: '#fff',
      width: 24,
      height: 24,
      marginRight: '12px',
    },
  },
  chevronRight: {
    fill: '#fff',
    height: 18,
    width: 18,
  },
  selectedItem: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  quality: {
    marginRight: 8,
  },
  qualityNumber: {
    fontWeight: 400,
    color: '#C3C6CC',
  },
  activeQuality: {
    marginLeft: 8,
    color: '#C3C6CC',
  },
  iconSubtitles: {
    fill: '#fff',
    width: 20,
    height: 14,
    marginRight: 14,
  },
});

const SettingsListItem = ({
  label,
  active,
  onClickListItem,
  valuesList,
  isInRoot,
  value,
  isQuality,
  isCaptions,
  isCaptionSize,
  isCaptionColor,
  description,
  customClass,
  itemKey,
}: SettingsListItemProps) => {
  const classes = useStyles();
  const selectable = !valuesList ? true : false;
  const switchable = !valuesList && typeof value !== 'undefined' ? true : false;
  const activeQuality = valuesList?.find((item) => item.active);
  const id = useId();

  const handleOnClick = () => {
    if (onClickListItem) {
      onClickListItem(itemKey || label, selectable, switchable);
    }
  };

  return (
    <li
      aria-haspopup={isInRoot}
      className={classNames(classes.listItem, {
        active,
        switchable,
        isInRoot,
        isCaptionSize,
        isCaptionColor,
      })}
      role="menuitem"
      onTouchMove={() => (document.activeElement as HTMLElement).blur()}
    >
      <button
        className={classNames(
          classes.button,
          {
            active,
            selectable,
            isInRoot,
            isQuality,
          },
          customClass
        )}
        type="button"
        onClick={handleOnClick}
      >
        {!isInRoot && (
          <span aria-checked={active} aria-labelledby={id} role="checkbox" tabIndex={-1}></span>
        )}
        {active && !isCaptionColor && !isCaptionSize && (
          <IconCheckMark aria-hidden="true" className={classes.selectedIcon} />
        )}

        {isQuality ? (
          <>
            <span className={classes.quality} id={id}>
              {label === 'Auto' ? label : description}
            </span>
            <span className={classes.qualityNumber} id={id}>
              {label === 'Auto' ? description : label}
            </span>
          </>
        ) : (
          <span id={id}>{label}</span>
        )}

        {valuesList && !isCaptions && (
          <span className={classes.selectedItem} id={id}>
            {valuesList.find((value) => value.active)?.label}
            {isInRoot && activeQuality && (
              <span className={classes.activeQuality}>{activeQuality.description}</span>
            )}
            <IconChevronRight className={classes.chevronRight}></IconChevronRight>
          </span>
        )}
        {switchable && (
          <Toggle
            aria-checked={value}
            isChecked={value}
            name={label}
            variant={ToggleVariant.MENU_TOGGLE}
            onChange={() => {
              return;
            }}
          />
        )}
      </button>
    </li>
  );
};

export default memo(SettingsListItem);
