import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../../utils/playerResponsive';
import { CaptionsStyleButton } from '../../CaptionsStyle/CaptionsStyleButton';
import SettingsListItem, {
  SettingsListItemProps,
  SettingsListItemsEnum,
} from './SettingsListItem/SettingsListItem';

interface SettingsListProps {
  isCaptionColor?: boolean;
  isCaptionSize?: boolean;
  isInRoot?: boolean;
  listContent: SettingsListItemProps;
  onGoBack?: Function;
  setActiveItem: (label: string) => void;
  setListContent: (listContent: SettingsListItemProps) => void;
  testId?: string;
}

const useStyles = createUseStyles({
  wrapper: {
    minWidth: '100%',
  },
  list: {
    display: 'flex',
    padding: 8,
    margin: [0, 0],
    flexWrap: 'wrap',
    flexDirection: 'row',
    [getPlayerResponsiveRule([Breakpoint.isMinDesktop])]: {
      flexDirection: 'column',
    },
    [getPlayerResponsiveRule([Breakpoint.isMaxTablet])]: {
      gap: '8px',
    },
    '&.isInRoot': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      gap: 'unset',
    },
    '&.isCaptionSize, &.isCaptionColor': {
      flexWrap: 'nowrap',
      flexDirection: 'row',
      padding: 6,
      '& .selectable': {
        padding: 0,
        borderRadius: 2,
        justifyContent: 'center',
      },
    },
    '&.isCaptionSize': {
      gap: 8,
    },
    '&.isCaptionColor': {
      gap: 4,
      backgroundColor: 'rgba(57, 57, 57, 1)',
      margin: [0, 8, 8, 8],
      borderRadius: 3,
      '& .selectable': {
        margin: 5,
      },
      '& .listItem': {
        height: 40,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
  center: {
    [getPlayerResponsiveRule([Breakpoint.isMaxTablet])]: {
      justifyContent: 'center',
    },
  },
});

export const SettingsList = ({
  listContent,
  isInRoot,
  isCaptionColor,
  isCaptionSize,
  setListContent,
  setActiveItem,
  testId,
  onGoBack = () => {},
}: SettingsListProps) => {
  const classes = useStyles();
  const oneItemSettingsList = listContent.valuesList && listContent.valuesList.length < 2;
  const isCaptions = listContent.label === SettingsListItemsEnum.SUBTITLES;

  const onClickListItem = (key: string, selectable: boolean, switchable: boolean) => {
    const clickedItem = listContent.valuesList?.find((item) => item.key === key);
    const activeItem = listContent.valuesList?.find((item) => item.active === true);
    if (switchable) {
      clickedItem?.onSwitch && clickedItem.onSwitch();
      return;
    }
    if (selectable) {
      // selectni aktivni polozku
      if (clickedItem && activeItem) {
        listContent.onSelect && listContent.onSelect(clickedItem);
        onGoBack();
      }
      return;
    }
    if (!clickedItem) {
      return;
    }
    setActiveItem(clickedItem.key);
    setListContent(clickedItem);
  };

  return (
    <div className={classes.wrapper}>
      <ul
        className={classNames(
          classes.list,
          { isInRoot, isCaptionSize, isCaptionColor },
          oneItemSettingsList && classes.center
        )}
        data-testid={testId}
        role="menu"
      >
        {listContent.valuesList?.map((item) => {
          return (
            <SettingsListItem
              active={item.active}
              customClass={item.customClass}
              description={item.description}
              isCaptionColor={isCaptionColor}
              isCaptionSize={isCaptionSize}
              isCaptions={isCaptions}
              isInRoot={isInRoot}
              isQuality={listContent.label === SettingsListItemsEnum.QUALITY}
              itemKey={item.key}
              key={item.key}
              label={item.label}
              value={item.value}
              valuesList={item.valuesList}
              onClickListItem={onClickListItem}
            />
          );
        })}
      </ul>

      {isCaptions && (
        <CaptionsStyleButton
          listContent={listContent}
          setActiveItem={setActiveItem}
          setListContent={setListContent}
        />
      )}
    </div>
  );
};
