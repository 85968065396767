import { ExternalLiveStreams } from '../constants';
import {
  NEW_CDN_CONTENT_ID_PARAM_KEY,
  OLD_CDN_CONTENT_ID_PARAM_KEY,
  getParamFromUrl,
} from './getParamFromUrl';
import { LegacyPlaylistSuccessResponse } from './playlists/constants';

const IVYS_CNT_BASE_URL = 'https://ivys-cnt.o2tv.cz/contentserver/contents';

export const buildExternalStreamsData = (
  externalStreams: ExternalLiveStreams,
  options: {
    canPlayDrm: boolean;
    streamType: 'dash' | 'hls';
  }
): LegacyPlaylistSuccessResponse => {
  const { main, timeshift } =
    externalStreams[options.streamType][options.canPlayDrm ? 'drm' : 'noDrm'];
  const contentId =
    getParamFromUrl(main, OLD_CDN_CONTENT_ID_PARAM_KEY) ||
    getParamFromUrl(main, NEW_CDN_CONTENT_ID_PARAM_KEY);
  const previewImageUrl = contentId
    ? `${IVYS_CNT_BASE_URL}/${contentId}/categories/screenshot/1.jpg`
    : undefined;
  const thumbnailsUrl = contentId
    ? `${IVYS_CNT_BASE_URL}/${contentId}/categories/preview_track_big/`
    : undefined;

  return {
    setup: {
      title: 'Česká televize',
      description: 'Externí stream',
      previewImageUrl: externalStreams.previewImageUrl || previewImageUrl || '',
      gemius: {
        IDENTIFIER: '',
        PREFIX: '',
        HITCOLLECTOR: '',
      },
      vast: {},
    },
    playlist: [
      {
        type: 'LIVE',
        id: contentId || 'external',
        assetId: 'external',
        title: externalStreams.title || 'ČT Sport',
        aspect: '16:9',
        duration: '1000',
        gemius: {
          ID: '',
          NAZEV: '',
          TYP: '',
          ZDROJ: '',
          CIL: '',
          KATEGORIE: '',
          GA: '',
        },
        previewTrackBaseUrl: externalStreams.thumbnailsUrl || thumbnailsUrl || '',
        previewImageUrl: externalStreams.previewImageUrl || previewImageUrl || '',
        streamUrls: {
          main,
          timeshift,
        },
      },
    ],
  };
};
