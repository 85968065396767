// cross borwser řešení pro zjištění dostupnosti různých client úložišť, viz.
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage

export const storageAvailable = (type: keyof Window) => {
  try {
    const storage = window[type] as Storage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};
