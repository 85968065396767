/* eslint-disable max-lines */
import { useState, useEffect } from 'react';
import { Encoder } from '@czechtv/utils';
import { CLIENT_ID } from '@czechtv/analytics';
import { isMobileDevice } from '@czechtv/styles';
import { log } from '../logger';
import { DRM, getIsDrmOnlyFromUrl, getStreamDRMFromUrl, useSupportedDRMs } from '../drm';
import { getMediaId, getNielsenC1forLive } from '../parseIds';
import {
  PlaylistError,
  PlaylistErrorCodes,
} from '../../components/Error/playlistError/playlistError';
import { VodIsLiveError } from '../../components/Error/vodIsLiveError';
import { IVYS_VAST_CONFIG, getAdOceanParams } from '../ads';
import { LiveMode } from '../../constants';
import { shouldPlayDash } from '../shouldPlayDash';
import {
  NEW_CDN_CONTENT_ID_PARAM_KEY,
  OLD_CDN_CONTENT_ID_PARAM_KEY,
  getParamFromUrl,
} from '../getParamFromUrl';
import { getSessionId } from '../getSessionId';
import { getBitrateByResolution } from '../bitrateRestrictions';
import { buildExternalStreamsData } from '../externalLiveStreamsDataBuilder';
import {
  GEMIUS_MAIN_CONTENT_EMPTY,
  GetPlaylistOptions,
  PlaylistOptions,
  NIELSEN_MAIN_CONTENT_EMPTY,
  PlaylistStreamResponse,
  PlaylistData,
  DEFAULT_PROMO_TIMEOUT,
  LegacyPlaylistSuccessResponse,
  GetLegacyStreamDataParams,
  GTM_MAIN_CONTENT_EMPTY,
  ADSCRIPT_MAIN_CONTENT_EMPTY,
  ChangeIdType,
  IdType,
  NewVodIdType,
  CT24_NIELSEN_PROGRAM,
  PEGI_RATING_DATA,
} from './constants';
import { getLegacyStreamData, getLiveStreamData, getVodStreamData } from './streamData';
import {
  channelWithCzechCharacter,
  createLegacyAdsFromExternalSource,
  encodeMultipleIds,
  encoderToChannelName,
  getBonusFromExternalId,
  getCrossId,
  getIsLabeling,
} from './utils';
import { retimeSubtitle } from './subtitles';
import { captureTaggedFallback } from './extraLogs';
import { reportToShadowPlaylistService } from './shadowPlaylistReporting';

// pro prehravani s DRM na O2 lab potrebujeme poptavat licenci jinak
const WIDEVINE_LICENSE_LAB_SERVER_URL = 'https://ivys-nw-wvproxy.o2-tv.cz/license';
const FAIRPLAY_LICENSE_LAB_SERVER_URL = 'https://ivys-nw-fpproxy.o2-tv.cz/license';

export async function getPlaylistData({ supportedDRMs, ...props }: GetPlaylistOptions) {
  const cropStart = 'cropStart' in props ? props.cropStart : undefined;
  const cropEnd = 'cropEnd' in props ? props.cropEnd : undefined;

  const {
    accessToken,
    adOceanCustomTargeting,
    adOceanSections,
    additionalAdKeywords,
    adminMode,
    bonusId: rawBonusId,
    bypassGeoIp,
    disableLabeling,
    encoder,
    endTimestamp,
    externalId,
    externalEpisodeTitle,
    externalShowTitle,
    fairplayAccessToken,
    fairplayLicenseCertificateUrl,
    fairplayLicenseServerUrl,
    forceAudioOnly,
    indexId,
    liveMode,
    logFallbackError,
    mediaId,
    maxQuality,
    maxAutoQuality,
    origin,
    playlistLiveUri,
    playlistUri,
    playlistVodUri,
    promoHash,
    promoTimeout,
    replayToken,
    shadowPlaylistReportEnabled,
    showId,
    startTimestamp,
    externalLiveStreams,
    useLegacyPlaylistFallback,
    useNewAds,
    useNewCdnForLiveIdec,
    useNewPlaylist: initialUseNewPlaylist,
    vastConfig,
    versionId,
    widevineAccessToken,
    widevineLicenseServerUrl,
  } = props;

  const bonusId = rawBonusId || getBonusFromExternalId(externalId);

  // pokud mame mediaId nebo versionId, tak pouzivame vzdy nove pl. nezavisle na nastaveni
  let useNewPlaylist = !!(mediaId || versionId || initialUseNewPlaylist);

  const audioOnlyType =
    forceAudioOnly?.audioOnly && forceAudioOnly.isAudioDescription ? 'audioad' : 'audio';

  let quality = forceAudioOnly?.audioOnly ? audioOnlyType : isMobileDevice() ? 'mobile' : 'web';
  const streamType = shouldPlayDash() ? 'dash' : 'hls';
  // pro hls v pripade nutnosti omezime abr tim, ze poptame nizsi kvalitu primo z playlistu
  // pozdeji nemame moznost prenastavit, protoze kvalita je vzdy jen auto
  // vybereme bud maxQuality nebo maxAutoQuality

  if ((maxQuality || maxAutoQuality) && streamType === 'hls' && !forceAudioOnly?.audioOnly) {
    // je hodnota maxQuality validni?
    if (getBitrateByResolution(maxQuality)) {
      quality = `${maxQuality}p${maxQuality === 144 ? '2' : ''}`;
      // je hodnota maxAutoQuality validni?
    } else if (getBitrateByResolution(maxAutoQuality)) {
      quality = `${maxAutoQuality}p${maxAutoQuality === 144 ? '2' : ''}`;
    }
  }

  const sessionId = getSessionId();

  let id: string | undefined;
  let idType: IdType;
  let newVodIdType: NewVodIdType;
  if (externalLiveStreams) {
    id = 'externalLiveStreams';
    idType = 'externalLiveStreams';
  } else if (indexId && useNewPlaylist) {
    id = indexId;
    idType = 'index';
    newVodIdType = 'indexId';
  } else if (indexId) {
    id = indexId;
    idType = 'index';
  } else if (bonusId && !useNewPlaylist) {
    id = bonusId;
    idType = 'bonus';
  } else if (bonusId && useNewPlaylist) {
    id = bonusId;
    idType = 'bonus';
  } else if (externalId && !useNewPlaylist) {
    id = externalId;
    idType = 'episode';
  } else if (externalId && useNewPlaylist) {
    id = externalId;
    idType = 'episode';
    newVodIdType = 'externalId';
  } else if (encoder) {
    id = encoder;
    idType = 'channel';
  } else if (versionId) {
    id = versionId;
    idType = 'episode';
    newVodIdType = 'versionId';
  } else if (mediaId) {
    id = mediaId;
    idType = 'episode';
    newVodIdType = 'mediaId';
  }

  if (!id) {
    throw new Error('Missing ID');
  }
  if (!idType) {
    throw new Error('Missing type');
  }

  let streamData: PlaylistStreamResponse;

  const changeIdType = (changed: ChangeIdType) => {
    id = changed.id;
    idType = changed.idType;
    newVodIdType = changed.newVodIdType;
  };

  const getLegacyPlaylistData = async (
    initialId: string,
    initialType: GetLegacyStreamDataParams['type'],
    error?: Error
  ): Promise<LegacyPlaylistSuccessResponse> => {
    let type = initialType;
    let id = initialId;

    /* Stare playlisty obcas na zetku pouzivaji napr. CT24-2683303 nebo CT24-babis.
       Pouziva se typ playlistu 'ct24' a jako id string bez prefixu 'ct24-'.
       Pro nove pl. neresime. Cele id protece do routy /externalId */
    const lowerCaseId = id.toLowerCase();
    if (lowerCaseId.includes('ct24-')) {
      id = lowerCaseId.replace('ct24-', '');
      type = 'ct24';
    }

    if (error) {
      if (logFallbackError) {
        log.error({
          error,
          message: `New Playlist failed to load id ${id}`,
        });
      } else {
        log.info({
          error,
          message: `New Playlist failed to load id ${id}`,
        });
      }
    }

    const logLegacyPlaylistError = (id: string): void => {
      log.error({
        error: error as Error,
        message: `Legacy Playlist failed to load id ${id}`,
      });
    };

    const legacyPlaylistData = await getLegacyStreamData({
      type,
      id: type === 'channel' ? id.toLowerCase().replace(/[_ ct ch]/g, '') : id,
      cropStart,
      cropEnd,
      supportedDRMs,
      playlistUri,
      origin,
      disableLabeling,
      streamType,
      logFallbackError: logLegacyPlaylistError,
    });
    if ('error' in legacyPlaylistData) {
      if (error) {
        logLegacyPlaylistError(id);
      }
      throw new Error(legacyPlaylistData.error);
    }
    if (!legacyPlaylistData.playlist.length) {
      if (error) {
        logLegacyPlaylistError(id);
      }
      throw new Error('Missing stream playlist data');
    }
    if (!legacyPlaylistData.playlist[0].streamUrls.main) {
      if (error) {
        logLegacyPlaylistError(id);
      }
      throw new Error('Missing main stream url');
    }
    if (disableLabeling) {
      legacyPlaylistData.playlist = legacyPlaylistData.playlist.filter(
        (item) => item.type !== 'TRAILER'
      );
      return legacyPlaylistData;
    }
    // na starych playlistech potrebujeme odfiltrovat upozorneni 15+
    legacyPlaylistData.playlist = legacyPlaylistData.playlist.filter(
      (item) => item.assetId !== PEGI_RATING_DATA.PEGI_15.assetId
    );

    if (error && logFallbackError) {
      captureTaggedFallback('Fallback_to_a_legacy_playlist', {
        tagName: 'VOD_FALLBACK_ID',
        tagValue: id,
        errorMessage: error.message,
      });
    }

    // iVysilani - zive IDECy - odlehceni stare O2 CDN pro zive IDECy
    // pokud puvodni typ id neni channel, ale stejne prijde ze starych pl. LIVE, je to zivy IDEC
    if (
      useNewCdnForLiveIdec &&
      legacyPlaylistData.playlist[0].type === 'LIVE' &&
      type !== 'channel'
    ) {
      // asseId v tomto pripade vraci legacy nazvy kanalu
      const mediaId = getMediaId({
        videoId: legacyPlaylistData.playlist[0].assetId,
        useNewPlaylist: true,
      });
      // pokud najdeme validni nazev pro enkoder, pokusime se poptat nove pl.
      if (mediaId.encoder) {
        try {
          const playlistUrl = `${playlistLiveUri}/stream-data/channel/${mediaId.encoder}`;
          const newLivePlaylistData = await getLiveStreamData({
            supportedDRMs,
            playlistUrl,
            encoder: mediaId.encoder as Encoder,
            startTimestamp,
            quality,
            endTimestamp,
            liveMode,
            sessionId,
            origin,
            streamType,
          });
          // nechame si detailni metadata, ktera chodi pro zivy IDEC ze st.pl., vymenime pouze streamUrls
          if ('timeshift' in newLivePlaylistData.playlist[0].streamUrls) {
            legacyPlaylistData.playlist[0].streamUrls = {
              main: newLivePlaylistData.playlist[0].streamUrls.main,
              timeshift: newLivePlaylistData.playlist[0].streamUrls.timeshift,
            };
          }
        } catch (error) {
          // nelogujeme, normalne se pojede dal dle starych pl.
        }
      }
    }
    // TODO: tohle cele je osklive, nejspis smazat po MSLH

    useNewPlaylist = false;
    return legacyPlaylistData;
  };

  if (idType === 'externalLiveStreams') {
    if (!externalLiveStreams) {
      throw new Error(`You can't use ${newVodIdType} when requesting the old playlist endpoint.`);
    }
    try {
      streamData = buildExternalStreamsData(externalLiveStreams, {
        canPlayDrm: supportedDRMs.length > 0,
        streamType,
      });
    } catch (error) {
      // tenhle error se muze zdat byt zbytecny, ale muze dojit k tomu, ze nekdo nedefinuje spravne vsechny potrebne
      // stream urls (obejde typescript), takze muze predchozi funkce vyhodit chybu
      throw new Error(`An error occurred while building external live streams data.`);
    }
  }
  // VOD a live stare playlisty
  else if (!useNewPlaylist && !promoHash) {
    if (newVodIdType) {
      throw new Error(`You can't use ${newVodIdType} when requesting the old playlist endpoint.`);
    }
    streamData = await getLegacyPlaylistData(id, idType);
    // Nové playlisty
  } else if (idType !== 'channel') {
    // VOD
    if (!playlistVodUri) {
      throw new Error('Missing new playlist VOD uri');
    }
    if (adminMode && !accessToken) {
      throw new Error('You have to provide your access token when using admin mode.');
    }
    if (promoHash) {
      const playlistUrl = `${playlistVodUri}/stream-data/promo/${id}/${promoHash}`;
      streamData = await getVodStreamData({
        supportedDRMs,
        playlistUrl,
        id,
        bypassGeoIp,
        quality,
        accessToken,
        replayToken,
        cropStart,
        cropEnd,
        disableLabeling,
        streamType,
        sessionId,
        origin,
      });
    } else if (newVodIdType === 'versionId') {
      const playlistUrl = `${playlistVodUri}/stream-data/version/${id}`;
      const playlistUrlUnrestricted = `${playlistVodUri}/stream-data/version/${id}/unrestricted`;
      streamData = await getVodStreamData({
        supportedDRMs,
        playlistUrl: adminMode ? playlistUrlUnrestricted : playlistUrl,
        id,
        bypassGeoIp,
        accessToken,
        quality,
        cropStart,
        cropEnd,
        disableLabeling,
        streamType,
        sessionId,
        origin,
      });
    } else if (newVodIdType === 'mediaId') {
      const playlistUrl = `${playlistVodUri}/stream-data/media/${id}`;
      streamData = await getVodStreamData({
        supportedDRMs,
        playlistUrl,
        id,
        bypassGeoIp,
        quality,
        cropStart,
        cropEnd,
        disableLabeling,
        sessionId,
        origin,
        streamType,
      });
    } else if (idType === 'bonus') {
      if (!id.startsWith('BO-')) {
        id = `BO-${id}`;
      }
      const playlistUrl = `${playlistVodUri}/stream-data/bonus/${id}`;
      streamData = await getVodStreamData({
        supportedDRMs,
        playlistUrl,
        id,
        bypassGeoIp,
        quality,
        cropStart,
        cropEnd,
        disableLabeling,
        sessionId,
        origin,
        streamType,
      });
    } else if (newVodIdType === 'externalId') {
      const playlistUrl = `${playlistVodUri}/stream-data/media/external/${id}`;

      // zkusi poptat nejdriv nove a potom stare playlisty, pokud je funkce zapnuta.
      try {
        streamData = await getVodStreamData({
          supportedDRMs,
          playlistUrl,
          id,
          bypassGeoIp,
          quality,
          cropStart,
          cropEnd,
          disableLabeling,
          sessionId,
          origin,
          streamType,
        });
      } catch (error) {
        // pokud je IDEC nutno prehrat zive
        if (error instanceof VodIsLiveError) {
          const encoder = error.channel;
          const playlistUrl = `${playlistLiveUri}/stream-data/channel/${encoder}`;
          streamData = await getLiveStreamData({
            supportedDRMs,
            playlistUrl,
            encoder,
            startTimestamp,
            quality,
            endTimestamp,
            liveMode,
            sessionId,
            origin,
            streamType,
          });
          changeIdType({ id: error.channel, idType: 'channel', newVodIdType: undefined });
        } else if (
          useLegacyPlaylistFallback
          // TODO: Toto je docasne, zatim chceme delat fb vzdy
          // !adminMode &&
          // (error as PlaylistError).error === PlaylistErrorCodes.MEDIUM_NOT_FOUND
        ) {
          streamData = await getLegacyPlaylistData(id, idType, error as Error);
        } else {
          throw error;
        }
      }
    } else if (newVodIdType === 'indexId') {
      const playlistUrl = `${playlistVodUri}/stream-data/index/${encodeMultipleIds(id)}`;
      const playlistUrlUnrestricted = `${playlistVodUri}/stream-data/index/${id}/unrestricted`;
      try {
        streamData = await getVodStreamData({
          supportedDRMs,
          playlistUrl: adminMode ? playlistUrlUnrestricted : playlistUrl,
          id,
          bypassGeoIp,
          accessToken,
          quality,
          cropStart,
          cropEnd,
          disableLabeling,
          sessionId,
          origin,
          streamType,
        });
      } catch (error) {
        if (
          useLegacyPlaylistFallback
          // TODO: Toto je docasne, zatim chceme delat fb vzdy
          // !adminMode &&
          // (error as PlaylistError).error === PlaylistErrorCodes.MEDIUM_NOT_FOUND
        ) {
          streamData = await getLegacyPlaylistData(id, idType, error as Error);
        } else {
          throw error;
        }
      }
    } else {
      throw new Error('No valid VOD id was provided while requesting new playlists.');
    }
  } else {
    // LIVE
    if (!playlistLiveUri) {
      throw new Error('Missing new playlist live uri');
    }
    if (!encoder) {
      throw new Error('Missing encoder for new live playlist');
    }
    if (liveMode === LiveMode.liveAsVod && (!startTimestamp || !endTimestamp)) {
      throw new Error('You have to provide startTimestamp and endTimestamp when using liveMode 1');
    }
    const playlistUrl = `${playlistLiveUri}/stream-data/channel/${encoder}`;
    try {
      streamData = await getLiveStreamData({
        supportedDRMs,
        playlistUrl,
        encoder,
        startTimestamp,
        endTimestamp,
        liveMode,
        quality,
        sessionId,
        origin,
        streamType,
      });
    } catch (error) {
      if (
        useLegacyPlaylistFallback
        // TODO: Toto je docasne, zatim chceme delat fb vzdy
        // !adminMode &&
        // (error as PlaylistError).error !== PlaylistErrorCodes.UNSUPPORTED_GEOLOCATION
      ) {
        streamData = await getLegacyPlaylistData(id, idType, error as Error);
      } else {
        throw error;
      }
    }
  }
  // LIVE
  const VODPlaylist = streamData.playlist.find((item) => item.type === 'VOD');
  const LivePlaylist = streamData.playlist.find((item) => item.type === 'LIVE');

  const isVOD = !!VODPlaylist;

  const streamDataPlaylist = streamData.playlist;

  const mainContentPlaylist = isVOD ? VODPlaylist : LivePlaylist;

  const isLab = mainContentPlaylist?.streamUrls.main.includes('https://ivys-nw-cdn.o2-tv.cz');

  if (isLab) {
    log.info({ message: 'O2 LAB!' });
  }

  if (!mainContentPlaylist) {
    throw new Error('Missing timeshift stream url');
  }

  const playlistVODData: PlaylistData = {
    analytics: {
      adScript: {
        ...ADSCRIPT_MAIN_CONTENT_EMPTY,
      },
      nielsen: {
        ...NIELSEN_MAIN_CONTENT_EMPTY,
      },
      gemius: {
        ...GEMIUS_MAIN_CONTENT_EMPTY,
      },
      gtm: {
        ...GTM_MAIN_CONTENT_EMPTY,
      },
    },
    id: '',
    indexes: [],
    replayToken: undefined,
    useNewPlaylist,
    streamUrls: [],
    meta: {
      duration: null,
      title: null,
      showTitle: null,
      hasAds: false,
    },
    encoder: null,
    assetId: id,
    subtitles: [],
    type: 'vod',
    isLab,
  };
  const playlistLiveData: PlaylistData = {
    analytics: {
      adScript: {
        ...ADSCRIPT_MAIN_CONTENT_EMPTY,
      },
      nielsen: {
        ...NIELSEN_MAIN_CONTENT_EMPTY,
      },
      gemius: {
        ...GEMIUS_MAIN_CONTENT_EMPTY,
      },
      gtm: {
        ...GTM_MAIN_CONTENT_EMPTY,
      },
    },
    id: '',
    streamUrls: [],
    meta: {
      duration: null,
      title: null,
      showTitle: null,
    },
    assetId: id,
    encoder: null,
    type: 'live',
    useNewPlaylist,
    isLab,
  };
  const playlistData = isVOD ? playlistVODData : playlistLiveData;

  // pokud hlavni obsah vyzaduje DRM a prohlizec zadne nepodporuje, zasekneme jeste pred reklamami
  if (getIsDrmOnlyFromUrl(mainContentPlaylist.streamUrls.main)) {
    if (!supportedDRMs.length) {
      throw new PlaylistError('DRM not supported', PlaylistErrorCodes.UNSUPPORTED_DRM);
    }
  }

  // pri prvnim nacteni promo obsahu chceme pouzit token z playlistu, pak pri pripadnem refetchi
  // pouzivame token, ktery jsme dostali na zacatku
  if (replayToken || streamData.setup.replayToken) {
    playlistData.replayToken = replayToken || streamData.setup.replayToken;
  }

  // Stream url
  if (streamDataPlaylist.length > 0) {
    playlistData.streamUrls = streamDataPlaylist.map((playlist) => {
      // DRM
      const streamDrm = getStreamDRMFromUrl(playlist.streamUrls.main);

      if (!playlistData.drm && streamDrm) {
        // nechceme nastavovat drm pokud neni podporovane (player muze spadnout) nebo neni potreba
        if (streamDrm === 'widevine' && supportedDRMs.find((drm) => drm.includes('widevine'))) {
          playlistData.drm = {
            widevineLicenseServerUrl: `${isLab ? WIDEVINE_LICENSE_LAB_SERVER_URL : widevineLicenseServerUrl}?access_token=${widevineAccessToken}`,
          };
        } else if (
          streamDrm === 'fairplay' &&
          supportedDRMs.find((drm) => drm.includes('fairplay'))
        ) {
          playlistData.drm = {
            fairplayLicenseServerUrl: `${isLab ? FAIRPLAY_LICENSE_LAB_SERVER_URL : fairplayLicenseServerUrl}?access_token=${fairplayAccessToken}`,
            fairplayLicenseCertificateUrl,
          };
        }
      }

      const isLabeling = getIsLabeling(playlist.type);

      return {
        main: playlist.streamUrls.main,
        audioDescription:
          'audioDescription' in playlist.streamUrls
            ? playlist.streamUrls.audioDescription || null
            : null,
        timeshift: 'timeshift' in playlist.streamUrls ? playlist.streamUrls.timeshift : undefined,
        // u VOD muze v poli playlistu prijit
        isLabeling,
        ...(promoHash && !isLabeling
          ? {
              promoReport: {
                url: `${playlistVodUri}/stream-data/promo/${id}/${promoHash}/commit`,
                timeout: promoTimeout || DEFAULT_PROMO_TIMEOUT,
              },
            }
          : {}),
      };
    });
  }

  // TODO: spis pouzit type jednotlivych polozek
  playlistData.type = streamData.playlist.find((item) => item.type === 'LIVE') ? 'live' : 'vod';
  // Reklamy (postroll/preroll urls)
  if (
    'vast' in streamData.setup &&
    !useNewAds &&
    (streamData.setup.vast?.preRoll || streamData.setup.vast?.postRoll)
  ) {
    playlistData.vast = { ...streamData.setup.vast };
    playlistData.meta.hasAds = true;
  }

  // CT SPORT hack MSLH 2024 - bude reseno systemove i pro dalsi platformy a produkty v ramci novych reklam pro iVysilani
  if (vastConfig && isVOD) {
    playlistData.vast = createLegacyAdsFromExternalSource({
      additionalAdKeywords,
      id,
      showId,
      streamType,
      origin: 'ct4',
      vastConfig,
    });
    playlistData.meta.hasAds = true;
    log.debug({ message: 'Using Ad-config tailored for Sport' });
  }

  // prozatimni reseni pro NVODPL na iVysilani
  if (origin?.toLowerCase() === 'ivysilani' && idType === 'episode') {
    const keywords =
      [streamData.setup.targetGroup, additionalAdKeywords].filter(Boolean).join(' ').trim() ||
      undefined;
    playlistData.vast = createLegacyAdsFromExternalSource({
      additionalAdKeywords: keywords,
      id,
      showId,
      streamType,
      title: externalShowTitle || externalEpisodeTitle || mainContentPlaylist.title,
      origin: 'ivysilani',
      vastConfig: IVYS_VAST_CONFIG,
    });
    playlistData.meta.hasAds = true;
    log.debug({ message: 'Using Ad-config tailored for Ivysilani' });
  }

  if (useNewAds && streamData.setup.adOcean) {
    const targeting =
      streamData.setup.targetGroup || adOceanCustomTargeting
        ? [
            ...(adOceanCustomTargeting || []),
            ...(streamData.setup.targetGroup ? [streamData.setup.targetGroup] : []),
          ]
        : undefined;
    const isLive = !!encoder;
    const additionalParams = {
      origin,
      id,
      sidp: showId,
      customTargeting: targeting,
      sections: adOceanSections,
      isLive,
    };

    playlistData.vast = {
      preRoll: [
        getAdOceanParams({
          adSpaceId: isLive
            ? streamData.setup.adOcean.preRollsLiveId
            : streamData.setup.adOcean.preRollsId,
          ...additionalParams,
        }),
      ],
      postRoll: isLive
        ? undefined
        : [
            getAdOceanParams({
              adSpaceId: streamData.setup.adOcean.postRollsId,
              ...additionalParams,
            }),
          ],
    };
    playlistData.meta.hasAds = true;
  }

  // nove nechceme ukazovat indexy (kotvy), pokud je video navic oriznute zvenku pres parametry
  const shouldDisplayIndexes = !(cropStart !== undefined || cropEnd);

  // Indexy
  if (
    'indexes' in mainContentPlaylist &&
    mainContentPlaylist.indexes?.length &&
    shouldDisplayIndexes
  ) {
    const { indexes, duration: rawDuration } = mainContentPlaylist;
    const duration = parseInt(rawDuration, 10);
    playlistVODData.indexes = indexes.map((indexData, index) => {
      const nextIndex = indexes[index + 1];

      return {
        imageUrl: indexData.imageURL,
        indexId: `${index}`,
        title: indexData.title,
        startTime: indexData.time,
        stopTime: nextIndex ? nextIndex.time - 1 : duration,
      };
    });

    // Pokud je ve videu pouze jeden index, ktery zacina hned na zacatku,
    // je zbytecne ho zobrazovat
    if (playlistVODData.indexes.length === 1 && playlistVODData.indexes[0].startTime === 0) {
      playlistVODData.indexes = [];
    }
  }

  // Titulky
  if ('subtitles' in mainContentPlaylist && mainContentPlaylist.subtitles?.length) {
    let { subtitles } = mainContentPlaylist;
    if (cropStart || cropEnd) {
      subtitles = await Promise.all(
        subtitles.map((subtitle) =>
          retimeSubtitle({
            subtitle,
            cropStart: cropStart || 0,
            cropEnd: cropEnd || parseInt(mainContentPlaylist.duration, 10),
          })
        )
      );
    }
    playlistVODData.subtitles = [...subtitles];
  }
  // Title
  if ('gemius' in mainContentPlaylist && mainContentPlaylist.gemius.NAZEV) {
    playlistData.meta.title = mainContentPlaylist.gemius.NAZEV;
  }
  // Show title
  if (mainContentPlaylist && mainContentPlaylist.title) {
    playlistData.meta.showTitle = mainContentPlaylist.title;
  }
  // Délka
  if (mainContentPlaylist && mainContentPlaylist.duration) {
    playlistData.meta.duration = parseInt(mainContentPlaylist.duration, 10);
  }
  // Nahledovy obrazek
  if (streamData.setup.previewImageUrl) {
    playlistData.meta.previewImageUrl = streamData.setup.previewImageUrl;
  }
  if (mainContentPlaylist.previewTrackBaseUrl) {
    playlistData.previewTrackBaseUrl = mainContentPlaylist.previewTrackBaseUrl;
  }
  // Enkoder
  if (mainContentPlaylist && mainContentPlaylist.assetId) {
    playlistLiveData.encoder = mainContentPlaylist.assetId.replace('CT', 'CH_') as Encoder;
  }
  // AssetId
  if (mainContentPlaylist && mainContentPlaylist.assetId) {
    playlistData.assetId = mainContentPlaylist.assetId;
  }
  // PlaylistId
  if (mainContentPlaylist && mainContentPlaylist.assetId) {
    playlistData.id = mainContentPlaylist.id;
  }

  // Nielsen: IDEC a Bonus maji zvlastni tvar, ktery musime podporovat pro stare i nove playlist
  // "pro filmy chodi IDEC s 11 cisly, pro epizody s 15, ale ne vždy to platí”
  const crossId = getCrossId({ type: idType, id });

  // docasne nechame zde, aby byla na prvni pohled videt logika - budeme brzy upravovat pro nove
  // playlisty, kde budou chodit uz hotove analyticke objekty
  const getNielsenVODTitle = (id: string) => {
    if (id.includes('ct24-')) {
      return id.toUpperCase();
    }
    if (useNewPlaylist) {
      // cely nazev vcetne pripadneho cisla epizody
      return mainContentPlaylist.title;
    }
    return mainContentPlaylist.gemius.NAZEV;
  };

  const getNielsenVODProgram = (id: string) => {
    if (id.includes('ct24-')) {
      return CT24_NIELSEN_PROGRAM;
    }
    if (useNewPlaylist) {
      const title = mainContentPlaylist.title.split('|')[0].trim();
      return title;
    }
    return mainContentPlaylist.title;
  };

  if (isVOD) {
    if (streamData.analytics) {
      playlistData.analytics = { ...streamData.analytics };
    } else {
      const lowerCaseId = id.toLowerCase();
      const contentId =
        getParamFromUrl(mainContentPlaylist.streamUrls.main, OLD_CDN_CONTENT_ID_PARAM_KEY) ||
        getParamFromUrl(mainContentPlaylist.streamUrls.main, NEW_CDN_CONTENT_ID_PARAM_KEY);
      const showTitle = getNielsenVODProgram(lowerCaseId);
      const title = getNielsenVODTitle(lowerCaseId);
      playlistData.analytics.nielsen = {
        ...playlistData.analytics.nielsen,
        assetid: `${CLIENT_ID}-${contentId}`,
        program: showTitle,
        title,
        length: Math.floor(Number(mainContentPlaylist.duration || '0')).toString(),
        airdate: '19700101 00:00:01', // konstanta
        isfullepisode: ['bonus', 'index'].includes(idType) ? 'n' : 'y',
        crossId1: crossId.toUpperCase(),
        type: 'content',
      };
      playlistData.analytics.gemius = {
        ...playlistData.analytics.gemius,
        programDuration: Number(mainContentPlaylist.duration || '0'),
        programId: id,
        programName: useNewPlaylist
          ? getNielsenVODTitle(lowerCaseId)
          : `${mainContentPlaylist.title} - ${mainContentPlaylist.gemius.NAZEV}`,
      };
      // TODO: pouze pripraveno, az bude chodit z playlistu, zatim objekt nepouzivame
      playlistData.analytics.gtm = {
        categories: undefined,
        channel: undefined,
        id,
        live: false,
        published: undefined,
        show: { id: showId, title: showTitle },
        title,
        version: undefined,
      };
    }
  }

  // playlistData.type muze byt "live" i v pripade, ze prijde id typu episode (napr. IDEC)
  const channelName =
    playlistData.type === 'live'
      ? encoderToChannelName({ id, idType, liveChannelFallback: mainContentPlaylist.assetId })
      : '';
  const czechChannelName = channelWithCzechCharacter(channelName);

  if (!isVOD) {
    if (streamData.analytics) {
      playlistData.analytics = { ...streamData.analytics };
    } else {
      playlistData.analytics.nielsen = {
        ...playlistData.analytics.nielsen,
        nol_c1: `p1,${getNielsenC1forLive(channelName)}`,
        assetid: `${CLIENT_ID}-live-${channelName}`,
        program: czechChannelName,
        title: czechChannelName,
        length: '86400', // konstanta pro live
        airdate: `${new Date().toLocaleDateString('sv').replaceAll('-', '')} 00:00:00`,
        isfullepisode: 'y',
        crossId1: '',
        type: 'content',
      };
      playlistData.analytics.gemius = {
        ...playlistData.analytics.gemius,
        // pro live je duration -1
        programDuration: -1,
        programId: channelName,
        programName: channelName,
      };
      playlistData.analytics.gtm = {
        categories: undefined,
        channel: id,
        id,
        live: true,
        published: undefined,
        show: {
          id: undefined,
          title: undefined,
        },
        title: '',
        version: undefined,
      };
      playlistData.analytics.adScript = {
        assetid: playlistData.analytics.nielsen.assetid,
        attribute: '1',
        type: 'content',
        program: playlistData.analytics.nielsen.program,
        title: playlistData.analytics.nielsen.title,
        crossId: playlistData.analytics.nielsen.crossId1,
        length: playlistData.analytics.nielsen.length,
        livestream: '0',
        channelId: '',
      };
    }
  }

  if (!initialUseNewPlaylist && playlistVodUri && shadowPlaylistReportEnabled) {
    void reportToShadowPlaylistService({ playlistVodUri, id, idType, playlistData });
  }

  return playlistData;
}

export function usePlaylist({ liveMode, skip, ...props }: PlaylistOptions): {
  data: PlaylistData | null;
  playlistError: PlaylistError | null;
  supportedDRMs?: DRM[];
} {
  const [playlistError, setPlaylistError] = useState<PlaylistError | null>(null);
  const [data, setData] = useState<PlaylistData | null>(null);
  const supportedDRMs = useSupportedDRMs();

  useEffect(() => {
    if (skip) {
      return;
    }
    if (!supportedDRMs) {
      return;
    }
    const fetchPlaylist = async () => {
      try {
        const playlistData = await getPlaylistData({
          supportedDRMs,
          liveMode,
          ...props,
        });
        setData(playlistData);
      } catch (error) {
        setPlaylistError(error as PlaylistError);
        log.error({ error, message: 'Error requesting playlist' });
      }
    };
    void fetchPlaylist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, supportedDRMs, liveMode]);

  return { playlistError, data, supportedDRMs };
}
