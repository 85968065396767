import {
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useForm as useReactHookForm,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn as UseFormReturnHookForm,
} from 'react-hook-form';
import { useEffect, useState } from 'react';

export interface RegisterExtraInfo {
  displayValidationStatus?: boolean;
  error?: FieldError;
}

// při použití funkce registration si do komponenty pošlu více informací
type UseFormRegister<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) => UseFormRegisterReturn<TFieldName> & RegisterExtraInfo;

// základní form hook obohacuji o asyncError - v zásadě odpověď z API
export interface UseFormReturn<TFieldValues extends FieldValues = FieldValues, TContext = any>
  extends Omit<UseFormReturnHookForm<TFieldValues, TContext>, 'register'> {
  asyncError?: unknown;
  // bohužel je to nutné brát z custom stavu, protože jinak při resetu formu se to smaže
  // je na to feature request https://github.com/react-hook-form/react-hook-form/issues/7464
  isSubmitSuccessful?: boolean;
  isSubmitting?: boolean;
  register: UseFormRegister<TFieldValues>;
}

// by default používáme mode all
// je nejméně výkonný :( ale kvůli UX zadání ho musíme používat
export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>({ mode = 'all', ...options }: UseFormProps<TFieldValues, TContext> = {}): UseFormReturn<
  TFieldValues,
  TContext
> {
  const response = useReactHookForm({ ...options, mode });

  const [asyncError, setAsyncError] = useState<unknown>(undefined);

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  // aby se mi stav nesmazal na reset
  useEffect(() => {
    if (response.formState.isSubmitSuccessful) {
      setIsSubmitSuccessful(true);
      response.reset();
    }
  }, [response.formState.isSubmitSuccessful, response]);

  return {
    ...response,
    handleSubmit: (...args) => {
      // před každým odeslání vyresetuji response z API
      setAsyncError(undefined);
      setIsSubmitSuccessful(false);
      setSubmitting(true);

      const callback = response.handleSubmit(...args);

      // přetížím si volání pokud je form zvalidovaný
      return async (event) => {
        try {
          await callback(event);
        } catch (error) {
          // pokud došlo k chybě, nastavím state chyby
          setAsyncError(error);
        } finally {
          setSubmitting(false);
        }
      };
    },
    // obohatím si vlastní data do register funkce
    register: (name, ...rest) => {
      const registred = response.register(name, ...rest);
      const { error: fieldError, isTouched } = response.getFieldState(name);
      // tohle je taková hrozně zajímavá věc, musím si na ně šáhnout, abych vyvolal rerender
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isSubmitted, touchedFields, errors } = response.formState;

      const displayValidationStatus = isTouched || isSubmitted;
      const error = displayValidationStatus ? fieldError : undefined;

      return {
        ...registred,
        displayValidationStatus,
        error,
      };
    },
    asyncError,
    isSubmitting,
    isSubmitSuccessful,
  };
}
