import classNames from 'classnames';
import React, { HTMLAttributes, memo } from 'react';
import { tooltipClassnames } from './Tooltip.css';

export interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  classNameTooltip?: string;
  text: string;
  visible?: boolean;
}

export const Tooltip = memo<TooltipProps>(
  ({ text, children, visible = true, className, classNameTooltip, ...rest }) => {
    return (
      <div className={classNames(tooltipClassnames.relative, className)} {...rest}>
        {children}
        {visible ? (
          <div className={classNames(tooltipClassnames.tooltip, classNameTooltip)}>{text}</div>
        ) : null}
      </div>
    );
  }
);
