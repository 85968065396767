import { Encoder } from '@czechtv/utils';
import {
  CachedAds,
  ExternalVastConfig,
  PlayerAdType,
  PlayerStream,
  PlayerStreamType,
} from '../../constants';
import { transformLabelingsToPrerolls } from '../ads';
import { getStreamDRMFromUrl } from '../drm';
import { shouldPlayDash } from '../shouldPlayDash';
import { PlaylistData } from './constants';

export const getOffsetFromStreamUrl = (
  url: string,
  key: 'startOffset' | 'endOffset'
): number | undefined => {
  const queryPart = url.split('?')[1];
  if (!queryPart) {
    return undefined;
  }
  const keyValueStrings = queryPart.split('&');
  const obj: Record<string, string> = {};
  keyValueStrings.forEach((param) => {
    const [key, value] = param.split('=');
    obj[key] = value;
  });
  const found = obj[key];
  if (found) {
    const asNumber = Number(found);
    if (!Number.isNaN(asNumber)) {
      return asNumber;
    }
  }
  return undefined;
};

export const getDurationFromStreamUrlOffsets = (url: string) => {
  const startOffset = getOffsetFromStreamUrl(url, 'startOffset');
  const endOffset = getOffsetFromStreamUrl(url, 'endOffset');
  if (startOffset && endOffset) {
    return endOffset - startOffset;
  }
  return undefined;
};

export const getBonusFromExternalId = (id: string | undefined) => {
  if (!id) {
    return undefined;
  }
  if (id.toUpperCase().includes('BO-')) {
    return id.toUpperCase().replace('BO-', '');
  }
  return undefined;
};

export const getCrossId = ({
  type,
  id,
}: {
  id: string;
  type: 'bonus' | 'index' | 'episode' | 'channel' | 'ct24' | 'externalLiveStreams';
}) => {
  switch (type) {
    case 'bonus': {
      if (!id.includes('BO-')) {
        return `BO-${id}`;
      }
      return id;
    }
    case 'episode': {
      if (id.length === 11) {
        // SRR TTT XXXXX
        return `${id.substring(0, 3)} ${id.substring(3, 6)} ${id.substring(6)}`;
      }
      if (id.length === 15) {
        // SRR TTT XXXXX/YYYY
        return `${id.substring(0, 3)} ${id.substring(3, 6)} ${id.substring(6, 11)}/${id.substring(
          11
        )}`;
      }
      return id;
    }
    default:
      return id;
  }
};

// ! pozor: existuje scenar, kdy je id typu "episode" - prehravame idec, ktery je premiera poradu,
// ktery zatim bezi pouze zive - divak se na nej dostane z TV programu
// player si zpocatku mysli, ze prehrava VOD a az po poptani playlistu se zmeni na live
export const encoderToChannelName = ({
  id,
  idType,
  liveChannelFallback,
}: {
  id: string;
  idType: 'bonus' | 'index' | 'episode' | 'channel' | 'ct24' | 'externalLiveStreams' | undefined;
  liveChannelFallback?: string;
}) => {
  if (idType === 'episode') {
    return liveChannelFallback || id;
  }
  const encoders = Object.values(Encoder) as string[];
  if (encoders.includes(id)) {
    return id.replace('CH_', 'CT');
  }
  return id;
};

export const channelWithCzechCharacter = (id: string) => {
  if (id.substring(0).includes('C')) {
    return id.replace('C', 'Č');
  }
  return id;
};

interface GetRawQueueConfig {
  cachedPostrolls: CachedAds;
  cachedPrerolls: CachedAds;
  disableAds?: boolean;
  useNewAds: boolean;
}

export const getRawQueue = (data: PlaylistData, config: GetRawQueueConfig): PlayerStream[] => {
  const { useNewAds, cachedPostrolls, cachedPrerolls, disableAds } = config;
  const timestamp = Date.now().toString();

  const isDash = shouldPlayDash();
  const streams = data.streamUrls.map((streamUrl) => ({
    ...('audioDescription' in streamUrl ? { audioDescription: streamUrl.audioDescription } : {}),
    ...('timeshift' in streamUrl ? { timeshift: streamUrl.timeshift } : {}),
    ...('promoReport' in streamUrl ? { promoReport: streamUrl.promoReport } : {}),
    main: streamUrl.main,
    isLabeling: streamUrl.isLabeling,
  }));

  const { drm, vast } = data;

  const adsConfig = vast;

  const contentStreams = streams.filter((url) => !url.isLabeling);
  const labelingStreams = streams.filter((url) => url.isLabeling);

  const getAds = (type: 'preRoll' | 'postRoll') => {
    if (!useNewAds && !disableAds) {
      const result: PlayerStream[] = [
        ...(adsConfig?.[type]?.length
          ? adsConfig[type]?.map((url, index) => {
              const adStream: PlayerStream = {
                id: timestamp,
                url,
                index,
                type: PlayerStreamType.adSource,
                category: PlayerAdType[type],
              };
              return adStream;
            }) || []
          : []),
      ];
      return result;
    }
    if (
      useNewAds &&
      type === 'preRoll' &&
      cachedPrerolls.parsed &&
      cachedPrerolls.wasParsed &&
      cachedPrerolls.source &&
      !disableAds
    ) {
      // na zacatku fronty budou uz vsechny prerolly poptane nebo pokud nic rs nevrati,
      // bude to prazdne pole
      return cachedPrerolls.parsed;
    }
    if (
      useNewAds &&
      type === 'postRoll' &&
      cachedPostrolls.parsed &&
      cachedPostrolls.source &&
      !disableAds
    ) {
      if (cachedPostrolls.wasParsed) {
        return cachedPostrolls.parsed;
      }
      const placeholder: PlayerStream = {
        id: timestamp,
        url: '',
        index: 0,
        type: PlayerStreamType.adPlaceholder,
        category: PlayerAdType.postRoll,
      };
      return [placeholder];
    }
    return [];
  };

  const prerolls = getAds('preRoll');
  const postrolls = getAds('postRoll');

  const result = [
    // Prerolly
    ...prerolls,
    ...(labelingStreams.length
      ? transformLabelingsToPrerolls(labelingStreams, prerolls.length || 0).map(
          ({ dashUrl, hlsUrl, ...rest }, index) => {
            const url = isDash ? dashUrl : hlsUrl;
            const adStream: PlayerStream = {
              id: timestamp,
              url,
              index: index + prerolls.length,
              type: PlayerStreamType.ad,
              category: PlayerAdType.preRoll,
              meta: { ...rest },
            };
            return adStream;
          }
        )
      : []),
    // Hlavni streamy
    ...contentStreams.map((stream, index) => {
      const isLivePlayer = data.type === 'live';
      // vicenasobny playlist jsou zpravidla jednotlive indexy
      const isIndexLike = contentStreams.length > 1;
      const vodCategory = isIndexLike ? 'index' : 'full';

      const mainStream: PlayerStream = {
        id: timestamp,
        url: stream.main,
        ...('audioDescription' in stream ? { audioDescription: stream.audioDescription } : {}),
        ...('timeshift' in stream ? { timeshift: stream.timeshift } : {}),
        ...('promoReport' in stream ? { promoReport: stream.promoReport } : {}),
        index: index + prerolls.length + labelingStreams.length,
        type: PlayerStreamType.main,
        category: isLivePlayer ? 'live' : vodCategory,
        drm: getStreamDRMFromUrl(stream.main) ? drm : undefined,
        ...(isIndexLike
          ? {
              startOffset: getOffsetFromStreamUrl(stream.main, 'startOffset'),
            }
          : {}),
      };

      return mainStream;
    }),
    // Postrolly
    ...postrolls.map((preroll, index) => ({
      ...preroll,
      index: index + prerolls.length + labelingStreams.length + contentStreams.length,
    })),
  ];
  return result;
};

export const getSubtitleDisplayName = (code: string) => {
  switch (code) {
    case 'nar':
      return 'Popisné titulky';
    case 'mul':
      return 'Vícejazyčné';
    case 'ces':
      // v zásadě české titulku u českého obsahu jsou CC - Closed Captions
      // používáme výraz 'Skryté titulky' - uvidíme, zda se to tak opravdu bude řešit
      // a zda se bude rozpoznávat původní znění vs. titulky
      return 'Skryté titulky';
    // TODO: později doplníme tabulku, až se upřesní
    default:
      return code;
  }
};

export const getIsLabeling = (type: string) => !['VOD', 'LIVE'].includes(type);

export const encodeMultipleIds = (originalId: string) => {
  const ids = originalId.split(',');
  if (ids.length === 1) {
    return originalId;
  }
  const arrayString = `[${ids.join(',')}]`;
  const encoded = encodeURIComponent(arrayString);
  return encoded;
};

interface CreateLegacyAdsDataParams {
  additionalAdKeywords?: string;
  id: string;
  origin: string;
  showId?: string;
  streamType: 'dash' | 'hls';
  title?: string;
  vastConfig: ExternalVastConfig;
}

// napodobeni puvodniho bustu pouzivaneho na Sportu :)
function generateRandomBust() {
  const min = 100000000;
  const max = 999999999;
  const bust = Math.floor(Math.random() * (max - min + 1) + min);
  return `${bust}`;
}

const getSpecificHrefFormatForOrigin = (data: CreateLegacyAdsDataParams) => {
  const { additionalAdKeywords, id, showId, streamType, origin, title } = data;
  if (origin === 'ct4') {
    return encodeURIComponent(
      `SIDP:${showId || ''} IDEC:${id} front-sport VOD ${streamType} ${additionalAdKeywords || ''}`.trim()
    );
  }
  // SIDP:<sidp> IDEC:<idec> IVYSILANI VOD <'dash' | 'flash'> <title> <additionalAdKeywords> napr. DETSKYPORAD
  return encodeURIComponent(
    `SIDP:${showId || ''} IDEC:${id} IVYSILANI VOD ${streamType} ${title || ''} ${additionalAdKeywords || ''}`.trim()
  );
};

export const createLegacyAdsFromExternalSource = (data: CreateLegacyAdsDataParams) => {
  const { vastConfig } = data;
  const { adOceanPostrollId1, adOceanPrerollId1, adOceanPrerollId2 } = vastConfig;
  const oneBustForAll = generateRandomBust();
  const createAdOceanUrl = (spaceId: string) => {
    const href = getSpecificHrefFormatForOrigin(data);
    return `https://cz.adocean.pl/ad.xml?id=${spaceId}&href=${href}&bust=${oneBustForAll}`;
  };
  return {
    preRoll: [createAdOceanUrl(adOceanPrerollId1), createAdOceanUrl(adOceanPrerollId2)],
    postRoll: [createAdOceanUrl(adOceanPostrollId1)],
  };
};
