import { useEffect, useState } from 'react';
import { storageAvailable } from '@czechtv/utils';

export interface VideoInProgress {
  duration: number;
  id?: string;
  indexId?: string;
  progressPercentage: number;
  progressSec: number;
  // versionId: string;
  viewDate: Date;
}

export interface UseVideoInProgressProps {
  bypassUserSettings: boolean;
  duration?: number | null;
  product: string;
  videoId?: string | null;
}

export const VIDEOS_IN_PROGRESS_LOCAL_STORAGE_CACHE_KEY = `videos-in-progress`;
export const MAX_CACHE_VIDEO_COUNT = 100;
// pocet sekund do konce videa, kdy povazujeme video za dokoukane
export const RESET_PROGRESS_THRESHOLD_SECONDS = 120;

export const useVideoInProgress = ({
  product,
  bypassUserSettings,
  videoId,
  duration,
}: UseVideoInProgressProps) => {
  const [progress, setProgress] = useState<undefined | VideoInProgress>(undefined);
  useEffect(() => {
    if (!storageAvailable('localStorage')) {
      return;
    }
    const videosInProgress = new Map<string | null | undefined, VideoInProgress>(
      JSON.parse(
        localStorage.getItem(`${product}/${VIDEOS_IN_PROGRESS_LOCAL_STORAGE_CACHE_KEY}`) || '[]'
      )
    );

    // pokusime se nacist progress k danemu videu
    setProgress(videosInProgress.get(videoId) as VideoInProgress);
  }, [product, videoId]);

  if (typeof window === 'undefined' || !storageAvailable('localStorage') || bypassUserSettings) {
    return undefined;
  }

  /**
   * pokud:
   * - progress nemame
   * - video je dokoukane (skoro na konci)
   * - delka videa se zmenila (+- 3s)
   * pak nic nevracime
   */
  const similarContentLength =
    progress &&
    duration &&
    (duration >= progress.duration + 3 || duration <= progress.duration - 3);
  if (
    !progress?.progressSec ||
    (progress.progressSec &&
      duration &&
      duration - progress.progressSec <= RESET_PROGRESS_THRESHOLD_SECONDS) ||
    similarContentLength
  ) {
    return undefined;
  }
  // video je skutecne rozkoukane, vratime progress
  return progress.progressSec;
};
