import { useEffect, useState } from 'react';
import { isIFrame } from './isIFrame';

const DEFAULT_BASE_URL = 'https://www.ceskatelevize.cz';

interface ReportErrorProps {
  baseUrl?: string;
  iFrameSource?: URLSearchParams;
  parentUrl?: string;
}

const playerSource = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  if (!isIFrame()) {
    return window?.top?.location.href;
  }
  return document.referrer;
};

const iframeSource = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return window?.location.href;
};

export const getReportErrorUrl = ({ baseUrl, parentUrl, iFrameSource }: ReportErrorProps) => {
  const source = parentUrl || playerSource();
  const base = baseUrl || DEFAULT_BASE_URL;
  const urlParams = iFrameSource || new URLSearchParams(iframeSource());
  const sidp = urlParams.get('sidp');
  const idec = urlParams.get('IDEC');
  const videoInfo = {
    sidp,
    idec,
    source,
    base,
  };

  const errorReportUrlParams = new URLSearchParams();
  errorReportUrlParams.set('video-info', JSON.stringify(videoInfo));

  return `${base}/nahlasit-chybu/?${errorReportUrlParams.toString()}`;
};

// tohle možná vypadá jako blbost, ale narazil jsem na nějaký divný bug,
// pokud jsem používal getReportErrorUrl napřímo nebo jako default statu, tak state se sice
// updatoval ale neupdatoval se html atribute href.
export const useReportErrorUrl = ({ baseUrl, parentUrl }: ReportErrorProps) => {
  const base = baseUrl || DEFAULT_BASE_URL;
  const [source, setSource] = useState(`${base}/nahlasit-chybu/`);
  useEffect(() => {
    setSource(getReportErrorUrl({ baseUrl, parentUrl }));
  }, [baseUrl, parentUrl]);

  return source;
};

export const supportWebsite = 'https://www.ceskatelevize.cz/faq/ivysilani/223/';
