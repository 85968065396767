import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { Spinner } from '@czechtv/components';

const useStyles = createUseStyles({
  spinnerWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spinner: {
    width: 40,
    height: 40,
  },
});

const VODBufferingOverlay = () => {
  const classes = useStyles();

  return (
    <div className={classes.spinnerWrapper}>
      <Spinner className={classes.spinner} />
    </div>
  );
};

export default memo(VODBufferingOverlay);
