/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { AnalyticsSubscriber } from '../common';
import { AnalyticsPlayerContext } from '../../PlayerEvent';
import { GemiusEvents } from './gemiusTypes';
import { gemiusSdkHandler } from './gemiusSdkHandler';

interface Options {
  currentDomain?: string;
  resolution?: string;
  volume?: number;
}

interface NewProgramOptions {
  programDuration: number;
  programName: string;
  programType: string;
  typology?: string;
}

interface GemiusClient {
  adEvent(programId: string, adId: string, offset: number, event: string): void;
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (playerId: string, gemiusId: string, options?: Options): GemiusClient;
  newAd(id: string, settings: Record<string, any>): void;
  newProgram(id: string, settings: NewProgramOptions): void;
  programEvent(programId: string, offset: number, eventName: string): void;
  setVideoObject(element: HTMLElement): void;
}

declare global {
  interface Window {
    GemiusPlayer: InstanceType<GemiusClient>;
  }
}

interface GemiusSubscriberConfig {
  gemiusId: string;
  id: string;
}

const getDataFromContext = (context: AnalyticsPlayerContext): { offset: number } => {
  const offset = context.type === 'VOD' ? context.position : 0;
  return { offset };
};

export const gemiusSubscriber = ({ id, gemiusId }: GemiusSubscriberConfig): AnalyticsSubscriber => {
  let client: GemiusClient;
  // na ssr žádné eventy nelogujeme
  if (typeof window === 'undefined') {
    return {
      name: 'ssr',
      callback: () => {},
    };
  }

  const getClient = () => {
    if (client) {
      return client;
    }
    gemiusSdkHandler();

    client = new window.GemiusPlayer(id, gemiusId);

    return client;
  };

  return {
    name: 'gemius',
    callback: (event) => {
      switch (event.type) {
        case 'PlayerLoad': {
          const { programDuration, programName, programType, typology, programId } =
            event.context.gemius;
          getClient().newProgram(programId, {
            programDuration,
            programType,
            programName,
            typology,
          });
          break;
        }
        case 'PlayerAdLoad': {
          const { id, type, title, timeLength } = event.context.ad;
          getClient().newAd(event.context.ad?.id, {
            adID: id,
            adName: title,
            adDuration: timeLength,
            adType: type,
          });
          break;
        }
        case 'PlayerHTMLElement':
          // nastavíme html objekt videa
          getClient().setVideoObject(event.data);
          break;
        case 'PlayerResume':
        case 'PlayerPlay': {
          const { offset } = getDataFromContext(event.context);
          const { programId } = event.context.gemius;
          getClient().programEvent(programId, offset, GemiusEvents.PLAY);
          break;
        }
        case 'PlayerPause': {
          const { offset } = getDataFromContext(event.context);
          const { programId } = event.context.gemius;
          getClient().programEvent(programId, offset, GemiusEvents.PAUSE);
          break;
        }
        case 'PlayerBuffering': {
          const { offset } = getDataFromContext(event.context);
          const { programId } = event.context.gemius;
          getClient().programEvent(programId, offset, GemiusEvents.BUFFER);
          break;
        }
        case 'PlayerEnd': {
          if (event.context.type === 'VOD') {
            const { offset } = getDataFromContext(event.context);
            const { programId } = event.context.gemius;
            getClient().programEvent(programId, offset, GemiusEvents.COMPLETE);
          }
          break;
        }
        case 'PlayerSeekBack':
        case 'PlayerSeekForward':
        case 'Player10sBack':
        case 'Player10sForward':
        case 'PlayerTimeshift10sBack':
        case 'PlayerTimeshift10sForward':
        case 'PlayerTimeshiftSeekBack':
        case 'PlayerTimeshiftSeekForward':
        case 'PlayerTimeshiftStart':
        case 'PlayerTimeshiftBackToLive':
        case 'PlayerTimeshiftFromBeginning': {
          const { offset } = getDataFromContext(event.context);
          const { programId } = event.context.gemius;
          getClient().programEvent(programId, offset, GemiusEvents.SEEK);
          break;
        }
        case 'PlayerUnload': {
          const { offset } = getDataFromContext(event.context);
          const { programId } = event.context.gemius;
          getClient().programEvent(programId, offset, GemiusEvents.CLOSE);
          break;
        }
        case 'PlayerAdPlay': {
          const adId = event.context.ad?.id;
          const { programId } = event.context.gemius;
          getClient().adEvent(programId, adId, event.context.ad.timePosition, GemiusEvents.PLAY);
          break;
        }
        case 'PlayerAdBuffering': {
          const adId = event.context.ad?.id;
          const { programId } = event.context.gemius;
          getClient().adEvent(programId, adId, event.context.ad.timePosition, GemiusEvents.BUFFER);
          break;
        }
        case 'PlayerAdSkip': {
          const adId = event.context.ad?.id;
          const { programId } = event.context.gemius;
          getClient().adEvent(programId, adId, event.context.ad.timePosition, GemiusEvents.SKIP);
          break;
        }
        case 'PlayerAdEnd': {
          const adId = event.context.ad?.id;
          const { programId } = event.context.gemius;
          getClient().adEvent(
            programId,
            adId,
            event.context.ad.timePosition,
            GemiusEvents.COMPLETE
          );
          break;
        }
        case 'PlayerAdPaused': {
          const adId = event.context.ad?.id;
          const { programId } = event.context.gemius;
          getClient().adEvent(programId, adId, event.context.ad.timePosition, GemiusEvents.PAUSE);
          break;
        }
        case 'PlayerAdClose': {
          const adId = event.context.ad?.id;
          const { programId } = event.context.gemius;
          getClient().adEvent(programId, adId, event.context.ad.timePosition, GemiusEvents.CLOSE);
          break;
        }
        case 'PlayerAdAllCompleted':
          break;
        case 'PlayerAdClick':
          break;
        case 'PlayerAdProgress':
          break;
        default:
      }
    },
  };
};
