import { formatMessage } from '../../utils/formatMessage';

const messages = {
  playerControlsItemPlay: {
    id: 'PlayerControlsItem.play',
    defaultMessage: 'Přehrát',
    description: 'Ovládání: PlayerControlsItem.PLAY',
  },
  playerControlsItemPause: {
    id: 'PlayerControlsItem.pause',
    defaultMessage: 'Pauza',
    description: 'Ovládání: PlayerControlsItem.PAUSE',
  },
  playerControlsItemReplay: {
    id: 'PlayerControlsItem.replay',
    defaultMessage: 'Přehrát znovu',
    description: 'Ovládání: PlayerControlsItem.REPLAY',
  },
  playerControlsItemSound: {
    id: 'PlayerControlsItem.sound',
    defaultMessage: 'Zvuk',
    description: 'Ovládání: PlayerControlsItem.SOUND',
  },
  playerControlsItemNoVideo: {
    id: 'PlayerControlsItem.noVideo',
    defaultMessage: 'Pouze zvuk',
    description: 'Ovládání: PlayerControlsItem.NO_VIDEO',
  },
  playerControlsItemInfo: {
    id: 'PlayerControlsItem.info',
    defaultMessage: 'Informace',
    description: 'Ovládání: PlayerControlsItem.INFO',
  },
  playerControlsItemCast: {
    id: 'PlayerControlsItem.cast',
    defaultMessage: 'Přehrát na TV',
    description: 'Ovládání: PlayerControlsItem.CAST',
  },
  playerControlsItemSettings: {
    id: 'PlayerControlsItem.settings',
    defaultMessage: 'Nastavení',
    description: 'Ovládání: PlayerControlsItem.SETTINGS',
  },
  playerControlsItemFullScreen: {
    id: 'PlayerControlsItem.fullscreen',
    defaultMessage: 'Přepnout režim celé obrazovky',
    description: 'Ovládání: PlayerControlsItem.FULLSCREEN',
  },
  playerControlsItemExitFullScreen: {
    id: 'PlayerControlsItem.exitfullscreen',
    defaultMessage: 'Ukončit režim celé obrazovky (f)',
    description: 'Ovládání: PlayerControlsItem.EXIT_FULLSCREEN',
  },
  playerControlsItemSeekForward: {
    id: 'PlayerControlsItem.seekForward',
    defaultMessage: 'Posun dopředu',
    description: 'Ovládání: PlayerControlsItem.SEEK_FORWARD',
  },
  playerControlsItemSeekBack: {
    id: 'PlayerControlsItem.seekBack',
    defaultMessage: 'Posun dozadu',
    description: 'Ovládání: PlayerControlsItem.SEEK_BACK',
  },
  playerControlsItemTimeshiftVideoStart: {
    id: 'PlayerControlsItem.timeshiftVideoStart',
    defaultMessage: 'Na začátek pořadu',
    description: 'Ovládání: PlayerControlsItem.TIMESHIFT_VIDEO_START',
  },
  playerControlsItemUnmute: {
    id: 'PlayerControlsItem.playerControlsItemUnmute',
    defaultMessage: 'Zrušit ztlumení zvuku',
    description: 'Ovládání: PlayerControlsItem.TIMESHIFT_VIDEO_START',
  },
};

export enum PlayerControlsItem {
  PLAY,
  PAUSE,
  REPLAY,
  SOUND,
  NO_VIDEO,
  INFO,
  CAST,
  SETTINGS,
  FULLSCREEN,
  EXIT_FULLSCREEN,
  SEEK_FORWARD,
  SEEK_BACK,
  TIMESHIFT_VIDEO_START,
  UNMUTE,
}

export const VODPlayerControlsItemsDefinition: {
  [key in PlayerControlsItem]: string;
} = {
  [PlayerControlsItem.PLAY]: formatMessage(messages.playerControlsItemPlay),
  [PlayerControlsItem.PAUSE]: formatMessage(messages.playerControlsItemPause),
  [PlayerControlsItem.REPLAY]: formatMessage(messages.playerControlsItemReplay),
  [PlayerControlsItem.SOUND]: formatMessage(messages.playerControlsItemSound),
  [PlayerControlsItem.NO_VIDEO]: formatMessage(messages.playerControlsItemNoVideo),
  [PlayerControlsItem.INFO]: formatMessage(messages.playerControlsItemInfo),
  [PlayerControlsItem.CAST]: formatMessage(messages.playerControlsItemCast),
  [PlayerControlsItem.SETTINGS]: formatMessage(messages.playerControlsItemSettings),
  [PlayerControlsItem.FULLSCREEN]: formatMessage(messages.playerControlsItemFullScreen),
  [PlayerControlsItem.UNMUTE]: formatMessage(messages.playerControlsItemUnmute),
  [PlayerControlsItem.EXIT_FULLSCREEN]: formatMessage(messages.playerControlsItemExitFullScreen),
  [PlayerControlsItem.SEEK_FORWARD]: formatMessage(messages.playerControlsItemSeekForward),
  [PlayerControlsItem.SEEK_BACK]: formatMessage(messages.playerControlsItemSeekBack),
  [PlayerControlsItem.TIMESHIFT_VIDEO_START]: formatMessage(
    messages.playerControlsItemTimeshiftVideoStart
  ),
};
