import { UrlObject } from 'url';

export enum Page {
  ACCOUNT = '/profil/',
  CATEGORY = '/ivysilani/kategorie/[category]/',
  EPISODE = '/porady/[showIdWithCode]/[id]/',
  FAVORITES = '/ivysilani/oblibene/',
  HOMEPAGE = '/ivysilani/',
  HOMEPAGE_BLOCK = '/ivysilani/vypis/[id]/',
  INDEX = '/porady/[showIdWithCode]/[id]/cast/[indexId]/',
  LIVE = '/zive/',
  LIVESTREAM = '/zive/[channelOrEncoder]/',
  LOGIN = '/profil/prihlasit/',
  LOGIN_PROCESSOR = '/profil/prihlaseni/',
  LOGOUT = '/profil/odhlasit/',
  LOGOUT_PROCESSOR = '/profil/odhlaseni/',
  RECENTLY_WATCHED = '/ivysilani/rozkoukane/',
  REGISTRATION = '/registrace/',
  REPORT_ERROR = '/nahlasit-chybu/',
  SEARCH = '/ivysilani/hledani/',
  SHOW = '/porady/[showIdWithCode]/',
  SUBCATEGORY = '/ivysilani/kategorie/[category]/[subCategory]/',
  TV_PROGRAM = '/tv-program/',
  VIDEO_BONUS = '/porady/[showIdWithCode]/bonus/',
  VIDEO_BONUS_DETAIL = '/porady/[showIdWithCode]/bonus/[bonusId]/',
}

export interface BasePageParam<P extends string> {
  page: P;
}

export interface WebsiteBasePageParams extends BasePageParam<Page> {}

export interface HomepageParams extends WebsiteBasePageParams {
  page: Page.HOMEPAGE;
}

export interface ReportErrorPageParams extends WebsiteBasePageParams {
  page: Page.REPORT_ERROR;
}

export interface HomepageBlockParams extends WebsiteBasePageParams {
  id: string;
  page: Page.HOMEPAGE_BLOCK;
}

export interface PlayerPageParams extends WebsiteBasePageParams {
  id: string;
  page: Page.EPISODE;
  showIdWithCode: string;
  t?: number;
}

export interface IndexPageParams extends Omit<PlayerPageParams, 'page'> {
  indexId: string;
  page: Page.INDEX;
}

export interface ShowPageParams extends WebsiteBasePageParams {
  interview?: string;
  page: Page.SHOW;
  showIdWithCode: string;
}

export interface SearchPageParams extends WebsiteBasePageParams {
  keyword?: string;
  onlyPlayable?: boolean;
  page: Page.SEARCH;
}

export interface TvProgramPageParams extends WebsiteBasePageParams {
  date: string;
  page: Page.TV_PROGRAM;
}

export interface LivestreamPageParams extends WebsiteBasePageParams {
  channelOrEncoder: string;
  page: Page.LIVESTREAM;
}

export interface LivePageParams extends WebsiteBasePageParams {
  page: Page.LIVE;
}

// TODO: Doplnit typy
export interface CategoryPageParams extends WebsiteBasePageParams {
  category: string;
  page: Page.CATEGORY;
  sort?: string;
}

export interface SubCategoryPageParams extends WebsiteBasePageParams {
  category: string;
  page: Page.SUBCATEGORY;
  sort?: string;
  subCategory: string;
}

export interface VideoBonusPageParams extends WebsiteBasePageParams {
  page: Page.VIDEO_BONUS;
  showIdWithCode: string;
}

export interface VideoBonusDetailParams extends WebsiteBasePageParams {
  bonusId: string;
  page: Page.VIDEO_BONUS_DETAIL;
  showIdWithCode: string;
}

export interface LoginPageParams extends WebsiteBasePageParams {
  page: Page.LOGIN;
  redirectTo?: string;
}

export interface LogoutPageParams extends WebsiteBasePageParams {
  page: Page.LOGOUT;
  redirectTo?: string;
}

export type PageParams =
  | HomepageParams
  | PlayerPageParams
  | SearchPageParams
  | TvProgramPageParams
  | LivestreamPageParams
  | ShowPageParams
  | CategoryPageParams
  | SubCategoryPageParams
  | HomepageBlockParams
  | ReportErrorPageParams
  | LivePageParams
  | VideoBonusPageParams
  | VideoBonusDetailParams
  | IndexPageParams
  | LoginPageParams
  | LogoutPageParams
  | { page: Page.FAVORITES }
  | { page: Page.REGISTRATION }
  | { page: Page.LOGOUT_PROCESSOR }
  | { page: Page.ACCOUNT }
  | { page: Page.RECENTLY_WATCHED }
  | { page: Page.LOGIN_PROCESSOR };

export function buildUrl<P extends BasePageParam<string>>(pageParams: P): string {
  const { page, ...params } = pageParams;
  let pageWithoutSlugs: string = page;

  if (!params) {
    return page;
  }

  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      Array.from(value).forEach((_value) => {
        searchParams.append(`${key}[]`, `${_value as string | number}`);
      });
    } else {
      const slugPlaceholder = `/[${key}]`;
      const isSlug = pageWithoutSlugs.includes(slugPlaceholder);
      if (isSlug) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        pageWithoutSlugs = pageWithoutSlugs.replace(slugPlaceholder, `/${value}`);
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
        searchParams.set(key, `${value}`);
      }
    }
  });

  let url: string = pageWithoutSlugs;

  if (Array.from(searchParams.keys()).length) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url += `?${searchParams}`;
  }
  return url;
}

export const buildWebsiteUrl = (pageParams: PageParams) => buildUrl(pageParams);

export interface LinkProps {
  as: string;
  href: UrlObject | string;
}

export function buildLinkProps<P extends BasePageParam<string>>(
  pageParams: P,
  query = {}
): LinkProps {
  return {
    href: { pathname: pageParams.page, query },
    as: buildUrl(pageParams),
  };
}

export const buildWebsiteLinkProps = (pageParams: PageParams, query = {}) =>
  buildLinkProps(pageParams, query);
