import React from 'react';
import { useContextWithProvider } from '@czechtv/components';
import { UseFormReturn } from '../../hooks/useForm';

const HookFormContext = React.createContext<UseFormReturn | undefined>(undefined);

export const useFormContext = () => useContextWithProvider(HookFormContext);

// jde o obdobu provideru z react hook formu, používáme ale přetížené typování, proto
// musíme mít vlastní
// TODO: Je otazné, jestli se to přetěžování vyplácí. Díky němu se komponenty lehčejí
// používají pro náš UC a naše komponenty inputů nejsou závislé na frameworku

// FormProvider se automaticky vkládá do všech komponent Form
export function FormProvider<Props extends UseFormReturn>(props: React.PropsWithChildren<Props>) {
  const { children, ...data } = props;
  return <HookFormContext.Provider value={data}>{children}</HookFormContext.Provider>;
}
