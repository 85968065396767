export const usesNativeFullscreen = (): boolean => {
  const userAgent = typeof navigator === 'undefined' ? undefined : navigator.userAgent;
  if (
    !userAgent ||
    userAgent.match(/(iPhone|iPod)/g) ||
    // na iPadu v Safari pouzivame nas custom fullscreen
    (userAgent.match(/(iPad)/g) && !userAgent.match(/(Safari)/g))
  ) {
    return true;
  }
  return false;
};
