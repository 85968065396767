import React, { useCallback } from 'react';
import { usePlayerDynamicImport } from '../../../../Providers/Setup/usePlayerSetup';
import { FinishedOverlayProps } from './FinishedOverlay';

interface VODFinishedOverlayProps extends FinishedOverlayProps {}

const VODFinishedOverlay = (props: VODFinishedOverlayProps) => {
  const apolloFinishedOverlayImport = useCallback(() => import('./ApolloFinishedOverlay'), []);
  const ApolloFinishedOverlay = usePlayerDynamicImport(apolloFinishedOverlayImport);

  return <ApolloFinishedOverlay {...props} />;
};

export default VODFinishedOverlay;
