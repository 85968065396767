import React, { memo } from 'react';
import { IconHeadphones } from '@czechtv/icons';
import GradientOverlay from '../GradientOverlay/GradientOverlay';
import { usePlayerSetup } from '../../../../Providers/Setup/usePlayerSetup';
import { PlayerVariantEnum } from '../../../../constants';
import { audioOnlyOverlayClassnames } from './AudioOnlyOverlay.css';

interface AudioOnlyOverlayProps {
  previewImage?: string;
}

export const AudioOnlyOverlay = memo(({ previewImage }: AudioOnlyOverlayProps) => {
  const { playerVariant } = usePlayerSetup();

  if (previewImage && playerVariant === PlayerVariantEnum.VOD) {
    return (
      <div
        className={audioOnlyOverlayClassnames.backgroundImageOverlay}
        style={{ backgroundImage: previewImage && `url('${previewImage}')` }}
      />
    );
  }

  return (
    <GradientOverlay>
      <div className={audioOnlyOverlayClassnames.icon}>
        <IconHeadphones />
      </div>
    </GradientOverlay>
  );
});
