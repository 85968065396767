import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { formatMessage } from '../../../../utils/formatMessage';
import Labeling from '../Labeling';
import { VideoTitle } from '../VideoTitle/VideoTitle';
import { VideoMeta } from '../../../../components/PlayerPreview/PlayerPreview';
import { PlayerClient } from '../../../../Providers/Client';
import PlayerClientState from '../../../../Providers/Client/state';
import { ShareDialog } from '../ShareDialog/ShareDialog';
import { videoHeaderClassnames } from './VideoHeader.css';
export interface VideoHeaderProps {
  hbbtvStreamingActiveDevice?: boolean;
  id?: string;
  indexTitle?: string | null;
  meta: VideoMeta;
  onIndexListVisible?: () => void;
  onInteraction?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  playerClient?: PlayerClient;
  showSimpleVideoHeader?: boolean;
  visible: boolean;
}
interface ShouldShowHeaderProps {
  ageRestriction?: string | null;
  hasIndexes?: boolean | null;
  showTitle?: string | null;
  videoTitle?: string | null;
}

export const shouldShowDivider = ({
  ageRestriction,
  showTitle,
  videoTitle,
  hasIndexes,
}: ShouldShowHeaderProps) => {
  // pokud není age restriction nezobrazime divider
  if (!ageRestriction) {
    return false;
  }
  // pokud mame název videa, pořadu nebo indexy, zobrazíme divider
  if (showTitle || videoTitle || hasIndexes) {
    return true;
  }
  // pokud máme age restriction ale žádný nadpis, nezobrazíme divider
  return false;
};

export const VideoHeader = memo(
  ({
    indexTitle,
    visible,
    onIndexListVisible = () => {},
    meta,
    showSimpleVideoHeader,
    hbbtvStreamingActiveDevice,
    playerClient,
    id,
    onMouseEnter = () => {},
    onMouseLeave,
    onInteraction = () => {},
  }: VideoHeaderProps) => {
    // TODO: VECKO-5256 - Non MVP, mělo by bý vypnutelné přes config
    // const analytics = useAnalytics();

    const [isShareDialogVisible, setShareDialogVisbility] = useState(false);
    const isTouch = useRef(false);
    const [isModalTrapped, setModalTrapped] = useState(false);

    const { indexes, title, showTitle, ageRestriction } = meta;

    useEffect(() => {
      const focusTrap = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setModalTrapped(false);
          setShareDialogVisbility(false);
        }
        if (!isModalTrapped && e.key === 'Tab') {
          setModalTrapped(true);
        }
      };
      document.addEventListener('keydown', focusTrap);
      return () => {
        document.removeEventListener('keydown', focusTrap);
      };
    }, [isModalTrapped, setShareDialogVisbility]);

    // pokud se skryje hlavička, skryje se i share okno
    useEffect(() => {
      if (!visible) {
        setShareDialogVisbility(false);
      }
    }, [visible]);

    useEffect(() => {
      if (!playerClient) {
        return () => {};
      }
      // pokud video hraje a uživatel si zobrazí share dialog
      // zastavíme video a po zavření dialogu ho zase spustíme
      if (isShareDialogVisible && playerClient.getState() === PlayerClientState.PLAYING) {
        playerClient.pause();
        return () => {
          playerClient.play();
        };
      }
      return () => {};
    }, [isShareDialogVisible, playerClient]);

    // TODO: VECKO-5256 - Non MVP, mělo by bý vypnutelné přes config*/}
    /* const onShareButtonClick = useCallback(() => {
    // Event posleme pouze pokud je Share dialog zavreny
    if (!isShareDialogVisible) {
      analytics.trigger({ type: 'PlayerShare' });
      setShareDialogVisbility(true);
    }
  }, [analytics, isShareDialogVisible]); */

    const onShareDialogClose = useCallback(() => setShareDialogVisbility(false), []);

    const indexCount = indexes.length || 0;
    const hasIndexes = indexCount > 0;

    // v případě ne-embedded playeru skryjeme v hlavičce videa název videa a pořadu

    const showTitleDisplay = showSimpleVideoHeader ? undefined : showTitle;
    const videoTitleDisplay = showSimpleVideoHeader ? undefined : title;

    const hasIndexTitle = indexCount > 0;
    const hasShowTitle = !!showTitleDisplay;
    const hasVideoTitle = !!videoTitleDisplay;

    const showDivider = shouldShowDivider({
      ageRestriction,
      showTitle: showTitleDisplay,
      videoTitle: videoTitleDisplay,
      hasIndexes,
    });

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(videoHeaderClassnames.container, { visible })}
          onClick={(ev) => {
            // nechceme kliky posílat do overlaye
            ev.stopPropagation();
            // interakce s hlavičkou prodlužuje dobu otevření controls
            onInteraction();
          }}
          onMouseEnter={() => {
            // event mouse enter posíláme pouze pokud ho nevyvolal touch
            // a pokud mame obsah hlavicky
            if (!isTouch.current && hasVideoTitle && hasShowTitle) {
              onMouseEnter();
            }
            isTouch.current = false;
          }}
          onMouseLeave={onMouseLeave}
          onTouchEnd={() => {
            isTouch.current = true;
          }}
        >
          <div
            className={classNames(videoHeaderClassnames.videoTitleSection, {
              visible: true,
              none: false,
              large: hasVideoTitle && hasIndexTitle && hasShowTitle,
            })}
          >
            {!!ageRestriction && <Labeling ageRestriction={ageRestriction} />}
            {showDivider && (
              <div
                className={classNames(videoHeaderClassnames.divider, {
                  high: hasIndexTitle && hasShowTitle && hasVideoTitle,
                  medium:
                    (hasShowTitle && hasVideoTitle) ||
                    (hasShowTitle && hasIndexTitle) ||
                    (hasVideoTitle && hasIndexTitle),
                })}
              />
            )}
            <VideoTitle
              hbbtvStreamingActiveDevice={hbbtvStreamingActiveDevice}
              indexTitle={indexTitle}
              meta={{ ...meta, title: videoTitleDisplay, showTitle: showTitleDisplay }}
              onIndexTitleClick={onIndexListVisible}
            />
          </div>
          {/* TODO: VECKO-5256 - Non MVP, mělo by bý vypnutelné přes config */}
          {/* id ? (
          <div data-testid="VideoHeaderShareButton">
            <ShareButton className={classes.shareButton} onClick={onShareButtonClick} />
          </div>
        ) : null */}
        </div>
        {isShareDialogVisible && id ? (
          <FocusTrap
            active={isModalTrapped}
            focusTrapOptions={{
              onDeactivate: () => setShareDialogVisbility(false),
              clickOutsideDeactivates: true,
              returnFocusOnDeactivate: true,
            }}
          >
            <div
              aria-modal
              aria-label={formatMessage({
                id: 'VideoHeader.ShareDialogAriaLabel',
                defaultMessage: 'Sdílení videa',
                description: 'Aria=label pro sdílení videa',
              })}
            >
              <ShareDialog id={id} playerClient={playerClient} onClose={onShareDialogClose} />
            </div>
          </FocusTrap>
        ) : null}
      </>
    );
  }
);
