import React, { memo, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { IndexItemProps } from '../../../Player/Controls/VOD/ProgressBar/IndexList';
import { ProgressBarPopup } from '../ProgressBarPopup/ProgressBarPopup';
import { usePlayerSetup } from '../../../Providers/Setup/usePlayerSetup';
import { PlayerVariantEnum } from '../../../constants';

interface TimestampsProps {
  duration?: number;
  getFullLiveStreamLength: () => number;
  positionPercentage?: number;
  progressBarTimestampsWidth: number;
  snapIndex?: (timestamp: number) => IndexItemProps | undefined;
  thumbnailsUrl?: string;
}

const useStyles = createUseStyles({
  timestamps: {
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  itemWrapper: {
    position: 'relative',
    width: '100%',
  },
});

export const Timestamps = memo(
  ({
    positionPercentage,
    progressBarTimestampsWidth,
    snapIndex = () => undefined,
    thumbnailsUrl,
    duration,
    getFullLiveStreamLength,
  }: TimestampsProps) => {
    const { playerClient, playerVariant } = usePlayerSetup();

    const classes = useStyles();

    // z % časové osy pořebujem získat timestamp videa
    const videoTimestamp = useMemo(() => {
      if (positionPercentage === undefined) {
        return 0;
      }
      // pro live získáváme dle začátky vysílání dle O2
      if (playerVariant === PlayerVariantEnum.LIVE) {
        const fullLiveStreamLength = getFullLiveStreamLength();
        const broadcastStart = playerClient.getBroadcastStart();
        if (!broadcastStart) {
          return 0;
        }
        return (
          (broadcastStart.getTime() -
            ((fullLiveStreamLength * 1000) / 100) * (100 - positionPercentage)) /
          1000
        );
      }

      // pro VOD klasika z duration
      return ((duration || playerClient.getDuration()) / 100) * positionPercentage;
    }, [positionPercentage, playerVariant, duration, playerClient, getFullLiveStreamLength]);

    if (positionPercentage === undefined) {
      return null;
    }

    const index = snapIndex(videoTimestamp);

    return (
      <div className={classes.timestamps} data-testid="Timestamps">
        <div className={classes.itemWrapper}>
          <ProgressBarPopup
            getFullLiveStreamLength={getFullLiveStreamLength}
            positionInPercentage={positionPercentage || 0}
            thumbnailUrl={index?.imageUrl || undefined}
            thumbnailsUrl={thumbnailsUrl}
            timestamp={index?.startTime || videoTimestamp}
            title={index?.title || undefined}
            wrapperWidth={progressBarTimestampsWidth}
          />
        </div>
      </div>
    );
  }
);
