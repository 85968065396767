import React, { memo, useContext, useEffect, useState } from 'react';
import { ImageLoader, AspectRatioPlaceholder } from '@czechtv/components';
import { assertNever } from '@czechtv/utils';
import { usePlayerSetup } from '../../../Providers/Setup/usePlayerSetup';
import { PlayerVariantEnum } from '../../../constants';
import { ThumbnailImg } from '../Thumbnail/Thumbnail';
import { currentImage, setAspectRatio, setImageSize } from '../Thumbnail/thumbnail.helper';
import { PlayerContext } from '../../../Player/PlayerContext';

interface Props {
  thumbnailUrl?: string;
  thumbnailsUrl?: string;
  timestamp: number;
}

const ThumbnailImage = ({ thumbnailUrl, timestamp, thumbnailsUrl }: Props) => {
  const playerContext = useContext(PlayerContext);
  const { playerVariant } = usePlayerSetup();
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    if (!playerContext?.liveMode) {
      setImageUrl(`${thumbnailsUrl}${currentImage(playerVariant, timestamp)}.jpg`);
    }
  }, [playerContext?.liveMode, playerVariant, thumbnailsUrl, timestamp]);

  useEffect(() => {
    if (playerContext && !playerContext.aspectRatio) {
      setAspectRatio(imageUrl, playerContext.setAspectRatio);
    }
  }, [imageUrl, playerContext]);

  useEffect(() => {
    setImageSize(playerContext?.aspectRatio, setWidth, setHeight);
  }, [playerContext?.aspectRatio]);

  if (thumbnailUrl) {
    return <ImageLoader src={thumbnailUrl} />;
  }

  if (!thumbnailsUrl) {
    return <AspectRatioPlaceholder />;
  }

  if (playerVariant === PlayerVariantEnum.VOD || playerVariant === PlayerVariantEnum.LIVE) {
    return (
      <ThumbnailImg height={height} thumbnailsUrl={imageUrl} timestamp={timestamp} width={width} />
    );
  }

  return assertNever(playerVariant);
};

export default memo(ThumbnailImage);
