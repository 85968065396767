import { assertNever, Encoder } from '@czechtv/utils';
import { AnalyticsPlayerContext } from '../../PlayerEvent';
import { NielsenMainContentPayload } from './nielsenTypes';

export const getEncoderName = (encoder: Encoder): string => {
  switch (encoder) {
    case Encoder.CH_6:
      return 'ČT art';
    case Encoder.CH_1:
      return 'ČT 1';
    case Encoder.CH_2:
      return 'ČT 2';
    case Encoder.CH_7:
      return 'ČT 3';
    case Encoder.CH_4:
      return 'ČT Sport';
    case Encoder.CH_24:
      return 'ČT 24';
    case Encoder.CH_5:
      return 'ČT :D';
    default:
      return encoder;
  }
};

// TODO: doresit extra prenosy

export const nielsenContextToMeta = (
  context: AnalyticsPlayerContext
): NielsenMainContentPayload => {
  const { nielsen } = context;
  return nielsen;
};

export const getNielsenPosition = (context: AnalyticsPlayerContext) => {
  if (context.type === 'VOD') {
    return Math.floor(context.position);
  }
  if (context.type === 'LIVE') {
    const { broadcastDate } = context;
    if (!broadcastDate) {
      return 0;
    }
    return Math.ceil(broadcastDate.getTime() / 1000);
  }
  return assertNever(context);
};
