import React from 'react';
import { warningClassnames } from './WarningClassnames.css';

interface WarningProps {
  message: string;
}

export const Warning = ({ message }: WarningProps) => {
  return (
    <div
      className={warningClassnames.container}
      title="Přehráváte z prostředí, na kterém je zapnutá O2 laborka. Poptavání playlistů a playback se nemusí chovat dle předpokladů."
    >
      {message}
    </div>
  );
};
