import React, { ForwardedRef, memo, RefObject, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useStyles = createUseStyles({
  video: {
    // skrytí černých pruhů kolem některých videí
    height: 'calc(100% + 3px)',
    width: 'calc(100% + 4px)',
    position: 'relative',
    left: -2,
    top: -1,
    // pozadi kdyz nam nesedi pomer videa a zobrazovaciho kontejneru
    backgroundColor: 'black',
    outline: 'none',
    // fix safari z-index issue
    '-webkit-transform-style': 'preserve-3d',
    margin: 'auto',
  },
  hideControls: {
    '&::-webkit-media-controls': {
      display: 'none !important',
    },
  },
  adContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  hide: {
    opacity: 0,
  },
});

export interface VideoProps {
  hide?: boolean;
  muted?: boolean;
  nativeControls?: boolean;
  previewImage?: string;
  ref: RefObject<HTMLVideoElement>;
}

const LIVE_O2_PREVIEW_IMAGE_PARTIAL_PATH = 'categories/screenshot/1';

const Video = React.forwardRef(
  (
    { muted, nativeControls = false, previewImage, hide = false }: VideoProps,
    ref: ForwardedRef<HTMLVideoElement>
  ) => {
    const classes = useStyles();

    // pro poster nepouzivame 'zivy' nahledovy obrazek z o2
    // vyhazuje to do konzole problem s CORS
    const poster = useMemo(
      () => (previewImage?.includes(LIVE_O2_PREVIEW_IMAGE_PARTIAL_PATH) ? undefined : previewImage),
      [previewImage]
    );

    return (
      <>
        <video
          playsInline
          className={classNames(classes.video, {
            [classes.hideControls]: !nativeControls,
            // na safari občas video element protekl přes z osu nad loading overlay
            [classes.hide]: hide,
          })}
          crossOrigin="anonymous"
          data-testid="video-tag"
          muted={!!muted}
          poster={poster}
          ref={ref}
        />
      </>
    );
  }
);

export default memo(Video);
