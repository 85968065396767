import React, { memo } from 'react';
import { IconInfoCircle } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../items';

interface InfoButtonProps {
  className?: string;
  onClick?: () => void;
}

const InfoButton = ({ className, onClick }: InfoButtonProps) => {
  return (
    <PlayerNativeButton
      aria-label={VODPlayerControlsItemsDefinition[PlayerControlsItem.INFO]}
      className={className}
      title={VODPlayerControlsItemsDefinition[PlayerControlsItem.INFO]}
      onClick={onClick}
    >
      <IconInfoCircle tabIndex={-1} />
    </PlayerNativeButton>
  );
};

export default memo(InfoButton);
