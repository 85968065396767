import React, { memo } from 'react';
import { FormElementProps, uniqueElementId } from '../../common';
import { Select } from '../../inputs/Select/Select';
import { ValidatedFormElementWrapper } from '../../ValidatedFormElementWrapper';

export interface ValidatedSelectProps
  extends FormElementProps,
    Omit<
      React.HTMLAttributes<HTMLSelectElement>,
      'aria-invalid' | 'id' | 'name' | 'onChange' | 'label'
    > {
  disabled?: boolean;
  maxWidth?: number | string;
  onSelected: (text: string) => void;
  options: { id: string | number; text: string }[];
  placeholder: string;
  selectedOption?: string;
  width?: number | string;
}

export const ValidatedSelect = memo(
  React.forwardRef<HTMLSelectElement, ValidatedSelectProps>(
    (
      {
        label,
        placeholder,
        name,
        prefix = '',
        options,
        disabled,
        selectedOption,
        onSelected,
        width = '100%',
        maxWidth = '100%',
        displayValidationStatus,
        error,
        ...restProps
      },
      ref
    ) => {
      // id by mělo být unikátní pro celou stránku
      const id = uniqueElementId(prefix, name);
      const idDescribedBy = uniqueElementId(prefix, `error-${name}`);

      return (
        <ValidatedFormElementWrapper
          error={displayValidationStatus ? error : undefined}
          errorId={idDescribedBy}
          id={id}
          label={label}
        >
          <Select
            {...restProps}
            ariaDescribedBy={idDescribedBy}
            disabled={disabled}
            id={id}
            isValid={displayValidationStatus ? !error : undefined}
            maxWidth={maxWidth}
            name={name}
            options={options}
            placeholder={placeholder}
            ref={ref}
            selectedOption={selectedOption}
            width={width}
            onChange={onSelected}
          />
        </ValidatedFormElementWrapper>
      );
    }
  )
);
