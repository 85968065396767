import React, { memo } from 'react';
import { IconFullscreen } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../items';

interface FullscreenEnterButtonProps {
  className?: string;
  onClick?: () => void;
}

const FullscreenEnterButton = ({ className, onClick }: FullscreenEnterButtonProps) => {
  return (
    <PlayerNativeButton
      aria-label={VODPlayerControlsItemsDefinition[PlayerControlsItem.FULLSCREEN]}
      className={className}
      data-testid="playerFullscreenIcon"
      title={VODPlayerControlsItemsDefinition[PlayerControlsItem.FULLSCREEN]}
      onClick={onClick}
    >
      <IconFullscreen tabIndex={-1} />
    </PlayerNativeButton>
  );
};

export default memo(FullscreenEnterButton);
