import { useEffect, useRef, useState } from 'react';
import { ResizeObserver } from '@czechtv/styles';
import { debounce } from '@czechtv/utils';

export const useHeight = (ref: React.RefObject<HTMLElement>) => {
  const [height, setHeight] = useState(0);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    let mounted = true;
    const element = ref.current;
    if (!element) {
      return () => {
        mounted = false;
      };
    }

    const computeNewHeight = ({ height }: { height: number }) => {
      if (!mounted) {
        return;
      }
      if (!height) {
        setHeight(0);
        return;
      }
      setHeight(Math.round(height));
    };

    computeNewHeight(element.getBoundingClientRect());

    const resizeObserverHandler = debounce((entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        computeNewHeight(entry.contentRect);
      });
    });

    resizeObserver.current = new ResizeObserver(resizeObserverHandler);
    resizeObserver.current.observe(element);

    return () => {
      resizeObserver.current?.unobserve(element);
      mounted = false;
    };
  }, [ref, setHeight]);

  return height;
};
