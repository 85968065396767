import { formatMessage } from '../../../utils/formatMessage';
import getLicenceTimeRangeError from './getLicenseTimeRangeError';

const STRING_SEPARATOR = '-';
export class PlaylistError extends Error {
  constructor(message: string, error: PlaylistErrorCodes) {
    super(message);
    this.error = error;
  }

  error!: PlaylistErrorCodes;
}

export interface ErrorResponse {
  errorCode?: string;
  errorMessage?: string;
  message?: string;
  validLicenceInterval?: ValidLicenceInterval;
}

export enum PlaylistErrorCodes {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  MEDIUM_NOT_FOUND = 'MEDIUM_NOT_FOUND',
  OUT_OF_TIME_RANGE = 'OUT_OF_TIME_RANGE',
  PROMO_EXPIRED = 'PROMO_EXPIRED',
  UNAVAILABLE_LICENCE = 'UNAVAILABLE_LICENCE',
  UNSUPPORTED_DRM = 'UNSUPPORTED_DRM',
  UNSUPPORTED_GEOLOCATION = 'UNSUPPORTED_GEOLOCATION',
}

export interface ValidLicenceInterval {
  validFrom: string | undefined;
  validTo: string | undefined;
}

export type ValidationError = {
  shortTitle?: string;
  subtitle?: string;
  title: string;
};

export const messages = {
  defaultPlaylistErrorTitle: {
    id: 'PlaylistError.defaultPlaylistError.title',
    defaultMessage: 'Je nám líto, video se nepodařilo spustit.',
    description: 'Titulek výchozí zprávy pro errory v playlistech',
  },
  defaultPlaylistErrorSubtitle: {
    id: 'PlaylistError.defaultPlaylistError.subtitle',
    defaultMessage: 'Pracujeme na nápravě. Zkuste to prosím později.',
    description: 'Podtitulek výchozí zprávy pro errory v playlistech',
  },
  // program
  licenseErrorProgramTitle: {
    id: 'PlaylistError.program.title',
    defaultMessage: 'Je nám líto, video nelze přehrát.',
    description: 'Titulek zprávy pro error při programové blokaci',
  },
  // device error
  licenseErrorInvalidDeviceTitle: {
    id: 'LicenseError.InvalidDevice.title',
    defaultMessage: 'Video nelze přehrát na vašem zařízení',
    description: 'Titulek zprávy pro error při špatném zařízení',
  },
  licenseErrorInvalidDeviceShortTitle: {
    id: 'LicenseError.InvalidDevice.shorttitle',
    defaultMessage: 'Nelze přehrát na vašem zařízení',
    description: 'Titulek zprávy pro error při špatném zařízení',
  },
  licenseErrorInvalidDeviceSubtitle: {
    id: 'LicenseError.InvalidDevice.subtitle',
    defaultMessage: 'Zkuste to jinde, třeba tabletu nebo počítači.',
    description: 'Podtitulek zprávy pro error při špatném zařízení',
  },
  // geolokace
  licenseErrorGeolocationTitle: {
    id: 'LicenseError.Geolocation.title',
    defaultMessage: 'Video nelze přehrát na vašem území',
    description: 'Titulek zprávy pro error při geolokaci',
  },
  licenseErrorGeolocationSubtitle: {
    id: 'LicenseError.Geolocation.subtitle',
    defaultMessage: 'Je nám líto, toto video je dostupné pouze v České republice.',
    description: 'Podtitulek zprávy pro error při geolokaci',
  },

  // drm
  licenseErrorDRMTitle: {
    id: 'LicenseError.DRM.title',
    defaultMessage: 'Video nelze spustit v tomto prohlížeči',
    description: 'Titulek zprávy pro error při DRM',
  },
  licenseErrorDRMShortTitle: {
    id: 'LicenseError.DRM.shorttitle',
    defaultMessage: 'Nelze spustit v prohlížečií',
    description: 'Titulek zprávy pro error při DRM',
  },
  licenseErrorDRMSubtitle: {
    id: 'LicenseError.DRM.subtitle',
    defaultMessage: 'Nainstalujte si jiný, který podporuje tzv. DRM. Například Google Chrome.',
    description: 'Podtitulek zprávy pro error při DRM',
  },
  promoExpiredTitle: {
    id: 'PromoExpired.title',
    defaultMessage: `Je nám líto. We're sorry.`,
    description: 'Titulek zprávy, když uživatel překročí počet povolených zhlédnutí.',
  },
  promoExpiredSubtitle: {
    id: 'PromoExpired.subtitle',
    defaultMessage: 'Odkaz již není platný. The link has expired.',
    description: 'Titulek zprávy, když uživatel překročí počet povolených zhlédnutí.',
  },
  promoExpiredShortTitle: {
    id: 'PromoExpired.shortTitle',
    defaultMessage: `Odkaz již není platný. The link has expired.`,
    description: 'Titulek zprávy, když uživatel překročí počet povolených zhlédnutí.',
  },
};

// message prijde obvykle ve formatu "Foo. - Bar."
const parsePlaylistMessage = (message: string) => {
  const messages = message.split(STRING_SEPARATOR).map((message) => message.trim());
  return {
    title: messages[0],
    ...(messages.length > 1 ? { subtitle: messages[1] } : {}),
  };
};

const defaultErrorMessage = {
  title: formatMessage(messages.defaultPlaylistErrorTitle),
  subtitle: formatMessage(messages.defaultPlaylistErrorSubtitle),
};

export const getPlaylistErrorDescription = ({
  errorCode,
  message,
}: ErrorResponse): ValidationError => {
  switch (errorCode) {
    case PlaylistErrorCodes.INTERNAL_ERROR: // 500
      return defaultErrorMessage;
    case PlaylistErrorCodes.MEDIUM_NOT_FOUND: // 400
      return defaultErrorMessage;
    // oboji ma zatim stejny vyznam
    case PlaylistErrorCodes.OUT_OF_TIME_RANGE:
    case PlaylistErrorCodes.UNAVAILABLE_LICENCE:
      return getLicenceTimeRangeError();
    case PlaylistErrorCodes.PROMO_EXPIRED:
      return {
        title: formatMessage(messages.promoExpiredTitle),
        subtitle: formatMessage(messages.promoExpiredSubtitle),
        shortTitle: formatMessage(messages.promoExpiredShortTitle),
      };
    case PlaylistErrorCodes.UNSUPPORTED_GEOLOCATION:
      return {
        title: formatMessage(messages.licenseErrorGeolocationTitle),
        subtitle: formatMessage(messages.licenseErrorGeolocationSubtitle),
      };
    case PlaylistErrorCodes.UNSUPPORTED_DRM:
      return {
        title: formatMessage(messages.licenseErrorDRMTitle),
        shortTitle: formatMessage(messages.licenseErrorDRMShortTitle),
        subtitle: formatMessage(messages.licenseErrorDRMSubtitle),
      };

    default:
      // pokud prijde neznasmy error code, zkusime pouzit message
      return message
        ? parsePlaylistMessage(message)
        : { title: formatMessage(messages.defaultPlaylistErrorTitle) };
  }
};
