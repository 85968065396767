import classNames from 'classnames';
import React, { memo } from 'react';
import { MandatoryStatus } from '../MandatoryStatus/MandatoryStatus';
import { labelClassnames } from './Label.css';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  inverted?: boolean;
  required?: boolean;
}

export const Label = memo<LabelProps>(({ inverted, className, children, required, ...rest }) => {
  return (
    /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
    <label
      className={classNames(className, labelClassnames.label, {
        [labelClassnames.inverted]: inverted,
      })}
      {...rest}
    >
      {children}
      <MandatoryStatus required={required} />
    </label>
  );
});
