/*
Pouzivame pro typescript test ze pokryjeme všechny moznosti enumu. napr.

enum Color = {
black = 'black'
white = 'white'
brown = 'brown'
}

if (color === Color.black) {
return ...
}
if (color === Color.white) {
return ...
}
return assertNever(color);

Vyhodi chybu, ze color muze byt jeste brown.

 */
export const assertNever = (x: never, message?: string): never => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(message || `Unexpected input: ${x}`);
};
