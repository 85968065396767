import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconArrowRightCircle: SvgComponent = memo((props) => (
  <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M20 2a18 18 0 1 0 0 36 18 18 0 0 0 0-36z" fill="#8FAEFA" />
      <path
        d="M20.61 12.21l.1.08 7 7a1 1 0 0 1 .08 1.32l-.08.1-7 7a1 1 0 0 1-1.5-1.32l.08-.1 5.3-5.3L12 21a1 1 0 0 1-.12-2H24.6l-5.3-5.3a1 1 0 0 1-.08-1.31l.08-.1a1 1 0 0 1 1.32-.08z"
        fill="#FFF"
      />
    </g>
  </svg>
));
