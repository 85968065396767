import { v4 as uuid } from 'uuid';
import { storageAvailable } from '@czechtv/utils';
import { LOCAL_STORAGE_GLOBAL_PREFIX } from '../constants';
import { log } from './logger';

const DEFAULT_EXPIRATION_DURATION = 24 * 60 * 60 * 1000; // 24 hodin v ms (po dohode s architektem)

export const getSessionId = () => {
  const localStorageKey = `${LOCAL_STORAGE_GLOBAL_PREFIX}/sessionId`;

  // Pro jistotu kontrolujeme, zda muzeme pouzit localStorage
  if (!storageAvailable('localStorage')) {
    log.info({ message: 'LocalStorage is not available' });
    return uuid();
  }

  try {
    const storedData = localStorage.getItem(localStorageKey);
    if (storedData) {
      const { id, timestamp } = JSON.parse(storedData) as { id: string; timestamp: number };
      // Pokud nalezneme id a neni expirovane, tak ho vratime
      if (new Date().getTime() - timestamp < DEFAULT_EXPIRATION_DURATION) {
        return id;
      }
    }
  } catch (e) {
    log.info({ message: 'Error reading sessionId from localStorage' });
  }

  // Vygenerujeme, ulozime a vratime nove id
  try {
    const newId = uuid();
    const currentTimestamp = new Date().getTime();
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({ id: newId, timestamp: currentTimestamp })
    );
    return newId;
  } catch (e) {
    log.info({ message: 'Error writing sessionId to localStorage' });
    return uuid();
  }
};
