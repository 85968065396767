export enum resizedImagesWidth {
  HERO_DESKTOP = 2600,
  HERO_TABLET = 1366,
  HERO_MOBILE = 640,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HERO_VIDEOBONUS = 2600,
  CARD = 480,
  LOGO = 600,
  PLAYER_PREVIEW = 1005,
}
export enum resizedImagesHeight {
  HERO_DESKTOP = 800,
  HERO_TABLET = 884,
  HERO_MOBILE = 544,
  HERO_VIDEOBONUS = 224,
  CARD = 270,
  LOGO = 280,
  PLAYER_PREVIEW = 565,
}

export const getResizedImage = (url: string, width: number, height: number) => {
  return `${url}?width=${width}&height=${height}`;
};
