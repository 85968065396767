import { useEffect, useState } from 'react';
import { useResponsiveContext } from './ResponsiveContext';

const touchDevices = /iPad|iPhone|iPod|android/i;
const windowsDevice = /Windows|Linux/i;

export const isMobileDevice = () => {
  const { userAgent } = navigator;
  // iPad OS posila nevypovidajici useragent
  // windows a Linux muze bezet na touch noteboocich
  const nonWinTouchDevice =
    ('ontouchstart' in window || navigator.maxTouchPoints > 0) && !windowsDevice.test(userAgent);
  return touchDevices.test(userAgent) || nonWinTouchDevice;
};

export const useMobileDevice = (): boolean => {
  const { wasHydrated = false } = useResponsiveContext();
  const [isMobileDeviceState, setIsMobileDeviceState] = useState(
    wasHydrated ? touchDevices.test(navigator.userAgent) : false
  );

  useEffect(() => {
    if (typeof navigator === 'undefined') {
      return;
    }
    setIsMobileDeviceState(isMobileDevice());
  }, []);

  return isMobileDeviceState;
};

export const isTouch = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const { userAgent } = navigator;

  return touchDevices.test(userAgent);
};

export const isMobileIOS = (customUserAgent?: string) => {
  const userAgent =
    customUserAgent || (typeof navigator === 'undefined' ? undefined : navigator.userAgent);

  if (userAgent === undefined) {
    return false;
  }

  // iPad OS posila nevypovidajici useragent
  // windows a Linux muze bezet na touch noteboocich
  const nonWinTouchDevice =
    ('ontouchstart' in window || navigator.maxTouchPoints > 0) && !windowsDevice.test(userAgent);

  if (userAgent.match(/(iPad|iPhone|iPod)/g) || nonWinTouchDevice) {
    return true;
  }

  return false;
};
