import React, { HTMLAttributes } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import {
  getThumbnailPositionInImage,
  getThumbnailLeftPosition,
  getThumbnailTopPosition,
} from './thumbnail.helper';

// Komponenta najde v sadě náhledových obrázků ten správný na základě časového razítka

interface StylesOptions {
  height: number;
  positionLeft: number;
  positionTop: number;
  thumbnailsUrl: string;
  width: number;
}

const useStyles = createUseStyles<string, StylesOptions>({
  image: {
    margin: 'auto',
    width: ({ width }) => width,
    height: ({ height }) => height,
    color: '#fff',
    backgroundImage: ({ thumbnailsUrl }) => `url('${thumbnailsUrl}')`,
    backgroundPosition: ({ positionTop, positionLeft }) => `-${positionLeft}px -${positionTop}px`,
    backgroundSize: ({ width, height }) => `${width * 10}px ${height * 10}px`,
  },
});

interface Props extends HTMLAttributes<HTMLDivElement> {
  height: number;
  thumbnailsUrl: string;
  timestamp: number;
  width: number;
}

export const ThumbnailImg = ({
  thumbnailsUrl,
  timestamp,
  width,
  height,
  className,
  ...rest
}: Props) => {
  const positionInImage = getThumbnailPositionInImage(timestamp);
  const positionLeft = getThumbnailLeftPosition(positionInImage, width);
  const positionTop = getThumbnailTopPosition(positionInImage, height);

  const classes = useStyles({ thumbnailsUrl, positionLeft, positionTop, width, height });

  return (
    <div className={classNames(className, classes.image)} data-testid="thumbnailImage" {...rest} />
  );
};
