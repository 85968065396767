import React, { memo } from 'react';
import { IconSeekForward } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../items';

interface SeekForwardButtonProps {
  className?: string;
  onClick?: () => void;
}

const SeekForwardButton = ({ className, onClick }: SeekForwardButtonProps) => {
  return (
    <PlayerNativeButton
      aria-label={VODPlayerControlsItemsDefinition[PlayerControlsItem.SEEK_FORWARD]}
      className={className}
      data-testid="playerSeekForwardIcon"
      title={VODPlayerControlsItemsDefinition[PlayerControlsItem.SEEK_FORWARD]}
      onClick={onClick}
    >
      <IconSeekForward tabIndex={-1} />
    </PlayerNativeButton>
  );
};

export default memo(SeekForwardButton);
