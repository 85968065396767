import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconSpinner: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M12 2a10 10 0 1 1 0 20v-3a7 7 0 0 0 0-14V2z" fill="#000" />
      <path d="M12 2v3a7 7 0 1 0 0 14v3a10 10 0 1 1 0-20z" fill="#E0E0E0" />
    </g>
  </svg>
));
