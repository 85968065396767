// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const adScriptSdkHandler = (clientId, implementationId) =>
  new Promise((resolve, reject) => {
    /* eslint-disable */
    !(function a(j, h, m, t, c, z) {
      c = c || 'JHMT';
      j[c] = j[c] || [];
      z = z || 1;
      var b = h.createElement('script');
      (b.async = !0),
        b.readyState
          ? (b.onreadystatechange = function () {
              ('loaded' !== b.readyState && 'complete' !== b.readyState) ||
                ((b.onreadystataechange = null), j.JHMTApi.init(c, m, t));
            })
          : (b.onload = function () {
              if (j.JHMTApi) {
                j.JHMTApi.init(c, m, t);
                resolve();
              } else {
                reject();
              }
            }),
        (b.src = h.location.protocol + '//cm' + z + '.jhmt.cz/api.js'),
        (b.onerror = function (e) {
          z < 2 && a(j, h, m, t, c, ++z);
          reject(e);
        }),
        h.getElementsByTagName('head')[0].appendChild(b);
    })(window, document, implementationId || 'ImplementationId', clientId || '');
  });
