import React, { memo } from 'react';
import { SvgComponent } from '../SvgComponent';

export const IconInstagramHoverWhiteColor: SvgComponent = memo((props) => (
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <radialGradient cx="0%" cy="100%" fx="0%" fy="100%" id="instagram-color-a" r="141.42%">
        <stop offset="0%" stopColor="#FFDA80" />
        <stop offset="28.02%" stopColor="#FB8F2F" />
        <stop offset="54.21%" stopColor="#E13269" />
        <stop offset="78.74%" stopColor="#BD309D" />
        <stop offset="100%" stopColor="#9237BF" />
      </radialGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" fill="#FFF" r="10" />
      <path
        d="M12 1a11 11 0 1 1 0 22 11 11 0 0 1 0-22zm-.47 5h-1.11l-.63.02-.56.03c-.4.04-.79.12-1.16.26-.8.32-1.44.95-1.76 1.76a4.4 4.4 0 0 0-.26 1.16l-.01.3-.03.5v3.64l.02.66.02.44c.04.4.12.79.26 1.16.32.8.95 1.44 1.76 1.76.37.14.76.22 1.16.26l.3.01.5.03h.57l1.87.01h1.08l.78-.03.44-.02c.4-.04.79-.12 1.16-.26.8-.32 1.44-.95 1.76-1.76.14-.37.22-.76.26-1.16l.01-.3.02-.44.02-.72V10.6l-.02-.76-.03-.61a4.4 4.4 0 0 0-.26-1.16 3.07 3.07 0 0 0-1.76-1.76 4.4 4.4 0 0 0-1.16-.26l-.3-.01-.44-.02-.64-.02h-1.86zm1.87 1.08h.26l1 .05c.3.02.6.09.88.2.52.2.94.6 1.14 1.13.1.29.17.58.2.89l.03.75v3.67l-.04.88c-.02.3-.09.6-.2.89-.2.52-.6.94-1.13 1.14-.29.1-.58.17-.89.2l-.77.03H10.2l-.85-.04a3.1 3.1 0 0 1-.89-.2c-.52-.2-.94-.6-1.14-1.13-.1-.29-.17-.58-.2-.89L7.1 14l-.02-.59v-3.07l.05-1c.02-.3.09-.6.2-.88.2-.52.6-.94 1.13-1.14.29-.1.58-.17.89-.2L10 7.1l.59-.02h2.8zM12 8.92a3.08 3.08 0 0 0-.18 6.16h.38a3.08 3.08 0 0 0-.2-6.16zM12 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm3.2-1.92a.72.72 0 1 0 0 1.44.72.72 0 0 0 0-1.44z"
        fill="url(#instagram-color-a)"
      />
    </g>
  </svg>
));
