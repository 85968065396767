import React, { memo } from 'react';
import { IconFullscreenExit } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../items';

interface FullscreenExitButtonProps {
  className?: string;
  onClick?: () => void;
}

const FullscreenExitButton = ({ className, onClick }: FullscreenExitButtonProps) => {
  return (
    <PlayerNativeButton
      aria-label={VODPlayerControlsItemsDefinition[PlayerControlsItem.EXIT_FULLSCREEN]}
      className={className}
      data-testid="playerFullscreenIcon"
      title={VODPlayerControlsItemsDefinition[PlayerControlsItem.EXIT_FULLSCREEN]}
      onClick={onClick}
    >
      <IconFullscreenExit tabIndex={-1} />
    </PlayerNativeButton>
  );
};

export default memo(FullscreenExitButton);
