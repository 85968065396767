type Primitive = number | string | boolean | bigint | symbol | null | undefined;
type Tags = Record<string, Primitive>;

interface LogValues {
  context?: LogContext;
  customLogData?: unknown;
  error?: unknown;
  level?: SeverityLevel;
  message: string;
  tags?: Tags;
}

type LogMethod = (values: LogValues) => void;
type SeverityLevel = 'debug' | 'info' | 'warning' | 'error' | 'fatal';
type LogContext = Record<string, any>;

interface ScopedCapture {
  extra?: Record<string, unknown>;
  level?: SeverityLevel;
  message: string;
  tags?: Tags;
}

interface LoggerMethods {
  debug?: LogMethod;
  error?: LogMethod;
  info?: LogMethod;
  scopedCapture?: (values: ScopedCapture) => void;
  setContext?: (name: string, context: LogContext) => void;
  setTags?: (tags: Tags) => void;
  warn?: LogMethod;
}

export interface Logger {
  methods: LoggerMethods;
  name: string;
}

interface LoggerInstance {
  debug: LogMethod;
  error: LogMethod;
  getContext: () => LogContext | null;
  getLoggerNames: () => string[];
  info: LogMethod;
  scopedCapture: (values: ScopedCapture) => void;
  setContext: (name: string, context: LogContext) => void;
  setTags: (tags: Tags) => void;
  subscribe: (logger: Logger) => () => void;
  unsubcribe: (name: string) => void;
  warn: LogMethod;
}

let instance: LoggerInstance | null = null;

const createLogger = () => {
  let loggers: Logger[] = [];
  let globalContext: LogContext | null = null;
  const unsubcribe = (name: string) => {
    loggers = loggers.filter((logger) => logger.name !== name);
  };
  const subscribe = (logger: Logger) => {
    const exists = loggers.find((item) => item.name === logger.name);
    if (!exists) {
      loggers.push(logger);
    }
    return () => {
      unsubcribe(logger.name);
    };
  };
  const debug = (values: LogValues) => {
    loggers.forEach((logger) => {
      if (logger.methods.debug) {
        logger.methods.debug(values);
      }
    });
  };
  const info = (values: LogValues) => {
    loggers.forEach((logger) => {
      if (logger.methods.info) {
        logger.methods.info(values);
      }
    });
  };
  const warn = (values: LogValues) => {
    loggers.forEach((logger) => {
      if (logger.methods.warn) {
        logger.methods.warn(values);
      }
    });
  };
  const error = (values: LogValues) => {
    loggers.forEach((logger) => {
      if (logger.methods.error) {
        logger.methods.error(values);
      }
    });
  };
  const setTags = (tags: Tags) => {
    loggers.forEach((logger) => {
      if (logger.methods.setTags) {
        logger.methods.setTags(tags);
      }
    });
  };
  const setContext = (name: string, context: LogContext) => {
    globalContext = {
      ...globalContext,
      [name]: context,
    };
    loggers.forEach((logger) => {
      if (logger.methods.setContext) {
        logger.methods.setContext(name, context);
      }
    });
  };
  const getContext = () => {
    return globalContext;
  };
  const scopedCapture = ({ message, level, tags, extra }: ScopedCapture) => {
    loggers.forEach((logger) => {
      if (logger.methods.scopedCapture) {
        logger.methods.scopedCapture({ message, level, tags, extra });
      }
    });
  };

  const getLoggerNames = () => {
    return loggers.map((logger) => logger.name);
  };

  return {
    info,
    warn,
    error,
    subscribe,
    unsubcribe,
    debug,
    setTags,
    setContext,
    scopedCapture,
    getLoggerNames,
    getContext,
  };
};

const getLoggerInstance = () => {
  if (!instance) {
    instance = createLogger();
  }
  return instance;
};

export const log = getLoggerInstance();
