import classNames from 'classnames';
import React, { DetailedHTMLProps, memo, TextareaHTMLAttributes } from 'react';
import { commonFormElementClassnames, commonFormTextElementClassnames } from '../../common.css';
import { ValidationIcon } from '../../ValidationIcon/ValidationIcon';
import { textAreaClassnames } from './TextArea.css';

interface TextAreaExtraProps {
  height?: number | string;
  inverted?: boolean;
  isValid?: boolean;
  maxWidth?: number | string;
  width?: number | string;
}

export interface TextAreaProps
  extends TextAreaExtraProps,
    Omit<
      DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
      'aria-invalid'
    > {}

export const TextArea = memo(
  React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ className, width, maxWidth, height, inverted = false, isValid, ...restProps }, ref) => {
      const showValidity = isValid !== undefined;

      return (
        <div
          className={classNames(commonFormElementClassnames.commonFormElementContainer)}
          style={{
            width: typeof width !== 'undefined' ? width : '100%',
            maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
            height: typeof height !== 'undefined' ? height : '100%',
          }}
        >
          <ValidationIcon
            className={classNames(commonFormTextElementClassnames.commonFormTextElementIcon, {
              showValidity,
            })}
            isValid={isValid}
          />
          <textarea
            // přístupnost stavu validace
            aria-invalid={isValid === false}
            className={classNames(
              commonFormElementClassnames.commonFormElement,
              commonFormTextElementClassnames.commonFormTextElement,
              textAreaClassnames.formElement,
              className,
              { inverted },
              { showValidity }
            )}
            ref={ref}
            {...restProps}
          />
        </div>
      );
    }
  )
);
