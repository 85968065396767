import React, { useRef, useCallback } from 'react';
import { Button } from '@czechtv/components';
import { IconArrowRight } from '@czechtv/icons';
import { usePlayerSetup } from '../../../Providers/Setup/usePlayerSetup';
import { SkipVideoButton } from '../../Controls/VOD/BeforePlay/SkipVideoButton/SkipVideoButton';
import { useMediaBreakpoints } from '../../../utils/playerResponsive';
import { adsOverlayClassnames } from './AdsOverlay.css';

export interface AdsOverlayProps {
  onAdButtonClick: () => void;
  onSkipAd: () => void;
  pauseCountdown?: boolean;
  showAdButton?: boolean;
  skipAdInSeconds?: number;
}

export const AdsOverlay = ({
  skipAdInSeconds,
  onSkipAd,
  pauseCountdown,
  onAdButtonClick,
  showAdButton = true,
}: AdsOverlayProps) => {
  const adOverlayRef = useRef<HTMLDivElement>(null);
  const { playerClient } = usePlayerSetup();
  const { isMaxSmallMobile } = useMediaBreakpoints();

  const onSkipVideoClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      playerClient.onAdSkipButtonClicked();
      onSkipAd();
    },
    [onSkipAd, playerClient]
  );

  const onAdClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onAdButtonClick();
    },
    [onAdButtonClick]
  );

  return (
    <div className={adsOverlayClassnames.wrapper} ref={adOverlayRef}>
      {showAdButton && (
        <Button
          className={adsOverlayClassnames.adButton}
          icon={IconArrowRight}
          iconPosition="right"
          styleType="inverted"
          onClick={onAdClick}
        >
          {isMaxSmallMobile ? 'Více' : 'Zjistit více'}
        </Button>
      )}

      {skipAdInSeconds !== undefined && (
        <div className={adsOverlayClassnames.skipVideoButtonWrapper}>
          <SkipVideoButton
            className={adsOverlayClassnames.skipVideoButton}
            pauseCountdown={pauseCountdown}
            waitTime={skipAdInSeconds}
            onClick={onSkipVideoClick}
          />
        </div>
      )}
    </div>
  );
};
