import { RESOLUTION_MESSAGES } from '../constants';

export const getResolutionLabel = (resolution: number | null, shortNames?: boolean): string => {
  const resolutionString = resolution
    ? `resolution${resolution.toString()}`
    : RESOLUTION_MESSAGES.resolutionAuto.defaultMessage;

  if (shortNames) {
    return (RESOLUTION_MESSAGES[resolutionString]?.defaultMobileMessage ||
      RESOLUTION_MESSAGES.resolutionAuto.defaultMobileMessage) as string;
  }

  return (
    RESOLUTION_MESSAGES[resolutionString]?.defaultMessage ||
    RESOLUTION_MESSAGES.resolutionAuto.defaultMessage
  );
};
