import React, { memo } from 'react';
import classNames from 'classnames';
import { GLOBAL_THEME_CLASSNAME } from '@czechtv/styles';
import { AspectRatioPlaceholder } from '@czechtv/components';
import PlayerBasedResponsive from '../PlayerBasedResponsive/PlayerBasedResponsive';
import PlayerPageWrapper from './PlayerPageWrapper';
import { playerWrappersClassnames } from './PlayerWrappers.css';

interface Props {
  children: React.ReactNode;
  customRef: React.RefObject<HTMLDivElement>;
  fluidAspect?: boolean;
}

// slučuje běžně používané wrappery pro přehrávač

const PlayerWrappers = ({ customRef, children, fluidAspect }: Props) => {
  return (
    <PlayerBasedResponsive customRef={customRef}>
      <AspectRatioPlaceholder
        className={classNames(GLOBAL_THEME_CLASSNAME, {
          [playerWrappersClassnames.fluidAspect]: fluidAspect,
        })}
      >
        <PlayerPageWrapper ref={customRef}>{children}</PlayerPageWrapper>
      </AspectRatioPlaceholder>
    </PlayerBasedResponsive>
  );
};

export default memo(PlayerWrappers);
