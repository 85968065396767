// import { StreamUrl } from '../constants';

import { PlaylistData } from './playlists/constants';

export const getAudioOnlyInfoFromStreamUrl = (streamUrls?: PlaylistData['streamUrls']) => {
  if (!streamUrls || !streamUrls.length || streamUrls.length > 1) {
    return false;
  }
  return streamUrls[0].main.includes('quality=audio');
};
