import React, { ButtonHTMLAttributes, memo, KeyboardEvent } from 'react';
import { NativeButton } from '@czechtv/components';

export const PlayerNativeButton = memo<Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onKeyDown'>>(
  ({ className, ...rest }) => {
    const handleTrigger = (e: KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === ' ') {
        e.stopPropagation();
      }
    };

    return <NativeButton className={className} {...rest} onKeyDown={handleTrigger} />;
  }
);
