import { AnalyticsPlayerContext, PlayerAdContext } from '../../PlayerEvent';
import { AdScriptMeta, AdScriptAdMeta } from './adScriptTypes';

// TODO: toto je docasne reseni kvuli nasazeni playeru na Sportu, nez se upravi na playlistech adScript objekt a zacne se pouzivat
const getChannelIdFromNolC1 = (nolc1?: string) => {
  if (nolc1 && nolc1.includes(',')) {
    const parts = nolc1.split(',');
    return parts[1];
  }
  // default z tabulky, nemelo by se stat
  return '9999';
};

export const mainContentToMeta = (context: AnalyticsPlayerContext): AdScriptMeta => {
  if (context.type === 'LIVE') {
    const { nielsen } = context;
    return {
      assetid: nielsen.assetid,
      attribute: '1',
      type: 'content',
      program: nielsen.program,
      title: nielsen.title,
      length: nielsen.length,
      crossId: nielsen.crossId1,
      livestream: '1',
      channelId: getChannelIdFromNolC1(nielsen.nol_c1),
    };
  }

  const { nielsen } = context;
  return {
    assetid: nielsen.assetid,
    attribute: '1',
    type: 'content',
    program: nielsen.program,
    title: nielsen.title,
    crossId: nielsen.crossId1,
    length: nielsen.length,
    livestream: '0',
    channelId: '',
  };
};

export const adToMeta = (context: PlayerAdContext): AdScriptAdMeta => {
  return {
    assetid: context.ad.nielsen.assetid,
    type: context.ad.slot,
    length: context.ad.nielsen.length,
    title: context.ad.nielsen.title,
    asmea: context.ad.asmea,
    // zde posilame bez nuly na zacatku
    attribute: context.ad.nielsenType.replace('0', ''),
  };
};
