import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { IconClose, SvgComponent } from '@czechtv/icons';
import { messageClassnames } from './Message.css';

export interface MessageProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  children?: React.ReactNode;
  leftIcon?: SvgComponent;
  leftIconClassName?: string;
  onClose?: () => void;
}

export const Message = ({
  leftIcon: LeftIcon,
  children,
  onClose,
  className,
  leftIconClassName,
  ...rest
}: MessageProps) => {
  return (
    <div className={classNames(messageClassnames.container, className)} {...rest}>
      {LeftIcon && <LeftIcon className={classNames(messageClassnames.icon, leftIconClassName)} />}
      <div className={messageClassnames.childrenWrapper}>{children}</div>
      {onClose && <IconClose className={messageClassnames.closeIcon} onClick={onClose} />}
    </div>
  );
};
