import React, { memo, PropsWithChildren, RefObject } from 'react';
import classNames from 'classnames';
import PlayerErrorBoundary from '../../Error/ErrorBoundary';
import PlayerWrappers from '../../PlayerWrapper/PlayerWrappers';
import { playerProviderClassnames } from './PlayerProvider.css';

export interface PlayerProviderProps {
  borderRadius?: boolean;
  className?: string;
  fluidAspect?: boolean;
  wrapperRef?: RefObject<HTMLDivElement>;
}

export const PlayerProvider = memo(
  ({
    borderRadius,
    children,
    className,
    wrapperRef,
    fluidAspect,
  }: PropsWithChildren<PlayerProviderProps>) => {
    const playerWrapperRef = React.useRef<HTMLDivElement>(null);

    return (
      <div
        className={classNames(playerProviderClassnames.playerStyleContainer, className, {
          [playerProviderClassnames.borderRadiusStyle]: borderRadius,
        })}
        id="player-provider"
      >
        <PlayerWrappers customRef={wrapperRef || playerWrapperRef} fluidAspect={fluidAspect}>
          <PlayerErrorBoundary>{children}</PlayerErrorBoundary>
        </PlayerWrappers>
      </div>
    );
  }
);
