import React from 'react';
import { FormElementProps, uniqueElementId } from '../../common';
import { TextArea } from '../../inputs/TextArea/TextArea';
import { ValidatedFormElementWrapper } from '../../ValidatedFormElementWrapper';

export interface ValidatedTextAreaProps
  extends FormElementProps,
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'aria-describedby' | 'id' | 'name'> {
  height?: number | string;
  maxWidth?: number | string;
  width?: number | string;
}

export const ValidatedTextArea = React.forwardRef<HTMLTextAreaElement, ValidatedTextAreaProps>(
  (
    {
      label,
      name,
      prefix,
      width = '100%',
      maxWidth = '100%',
      height = '100%',
      inverted = false,
      displayValidationStatus,
      error,
      ...restProps
    },
    ref
  ) => {
    // id by mělo být unikátní pro celou stránku
    const id = uniqueElementId(prefix, name);
    const idDescribedBy = uniqueElementId(prefix, `error-${name}`);

    return (
      <ValidatedFormElementWrapper
        error={displayValidationStatus ? error : undefined}
        errorId={idDescribedBy}
        id={id}
        inverted={inverted}
        label={label}
      >
        <TextArea
          aria-describedby={idDescribedBy}
          height={height}
          id={id}
          inverted={inverted}
          isValid={displayValidationStatus ? !error : undefined}
          maxWidth={maxWidth}
          name={name}
          ref={ref}
          width={width}
          {...restProps}
        />
      </ValidatedFormElementWrapper>
    );
  }
);
