/*
  Fuknce pro ovladani playeru klavesovymi zkratkami
  https://confluence.vecko.dev/pages/viewpage.action?pageId=983875
 */

import { KeyboardEvent } from 'react';
import { Keys, HbbtvDevice } from '../../../../constants';

interface KeyboardControlProps {
  audioDescription?: () => void;
  fullscreenEnter?: () => void;
  keyboardControlsHide?: () => void;
  mediaStop?: () => void;
  nextIndex?: () => void;
  playPause?: () => void;
  prevIndex?: () => void;
  seekBack?: () => void;
  seekForward?: () => void;
  seekFraction?: (fraction: number) => void;
  subtitles?: () => void;
  switchDebugMode?: () => void;
  toggleFullscreen?: () => void;
  toggleMute?: () => void;
  volumeDown?: () => void;
  volumeUp?: () => void;
}

export interface KeyboardControl {
  (
    event: KeyboardEvent<HTMLDivElement>,
    hbbtvState?: HbbtvDevice | null,
    controlsVisible?: boolean
  ): void;
}

/*
  Vyrobi funkci, ktera podle KeyboardEventy provede pozadovanou akci
 */
const KeyboardControlFactory =
  ({
    audioDescription,
    fullscreenEnter,
    toggleFullscreen,
    toggleMute,
    playPause,
    seekBack,
    seekForward,
    volumeDown,
    volumeUp,
    nextIndex,
    subtitles,
    seekFraction,
    prevIndex,
    switchDebugMode,
    keyboardControlsHide,
  }: KeyboardControlProps): KeyboardControl =>
  (event, hbbtvState, controlsVisible) => {
    let match = false;
    if (hbbtvState) {
      return false;
    }
    switch (event.code) {
      case Keys.TAB: {
        // preventDefault pokud jsou controls schovane, jinak focus na Tab zpusobuje bug
        // v animaci - nejdrive tedy jen zobrazime controls, az nasledne navigujeme
        if (!controlsVisible) {
          match = true;
        }
        break;
      }
      case Keys.SPACEBAR:
        if (playPause) {
          playPause();
        }
        match = true;
        break;
      case Keys.K:
        if (playPause) {
          playPause();
        }
        match = true;
        break;
      case Keys.PLAY_PAUSE:
        if (playPause) {
          playPause();
        }
        match = true;
        break;
      case Keys.F:
        if (fullscreenEnter) {
          fullscreenEnter();
        }
        match = true;
        break;
      case Keys.ESC:
        if (toggleFullscreen) {
          toggleFullscreen();
        }
        match = true;
        break;
      case Keys.M:
        if (toggleMute) {
          toggleMute();
        }
        match = true;
        break;
      case Keys.ARROW_RIGHT:
        if (event.ctrlKey) {
          if (nextIndex) {
            nextIndex();
          }
        } else if (seekForward) {
          seekForward();
        }
        match = true;
        break;
      case Keys.L:
        if (seekForward) {
          seekForward();
        }
        match = true;
        break;
      case Keys.J:
        if (seekBack) {
          seekBack();
        }
        match = true;
        break;
      case Keys.H:
        if (event.shiftKey) {
          if (keyboardControlsHide) {
            keyboardControlsHide();
          }
          match = true;
        } else {
          match = false;
        }
        break;
      case Keys.GREATER:
        if (nextIndex) {
          nextIndex();
        }
        match = true;
        break;
      case Keys.ARROW_LEFT:
        if (event.ctrlKey) {
          if (prevIndex) {
            prevIndex();
          }
        } else if (seekBack) {
          seekBack();
        }
        match = true;
        break;
      case Keys.C:
        if (subtitles) {
          subtitles();
        }
        match = true;
        break;
      case Keys.NUM_0:
        if (seekFraction) {
          seekFraction(0);
        }
        match = true;
        break;
      case Keys.NUM_1:
        if (seekFraction) {
          seekFraction(1);
        }
        match = true;
        break;
      case Keys.NUM_2:
        if (seekFraction) {
          seekFraction(2);
        }
        match = true;
        break;
      case Keys.NUM_3:
        if (seekFraction) {
          seekFraction(3);
        }
        match = true;
        break;
      case Keys.NUM_4:
        if (seekFraction) {
          seekFraction(4);
        }
        match = true;
        break;
      case Keys.NUM_5:
        if (seekFraction) {
          seekFraction(5);
        }
        match = true;
        break;
      case Keys.NUM_6:
        if (seekFraction) {
          seekFraction(6);
        }
        match = true;
        break;
      case Keys.NUM_7:
        if (seekFraction) {
          seekFraction(7);
        }
        match = true;
        break;
      case Keys.NUM_8:
        if (seekFraction) {
          seekFraction(8);
        }
        match = true;
        break;
      case Keys.NUM_9:
        if (seekFraction) {
          seekFraction(9);
        }
        match = true;
        break;
      case Keys.LESS:
        if (prevIndex) {
          prevIndex();
        }
        match = true;
        break;
      case Keys.ARROW_UP:
        if (volumeUp) {
          volumeUp();
        }
        match = true;
        break;
      case Keys.ARROW_DOWN:
        if (volumeDown) {
          volumeDown();
        }
        match = true;
        break;
      case Keys.A:
        if (audioDescription) {
          audioDescription();
        }
        match = true;
        break;
      case Keys.I:
        if (event.shiftKey && event.ctrlKey && switchDebugMode) {
          switchDebugMode();
          match = true;
        } else {
          match = false;
        }
        break;
      default:
        break;
    }

    if (match) {
      event.preventDefault();
    }
    return null;
  };

export default KeyboardControlFactory;
