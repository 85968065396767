import React, { memo } from 'react';
import { formatMessage } from '../../../../utils/formatMessage';
import BaseLoadingOverlay, {
  BaseLoadingOverlayProps,
} from '../BaseLoadingOverlay/BaseLoadingOverlay';

interface VODLoadingOverlayProps extends Omit<BaseLoadingOverlayProps, 'defaultMessage'> {
  defaultMessage?: string;
}

export const VODLoadingOverlay = memo(
  ({ defaultMessage: propsDefaultMessage, ...rest }: VODLoadingOverlayProps) => {
    const defaultMessage =
      propsDefaultMessage ||
      formatMessage({
        id: 'VODLoadingOverlay.loadingMessage',
        defaultMessage: 'Nahrávám video',
        description: 'Titulek při nahrávání videa',
      });

    return <BaseLoadingOverlay defaultMessage={defaultMessage} {...rest} />;
  }
);
