import React, { memo } from 'react';
import { IconHeadphones } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../items';

interface NoVideoButtonProps {
  className?: string;
  onClick?: () => void;
}

const NoVideoButton = ({ className, onClick }: NoVideoButtonProps) => {
  return (
    <PlayerNativeButton
      aria-label={VODPlayerControlsItemsDefinition[PlayerControlsItem.NO_VIDEO]}
      className={className}
      title={VODPlayerControlsItemsDefinition[PlayerControlsItem.NO_VIDEO]}
      onClick={onClick}
    >
      <IconHeadphones tabIndex={-1} />
    </PlayerNativeButton>
  );
};

export default memo(NoVideoButton);
