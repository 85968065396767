import React from 'react';
import { IconCheckMarkCircle } from '@czechtv/icons';
import { MessageProps } from './Message';
import { MessageInfo } from './MessageInfo';
import { messageSuccessClassnames } from './MessageSuccess.css';

export interface MessageSuccessProps extends Omit<MessageProps, 'children'> {
  description?: React.ReactNode;
  title?: React.ReactNode;
}

export const MessageSuccess = ({
  leftIcon = IconCheckMarkCircle,
  leftIconClassName,
  description,
  title,
  ...rest
}: MessageSuccessProps) => {
  return (
    <MessageInfo
      {...rest}
      description={description}
      leftIcon={leftIcon}
      leftIconClassName={leftIconClassName || messageSuccessClassnames.iconSuccess}
      title={title}
    />
  );
};
