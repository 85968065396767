export const getEnumKeys = <K extends string>(enumMap: Record<K, unknown>): K[] => {
  const keys: K[] = [];

  (Object.keys(enumMap) as K[]).forEach((key) => {
    // Vybere jen String prvky z enumu
    if (Number.isNaN(Number(key))) {
      keys.push(key);
    }
  });

  return keys;
};

export const getEnumValues = <I>(enumMap: Record<string, I>): I[] =>
  getEnumKeys(enumMap).map((key) => enumMap[key]);

export const getEnumValuesAsStrings = (enumMap: Record<string, string>): string[] =>
  getEnumValues(enumMap);
