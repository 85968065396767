import { useMemo } from 'react';
import {
  nielsenSubscriber,
  gemiusSubscriber,
  gtmSubscriber,
  adScriptSubscriber,
  AnalyticsSubscriber,
} from '@czechtv/analytics';
import { Product } from '../../constants';
import { getPlayerHasAnalyticsConsent } from '../../utils/cookies';
import { log } from '../../utils/logger';

export const getNielsenAppId = (origin: Product, isDev: boolean) => {
  const TEST_APP_ID = 'PDA91E2F7-078D-47EC-ACA4-2677EE94E1D1';
  const PROD_VECKO = 'PC70DE95F-5C3D-44FA-9126-C983CEB9D1DA';

  if (isDev) {
    return TEST_APP_ID;
  }

  switch (origin) {
    case Product.ct24:
      return 'P19860005-6C2B-4699-A21C-8071E2A198D2';
    case Product.ct4:
      return 'P84B61B84-7BEA-464E-812E-59EE2473A332';
    default:
      return PROD_VECKO;
  }
};

type Props = {
  dev?: boolean;
  gemiusId?: string;
  gemiusPlayerId?: string;
  nielsenAppId?: string;
  nielsenDebug?: boolean;
  product: Product;
  suppressAnalytics?: boolean;
};

export const useDefaultAnalyticsSubscribers = ({
  suppressAnalytics,
  dev = false,
  nielsenDebug = false,
  product,
  nielsenAppId: customNielsenAppId,
  gemiusPlayerId: customGemiusPlayerId,
  gemiusId: customGemiusId,
}: Props) => {
  const nielsenAppId = customNielsenAppId || getNielsenAppId(product, dev);
  const gemiusPlayerId = customGemiusPlayerId || (dev ? 'czech-tv-html5_' : 'czech-tv-html5');
  const gemiusId =
    customGemiusId ||
    (dev
      ? 'AAAAAAAAAAAA.AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA.H7'
      : 'nAuadsv3NzD0.aB0W78wGZbfTBLg0uxfjsL2yYaNkPD.H7');

  const consent = getPlayerHasAnalyticsConsent();

  const nielsonClient = useMemo(() => {
    if (suppressAnalytics) {
      return null;
    }
    log.info({ message: `Consent with analytics: ${consent}` });
    // nesmime nahrat analyticky script, pokud to uzivatel neodsouhlasil v cookie liste
    if (!consent) {
      return null;
    }
    return nielsenSubscriber({
      // testovací id
      id: nielsenAppId,
      debug: nielsenDebug,
    });
  }, [consent, nielsenAppId, nielsenDebug, suppressAnalytics]);

  const gemiusClient = useMemo(() => {
    if (suppressAnalytics) {
      return null;
    }
    // pred nactenim scriptu nastavime promennou, ktera rika, jestli mame consent
    (window as any).pp_gemius_consent = consent;
    (window as any).pp_gemius_gdpr_applies = true;
    (window as any).pp_gemius_gdpr_consent = consent;
    (window as any).pp_gemius_dmp_purpose = consent;
    return gemiusSubscriber({
      id: gemiusPlayerId,
      gemiusId,
    });
  }, [gemiusPlayerId, gemiusId, suppressAnalytics, consent]);

  const gtmClient = useMemo(() => {
    if (suppressAnalytics) {
      return null;
    }
    // GTM si resi Optanon
    return gtmSubscriber();
  }, [suppressAnalytics]);

  const adScriptClient = useMemo(() => {
    if (suppressAnalytics) {
      return null;
    }
    // Ad Script = Nielsen - nesmime nahrat analyticky script
    if (!consent) {
      return null;
    }
    return adScriptSubscriber({});
  }, [suppressAnalytics, consent]);

  const subscribers = useMemo<AnalyticsSubscriber[]>(
    () =>
      [nielsonClient, gemiusClient, gtmClient, adScriptClient].filter(
        (subscriber) => !!subscriber
      ) as AnalyticsSubscriber[],
    [nielsonClient, gemiusClient, gtmClient, adScriptClient]
  );

  return subscribers;
};
