import { Encoder } from '@czechtv/utils';

export class VodIsLiveError extends Error {
  channel: Encoder;

  constructor(message: string, channel: Encoder) {
    super(message);
    this.channel = channel;
  }
}
