import { AnalyticsSubscriber } from '../common';
import { adScriptSdkHandler } from './adScriptSdkHandler';
import { AdScriptEvents, AdScriptAdMeta, AdScriptMeta } from './adScriptTypes';
import { mainContentToMeta, adToMeta } from './adScriptTransformers';

declare global {
  interface Window {
    JHMT: JhmtApi;
  }
}

interface JhmtApi {
  push: (args: [string, AdScriptMeta | AdScriptAdMeta]) => void;
}

interface AdScriptSubscriberConfig {
  clientId?: string;
  implementationId?: string;
}

export const adScriptSubscriber = ({
  clientId = 'cz-509218',
  implementationId = 'P01G5A2B',
}: AdScriptSubscriberConfig): AnalyticsSubscriber => {
  let jhmtApi: JhmtApi;
  let disabled = false;

  if (typeof window === 'undefined') {
    return {
      name: 'ssr',
      callback: () => {},
    };
  }

  const fireEvent = async (eventName: string, metadataObject: AdScriptMeta | AdScriptAdMeta) => {
    if (disabled) {
      return;
    }
    if (!jhmtApi) {
      try {
        await adScriptSdkHandler(clientId, implementationId);
        if (window.JHMT) {
          jhmtApi = window.JHMT;
        } else {
          disabled = true;
        }
      } catch (e) {
        disabled = true;
        // eslint-disable-next-line no-console
        console.info({
          message: 'Failed to load JHMT Api for AdScript. Skipping AdScript analytics events.',
          error: e,
        });
      }
    }

    if (jhmtApi) {
      // eslint-disable-next-line no-console
      jhmtApi.push([eventName, metadataObject]);
    }
  };

  return {
    name: 'adScript',
    callback: (event) => {
      switch (event.type) {
        case 'PlayerPlay':
        case 'PlayerReplay':
        case 'PlayerLiveStreamChanged':
          fireEvent(AdScriptEvents.START, mainContentToMeta(event.context));
          break;
        case 'PlayerAdPlay':
          fireEvent(AdScriptEvents.START, adToMeta(event.context));
          break;
        case 'PlayerEnd':
          fireEvent(AdScriptEvents.COMPLETE, mainContentToMeta(event.context));
          break;
        case 'PlayerAdEnd':
          fireEvent(AdScriptEvents.COMPLETE, adToMeta(event.context));
          break;
        case 'Player1stSecond':
          fireEvent(AdScriptEvents.PROGRESS1, mainContentToMeta(event.context));
          break;
        case 'Player1stQuartile':
          fireEvent(AdScriptEvents.FIRST_QUARTILE, mainContentToMeta(event.context));
          break;
        case 'Player2ndQuartile':
          fireEvent(AdScriptEvents.MIDPOINT, mainContentToMeta(event.context));
          break;
        case 'Player3rdQuartile':
          fireEvent(AdScriptEvents.THIRD_QUARTILE, mainContentToMeta(event.context));
          break;
        case 'PlayerAd1stSecond':
          fireEvent(AdScriptEvents.PROGRESS1, adToMeta(event.context));
          break;
        case 'PlayerAd1stQuartile':
          fireEvent(AdScriptEvents.FIRST_QUARTILE, adToMeta(event.context));
          break;
        case 'PlayerAd2ndQuartile':
          fireEvent(AdScriptEvents.MIDPOINT, adToMeta(event.context));
          break;
        case 'PlayerAd3rdQuartile':
          fireEvent(AdScriptEvents.THIRD_QUARTILE, adToMeta(event.context));
          break;
        default:
          break;
      }
    },
  };
};
