import classNames from 'classnames';
import React, { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactNode, Ref } from 'react';
import { MandatoryStatus } from '../../MandatoryStatus/MandatoryStatus';
import { checkboxClassnames } from './Checkbox.css';
import { CheckboxIcon } from './CheckboxIcon';

export interface CheckboxProps<Value extends boolean | boolean[] = boolean>
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'type' | 'checked' | 'value' | 'id'
  > {
  className?: string;
  description?: ReactNode;
  idDescribedBy?: string;
  inverted?: boolean;
  isChecked?: Value;
  isValid?: boolean;
  item: Value extends boolean ? CheckboxItem : CheckboxItem[];
  onChange?: (event: ChangeEvent, isChecked: Value) => void;
}

export interface CheckboxItem {
  id: string;
  required?: boolean;
  title: string | React.ReactNode;
}

const CheckboxComponent = <Value extends boolean | boolean[] = boolean>(
  {
    idDescribedBy,
    className,
    item,
    inverted = false,
    isChecked,
    isValid,
    disabled,
    name,
    description,
    onChange = () => {},
    ...rest
  }: CheckboxProps<Value>,
  ref: Ref<HTMLInputElement>
) => {
  // pokud prijde item jako single object zabalit ho do array aby splnoval CheckboxItem[]
  const checkbox = Array.isArray(item) ? item : [item];

  const checkboxChecked: (boolean | undefined)[] = Array.isArray(isChecked)
    ? isChecked
    : [isChecked];

  const handleCheckboxChange = (event: ChangeEvent, key: number) => {
    const arrayWithoutRef = [...checkboxChecked];
    arrayWithoutRef[key] = !arrayWithoutRef[key];
    onChange(event, (Array.isArray(isChecked) ? arrayWithoutRef : arrayWithoutRef[key]) as Value);
  };

  return (
    <div
      className={classNames(inverted, {
        multiple: checkbox.length > 1,
      })}
    >
      {checkbox.map(({ title, id, required }, key) => (
        <div
          className={classNames(checkboxClassnames.checkbox, className, {
            inverted,
          })}
          data-testid={id}
          key={id}
        >
          <input
            {...rest}
            aria-describedby={idDescribedBy}
            checked={checkboxChecked[key]}
            className={classNames(checkboxClassnames.input, {
              inverted,
              invalid: isValid === false,
            })}
            disabled={disabled}
            id={id}
            name={name}
            ref={ref}
            required={required}
            type="checkbox"
            value={id}
            onChange={(event) => {
              handleCheckboxChange(event, key);
            }}
          />
          <CheckboxIcon disabled={disabled} inverted={inverted} />
          <label className={classNames(checkboxClassnames.label, { inverted })} htmlFor={id}>
            <span>
              {title}
              <MandatoryStatus required={required} />
            </span>
          </label>
          {description && (
            <div className={classNames(checkboxClassnames.description)} id={idDescribedBy}>
              {description}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

// potřebuju přechcat typescript, aby mi umožnil nastavit generické typy pro forward ref
type CheckboxComponentType = <Value extends boolean | boolean[] = boolean>(
  props: CheckboxProps<Value>,
  ref?: React.Ref<HTMLInputElement>
) => React.ReactElement | null;

export const Checkbox = React.forwardRef(CheckboxComponent) as CheckboxComponentType;
