import { SHAKA_QUOTA_EXCEEDED_ERROR, ShakaError } from '../../Providers/Client/Shaka/ShakaError';
import { NativeClientError } from '../../Providers/Client/Native/NativeClientError';
import { PlayerErrorCategory } from './PlayerErrorCategory';

export interface PlayerNormalizedError {
  category: PlayerErrorCategory;
  code?: number;
  message?: string;
  wasNetworkOnline: boolean;
}

export const isNetworkOnline = (): boolean => navigator.onLine;

export const getPlayerError = (rawError: unknown): PlayerNormalizedError => {
  const defaultData = {
    category: PlayerErrorCategory.APPLICATION,
    wasNetworkOnline: isNetworkOnline(),
  };

  if (rawError instanceof ShakaError) {
    return {
      ...defaultData,
      category: rawError.playerErrorCategory,
      code: rawError.getCode(),
      message: rawError.message,
    };
  }

  if (rawError instanceof NativeClientError) {
    return {
      ...defaultData,
      category: rawError.playerErrorCategory,
      message: rawError.message,
    };
  }

  return defaultData;
};

export const isRecoverableError = (playerError: PlayerNormalizedError) => {
  return (
    [PlayerErrorCategory.TEXT, PlayerErrorCategory.NETWORK].includes(playerError.category) ||
    playerError.code === SHAKA_QUOTA_EXCEEDED_ERROR
  );
};
