/* eslint-disable canonical/filename-no-index */
import React, { memo, RefObject } from 'react';
import { ScreenMode } from '../../constants';
import { PlayerProvider } from '../Loader/Provider/PlayerProvider';
import { VODLoadingOverlay } from '../../Player/Overlay/VOD/LoadingOverlay/LoadingOverlay';

interface PlayerLoadingOverlayProps {
  borderRadius?: boolean;
  defaultMessage?: string;
  fluidAspect?: boolean;
  previewImage?: string;
  screenMode?: ScreenMode;
  showProgress?: boolean;
  wrapperRef?: RefObject<HTMLDivElement>;
}

export const PlayerLoadingOverlay = memo(
  ({
    previewImage,
    wrapperRef,
    screenMode,
    showProgress,
    defaultMessage,
    fluidAspect,
    borderRadius,
  }: PlayerLoadingOverlayProps) => {
    return (
      <PlayerProvider borderRadius={borderRadius} fluidAspect={fluidAspect} wrapperRef={wrapperRef}>
        {/* Prázdný video tag pro SEO účely */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video hidden poster={previewImage} />
        <VODLoadingOverlay
          defaultMessage={defaultMessage}
          screenMode={screenMode}
          showProgress={showProgress}
        />
      </PlayerProvider>
    );
  }
);
