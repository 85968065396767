import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { aspectRatioPlaceholderClassnames } from './AspectRatioPlaceholder.css';

export interface AspectRatioPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  aspectRatio?: number;
  children?: React.ReactNode;
}
export const AspectRatioPlaceholder = ({
  aspectRatio = 16 / 9,
  className,
  style,
  ...rest
}: AspectRatioPlaceholderProps) => {
  return (
    <div
      className={classNames(aspectRatioPlaceholderClassnames.element, className)}
      style={{ ...style, paddingBottom: `${(1 / aspectRatio) * 100}%` }}
      {...rest}
    />
  );
};
