/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import { INDICATOR_SIZE } from '../../../../../components/ProgressBar/constants';

export interface IndexItemProps {
  description?: string | null;
  imageUrl: string | null;
  indexId: string;
  startTime: number;
  title: string | null;
  url?: string;
}

interface IndexListProps {
  indexes: IndexItemProps[];
  videoDuration: number;
}

const useStyles = createUseStyles({
  indexIndicator: {
    width: INDICATOR_SIZE,
    position: 'absolute',
    backgroundColor: '#f0f000',
    height: INDICATOR_SIZE,
    pointerEvents: 'none',
    left: `-${INDICATOR_SIZE / 2}px`,
  },
  progressBarIndexList: {
    width: '100%',
    height: 'inherit',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    overflow: 'hidden',
  },
  listWrapper: {
    margin: 0,
    listStyle: 'none',
    height: 4,
    position: 'relative',
    width: '100%',
  },
  progressBarIndex: {
    zIndex: 2,
    height: 'inherit',
    width: 0,
    position: 'absolute',
  },
});

const IndexList = ({ indexes, videoDuration }: IndexListProps) => {
  const classes = useStyles();

  let progressBarIndexList: JSX.Element[] = [];

  if (indexes && videoDuration > 0) {
    progressBarIndexList = indexes.map((item: IndexItemProps) => {
      const position = (item.startTime / videoDuration) * 100;
      return (
        <li
          className={classes.progressBarIndex}
          data-testid="IndexListItem"
          key={item.indexId}
          style={{ left: `${position}%` }}
        >
          <div
            className={classes.indexIndicator}
            // TODO zjistit jak spravne resit indexy z hlediska pristupnosti
            onKeyDown={() => {}}
          />
        </li>
      );
    });
  }

  return (
    <div className={classes.progressBarIndexList} data-testid="IndexList">
      <ul className={classes.listWrapper}>{progressBarIndexList}</ul>
    </div>
  );
};

export default memo(IndexList);
