import React, { memo, useCallback, useEffect, useState } from 'react';
import { Keys, ScreenMode } from '../../constants';
import { VideoIndexList } from '../../Player/Controls/VOD/VideoIndexList/VideoIndexList';
import { ReadyOverlay } from '../../Player/Overlay/VOD/ReadyOverlay/ReadyOverlay';
import { IndexItemProps } from '../../Player/Controls/VOD/ProgressBar/IndexList';

export interface VideoMeta {
  ageRestriction?: string;
  duration?: number;
  indexes: IndexItemProps[];
  isAudioOnly?: boolean;
  showTitle?: string;
  title?: string;
}

interface PlayerPreviewProps {
  canPlayOnlySound?: boolean;
  isAudioOnly?: boolean;
  licenseInfo?: string;
  meta: VideoMeta;
  play: (startTime?: number) => void;
  previewImage?: string;
  showSimpleVideoHeader?: boolean;
  startsAt?: number;
  videoStartTime?: number;
}

const PlayerPreview = memo(
  ({
    videoStartTime = 0,
    previewImage,
    meta,
    canPlayOnlySound = false,
    play,
    licenseInfo,
    showSimpleVideoHeader,
    isAudioOnly,
    startsAt,
  }: PlayerPreviewProps) => {
    const { indexes } = meta;

    const [indexMenuVisible, setIndexMenuVisible] = useState<boolean | undefined>(undefined);

    const onClickIndexItem = useCallback(
      (id: string) => {
        const clickedIndex = indexes.find((index) => index.indexId === id);
        if (!clickedIndex) {
          return;
        }
        play(clickedIndex.startTime);
      },
      [indexes, play]
    );
    const showIndexMenu = useCallback(() => {
      setIndexMenuVisible(true);
    }, []);
    const hideIndexMenu = useCallback(() => {
      setIndexMenuVisible(false);
    }, []);
    const onPlay = useCallback(() => {
      play();
    }, [play]);

    useEffect(() => {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.code === Keys.K) {
          play();
        }
      };

      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }, [play]);

    const indexesAvailable = !!indexes?.length;

    return (
      <>
        {/* Prázdný video tag pro SEO účely */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video hidden poster={previewImage} />

        {indexesAvailable && !startsAt && (
          <VideoIndexList
            playerPreview
            indexListVisible={indexMenuVisible}
            indexes={indexes}
            licenseInfo={licenseInfo}
            onClickCloseButton={hideIndexMenu}
            onClickIndexItem={onClickIndexItem}
          />
        )}
        <ReadyOverlay
          canPlayOnlySound={canPlayOnlySound}
          isAudioOnly={isAudioOnly}
          licenseInfo={licenseInfo}
          meta={meta}
          previewImage={previewImage}
          progress={meta.duration ? (videoStartTime / meta.duration) * 100 : 0}
          screenMode={ScreenMode.NORMAL}
          showSimpleVideoHeader={showSimpleVideoHeader}
          startsAt={startsAt}
          onClick={onPlay}
          onIndexListVisible={showIndexMenu}
          onPlayButtonClick={onPlay}
        />
      </>
    );
  }
);

export { PlayerPreview };
