export const ZINDEX_VIDEO_HEADER = 0;
export const ZINDEX_OVERLAY = 2;
export const ZINDEX_OVERLAY_FULLSCREEN = 2;
export const ZINDEX_CONTROLS_CONTAINER = 3;
export const ZINDEX_CONTROLS = 3;
export const ZINDEX_PROGRESS_BAR = 0;
export const ZINDEX_RELATED_VIDEOS = 4;
export const ZINDEX_SETTINGS_MENU = 6;
export const ZINDEX_TOAST_MESSAGE = 4;
export const ZINDEX_VIDEO_INDEX_LIST = 7;
export const ZINDEX_AFTER_PLAY_NEXT = 8;
export const ZINDEX_PLAYER_POPUP = 8;
export const ZINDEX_BEFORE_PLAY_SKIP = 10;
export const ZINDEX_UNMUTE_BUTTON = 1;
export const ZINDEX_CAPTIONS = 1;
export const ZINDEX_VOLUME_CONTROLS = 5;
