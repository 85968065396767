import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { AspectRatioPlaceholder, Button } from '@czechtv/components';
import { body14, body18, header16, header24, hero36, pxToRem } from '@czechtv/styles';
import GradientOverlay from '../../Player/Overlay/VOD/GradientOverlay/GradientOverlay';
import {
  Breakpoint,
  getPlayerResponsiveRule,
  useMediaBreakpoints,
} from '../../utils/playerResponsive';
import PlayerBasedResponsive from '../PlayerBasedResponsive/PlayerBasedResponsive';

export interface InfoOverlayProps {
  buttonText: string;
  onClick: () => void;
  short?: string;
  subtitle: string;
  title: string;
}

const useStyles = createUseStyles({
  overlayContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    outline: 'none',
    overflow: 'hidden',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    maxWidth: 560,
    [getPlayerResponsiveRule(Breakpoint.isMaxTablet)]: {
      maxWidth: 416,
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      maxWidth: 288,
    },
  },
  title: {
    ...hero36,
    fontWeight: 700,
    color: '#fff',
    paddingBottom: 24,
    whiteSpace: 'pre-wrap',
    '&.separator': {
      borderBottom: '1px solid rgba(255,255,255, .3)',
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxTablet)]: {
      ...header24,
      fontWeight: 500,
      paddingBottom: 12,
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      ...header16,
      fontWeight: 500,
      lineHeight: pxToRem(19),
      paddingBottom: 10,
    },
  },
  subtitle: {
    ...body18,
    marginTop: 21,
    color: '#fff',
    [getPlayerResponsiveRule(Breakpoint.isMaxTablet)]: {
      fontSize: pxToRem(16),
      marginTop: 7,
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      ...body14,
    },
  },
  footer: {
    extend: body14,
    display: 'flex',
    marginTop: 25,
    color: 'rgba(255, 255, 255, 0.7)',
    alignContent: 'center',
    alignItems: 'center',
    '& > span': {
      lineHeight: '18px',
    },
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      marginTop: 10,
    },
  },
  button: {
    margin: [0, 32, 0, 0],
    minWidth: 150,
    [getPlayerResponsiveRule(Breakpoint.isMaxMediumMobile)]: {
      minWidth: 125,
      marginRight: 8,
    },
  },
});

const InfoOverlay = ({ title, subtitle, short, buttonText, onClick }: InfoOverlayProps) => {
  const classes = useStyles();
  const { isMaxMediumDesktop, isMaxMediumMobile } = useMediaBreakpoints();

  return (
    <div className={classes.overlayContainer}>
      <GradientOverlay className={classes.overlay}>
        <div className={classes.wrapper}>
          <div className={classNames(classes.title)}>{title}</div>
          <div className={classes.subtitle}>{isMaxMediumMobile && short ? short : subtitle}</div>
          {onClick && (
            <div className={classes.footer}>
              <Button
                className={classes.button}
                size={isMaxMediumDesktop ? 'medium' : 'large'}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </GradientOverlay>
    </div>
  );
};

export const InfoOverlayStandalone = (props: InfoOverlayProps) => {
  return (
    <PlayerBasedResponsive>
      <AspectRatioPlaceholder>
        <InfoOverlay {...props} />
      </AspectRatioPlaceholder>
    </PlayerBasedResponsive>
  );
};
