// slouzi kvuli kompatibilite s verzi Reactu, kde neni nativni useId
// toto reseni by melo fungovat i v ramci SSR a client-side hydrataces
// vhodne pro generovani id pro html elementy atp.
// pro pouziti je potreba relevantni cast aplikace zabalit do provideru

import React, { createContext, useContext, useRef } from 'react';

interface IdContextType {
  getId: () => string;
}

const IdContext = createContext<IdContextType | null>(null);

export const IdProvider = ({
  children,
  uniquePrefix = 'ctv-id-',
}: {
  children: React.ReactNode;
  uniquePrefix?: string;
}) => {
  const idRef = useRef(0);
  const getId = (): string => {
    const newId = `${uniquePrefix}${idRef.current}`;
    idRef.current += 1;
    return newId;
  };

  return <IdContext.Provider value={{ getId }}>{children}</IdContext.Provider>;
};

export const useId = () => {
  const context = useContext(IdContext);

  if (!context) {
    throw new Error('useId must be used within an IdProvider');
  }

  // pouzito na tvorbu perzistentniho ID pro instanci komponenty
  const idRef = useRef<string | null>(null);

  if (idRef.current === null) {
    idRef.current = context.getId();
  }

  return idRef.current;
};
