/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAnalytics } from '@czechtv/analytics-react';
import { log } from '../logger';
import {
  CT_CHROMECAST_RECEIVER,
  ChromecastDevice,
  PlayerVariantEnum,
  VideoSubtitles,
} from '../../constants';
import { PlayerAudioTrack, PlayerClient } from '../../Providers/Client';
import PlayerClientState from '../../Providers/Client/state';
import { transformEncoderName } from '../transformEncoderName';
import { initChromecastLib } from './lib/chromecastSender';
import { ChromecastTrack } from './types';

interface UseChromecastProps {
  disabled?: boolean;
  mainContentId: string;
  playerClient: PlayerClient;
  playerVariant: PlayerVariantEnum;
  previewImage?: string;
  streamUrl: string | null;
  subtitles?: VideoSubtitles[];
}

export enum ChromecastState {
  BUFFERING = 'BUFFERING',
  CONNECTING = 'CONNECTING',
  IDLE = 'IDLE',
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  UNKNOWN = 'UNKNOWN',
}

enum ChromecastTrackType {
  audio = 'AUDIO',
  subtitles = 'TEXT',
}

// Bohuzel je potreba resit takto. Pokud se totiz unmountne hook,
// tak se pri dalsim mountu uz nezavola "__onGCastApiAvailable" callback.
// Nepomuze v tomto ani odstranit a znovu pridat skript s Chromecastem.
let isAvailableCached = false;

const CHROMECAST_ANALYTICS_DATA = { chromecast: true };

const getActiveIdsForTrackType = (
  trackType: ChromecastTrackType,
  castSession: any,
  trackCriterion: (track: ChromecastTrack) => boolean
): number[] => {
  const mediaSession = castSession.getMediaSession();
  const mediaTracks: ChromecastTrack[] = mediaSession.media.tracks || [];
  const currentlyActiveIds: number[] = mediaSession.activeTrackIds || [];

  const availableTracks = mediaTracks
    .filter((track) => track.type === trackType)
    .map((track) => track.trackId);

  const currentlyActiveNonTrackIds = currentlyActiveIds.filter(
    (id) => !availableTracks.includes(id)
  );

  const selectedTrack = mediaTracks.find(trackCriterion);
  const selectedTrackId = selectedTrack?.trackId;

  return selectedTrackId
    ? [selectedTrackId, ...currentlyActiveNonTrackIds]
    : [...currentlyActiveNonTrackIds];
};

export const useChromecast = ({
  disabled,
  mainContentId,
  playerClient,
  playerVariant,
  previewImage,
  streamUrl,
  subtitles,
}: UseChromecastProps) => {
  const [isChromecastApiAvailable, setIsChromecastAvailable] = useState(isAvailableCached);
  const [activeChromecastDevice, setActiveChromecastDevice] = useState<ChromecastDevice | null>(
    null
  );
  const [chromecastState, setChromecastState] = useState<ChromecastState>(ChromecastState.UNKNOWN);
  const [selectedChromecastSubtitles, setSelectedChromecastSubtitles] =
    useState<VideoSubtitles | null>(null);
  const [selectedChromecastAudioTrack, setSelectedChromecastAudioTrack] =
    useState<PlayerAudioTrack | null>(null);
  const availableAudioTracksRef = useRef<PlayerAudioTrack[]>([]);

  const playerRef = useRef<any>(null);
  const controllerRef = useRef<any>(null);
  const requestRef = useRef<any>(null);

  const currentChromecastTime = useRef(playerClient.getCurrentTime());
  const onTimeUpdateCallbackRef = useRef<Function | null>(null);
  const onVolumeUpdateCallbackRef = useRef<Function | null>(null);
  const onActiveIndexCheckCallbackRef = useRef<Function | null>(null);

  const analytics = useAnalytics();
  // pro usnadneni prace s window.cast a window.chrome.cast
  const cast =
    typeof window !== 'undefined' && isChromecastApiAvailable ? (window.cast as any) : undefined;
  const chromeCast =
    typeof window !== 'undefined' && isChromecastApiAvailable && window.chrome
      ? (window.chrome.cast as any)
      : undefined;

  /**
   * Inicializace Chromecast skriptu a kontrola dostupnosti
   */
  useEffect(() => {
    if (!streamUrl || disabled || isAvailableCached) {
      return;
    }
    const loadCastSDK = async () => {
      const scriptLoaded = await initChromecastLib();
      if (scriptLoaded) {
        (window as any).__onGCastApiAvailable = (_isAvailable: boolean) => {
          setIsChromecastAvailable(_isAvailable);
          isAvailableCached = _isAvailable;
        };
      }
    };
    void loadCastSDK();
  }, [streamUrl, disabled]);

  /**
   * Nastaveni pripojeni k Chromecastu
   */
  useEffect(() => {
    if (streamUrl && isChromecastApiAvailable && cast) {
      if (!chromeCast) {
        setIsChromecastAvailable(false);
        return;
      }
      cast.framework.CastContext.getInstance().setOptions({
        receiverApplicationId: CT_CHROMECAST_RECEIVER,
        autoJoinPolicy: chromeCast.AutoJoinPolicy.PAGE_SCOPED,
      });
    }
  }, [streamUrl, isChromecastApiAvailable, chromeCast, cast]);

  useEffect(() => {
    if (!streamUrl || !isChromecastApiAvailable || disabled || !cast || !chromeCast) {
      return;
    }
    const { nielsen } = analytics.getContext();
    const mediaInfo = new chromeCast.media.MediaInfo(streamUrl, 'application/dash+xml');

    mediaInfo.duration = playerClient.getDuration();

    if (subtitles?.length) {
      mediaInfo.tracks = subtitles.map((sub, id) => {
        const track = new chromeCast.media.Track(id + 1, chromeCast.media.TrackType.TEXT);
        track.trackContentId = sub.url;
        track.trackContentType = 'text/vtt';
        track.subtype = chromeCast.media.TextTrackType.SUBTITLES;
        track.name = sub.title;
        track.language = sub.code;
        return track;
      });
    }

    const mediaMetadata = new chromeCast.media.MovieMediaMetadata();
    const title =
      playerVariant === PlayerVariantEnum.LIVE
        ? transformEncoderName(mainContentId)
        : nielsen.program;
    mediaMetadata.title = title;
    mediaMetadata.subtitle = playerVariant;
    mediaMetadata.images = [
      {
        url: previewImage || '',
      },
    ];

    Object.keys(nielsen).forEach((key) => {
      mediaMetadata[key === 'title' ? 'nielsenTitle' : key] = nielsen[key];
    });

    mediaInfo.metadata = mediaMetadata;
    requestRef.current = new chromeCast.media.LoadRequest(mediaInfo);
  }, [
    analytics,
    cast,
    chromeCast,
    disabled,
    isChromecastApiAvailable,
    mainContentId,
    playerClient,
    previewImage,
    playerVariant,
    streamUrl,
    subtitles,
  ]);

  // kvuli performance nechceme pres cely context kazdou chvili posilat zmenu stavu,
  // coz by zapricinilo zbytecne rerendery - posouvani volume slideru a progress resime
  // pres callbacky
  const setTimeUpdateCallback = useCallback((cb: (value: number) => void) => {
    onTimeUpdateCallbackRef.current = cb;
  }, []);

  const setVolumeUpdateCallback = useCallback((cb: (value: number) => void) => {
    onVolumeUpdateCallbackRef.current = cb;
  }, []);

  const setActiveIndexCheckCallback = useCallback((cb: (value: number) => void) => {
    onActiveIndexCheckCallbackRef.current = cb;
  }, []);

  useEffect(() => {
    if (!cast) {
      return () => {};
    }
    const analyticsContext = analytics.getContext();

    playerRef.current = new cast.framework.RemotePlayer();
    controllerRef.current = new cast.framework.RemotePlayerController(playerRef.current);

    const controller = controllerRef.current;
    const castContext = cast.framework.CastContext.getInstance();

    const onTimeUpdate = (event: { value: number }) => {
      if (!event.value) {
        return;
      }
      currentChromecastTime.current = event.value;
      if (onTimeUpdateCallbackRef.current) {
        onTimeUpdateCallbackRef.current(event.value);
      }

      if (onActiveIndexCheckCallbackRef.current) {
        onActiveIndexCheckCallbackRef.current();
      }

      // tuto cast je nutne synchronizovat s klasickym prehravanim
      if (!playerRef.current.isPaused) {
        playerClient.addToPlayedTimeSet(Math.floor(currentChromecastTime.current));

        const playedTime = playerClient.getPlayedTime();
        const lastReportedProgress = playerClient.getLastReportedProgress();
        // pro LIVE hlasime kazdou sekundu
        if (playerVariant === PlayerVariantEnum.LIVE) {
          analytics.trigger({ type: 'PlayerProgress', data: CHROMECAST_ANALYTICS_DATA });
          // pro VOD hlasime kadych 5%
        } else {
          const currentTime = Math.floor(playerRef.current.currentTime);
          const duration = Math.floor(playerRef.current.duration);
          const progress = Math.floor((playedTime / duration) * 100);
          if (progress - lastReportedProgress >= 5) {
            playerClient.setLastReportedProgress(progress);
            analytics.trigger({ type: 'PlayerProgress', data: CHROMECAST_ANALYTICS_DATA });
          }
          if (currentTime >= duration) {
            analytics.trigger({ type: 'PlayerEnd', data: CHROMECAST_ANALYTICS_DATA });
          }
        }
      }
    };

    const onPlayerStateChanged = (event: { value: ChromecastState }) => {
      setChromecastState(event.value);
    };

    const onCastStateChanged = async (event: {
      castState: 'CONNECTED' | 'CONNECTING' | 'NOT_CONNECTED';
    }) => {
      if (event.castState === 'CONNECTED') {
        const castSession = castContext.getCurrentSession();
        if (playerVariant === PlayerVariantEnum.VOD) {
          const startTime = playerClient.getCurrentTime();
          requestRef.current.currentTime = startTime;
        }

        // chromecast si pamatuje volume zmenene v posledni session - reflektujeme v UI
        if (playerRef.current && onVolumeUpdateCallbackRef.current) {
          if (playerRef.current.isMuted) {
            onVolumeUpdateCallbackRef.current(0);
          } else {
            onVolumeUpdateCallbackRef.current(playerRef.current.volumeLevel);
          }
        }

        try {
          playerClient.pause();
          await castSession.loadMedia(requestRef.current);
          const mediaSession = castSession.getMediaSession();

          if (mediaSession?.media?.tracks?.length) {
            const mediaTracks: ChromecastTrack[] = mediaSession.media.tracks || [];
            const availableAudioTracks = mediaTracks.filter((track) => track.type === 'AUDIO');
            availableAudioTracksRef.current = availableAudioTracks.map((track) => ({
              id: track.language,
              language: track.language,
            }));
            // neresime audiotrack na pocitaci, castovat zaciname vzdy prvni v rade, syncneme UI
            setSelectedChromecastAudioTrack(availableAudioTracksRef.current[0]);
            analytics.setContext({ ...analyticsContext, cast: 'chromecast' });
            analytics.trigger({
              type: 'PlayerSettingsChangeCast',
              data: CHROMECAST_ANALYTICS_DATA,
            });
          }
        } catch (error) {
          log.error({
            message: 'Error streaming to Chromecast',
            error: error as Error,
            // logData: { customData: { uri: streamUrl } },
          });
        }
      }
      if (event.castState === 'NOT_CONNECTED') {
        // TODO: tento stav nechavam pro budouci pouziti
      }
      if (event.castState === 'CONNECTING') {
        setChromecastState(ChromecastState.CONNECTING);
        setSelectedChromecastSubtitles(null);
      }
    };

    castContext.addEventListener(
      cast.framework.CastContextEventType.CAST_STATE_CHANGED,
      onCastStateChanged
    );
    controller.addEventListener(
      cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      onTimeUpdate
    );
    controller.addEventListener(
      cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      onPlayerStateChanged
    );

    return () => {
      castContext.removeEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        onCastStateChanged
      );
      controller.removeEventListener(
        cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
        onTimeUpdate
      );
      controller.removeEventListener(
        cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
        onPlayerStateChanged
      );
    };
  }, [analytics, cast, playerClient, playerVariant, streamUrl]);

  const toggleChromecast = useCallback(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (!isChromecastApiAvailable || !cast) {
      return () => {};
    }
    const castContext = cast.framework.CastContext.getInstance();

    const updateState = () => {
      const sessionState = castContext.getSessionState();
      if (sessionState === cast.framework.SessionState.SESSION_STARTED) {
        const device = castContext.getCurrentSession().getCastDevice() as ChromecastDevice;
        if (playerClient.getState() === PlayerClientState.PLAYING) {
          playerClient.pause();
        }
        setActiveChromecastDevice(device);
      }
      if (sessionState === cast.framework.SessionState.SESSION_ENDED) {
        setActiveChromecastDevice(null);
        if (currentChromecastTime.current) {
          if (playerVariant === PlayerVariantEnum.VOD) {
            playerClient.setCurrentTime(currentChromecastTime.current);
            playerClient.play();
          } else {
            playerClient.setCurrentTime(0);
          }
        }
        const analyticsContext = analytics.getContext();
        analytics.setContext({ ...analyticsContext, cast: 'off' });
        analytics.trigger({
          type: 'PlayerSettingsChangeCast',
          data: CHROMECAST_ANALYTICS_DATA,
        });
      }
    };

    castContext.addEventListener(
      cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
      updateState
    );

    return () => {
      castContext.removeEventListener(
        cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        updateState
      );
    };
  }, [analytics, cast, isChromecastApiAvailable, playerClient, playerVariant]);

  const onChromecastPlayPause = useCallback(() => {
    if (playerRef.current.isPaused) {
      analytics.trigger({ type: 'PlayerResume', data: CHROMECAST_ANALYTICS_DATA });
    } else {
      analytics.trigger({ type: 'PlayerPause', data: CHROMECAST_ANALYTICS_DATA });
    }
    controllerRef.current?.playOrPause();
  }, [analytics]);

  const onChromecastSeek = useCallback(
    (value: number) => {
      if (playerRef.current && controllerRef.current) {
        if (value > playerRef.current.currentTime) {
          analytics.trigger({ type: 'PlayerSeekForward', data: CHROMECAST_ANALYTICS_DATA });
        } else {
          analytics.trigger({ type: 'PlayerSeekBack', data: CHROMECAST_ANALYTICS_DATA });
        }
        playerRef.current.currentTime = value;
        controllerRef.current.seek();
      }
    },
    [analytics]
  );

  const onChromecastSeekBy = useCallback(
    (value: number) => {
      if (playerRef.current && controllerRef.current) {
        const current = playerRef.current.currentTime;
        const next = current + value;
        playerRef.current.currentTime = next;
        controllerRef.current.seek();
        if (next > current) {
          analytics.trigger({ type: 'Player10sForward', data: CHROMECAST_ANALYTICS_DATA });
        } else {
          analytics.trigger({ type: 'Player10sBack', data: CHROMECAST_ANALYTICS_DATA });
        }
      }
    },
    [analytics]
  );

  const onChromecastVolumeChange = useCallback((value: number) => {
    if (playerRef.current && controllerRef.current) {
      playerRef.current.volumeLevel = value;
      controllerRef.current.setVolumeLevel();
      if (onVolumeUpdateCallbackRef.current) {
        onVolumeUpdateCallbackRef.current(value);
      }
    }
  }, []);

  const onChromecastMute = useCallback(() => {
    if (controllerRef.current && onVolumeUpdateCallbackRef.current) {
      controllerRef.current.muteOrUnmute();
      // aktualizujeme UI
      if (playerRef.current.isMuted) {
        onVolumeUpdateCallbackRef.current(0);
      } else {
        onVolumeUpdateCallbackRef.current(playerRef.current.volumeLevel);
      }
      analytics.trigger({ type: 'PlayerSettingsChangeMute', data: CHROMECAST_ANALYTICS_DATA });
    }
  }, [analytics]);

  const onChromecastChangeTrack = useCallback(
    (
      type: ChromecastTrackType,
      data: VideoSubtitles | PlayerAudioTrack | null,
      setterFunction: (data: any) => void
    ) => {
      if (!cast || !chromeCast) {
        return;
      }
      const castSession = cast.framework.CastContext.getInstance().getCurrentSession();

      const trackCriterion = (track: ChromecastTrack) => {
        if (type === ChromecastTrackType.subtitles && data && 'url' in data) {
          return track.type === ChromecastTrackType.subtitles && track.trackContentId === data.url;
        }
        if (type === ChromecastTrackType.audio && data && 'language' in data) {
          return track.type === ChromecastTrackType.audio && track.language === data.language;
        }
        return false;
      };

      const activeIds = getActiveIdsForTrackType(type, castSession, trackCriterion);

      const request = new chromeCast.media.EditTracksInfoRequest(activeIds);
      castSession.getMediaSession().editTracksInfo(request);
      setterFunction(data);
    },
    [cast, chromeCast]
  );

  const onChromecastChangeSubtitles = useCallback(
    (subtitles: VideoSubtitles | null) => {
      onChromecastChangeTrack(
        ChromecastTrackType.subtitles,
        subtitles,
        setSelectedChromecastSubtitles
      );
    },
    [onChromecastChangeTrack]
  );

  const onChromecastChangeAudioTrack = useCallback(
    (audioTrack: PlayerAudioTrack | null) => {
      onChromecastChangeTrack(
        ChromecastTrackType.audio,
        audioTrack,
        setSelectedChromecastAudioTrack
      );
    },
    [onChromecastChangeTrack]
  );

  const getChromecastCurrentTime = useCallback(() => {
    if (!playerRef.current) {
      return 0;
    }
    return playerRef.current.currentTime as number;
  }, []);

  const getChromecastVolume = useCallback(() => {
    if (playerRef.current) {
      return playerRef.current.volumeLevel as number;
    }
    return 0;
  }, []);

  const isChromecastSession =
    !!activeChromecastDevice || chromecastState === ChromecastState.CONNECTING;

  return {
    activeChromecastDevice,
    chromecastState,
    getChromecastCurrentTime,
    getChromecastVolume,
    isChromecastApiAvailable,
    isChromecastSession,
    onChromecastChangeAudioTrack,
    onChromecastChangeSubtitles,
    onChromecastMute,
    onChromecastPlayPause,
    onChromecastSeek,
    onChromecastSeekBy,
    onChromecastVolumeChange,
    setTimeUpdateCallback,
    setActiveIndexCheckCallback,
    setVolumeUpdateCallback,
    selectedChromecastSubtitles,
    selectedChromecastAudioTrack,
    toggleChromecast,
  };
};
