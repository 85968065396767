import React, { memo, PropsWithChildren, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useAnalytics } from '@czechtv/analytics-react';
import { Encoder } from '@czechtv/utils';
import { PlayerLiveContext, PlayerVODContext } from '@czechtv/analytics';
import { LiveAnalyticsData } from '../../components/Loader/PlayerLoader';
import { VODAnalyticsData } from '../../components/Loader/VODtypings';
import { PLAYER_VERSION, ScreenMode } from '../../constants';
import { isIFrame } from '../../utils/isIFrame';
import { usePlayerSetup } from '../Setup/usePlayerSetup';
import { getAudioLanguageLabel } from '../../utils/getAudioLanguageLabel';

interface AnalyticsContextVODProps {
  analyticsData: VODAnalyticsData;
  audioTracks?: string[];
  duration?: number;
  indexId?: string;
  premiere?: string;
  subtitles?: any[];
  type: 'vod';
}
interface AnalyticsContextLiveProps {
  analyticsData: LiveAnalyticsData;
  encoder?: Encoder;
  type: 'live';
}

type Props = {
  assetId?: string;
  desiredAutoPlay?: boolean;
  externalId?: string;
  isAudioOnly?: boolean;
  mainContentId: string;
  origin?: string;
  product?: string;
  showId?: string;
  showTitle?: string;
  title?: string;
  versionId?: string;
} & (AnalyticsContextVODProps | AnalyticsContextLiveProps);

const isLivePlayer = (props: Partial<Props>): props is AnalyticsContextLiveProps =>
  props.type === 'live';

const AnalyticsContextFactory = (props: PropsWithChildren<Props>) => {
  const {
    assetId = '',
    analyticsData,
    showTitle = '',
    title = '',
    mainContentId,
    versionId = '',
    isAudioOnly,
    children,
    showId = '',
    externalId = '',
    product = '',
    origin = '',
    desiredAutoPlay,
  } = props;
  const analytics = useAnalytics();
  const { playbackId, playerWrapperRef } = usePlayerSetup();

  useEffect(() => {
    const instanceId = uuid();
    const baseContext = {
      gemius: analyticsData.gemius,
      gtm: analyticsData.gtm,
      origin,
      version: PLAYER_VERSION,
      inIframe: isIFrame(),
      playbackId,
      instanceId,
      mainContentId,
      width: playerWrapperRef.current?.offsetWidth,
      autoplay: !!desiredAutoPlay,
      audioOnly: !!isAudioOnly,
      playtime: 0,
      playbackRate: 1,
      screenmode: ScreenMode.NORMAL,
      ad: {
        asmea: '',
        id: '',
        nielsen: {
          type: '',
          assetid: '',
          nol_c4: '',
          nol_c5: '',
          nol_c6: '',
          title: '',
          length: '',
        },
        nielsenType: '',
        position: 0,
        skipAfter: 0,
        slot: 'preroll' as 'preroll' | 'postroll' | 'midroll',
        timeLength: 0,
        timePosition: 0,
        title: '',
        type: 'ad' as 'ad' | 'self-promo' | 'labeling',
      },
    };

    if (isLivePlayer(props)) {
      const liveContext: PlayerLiveContext = {
        ...baseContext,
        type: 'LIVE',
        nielsen: analyticsData.nielsen,
        position: 0,
        timeshift: 0,
        encoder: assetId || props.encoder || '',
      };
      analytics.setContext(liveContext);
    } else {
      const vodContext: PlayerVODContext = {
        ...baseContext,
        type: 'VOD',
        nielsen: analyticsData.nielsen,
        showTitle,
        title,
        product,
        versionId,
        // TODO: Doplnit resoving zda jde o plnou verzi videa (nejde o jeden index a
        //  neořezává se přes offset)
        isFullVideo: true,
        duration: props.duration || 0,
        playbackRate: 1,
        position: 0,
        // TODO: Doplnit reálné
        categories: [
          {
            fid1: 'Vymyslene FrontID 1',
            fid2: ['Vymyslene FrontID 2', 'Druhe vymyslene FrontID 2'],
          },
        ],
        // TODO: Doplnit chybu licence
        licenceErrorText: undefined,
        showId,
        // TODO: Vkládat pouze pokud externalId je idec
        idec: externalId,
        // TODO: Doplnit číselník ebu
        ebu: 'Fake číselník ebu',
        ...('indexId' in props ? { indexId: props.indexId } : {}),
        ...('premiere' in props && props.premiere ? { premiere: new Date(props.premiere) } : {}),
        // analytika chce poslat undefined v pripade, kdy titulky nemame
        // 'Vypnuto' posilame jen v pripade, ze titulky mame a nejsou zvolene
        ...('subtitles' in props && props.subtitles?.length ? { subtitles: 'Vypnuto' } : {}),
        ...('audioTracks' in props && props.audioTracks?.length
          ? { audiotrack: getAudioLanguageLabel(props.audioTracks[0]) }
          : {}),
      };
      analytics.setContext(vodContext);
    }

    analytics.trigger({ type: 'PlayerLoad' });

    const unload = () => analytics.trigger({ type: 'PlayerUnload' });

    window.addEventListener('beforeunload', unload);

    return () => {
      window.removeEventListener('beforeunload', unload);
      unload();
      analytics.setContext(null);
    };
    // změna hodnot by neměla vyvolat nový LoadEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  return <>{children}</>;
};

export default memo(AnalyticsContextFactory);
