import { PlayerControlsItem } from '../../Player/Controls/items';

export const LivePlayerControlsItems: PlayerControlsItem[] = [
  PlayerControlsItem.PLAY,
  PlayerControlsItem.TIMESHIFT_VIDEO_START,
  PlayerControlsItem.SEEK_BACK,
  PlayerControlsItem.SEEK_FORWARD,
  PlayerControlsItem.SOUND,
  PlayerControlsItem.CAST,
  PlayerControlsItem.SETTINGS,
  PlayerControlsItem.FULLSCREEN,
];
export const VODPlayerControlsItems: PlayerControlsItem[] = [
  PlayerControlsItem.PLAY,
  PlayerControlsItem.SEEK_BACK,
  PlayerControlsItem.SEEK_FORWARD,
  PlayerControlsItem.SOUND,
  PlayerControlsItem.CAST,
  PlayerControlsItem.SETTINGS,
  PlayerControlsItem.FULLSCREEN,
];

export const AdsPlayerControlsItems: PlayerControlsItem[] = [
  PlayerControlsItem.PLAY,
  PlayerControlsItem.SOUND,
  PlayerControlsItem.FULLSCREEN,
];

export const StreamingPlayerControlsItems: PlayerControlsItem[] = [PlayerControlsItem.CAST];
