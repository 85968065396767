import React, { memo, useState, useEffect, useRef, MouseEvent, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Button } from '@czechtv/components';
import { timeUnitSecond } from '@czechtv/utils';
import { useMediaBreakpoints } from '../../../../../utils/playerResponsive';
import { formatMessage } from '../../../../../utils/formatMessage';
import { skipVideoButtonClassnames } from './SkipVideoButton.css';

interface SkipVideoButtonProps {
  className?: string;
  onClick: MouseEventHandler;
  pauseCountdown?: boolean;
  waitTime: number;
}

export const SkipVideoButton = memo(
  ({ waitTime, onClick, pauseCountdown, className }: SkipVideoButtonProps) => {
    const [remaining, setRemaining] = useState(0);
    const { isMaxLargeMobile } = useMediaBreakpoints();

    const pausedWaitTime = useRef(waitTime * 1000);

    const handleClick = (e: MouseEvent) => {
      e.stopPropagation();
      onClick(e);
    };

    useEffect(() => {
      if (pauseCountdown) {
        return () => {};
      }

      setRemaining(pausedWaitTime.current);

      const interval = setInterval(() => {
        if (pauseCountdown) {
          return;
        }

        if (pausedWaitTime.current > 0) {
          pausedWaitTime.current -= 100;
          setRemaining(pausedWaitTime.current);
        }
        if (pausedWaitTime.current <= 0) {
          clearInterval(interval);
        }
      }, 100);

      return () => clearInterval(interval);
    }, [pauseCountdown, waitTime]);

    const countingDown = remaining > 0;

    return (
      <>
        {countingDown ? (
          <div
            className={classNames(skipVideoButtonClassnames.wrapper, className)}
            data-testid="SkipVideoPlaceholder"
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onKeyPress={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <span
              className={classNames(skipVideoButtonClassnames.animation, {
                [skipVideoButtonClassnames.pauseAnimation]: pauseCountdown,
              })}
              style={{ animationDuration: `${waitTime * 1000}ms` }}
            ></span>
            <div className={skipVideoButtonClassnames.wrapperTitle}>
              {formatMessage(
                {
                  id: 'SkipVideoButtonWithCountdown',
                  defaultMessage: 'Přeskočit za {remainingTime}',
                  description: 'Přeskočit za (x sekund)',
                },
                {
                  remainingTime: `${Math.ceil(remaining / 1000)} ${
                    isMaxLargeMobile ? 's' : timeUnitSecond(remaining)
                  }`,
                }
              )}
            </div>
          </div>
        ) : null}
        {!countingDown ? (
          <Button
            className={classNames(skipVideoButtonClassnames.button, className)}
            size="large"
            onClick={handleClick}
          >
            {formatMessage({
              id: 'SkipVideoButton',
              defaultMessage: 'Přeskočit',
              description: 'Tlačítko Přeskočit',
            })}
          </Button>
        ) : null}
      </>
    );
  }
);
