import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { DEFAULT_FONT, Grey_20, body16, label14, label16 } from '@czechtv/styles';
import { Breakpoint, getPlayerResponsiveRule } from '../../../../utils/playerResponsive';

interface StreamingStatus {
  contentNotAllowed: boolean;
  isAirplayEnabled: boolean;
  isAirplaySupported: boolean;
  isChromecastEnabled: boolean;
  isChromecastSupported: boolean;
}

const useStyles = createUseStyles({
  text: {
    ...body16,
    margin: [0, 0, 12, 0],
    color: Grey_20,
    '& a': {
      color: Grey_20,
      fontFamily: DEFAULT_FONT,
      fontWeight: 600,
      textDecoration: 'underline',
    },
  },
  tvListTitle: {
    margin: [0, 0, 12, 0],
    ...label14,
    fontWeight: 600,
    '&.large': {
      ...label16,
      fontweight: 600,
    },
    '&.hideOnMobile': {
      [getPlayerResponsiveRule([Breakpoint.isMaxLargeMobile])]: {
        display: 'none',
      },
    },
  },
  tvListContainer: {
    marginTop: 12,
    '&:not(:first-child)': {
      marginTop: 24,
    },
  },
});

// odkaz
const FAQ_HREF = 'https://www.ceskatelevize.cz/mobilni-aplikace/';

// titulek te zpravy o nedostupnosti
const MESSAGE_TITLE = {
  both: 'Přehrát přes Chromecast a AirPlay',
  airplay: 'Přehrát přes AirPlay',
  chromecast: 'Přehrát přes Chromecast',
};

// specialni case, kdy informujeme pouze o tom, ze tohle video neni mozne "castovat"
// stejne jako mobilni apky neumime prehravat timeshift v zivku + audio description
const CONTENT_NOT_SUPPORTED = {
  description: 'Přehrávání přes Chromecast a AirPlay není pro tento obsah dostupné',
  linkText: '',
};

// zbytek kombinaci
const BOTH_DISABLED = {
  // informace
  description: 'Přehrávání přes Chromecast a AirPlay je zatím možné pouze přes ',
  // sklonovani textu odkazu
  linkText: 'mobilní aplikace',
};
const CHROMECAST_ENABLED_AIRPLAY_DISABLED = {
  description: 'Přehrávání přes AirPlay je zatím možné pouze přes ',
  linkText: 'mobilní aplikace',
};
const CHROMECAST_DISABLED_AIRPLAY_ENABLED = {
  description: 'Přehrávání přes Chromecast je zatím možné pouze přes ',
  linkText: 'mobilní aplikace',
};
const CHROMECAST_ENABLED_NOT_SUPPORTED_AIRPLAY_DISABLED = {
  description:
    'Chromecast není podporován v této kombinaci prohlížeče a zařízení. Přehrávání přes AirPlay je zatím možné pouze přes ',
  linkText: 'mobilní aplikace',
};

const CHROMECAST_DISABLED_AIRPLAY_ENABLED_NOT_SUPPORTED = {
  description:
    'AirPlay není podporován v této kombinaci prohlížeče a zařízení. Přehrávání přes Chromecast je zatím možné pouze přes ',
  linkText: 'mobilní aplikace',
};

const CHROMECAST_ENABLED_NOT_SUPPORTED_AIRPLAY_ENABLED_NOT_SUPPORTED = {
  description:
    'Přehrávání přes Chromecast a AirPlay není podporováno v této kombinaci prohlížeče a zařízení. Zkuste jiný prohližeč nebo použijte ',
  linkText: 'mobilní aplikaci',
};

const CHROMECAST_ENABLED_NOT_SUPPORTED_AIRPLAY_ENABLED_SUPPORTED = {
  description:
    'Přehrávání přes Chromecast není podporováno v této kombinaci prohlížeče a zařízení. Zkuste jiný prohližeč nebo použijte ',
  linkText: 'mobilní aplikaci',
};

const CHROMECAST_ENABLED_SUPPORTED_AIRPLAY_ENABLED_NOT_SUPPORTED = {
  description:
    'Přehrávání přes AirPlay není podporováno v této kombinaci prohlížeče a zařízení. Zkuste jiný prohližeč nebo použijte ',
  linkText: 'mobilní aplikaci',
};

const getMessages = (status: StreamingStatus) => {
  const {
    isChromecastEnabled,
    isChromecastSupported,
    isAirplayEnabled,
    isAirplaySupported,
    contentNotAllowed,
  } = status;

  if (!isChromecastEnabled && !isAirplayEnabled) {
    return { title: MESSAGE_TITLE.both, ...BOTH_DISABLED };
  }

  if (contentNotAllowed) {
    return { title: MESSAGE_TITLE.both, ...CONTENT_NOT_SUPPORTED };
  }

  if (isChromecastEnabled && !isAirplayEnabled) {
    if (!isChromecastSupported) {
      return { title: MESSAGE_TITLE.both, ...CHROMECAST_ENABLED_NOT_SUPPORTED_AIRPLAY_DISABLED };
    }
    return { title: MESSAGE_TITLE.airplay, ...CHROMECAST_ENABLED_AIRPLAY_DISABLED };
  }

  if (!isChromecastEnabled && isAirplayEnabled) {
    if (!isAirplaySupported) {
      return {
        title: MESSAGE_TITLE.both,
        ...CHROMECAST_DISABLED_AIRPLAY_ENABLED_NOT_SUPPORTED,
      };
    }
    return { title: MESSAGE_TITLE.chromecast, ...CHROMECAST_DISABLED_AIRPLAY_ENABLED };
  }

  if (isChromecastEnabled && isAirplayEnabled) {
    if (!isChromecastSupported && !isAirplaySupported) {
      return {
        title: MESSAGE_TITLE.both,
        ...CHROMECAST_ENABLED_NOT_SUPPORTED_AIRPLAY_ENABLED_NOT_SUPPORTED,
      };
    }
    if (!isChromecastSupported) {
      return {
        title: MESSAGE_TITLE.chromecast,
        ...CHROMECAST_ENABLED_NOT_SUPPORTED_AIRPLAY_ENABLED_SUPPORTED,
      };
    }
    if (!isAirplaySupported) {
      return {
        title: MESSAGE_TITLE.airplay,
        ...CHROMECAST_ENABLED_SUPPORTED_AIRPLAY_ENABLED_NOT_SUPPORTED,
      };
    }
  }

  return null;
};

export const CastMessage = (status: StreamingStatus): ReactNode | null => {
  const classes = useStyles();
  const {
    contentNotAllowed,
    isChromecastEnabled,
    isChromecastSupported,
    isAirplayEnabled,
    isAirplaySupported,
  } = status;

  const messages = getMessages({
    contentNotAllowed,
    isChromecastEnabled,
    isChromecastSupported,
    isAirplayEnabled,
    isAirplaySupported,
  });

  if (!messages) {
    return null;
  }

  return (
    <div className={classes.tvListContainer}>
      <p className={classes.tvListTitle}>{messages.title}</p>
      <p className={classes.text}>
        {messages.description}
        {!contentNotAllowed && (
          <a href={FAQ_HREF} rel="noreferrer" target="_blank">
            {messages.linkText}
          </a>
        )}
      </p>
    </div>
  );
};
