export const getCzechDay = (day: string): string | null => {
  switch (day) {
    case '1':
      return 'v pondělí';
    case '2':
      return 'v úterý';
    case '3':
      return 've středu';
    case '4':
      return 've čtvrtek';
    case '5':
      return 'v pátek';
    case '6':
      return 'v sobotu';
    case '0':
      return 'v neděli';
    default:
      return null;
  }
};
