export enum CaptionFontSize {
  default = 'default',
  large = 'large',
  small = 'small',
}

type FontSizeDetails = {
  baseFullScreenFontSize: number;
  minFontSize: number;
};

export const CLOSED_CAPTION_FONT_SIZES: Record<CaptionFontSize, FontSizeDetails> = {
  [CaptionFontSize.default]: {
    baseFullScreenFontSize: 47,
    minFontSize: 13,
  },
  [CaptionFontSize.small]: {
    baseFullScreenFontSize: 39,
    minFontSize: 12,
  },
  [CaptionFontSize.large]: {
    baseFullScreenFontSize: 58,
    minFontSize: 15,
  },
};

export enum CaptionColorVariant {
  blackText = 'blackText',
  default = 'default',
  noBackground = 'noBackground',
  yellowText = 'yellowText',
}

type ColorDetails = {
  background: string;
  text: string;
  textShadow?: string;
};

export const CLOSED_CAPTION_COLOR_VARIANTS: Record<CaptionColorVariant, ColorDetails> = {
  [CaptionColorVariant.default]: {
    text: '#fff',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  [CaptionColorVariant.blackText]: {
    text: '#000',
    background: 'rgba(255, 255, 255, 0.8)',
  },
  [CaptionColorVariant.yellowText]: {
    text: 'yellow',
    background: '#000',
  },
  [CaptionColorVariant.noBackground]: {
    text: '#fff',
    background: 'none',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
  },
};

export const getClosedCaptionFontSize = (
  videoElement: HTMLVideoElement,
  variant: CaptionFontSize
) => {
  const { baseFullScreenFontSize, minFontSize } = CLOSED_CAPTION_FONT_SIZES[variant];
  // používáme šířku kvůli fullscreenu v portrait modu na Androidu
  // tam by se počítaly titulky dle výšky a byly by moc velké
  const ratio = baseFullScreenFontSize / 1920;
  const width = videoElement.offsetWidth;
  return Math.max(ratio * width, minFontSize);
};

export const getClosedCaptionColorVariant = (
  variant: CaptionColorVariant = CaptionColorVariant.default
) => {
  return CLOSED_CAPTION_COLOR_VARIANTS[variant];
};
