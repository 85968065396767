import { PlayerVariantEnum } from '../../../constants';

export const THUMBNAIL_SPAN = 5;

enum AspectRatio {
  ratio16by9 = '16 : 9',
  ratio4by3 = '4 : 3',
}

export const setAspectRatio = (
  imageUrl: string,
  setAspectRatio: React.Dispatch<React.SetStateAction<string>>
): void => {
  let origHeight;
  let origWidth;
  const image = new Image();
  image.src = imageUrl;

  image.onload = () => {
    origHeight = image.naturalHeight;
    origWidth = image.naturalWidth;
    if ((origWidth * 3) / 4 === origHeight) {
      setAspectRatio(AspectRatio.ratio4by3);
    } else {
      // (origWidth * 9) / 16 === origHeight
      setAspectRatio(AspectRatio.ratio16by9);
    }
  };
};

export const setImageSize = (
  aspectRatio: string | null | undefined,
  setWidth: React.Dispatch<React.SetStateAction<number>>,
  setHeight: React.Dispatch<React.SetStateAction<number>>
): void => {
  if (aspectRatio === AspectRatio.ratio4by3) {
    setHeight(90);
    setWidth(120);
  } else {
    // '16 : 9'
    setHeight(90);
    setWidth(160);
  }
};

export const getImageNumberVOD = (timestamp: number) =>
  timestamp < 0 ? 0 : Math.floor(timestamp / (100 * THUMBNAIL_SPAN));

export const getImageNumber = (timestamp: number) => Math.floor(timestamp / 500) * 500;

export const currentImage = (playerVariant: PlayerVariantEnum, timestamp: number) => {
  if (playerVariant === PlayerVariantEnum.VOD) {
    return getImageNumberVOD(timestamp);
  }
  return getImageNumber(timestamp);
};

export const getThumbnailPositionInImage = (timestamp: number) =>
  Math.floor(timestamp / THUMBNAIL_SPAN) % 100;

export const getThumbnailLeftPosition = (positionInImage: number, width: number) =>
  (positionInImage % 10) * width;

export const getThumbnailTopPosition = (positionInImage: number, height: number) =>
  Math.floor(positionInImage / 10) * height;
