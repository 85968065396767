import classNames from 'classnames';
import React, { memo } from 'react';
import { commonFormElementClassnames, commonFormTextElementClassnames } from '../../common.css';
import { ValidationIcon } from '../../ValidationIcon/ValidationIcon';
import { textInputClassnames } from './TextInput.css';

interface TextInputExtraProps {
  inverted?: boolean;
  isValid?: boolean;
  maxWidth?: number | string;
  showValidationIcon?: boolean;
  width?: number | string;
}

export interface TextInputProps
  extends TextInputExtraProps,
    Omit<React.HTMLProps<HTMLInputElement>, 'aria-invalid'> {}

export const TextInput = memo(
  React.forwardRef<HTMLInputElement, TextInputProps>(
    (
      {
        className,
        width,
        maxWidth,
        type = 'text',
        inverted = false,
        isValid,
        showValidationIcon = true,
        ...restProps
      },
      ref
    ) => {
      const showValidity = isValid !== undefined;

      return (
        <div
          className={classNames(
            commonFormElementClassnames.commonFormElementContainer,
            textInputClassnames.formElementContainer
          )}
          style={{
            width: typeof width !== 'undefined' ? width : '100%',
            maxWidth: typeof maxWidth !== 'undefined' ? maxWidth : '100%',
          }}
        >
          {showValidationIcon && (
            <ValidationIcon
              className={classNames(commonFormTextElementClassnames.commonFormTextElementIcon, {
                showValidity,
              })}
              isValid={isValid}
            />
          )}
          <input
            // přístupnost stavu validace
            aria-invalid={isValid === false}
            className={classNames(
              commonFormElementClassnames.commonFormElement,
              commonFormTextElementClassnames.commonFormTextElement,
              textInputClassnames.formElement,
              className,
              {
                inverted,
              },
              { showValidity }
            )}
            ref={ref}
            type={type}
            {...restProps}
          />
        </div>
      );
    }
  )
);
