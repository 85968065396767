// https://gomakethings.com/debouncing-events-with-requestanimationframe-for-better-performance/
// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function) => {
  let frame: number;

  return (...params: unknown[]) => {
    if (frame) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(() => {
      fn(...params);
    });
  };
};
